import { useState } from "react";
import { useGetFooterContentByCityQuery } from "@/store/services/footerContainer";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import FooterComponent from "./FooterComponent";
import Accordion from "../Accordion";
import { useDataContext } from "@/context/useDataContext";
import { track } from "@/utils/mixpanel/actions";
import { FOOTER_CONTAINER_CLICKED } from "@/utils/mixpanel/Events/footer_container_events";
import { useSelector } from "react-redux";
import { IS_CLIENT_SIDE } from "@/utils/constants";

const FooterContainer = () => {
  const { data: ssrData } = useDataContext();
  const user = useSelector((state: any) => state.user.currentUser);
  const { city } = useCurrentCity();
  const { data } = useGetFooterContentByCityQuery({
    params: {
      "populate[0]": "container",
      "filters[city][$eq]": city,
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
    track(FOOTER_CONTAINER_CLICKED, {
      page: "Product Listing Page",
      city: city,
      name: user?.userName,
      phone_number: user?.phoneNumber,
      date_clicked: IS_CLIENT_SIDE && new Date().toISOString(),
    });
  };

  if (
    (!data ||
      data?.data?.length === 0 ||
      data?.data[0]?.attributes?.container?.length === 0) &&
    (!ssrData?.footerContainer ||
      ssrData?.footerContainer?.length === 0 ||
      ssrData?.footerContainer[0]?.attributes?.container?.length === 0)
  )
    return;

  const footerContainerData =
    data?.data[0]?.attributes?.container ||
    ssrData?.footerContainer[0]?.attributes?.container;

  return (
    <section className="flex flex-col max-w-[60rem] mx-auto w-full gap-y-4 pl-2 pr-2">
      <div className="p-1 pb-8">
        <Accordion
          heading="More about DriveX"
          isOpen={isOpen}
          onToggle={toggleAccordion}
          footerContainer={true}
        >
          {footerContainerData?.map((footer: any) => (
            <div key={footer.id} className="mt-5">
              <h2 className="mb-4 font-bold">{footer.heading}</h2>
              <FooterComponent content={footer.description} />
            </div>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default FooterContainer;
