import ProductCard from "@/components/ProductCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { useCurrentCity } from "@/hooks/useCurrentCity";

const PDPViewed = ({
  data,
  handleProductCardClick,
}: {
  data: any;
  handleProductCardClick: (item: IVehicleInfo) => void;
}) => {
  const { city } = useCurrentCity();

  return (
    <div className="flex flex-col lg:absolute lg:right-0 lg:top-[9rem]">
      {data && (
        <p className="text-white font-semibold text-base">
          Recently viewed by You
        </p>
      )}
      <Carousel className="lg:w-[30rem] xl:w-[45rem] select-none">
        <CarouselContent className="flex p-4 pl-0">
          {data &&
            data?.map((item: any, index: number) => (
              <CarouselItem key={index} className="basis-[340px]">
                <ProductCard
                  className={"h-[24rem]"}
                  product={item.data}
                  handleClick={handleProductCardClick}
                  index={index}
                  returnCustomer={true}
                  showWishList={false}
                  navigateTo={`${city}/buy-two-wheelers/${data[index]?.data?.vehicleId}`}
                />
              </CarouselItem>
            ))}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default PDPViewed;
