import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { LoaderIcon } from "lucide-react";

interface IProps {
  dealersFormData: { name: string; phone_number: string; pincode: string };
  errorMsg: string;
  handleFormInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmitButton: () => void;
  isFormButtonClicked: boolean;
  user: {
    userName: string;
    phoneNumber: string;
  };
}

const DealerForm = ({
  dealersFormData,
  errorMsg,
  handleFormInputChange,
  handleSubmitButton,
  isFormButtonClicked,
  user,
}: IProps) => {
  return (
    <div className="flex flex-col gap-8 bg-white p-4 rounded-lg">
      <div className="flex flex-col gap-4">
        {/* Name */}
        <label htmlFor="name" className="text-sm font-semibold">
          Name
        </label>
        <Input
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            isFormButtonClicked &&
              (dealersFormData.name === "" || errorMsg === "Invalid name") &&
              "bg-red-100",
          )}
          name="name"
          onChange={handleFormInputChange}
          value={dealersFormData?.name}
          id="name"
          placeholder="Enter Name"
          type="text"
          disabled={user?.userName ? true : false}
        />
        {isFormButtonClicked &&
          (dealersFormData.name === "" || errorMsg === "Invalid name") && (
            <p className="text-sm">Please Enter Your Name</p>
          )}

        {/* Phone Number */}
        <label htmlFor="phone_number" className="text-sm font-semibold">
          Phone Number
        </label>
        <Input
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            isFormButtonClicked &&
              (dealersFormData.phone_number === "" ||
                errorMsg === "Invalid number") &&
              "bg-red-100",
          )}
          name="phone_number"
          onChange={handleFormInputChange}
          value={dealersFormData?.phone_number}
          id="phone_number"
          placeholder="Enter Phone Number"
          type="text"
          disabled={user?.phoneNumber ? true : false}
        />
        {isFormButtonClicked &&
          (dealersFormData.phone_number === "" ||
            errorMsg === "Invalid number") && (
            <p className="text-sm">Please Enter Your Number</p>
          )}

        {/* Pincode */}
        <label htmlFor="pincode" className="text-sm font-semibold">
          Enter Pincode
        </label>
        <Input
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            isFormButtonClicked &&
              (dealersFormData.pincode === "" ||
                errorMsg === "Invalid pincode") &&
              "bg-red-100",
          )}
          name="pincode"
          onChange={handleFormInputChange}
          value={dealersFormData?.pincode}
          id="pincode"
          placeholder="Enter Pincode"
          type="text"
        />
        {isFormButtonClicked &&
          (dealersFormData.pincode === "" ||
            errorMsg === "Invalid pincode") && (
            <p className="text-sm">Please Enter Pincode</p>
          )}
      </div>

      <Button
        variant="outline"
        className="bg-primaryA2 border-none text-white text-base flex gap-2 py-6 hover:bg-primaryA2 hover:text-white"
        onClick={handleSubmitButton}
        disabled={isFormButtonClicked}
      >
        Become a partner
        {isFormButtonClicked && errorMsg === "" && (
          <LoaderIcon className="animate-spin" />
        )}
      </Button>
    </div>
  );
};

export default DealerForm;
