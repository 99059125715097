export const NUDGE_DELAY = 1000;
export const DEBOUNCE_DELAY = 200;
export const NAVBAR_HEIGHT = 128;
export const DESKTOP_NAVBAR_HEIGHT = 74;
export const EMI_BANNER_INDEX = 3;
export const EMI_BANNER_TABLET_INDEX = 6;
export const EMI_BANNER_DESKTOP_INDEX = 9;
export const DESKTOP_LISTING_PAGE_SIZE = 9;
export const MOBILE_LISTING_PAGE_SIZE = 10;
export const CAROUSEL_IMAGE_UPDATE_INTERVAL = 4000;
export const BOTTOM_NAVIGATION_HEIGHT = "56px";
export const TIME_SLOTS = [
  { timeOfDay: "Morning", time: "10am - 12pm" },
  { timeOfDay: "Afternoon", time: "12pm - 4pm" },
  { timeOfDay: "Evening", time: "4pm - 8pm" },
];
export const RATE_OF_INTEREST_DEFAULT_VALUE = 14.5;
export const MIN_RATE_OF_INTEREST = 12;
export const MAX_RATE_OF_INTEREST = 15;
export const DEFAULT_DOWN_PAYMENT_VALUE = 10; // meaning 10% of total price
export const DEFAULT_TENURE_VALUE = 60; // in months
export const MINIMUM_DOWN_PAYMENT = 10; // meaning 10% of total price
export const MINIMUM_TENURE = 12; // in months
export const MAXIMUM_TENURE = 60; // in months
export const FILTER_CHIPS_HEIGHT = 47;
export const NAVBAR_WITH_FILTER_CHIPS = `${NAVBAR_HEIGHT + FILTER_CHIPS_HEIGHT}px`;

export const supportContactNumber = "08069575175";
export const supportContactEmail = "cso@drivex.in";
export const dealerSupportContactNumber = "9731041911";
export const ContactUsSupportEmail = "support@drivex.in"; //Used only in contact us page
export const PLACEHOLDER_TEXTS = ["TVS", "Hero", "Bajaj", "Yamaha", "Suzuki"];
export const PLACEHOLDER_TEXT_INTERVAL = 1800;
export const LOCALSTORAGE_EXPIRY_TIME_FOR_FILTER = 5; // in minutes
export const CIN_NUMBER = "U63040TZ2020PTC033680";

// Constants for slicing the initial and additional cities for display
export const INITIAL_CITIES_COUNT = 6;

export const PLP_SKELETON_LOADER_DELAY = 1000;
export const PLP_FILTERS_DEBOUNCE_VALUE = 300;
export const LOAD_MORE_DELAY = 1000;

export const OTP_INPUT_SLOTS = 4;

export enum EnquiryType {
  STORE_ENQUIRY = "STORE_ENQUIRY",
  VEHICLE_ENQUIRY = "VEHICLE_ENQUIRY",
}

export const PROCUREMENT_YEAR_LIMIT = 9; // Maximum age of a vehicle in years for procurement

export const CAREERS_URL = "https://drivex.zohorecruit.com/jobs/Careers";

export const AUTO_SWIPE_INTERVAL = 5000;

export const STORY_DURATION = 10;
export const STORY_PROGRESS_BAR_UPDATE_INTERVAL = 100; // in miliseconds

export const IMAGE_KIT_BASE_URL = "https://ik.imagekit.io/drivex";
export const S3_BASE_URL = "https://s3.drivex.dev";
export const DMS_ASSETS_BASE_URL = "https://dms-assets.drivex.in/images";

export const DISABLE_IMAGE_KIT = false;

export const IS_CLIENT_SIDE = typeof window !== "undefined";

export const cities = [
  "Bangalore",
  "Chennai",
  "Coimbatore",
  "New-Delhi",
  "Patna",
  "Nashik",
  "Aruppukottai",
  "Davanagere",
  "Hassan",
  "Holenarasipura",
  "Hosur",
  "Kallakurichi",
  "Karaikudi",
  "Kolar",
  "Kumbakonam",
  "Madurai",
  "Pollachi",
  "Puducherry",
  "Salem",
  "Shrirampur",
  "Villupuram",
];

// regex-safe city pattern from the cities array
const cityPattern = cities?.map((city) => city.replace(/-/g, "\\-")).join("|");

export const productListingRegex = new RegExp(
  `^\\/(${cityPattern})\\/buy-two-wheelers\\/?(?:\\?.*)?$`,
); // /Bangalore/buy-two-wheelers
export const productDetailRegex = new RegExp(
  `^\\/(${cityPattern})\\/buy-two-wheelers\\/\\d+\\/?$`,
); // /Bangalore/buy-two-wheelers/6924
export const storePageRegex = new RegExp(
  `^\\/(${cityPattern})\\/[a-zA-Z-]+\\/?$`,
); // /Bangalore/drivex-greenage-motors
export const blogDetailRegex = /^\/blogs\/[a-zA-Z-]+\/?$/; // /blogs/slug

export const staticFileExts =
  /\.(xml|css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$/;

export const allowedSSRPaths = [
  { path: "/", type: "home_page" },
  { path: productListingRegex, type: "plp" },
  { path: productDetailRegex, type: "pdp" },
  { path: "/sell-two-wheelers", type: "sell_page" },
  { path: blogDetailRegex, type: "blog_details" },
  { path: "/blogs", type: "blogs_listing" },
];
export const SSR_CACHE_TTL = 10 * 60; // 10 minutes
export const DISCOUNT_MAX_VALUE = 2500;

export const DropdownOpenDelay = 100;

export const isDealerMode = IS_CLIENT_SIDE && localStorage?.getItem("role");
