import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getFetchBaseQuery: any = () => {
  const baseUrl = import.meta.env.VITE_API_URL; // update this with drivex url

  return {
    baseUrl,
    credentials: "include",
    prepareHeaders: () => {},
  };
};

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery(getFetchBaseQuery()),
  tagTypes: [],
  keepUnusedDataFor: 0, // setting the cache time to 5 minutes for all the endpoints under this base url
  endpoints: () => ({}),
});

export default api;
