interface SortContentType {
  id: number;
  sortText: string;
  sort: string;
  sortBy: string;
}

export const sortContent: SortContentType[] = [
  { id: 0, sortText: "Newest First", sort: "createdOn", sortBy: "desc" },
  { id: 1, sortText: "Price - Low to High", sort: "price", sortBy: "asc" },
  { id: 2, sortText: "Price - High to Low", sort: "price", sortBy: "desc" },
  {
    id: 3,
    sortText: "KMs driven - High to Low",
    sort: "kmDriven",
    sortBy: "desc",
  },
  {
    id: 4,
    sortText: "KMs driven - Low to High",
    sort: "kmDriven",
    sortBy: "asc",
  },
  {
    id: 5,
    sortText: "Year - New to Old",
    sort: "manufactureYear",
    sortBy: "desc",
  },
];
