import { Link, useLocation } from "react-router-dom";
import { DmxMoreOptions, moreOptions } from "./data";
import { cn } from "@/lib/utils";

interface IProps {
  dealerMode: boolean;
}

const MoreOptions = ({ dealerMode }: IProps) => {
  const location = useLocation();
  const allOptions = dealerMode ? DmxMoreOptions : moreOptions;

  return (
    <>
      {allOptions.map((options, index) => {
        const isActive = location.pathname === options.link; // Check if the current location matches the option link
        return (
          <Link
            data-test-id={`sidebar-${options.text.replaceAll(" ", "-").toLowerCase()}`}
            key={index}
            className={cn(
              "flex items-center gap-3 p-4 px-2",
              isActive && dealerMode && "bg-neutral30 rounded-lg",
            )}
            to={options.link}
          >
            {dealerMode ? options.icon(isActive) : options.icon}
            <p className="font-semibold text-sm">{options.text}</p>
          </Link>
        );
      })}
    </>
  );
};

export default MoreOptions;
