import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";

function ServiceMiniCard({
  service,
  vehicleName,
  className,
  containerRef,
  handleOrderedVehicleCardClick,
}: {
  service: IService;
  vehicleName: string;
  className?: string;
  containerRef?: React.FC<any>;
  handleOrderedVehicleCardClick?: (transaction_Id: number | undefined) => void;
}) {
  return (
    <div
      ref={containerRef}
      className={cn("flex gap-4 items-center", className)}
      onClick={() =>
        handleOrderedVehicleCardClick &&
        handleOrderedVehicleCardClick(service?.transaction_id)
      }
    >
      <>
        <Image
          className="w-[72px] h-[72px] rounded-lg object-cover"
          src={service.image_url}
          alt="service"
        />

        <div className="flex flex-col gap-1">
          <p className="text-sm line-clamp-1">{service?.name}</p>
          <p className="text-xs text-neutral300">{vehicleName}</p>
          <p className="text-sm text-primaryA2">Starts at ₹{service?.price}*</p>
        </div>
      </>
    </div>
  );
}

export default ServiceMiniCard;
