import { TickIconFilled } from "@/assets/Icons";

const DealerFormSubmitted = ({ dealersName }: { dealersName: string }) => {
  return (
    <div className="flex flex-col gap-4 p-4 py-5 text-center items-center bg-white rounded-lg">
      <TickIconFilled size={50} oneStopColor="#5FA74E" twoStopColor="#5FA74E" />
      <p className="font-semibold text-lg">
        Hi {dealersName}, your query has been recorded successfully!
      </p>
      <p className="text-sm font-medium text-neutral300">
        Our DriveX executive will contact you shortly with further information
        on your query.
      </p>
    </div>
  );
};

export default DealerFormSubmitted;
