import { cn } from "@/lib/utils";
import React from "react";

interface Iprops {
  name: string;
  leftHalf?: React.ReactNode | null;
  rightHalf?: React.ReactNode | null;
  className: string;
  childClassName?: string;
  handleClick?: () => void;
  id?: string;
}

const Pill = ({
  handleClick,
  name,
  leftHalf = null,
  rightHalf = null,
  className,
  childClassName,
  id,
}: Iprops) => {
  return (
    <div
      id={id}
      className={cn(
        "flex-center gap-2 rounded-md p-1 px-2 text-sm w-fit whitespace-nowrap border border-[neutral50]",
        className,
      )}
      onClick={handleClick}
    >
      {leftHalf}

      <p className={cn("text-neutral300", childClassName)}>{name}</p>

      {rightHalf}
    </div>
  );
};

export default Pill;
