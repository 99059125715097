export function checkAllValuesWithType(
  obj: { [key: string]: any },
  type = undefined,
): boolean {
  return Object.values(obj).every((value) => value === type);
}

export function deepEqual(
  obj1: { [x: string]: any } | null,
  obj2: { [x: string]: any } | null,
) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 != "object" ||
    typeof obj2 != "object" ||
    obj1 == null ||
    obj2 == null
  )
    return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

export const isArrayOfStrings = (data: any[]) => {
  return Array.isArray(data) && data.some((item) => typeof item === "string");
};

export function deepCopy(value: any): any {
  // Handle primitive values (numbers, strings, booleans, etc.)
  if (value === null || typeof value !== "object") {
    return value;
  }

  // Handle Arrays
  if (Array.isArray(value)) {
    const arrayCopy = [];
    for (let i = 0; i < value.length; i++) {
      arrayCopy[i] = deepCopy(value[i]);
    }
    return arrayCopy;
  }

  // Handle Objects (including plain objects and non-array types)
  if (typeof value === "object") {
    const objectCopy = {};
    for (const key in value) {
      // eslint-disable-next-line
      if (value.hasOwnProperty(key)) {
        // eslint-disable-next-line
        // @ts-ignore
        objectCopy[key] = deepCopy(value[key]);
      }
    }
    return objectCopy;
  }

  // For any other types (like functions, React elements, etc.), return the original value
  return value;
}
