import React, { forwardRef } from "react";
import { getTransformedSrc } from "@/utils/transformedSrc";

type ImageProps = {
  src: string;
  alt: string;
  transform?: string;
  className?: string;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, alt, transform, className, ...props }, ref) => {
    const { transformedSrc } = getTransformedSrc(src, transform);

    return (
      <img
        src={transformedSrc}
        alt={alt}
        className={className}
        loading="lazy"
        ref={ref}
        {...props}
      />
    );
  },
);

Image.displayName = "Image";

export default Image;
