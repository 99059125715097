export const DX_PDP_EDIT_DETAILS_BUTTON_CLICKED = "DX_Edit Details";
export const DX_EDIT_DETAILS = "DX_Edit Details";
export const DX_SELL_OR_RESERVE = "DX_Sell or Book";
export const DX_DOWNLOAD_QR_CODE = "DX_Download QR Code";
export const DX_PDP_VIEW = "DX_View Vehicle Page ";
export const DX_CUSTOMER_DETAILS_SUBMITTED = "DX_Customer Details Submitted";
export const DX_CUSTOMER_VERIFICATION_SUCCESSFUL =
  "DX_Customer Verification Successful";
export const DX_DOCUMENT_SHARED = "DX_Document Shared";
export const DX_DOCUMENT_DOWNLOADED = "DX_Document Downloaded";
export const DX_BOOKING_CANCELLED = "DX_Booking Cancelled";
