export const HOME_EXPLORE_ALL_EVENT = "explore_all_clicked";
export const HOME_BRAND_CLICKED = "brand_clicked";
export const HOME_BUDGET_CLICKED = "budget_clicked";
export const HOME_BUY_CLICKED_EVENT = "buy_clicked";
export const HOME_SELL_CLICKED_EVENT = "sell_clicked";
export const HOME_SERVICE_CLICKED_EVENT = "service_clicked";
export const HOME_STORY_OPENED = "story_opened";
export const HOME_STORY_LINK_CLICKED = "story_link_clicked";
export const HOME_STORY_CLOSED = "story_closed";
export const HOME_STORY_VIEWED = "story_viewed";
