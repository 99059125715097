import React from "react";
import { Skeleton } from "@/components/ui/skeleton";

function VehicleSoldSuccessSkeleton() {
  return (
    <div className="p-2 mt-20 flex-center flex-col">
      <Skeleton className="w-44 h-4 rounded-xl shadow-sm bg-neutral-200" />
      <Skeleton className="w-44 h-10 rounded-xl shadow-sm bg-neutral-200 mt-4" />

      <div className="flex items-center border-y py-4 mt-10 w-full">
        <Skeleton className="w-[92px] h-[64px] rounded-lg object-cover bg-neutral-200" />

        <div className="flex flex-col gap-1 ml-2 w-full">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton
              key={index}
              className="w-full h-4 rounded-xl shadow-sm bg-neutral-200"
            />
          ))}
        </div>
      </div>

      <div className="flex items-center py-4 mt-2 w-full border-b">
        <div className="flex flex-col gap-4 w-full">
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton
              key={index}
              className="w-full h-6 rounded-xl shadow-sm bg-neutral-200"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default VehicleSoldSuccessSkeleton;
