import AvailabilityData from "@/components/AvailabilityData";
import { sellingSteps } from "../data";
import Vahan from "./Vahan";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const HeroSection = () => {
  const { isMobile } = useScreenDetector();

  return (
    <section className="flex flex-col md:flex-row gap-4 md:min-h-[600px] max-w-7xl mx-auto w-full justify-between items-center">
      <div className="flex flex-col flex-1 gap-6 md:gap-10">
        <>
          <h1 className="text-white text-center text-2xl md:text-4xl md:text-start md:leading-[52px] font-semibold">
            Sell your two-wheeler <br /> at the{" "}
            <span className="text-primaryA2">Best Price</span>
          </h1>
          <div className="flex justify-center md:justify-start flex-wrap gap-10">
            {sellingSteps.map((step, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row items-center gap-2 md:gap-4"
              >
                <span className="w-12 h-12 bg-accent rounded-full flex-center">
                  {step.icon}
                </span>
                <p className="font-semibold text-sm md:text-lg text-white text-center lg:text-start">
                  {step.step}
                </p>
              </div>
            ))}
          </div>
        </>

        {!isMobile && (
          <div className="flex justify-between text-white text-center max-w-[500px]">
            <AvailabilityData />
          </div>
        )}
      </div>

      <Vahan />

      {isMobile && (
        <div className="flex justify-between text-white text-center w-full">
          <AvailabilityData />
        </div>
      )}
    </section>
  );
};

export default HeroSection;
