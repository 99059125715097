import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import React, { useState } from "react";
import Form from "./Form";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useSelector, useDispatch } from "react-redux";
import { DialogContent } from "@/components/ui/dialog";
import {
  fetchUserDetailsAndUpdateState,
  updateCurrentUser,
} from "@/store/services/authApi";
import { setCurrentUser } from "@/store/features/userSlice";
import { useToast } from "@/components/ui/use-toast";
import { isValidEmail } from "@/utils/validation";

interface IProps {
  setOpenProfileEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfile = ({ setOpenProfileEdit }: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const dispatch = useDispatch();
  const { toast } = useToast();
  const [activateButton, setActivateButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    full_name: user?.userName,
    email: user?.email,
  });
  const [emailValidation, setEmailValidation] = useState("");

  const handleSubmitClick = async () => {
    setIsLoading(true);
    setActivateButton(true);

    if (!isValidEmail(formData.email)) {
      setEmailValidation("Please Enter a valid email");
      setIsLoading(false);
      setActivateButton(true);
      return;
    } else {
      setEmailValidation("");
    }

    await updateCurrentUser(formData);
    await fetchUserDetailsAndUpdateState(dispatch, setCurrentUser);
    toast({
      title: "Updated successfully",
      variant: "success",
    });
    setIsLoading(false);
    setOpenProfileEdit(false);
  };

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setActivateButton(false);
  };

  return (
    <DialogContent className="flex flex-col gap-12 p-4 h-svh">
      <div className="flex gap-3" onClick={() => setOpenProfileEdit(false)}>
        <LeftIcon />
        <p>Edit details</p>
      </div>

      <div className="flex flex-col justify-between h-full">
        <Form
          user={user}
          formData={formData}
          handleFormInput={handleFormInput}
          emailValidation={emailValidation}
        />
        <Button
          variant="outline"
          className={cn(
            "w-full bg-primaryA2 border-none text-white hover:bg-primaryA2 flex gap-2 hover:text-white text-base py-6",
          )}
          onClick={handleSubmitClick}
          disabled={activateButton}
        >
          Save Changes {isLoading && <LoaderIcon className="animate-spin" />}
        </Button>
      </div>
    </DialogContent>
  );
};

export default EditProfile;
