import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NAVBAR_HEIGHT } from "@/utils/constants.ts";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils.ts";
import { track } from "@/utils/mixpanel/actions.ts";
import {
  NAVBAR_SEARCH_BAR_EVENT,
  NAVBAR_LOCATION_EVENT,
  NAVBAR_HAMBURGER_MENU_EVENT,
  NAVBAR_LOGO_EVENT,
} from "../../utils/mixpanel/Events/navbar_clicked_events.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import MobileNavbar from "./MobileNavbar.tsx";
import DesktopNavbar from "./DesktopNavbar.tsx";
import Sidebar from "../Sidebar/index.tsx";
import { useGetStoresQuery } from "@/store/services/Dmx/storeVehicles.tsx";
import { setStore } from "@/store/features/storeSlice.ts";
import { getPlaceholder } from "@/utils/url.ts";

interface IProps {
  backgroundColor?: string;
  showLocation?: boolean;
  showSearchbar?: boolean;
  showLinks?: boolean;
  disableLocation?: boolean;
  showStores?: boolean;
  disableStore?: boolean;
  showDmxLogo?: boolean;
  showRecentSearches?: boolean;
  showPopularSearches?: boolean;
  showPopularBrands?: boolean;
}

const Navbar = ({
  backgroundColor,
  showLocation = true,
  showSearchbar = true,
  showLinks = true,
  disableLocation = false,
  showStores = false,
  disableStore = false,
  showDmxLogo = false,
  showRecentSearches = true,
  showPopularSearches = true,
  showPopularBrands = true,
}: IProps) => {
  const [showNudge, setShowNudge] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openSearch, setOpenSearch] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const dispatch = useDispatch();
  let { location } = useSelector((state: any) => state.location);
  location = location?.replaceAll("-", " ");
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const pathURL = useLocation();
  const pageSource = getPageSource(pathURL.pathname);
  const { data: stores } = useGetStoresQuery({}, { skip: !showStores });
  const storesData = stores?.data?.stores;
  const dmxSearchQuery = searchParams.get("q");

  useEffect(() => {
    if (storesData && storesData.length > 0) {
      const storedStore = JSON.parse(
        localStorage.getItem("current_store_dmx") || "null",
      );
      if (!storedStore) {
        // If no store is in localStorage, select the first store
        handleStoreChange(storesData[0].store_id);
      }
    }
  }, [storesData]);

  useEffect(() => {
    if (searchParams.get("search") === "true") {
      setOpenSearch(true);
    }
  }, [searchParams]);

  const handleAccountClick = () => {
    setOpenSidebar(true);
  };

  const handleSearchbarClick = () => {
    setOpenSearch(true);
    track(NAVBAR_SEARCH_BAR_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    if (pathURL.search) {
      navigate(`${pathURL.search}&search=true`);
    } else {
      navigate("?search=true");
    }
  };

  const handleSelectCity = () => {
    setShowNudge(false);
    setOpenLocation(true);
    track(NAVBAR_LOCATION_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleHamburgerClick = () => {
    track(NAVBAR_HAMBURGER_MENU_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setOpenSidebar(true);
  };

  const handleLogoClick = () => {
    track(NAVBAR_LOGO_EVENT, {
      url: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });

    if (showDmxLogo) {
      navigate("/dealer/vehicles");
    } else {
      navigate("/");
    }
  };

  const handleStoreChange = (value: string) => {
    if (dmxSearchQuery) {
      searchParams.delete("q");
      setSearchParams(searchParams);
    }
    const selectedStore = storesData?.find(
      (store: { store_id: number }) => store.store_id === Number(value),
    );
    dispatch(setStore(selectedStore));
  };

  const commonProps = {
    backgroundColor: backgroundColor as string,
    handleLogoClick,
    handleSearchbarClick,
    handleSelectCity,
    location,
    openLocation,
    setOpenLocation,
    setShowNudge,
    showNudge,
    showLocation,
    showSearchbar,
    openSearch,
    setOpenSearch,
    showStores,
    storesData,
    handleStoreChange,
    showDmxLogo,
    showRecentSearches,
    showPopularSearches,
    showPopularBrands,
    placeholder: getPlaceholder(),
    disableStore,
  };

  return (
    <div
      className={cn(
        `flex flex-col px-4 py-4 fixed w-full z-10 navbar transition duration-300 ease max-h-[${NAVBAR_HEIGHT}px] z-20`,
        backgroundColor,
      )}
    >
      <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      <div className="hidden md:block">
        <DesktopNavbar
          {...commonProps}
          handleAccountClick={handleAccountClick}
          showLinks={showLinks}
          disableLocation={disableLocation}
          user={user}
        />
      </div>
      <div className="md:hidden">
        <MobileNavbar
          {...commonProps}
          handleHamburgerClick={handleHamburgerClick}
        />
      </div>
    </div>
  );
};

export default Navbar;
