import Navbar from "@/components/Navbar";
import errorImg from "../../assets/svg/ErrorPage/error404.svg";
import { Button } from "../../components/ui/button";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { redirectionRoutes } from "@/utils/url";
import Image from "@/components/ui/image";

interface Iprops {
  onFallback: boolean;
}

const HOMEPAGE_BASE_URL = import.meta.env.VITE_URL;
let sentryResponse = false;
const ErrorPage = ({ onFallback }: Iprops) => {
  if (onFallback) sentryResponse = onFallback;

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/$/, "");

  useEffect(() => {
    if (!redirectionRoutes.find((rule) => rule.from === currentPath)) {
      navigate("/", { replace: true });
    }
  }, [navigate]);

  return (
    <div className="flex flex-col gap-4 relative">
      {!sentryResponse && <Navbar backgroundColor="bg-white" />}

      <div className="flex flex-col justify-center items-center mt-40">
        <div className="flex flex-col justify-center items-center">
          <Image
            className="errorImg"
            src={errorImg as string}
            alt="SomeThing Went Wrong"
          />
        </div>
        <div className="flex flex-col justify-center items-center text-center px-10 py-4">
          {location.pathname.startsWith("/error/") || sentryResponse ? (
            <>
              <h4 className="text-black text-xl font-bold">
                Oops, the bike&apos;s in repair mode!
              </h4>
              <h6 className="text-neutral300 text-sm">
                Let&apos;s rewind to the last junction. We&apos;re fine-tuning
                the gears.
              </h6>
            </>
          ) : (
            <>
              <h4 className="text-black text-xl font-bold">
                Lost in the bike lane
              </h4>
              <h6 className="text-neutral300 text-sm mt-4">
                Oops, looks like you took a wrong turn. Let&apos;s steer you
                back to our amazing two-wheelers collection at{" "}
                <a href="/" className="text-orange-500">
                  {HOMEPAGE_BASE_URL}
                </a>
              </h6>
            </>
          )}
        </div>
        <div className="flex gap-4">
          <Button
            className="p-2 px-8 rounded-md bg-primaryA2 border-none text-white mt-8"
            onClick={() => (window.location.href = "/")}
          >
            Go Home
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ErrorPage;
