import Brand from "@/components/Brand";
import { brandData } from "./data.tsx";
import { track } from "@/utils/mixpanel/actions";
import { HOME_BRAND_CLICKED } from "@/utils/mixpanel/Events/home_clicked_events";
import { useNavigate } from "react-router-dom";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import { useSelector } from "react-redux";

const ShopByBrand = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const pageSource = getPageSource("/");
  const { city } = useCurrentCity();
  const handleBrandClick = (brandName: string) => {
    track(HOME_BRAND_CLICKED, {
      url: pageSource,
      brand: brandName,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });

    if (brandName.toLowerCase() === "hero") {
      navigate(
        `/${city}/buy-two-wheelers?f=makeModel:${encodeURIComponent("Hero Motors")}`,
        { state: { from: "home" } },
      );
    } else {
      navigate(
        `/${city}/buy-two-wheelers?f=makeModel:${encodeURIComponent(brandName)}`,
        { state: { from: "home" } },
      );
    }
  };

  return (
    <div className="flex flex-col gap-5 max-w-screen-lg mx-auto w-full">
      <p className="text-xl md:text-[28px] md:text-center">
        Shop by <span className="text-primaryA2">Brand</span>
      </p>

      <div className="flex gap-4 flex-wrap justify-center mt-4">
        {brandData.map((brand, index) => (
          <Brand
            key={index}
            brand={brand}
            className="px-9 max-w-[45%] lg:max-w-[15%]"
            handleClick={() => handleBrandClick(brand.name)}
          />
        ))}
      </div>
    </div>
  );
};

export default ShopByBrand;
