import Brand from "@/components/Brand";
import { useCallback, useEffect } from "react";
import { cn } from "@/lib/utils";
import { SelectedCurrentStepComponentProps } from "../../data";
import { getModelAndVariant } from "@/store/services/scheduleApi";
import { useSelector } from "react-redux";
import { IBrand, useGetBrands } from "../../HeroSection/ServiceInfo/data";
import { useLocation } from "react-router-dom";

type ExtraProps = {
  setModels: any;
};

function SelectBrand({
  serviceFlowData,
  setModels,
  setSearchParams,
  setServiceFlowData,
  type,
}: SelectedCurrentStepComponentProps & ExtraProps) {
  const location = useLocation();
  const locationState = location.state as {
    store_id?: string;
    store_address?: string;
  };
  const storeId = locationState?.store_id;
  const storeAddress = locationState?.store_address;
  const { allBrands } = useSelector((state: any) => state.allBrands);
  const { getBrands } = useGetBrands();

  useEffect(() => {
    if (!allBrands) {
      getBrands();
    }
  }, []);

  const retrieveServiceCenterData = () => {
    if (storeId && storeAddress) {
      setServiceFlowData({
        steps: {
          ...serviceFlowData.steps,
        },
        store_id: storeId,
        store_address: storeAddress,
      });
    }
  };

  useEffect(() => {
    retrieveServiceCenterData();
  }, [storeId, storeAddress]);

  const handleBrandClick = useCallback(
    async (brandName: string) => {
      const res = await getModelAndVariant(brandName);
      if (res.status === "OK") {
        setModels(res.data);
        setSearchParams({ step: "year", type });
        setServiceFlowData({
          steps: {
            ...serviceFlowData.steps,
            brand: brandName,
          },
          store_id: serviceFlowData.store_id,
          store_address: serviceFlowData.store_address,
        });
      }
    },
    [type, serviceFlowData],
  );

  if (!allBrands) return;

  return (
    <div className="grid max-[250px]:flex flex-wrap max-[400px]:grid-cols-2 grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
      {allBrands.map((brand: IBrand, index: number) => (
        <Brand
          key={index}
          brand={brand}
          className={cn(
            "w-full border text-center",
            serviceFlowData.steps.brand === brand.name
              ? "border-primaryA1"
              : "bg-white",
          )}
          handleClick={() => handleBrandClick(brand.name)}
        />
      ))}
    </div>
  );
}

export default SelectBrand;
