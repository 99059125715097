import { availabilityData } from "./data";

const AvailabilityData = () => {
  return (
    <>
      {availabilityData.map((data, index) => (
        <div
          key={index}
          className="flex flex-col md:flex-row items-center gap-1"
        >
          <p className="text-xl">{data.amount}</p>
          <p className="text-sm text-neutral300">{data.type}</p>
        </div>
      ))}
    </>
  );
};

export default AvailabilityData;
