import { AvatarIcon, ColouredPencil, RightArrowIcon } from "@/assets/Icons";
import { Avatar, AvatarFallback } from "../ui/avatar";

interface IProps {
  showEditButton?: boolean;
  handleOnUserDetailsClick?: any;
  handleEditClick?: any;
  user?: any;
  updateEditButton?: string;
}

const UserDetails = ({
  showEditButton = true,
  handleOnUserDetailsClick,
  handleEditClick,
  user,
  updateEditButton,
}: IProps) => {
  return (
    <>
      <Avatar>
        {!user && (
          <span className="w-full h-full flex justify-center items-center">
            <AvatarIcon />
          </span>
        )}
        <AvatarFallback>
          {user?.userName?.split(" ").map((name: string) => name.charAt(0))}
        </AvatarFallback>
      </Avatar>
      <div
        data-test-id="sidebar-login"
        className="flex justify-between items-center w-full px-2 cursor-pointer"
        onClick={handleOnUserDetailsClick}
      >
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-sm">{user?.userName ?? "Login"}</p>
          <p className="font-semibold text-neutral300 text-xs">
            {user?.phoneNumber ?? "Access Your Account"}
          </p>
        </div>
        {showEditButton && (
          <div onClick={handleEditClick}>
            {updateEditButton ? (
              <div className="flex items-center gap-2">
                <p className="text-primaryA2">Edit</p>
                <ColouredPencil size={14} />
              </div>
            ) : (
              <RightArrowIcon />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
