import { convertToDDMMYYYY, getTimeSlot } from "@/utils/date";
import { ensureHttpsProtocol } from "@/utils/storeLocation";
import { Calendar, MapPinIcon, TimerIcon } from "lucide-react";
import { PhoneIcon } from "@/assets/Icons";

interface IProps {
  productInfo: {
    storeMapLink: string;
    enquiry_type: string;
    isAvailable: boolean;
    storeName: string;
    pincode: number;
    lead_date: string;
    time: string;
  };
  isTabSelected: string;
  handleCallStore: () => void;
}

const OrderedcardBottom = ({
  productInfo,
  isTabSelected,
  handleCallStore,
}: IProps) => {
  const storeURL = ensureHttpsProtocol(productInfo?.storeMapLink);

  const handleStoreClick = () => {
    if (!productInfo?.isAvailable) return;
    window.open(productInfo?.storeMapLink && storeURL);
  };

  const handleDirectionsClick = () => {
    if (isTabSelected !== "services") return;
    handleStoreClick();
  };

  return (
    <>
      <div className="flex items-center gap-2 border-t border-[#EDEDED] pt-3">
        <MapPinIcon size="20" />
        {isTabSelected === "buy" || isTabSelected === "services" ? (
          <div className="flex flex-between items-center w-[100%]">
            <div
              className="flex items-center text-xs text-neutral300 gap-1 max-w-[60%]"
              onClick={() => handleStoreClick()}
            >
              <p className="font-semibold text-neutral900 text-sm capitalize leading-5 overflow-hidden text-ellipsis whitespace-nowrap">
                {productInfo?.storeName}
              </p>
              {isTabSelected !== "services" && (
                <span className="text-base">&gt;</span>
              )}
            </div>

            {productInfo?.isAvailable && (
              <div
                className="text-sm flex gap-1 items-center"
                onClick={handleCallStore}
              >
                {isTabSelected !== "services" && <PhoneIcon />}
                <p
                  className="text-primaryA2 underline font-semibold underline-offset-[2px] decoration-orange-500 whitespace-nowrap"
                  onClick={() => handleDirectionsClick()}
                >
                  {isTabSelected === "buy" ? "Call store" : "Directions"}
                </p>
              </div>
            )}
          </div>
        ) : (
          <p className="text-sm font-semibold">{productInfo?.pincode}</p>
        )}
      </div>
      {productInfo?.isAvailable !== false && (
        <div className="flex justify-between">
          <div className="flex gap-2">
            <Calendar size="20" />
            <p className="text-sm font-semibold">
              {productInfo && convertToDDMMYYYY(productInfo?.lead_date)}
            </p>
          </div>
          {isTabSelected !== "services" && productInfo?.time && (
            <div className="flex gap-2">
              <TimerIcon size="20" />
              <p className="text-sm font-semibold capitalize">
                {productInfo?.time.toLowerCase()}{" "}
                <span className="text-xs font-semibold">
                  ({getTimeSlot(productInfo?.time)})
                </span>
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default OrderedcardBottom;
