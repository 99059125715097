import { BookmarkIcon, DriveXFilledIcon } from "@/assets/Icons";
import assuredAnimation from "@/assets/lottie/assured.json";
import { useLottie } from "@/hooks/useLottie";
import { Options } from "react-lottie";

type ProductLabelProps = {
  label?: {
    isAssured?: string;
    isReserved?: string;
  };
};

const defaultOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: assuredAnimation,
};

function ProductLabel({ label }: ProductLabelProps) {
  const { Lottie, loading } = useLottie();

  if (loading) return null;

  if (label) {
    return (
      <>
        {label.isAssured && (
          <div className="flex gap-2 items-center absolute top-3.5 p-2 py-1.5 bg-accent rounded-e-sm">
            <DriveXFilledIcon />
            <p className="text-white text-sm font-medium">{label.isAssured}</p>
          </div>
        )}

        {label.isReserved && (
          <div className="flex gap-1 items-center absolute bottom-3.5 px-2 py-1.5 bg-[#F0F8EE] rounded-e-sm">
            <BookmarkIcon size={20} />
            <p className="text-base font-semibold">{label.isReserved}</p>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="absolute top-3.5">
      {Lottie && <Lottie options={defaultOptions} height={40} width={100} />}
    </div>
  );
}

export default ProductLabel;
