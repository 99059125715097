import Navbar from "@/components/Navbar";
import ContactUsForm from "./ContactUsForm/index.tsx";
import ContactUsDetails from "./ContactUsDetails.tsx";
import Footer from "@/components/Footer/index.tsx";
import BottomNavigation from "@/components/BottomNavigation/index.tsx";
import { HomeTabs } from "@/components/BottomNavigation/data.tsx";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validation.ts";
import { submitFeedback } from "@/store/services/scheduleApi.ts";
import { useSelector } from "react-redux";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { cn } from "@/lib/utils.ts";
import FeedbackSubmitted from "./FeedbackSubmitted.tsx";
import { useToast } from "@/components/ui/use-toast.ts";

const ContactUs = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const [errorMsg, setErrorMsg] = useState("");
  const [isFeedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [isFeedbackButtonClicked, setIsFeedbackButtonClicked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    type_of_query: "",
    query: "",
  });
  const { homeTabsData } = HomeTabs();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        name: user ? user?.userName : "",
        phone_number: user ? user?.phoneNumber : "",
        email: user && user?.email ? user?.email : "",
      });
    }
  }, [user]);

  const handleFormInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFeedbackButtonClicked(false);
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrorMsg("");
  };

  const handleSubmitButton = async () => {
    const ensureCountryCodePresent =
      formData.phone_number.slice(0, 3) === "+91"
        ? formData.phone_number.slice(3, 13)
        : formData.phone_number;

    setIsFeedbackButtonClicked(true);

    if (!isValidPhoneNumber(ensureCountryCodePresent)) {
      setErrorMsg("Invalid number");
      return;
    } else if (formData.name === "") {
      setErrorMsg("Invalid name");
      return;
    } else if (formData.type_of_query === "") {
      setErrorMsg("Invalid TypeOfQuery");
      return;
    } else if (!isValidEmail(formData.email)) {
      setErrorMsg("Invalid email");
      return;
    } else {
      setErrorMsg("");
    }

    try {
      await submitFeedback({
        user_name: formData.name,
        email: formData.email,
        phone_number: formData.phone_number,
        query_type: formData.type_of_query,
        query: formData.query,
      });
      setFeedbackSubmitted(true);
      setIsFeedbackButtonClicked(false);
    } catch {
      setFeedbackSubmitted(false);
      setIsFeedbackButtonClicked(false);
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  const handleSelectValueChange = (value: string) => {
    setFormData({ ...formData, type_of_query: value });
  };

  const handleBottomNavigationClick = (url: string) => {
    navigate(url);
  };
  return (
    <>
      <Navbar
        backgroundColor={"bg-neutral900"}
        showLocation={false}
        showSearchbar={false}
      />

      <div
        className={cn(
          "flex flex-col gap-4 p-4 bg-neutral900 pt-[90px]",
          !isMobile && "flex-row justify-center items-center gap-[25%]",
          !isMobile && isFeedbackSubmitted && "h-[40rem]",
        )}
      >
        <h1 className="text-2xl text-white font-semibold text-center sm:text-left sm:text-4xl mb-3">
          Looking for something?
          <br />
          <span className="text-primaryA1">We’re here to help</span>
        </h1>

        {/*Contact Us Form */}
        <div
          className={cn(
            "flex flex-col gap-4 p-4 bg-white rounded-lg mb-4",
            !isMobile && "w-[30rem]",
          )}
        >
          {!isFeedbackSubmitted ? (
            <ContactUsForm
              handleFormInputChange={handleFormInputChange}
              handleSubmitButton={handleSubmitButton}
              handleSelectValueChange={handleSelectValueChange}
              isFeedbackButtonClicked={isFeedbackButtonClicked}
              formData={formData}
              errorMsg={errorMsg}
              user={user}
            />
          ) : (
            <FeedbackSubmitted formData={formData} />
          )}
        </div>
      </div>

      {/* Contact Us Details */}
      <div
        className={cn(
          "flex flex-col gap-8 bg-neutral30 p-4 py-12",
          !isMobile && "items-center",
        )}
      >
        <ContactUsDetails />
      </div>

      {/* Footer */}
      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>

      <BottomNavigation
        handleClick={handleBottomNavigationClick}
        tabs={homeTabsData}
      />
    </>
  );
};

export default ContactUs;
