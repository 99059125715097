import { X as CloseIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CrossIcon, TickIcon } from "@/assets/Icons";
import Image from "@/components/ui/image";

interface IProps {
  service: IService;
  setOpenWhatsIncluded: React.Dispatch<React.SetStateAction<any>>;
  handleBookCTA: () => void;
}

const WhatsIncluded = ({
  setOpenWhatsIncluded,
  service,
  handleBookCTA,
}: IProps) => {
  return (
    <>
      <div className="flex flex-col gap-3 mb-[80px] sm:mb-[60px] overflow-auto max-h-[80vh]">
        <div className="flex justify-between items-center mb-4 mt-2">
          <p className="text-lg">What&apos;s included?</p>
          <CloseIcon
            size={20}
            onClick={(e) => {
              e.stopPropagation();
              setOpenWhatsIncluded(false);
            }}
          />
        </div>

        <Image
          transform="q-10,fo-auto"
          className="w-full h-[190px] sm:w-[400px] sm:h-[400px] object-cover object-center rounded-md"
          src={service.image_url}
          alt="Service Repair"
        />

        <div className="flex flex-col gap-2">
          {" "}
          <p className="text-xl md:text-[28px] text-left sm:my-2">
            {service.name}
          </p>
          <p className="text-sm text-neutral300">{service.description}</p>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-3">
            {service?.included_services.map((item, index) => (
              <p key={index} className="flex items-center text-sm gap-3">
                <TickIcon /> {item}
              </p>
            ))}
          </div>

          {service?.excluded_services.length > 0 && (
            <>
              <span className="text-xs text-neutral300">Excluded</span>

              <div className="flex flex-col gap-3">
                {service?.excluded_services.map((item, index) => (
                  <p key={index} className="flex items-center text-sm gap-3">
                    <CrossIcon />
                    {item}
                  </p>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col space-y-1.5 text-center sm:text-left bg-white border-neutral50 border-t absolute bottom-0 right-0 left-0 w-full sm:py-2 py-4 px-3 z-10">
        <Button
          onClick={() => handleBookCTA()}
          variant="outline"
          className="w-full py-6 bg-primaryA2 border-none md:w-[200px] text-white text-base font-semibold hover:bg-primaryA2"
        >
          Book - Starts at ₹{service?.price}*
        </Button>
      </div>
    </>
  );
};

export default WhatsIncluded;
