import { Dialog, DialogContent } from "@/components/ui/dialog";

const VideoPopup = ({
  isModalOpen,
  setIsModalOpen,
  videoSource,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  videoSource: string;
}) => {
  return (
    <>
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="p-1 bg-black h-[60vh] w-[100vw] sm:w-[70vw] sm:h-[50vh] border-none">
          <iframe
            className="w-full h-full"
            src={videoSource}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            allowFullScreen
            title="video"
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoPopup;
