import api from "./api";

export const citiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCities: builder.query({
      query: () => "/cities/",
    }),
  }),
});

export const { useLazyGetCitiesQuery } = citiesApi;
