import { useNavigate } from "react-router-dom";

const useNavigateWithFallback = () => {
  const navigate = useNavigate();

  const navigateWithFallback = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return navigateWithFallback;
};

export default useNavigateWithFallback;
