import { useEffect, useRef } from "react";

const useDebouncedApiCall = <T>(
  callback: (value: T) => void,
  delay: number,
) => {
  const timerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current);
      }
    }; // Cleanup on unmount
  }, []);

  const debounce = (...args: any[]) => {
    if (timerId.current) {
      clearTimeout(timerId.current);
    }
    timerId.current = setTimeout(() => {
      callback(...(args as [T]));
    }, delay);
  };

  return debounce;
};

export default useDebouncedApiCall;
