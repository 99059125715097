import { IS_CLIENT_SIDE } from "@/utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  location: IS_CLIENT_SIDE ? localStorage.getItem("location") : null,
  citiesData: [] as ICitiesData[],
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action) => {
      state.location = action.payload;
      if (IS_CLIENT_SIDE) {
        localStorage.setItem("location", action.payload);
      }
    },
    setCitiesData: (state, action) => {
      state.citiesData = action.payload;
    },
  },
});

export const { setLocation, setCitiesData } = locationSlice.actions;

export default locationSlice.reducer;
