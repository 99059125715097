import DateOrTime from "@/components/DateOrTime";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { TIME_SLOTS } from "@/utils/constants";
import { getNextNDays } from "@/utils/date";
import ServiceMiniCard from "../../ServiceMiniCard";
import { SelectedCurrentStepComponentProps } from "../../data";
import { useState } from "react";
import { useSelector } from "react-redux";
import { usePendingAction } from "@/hooks/usePendingAction";
import { useNavigate } from "react-router-dom";
import { LoaderIcon } from "lucide-react";
import { track } from "@/utils/mixpanel/actions";
import { SERVICE_REQUESTED } from "@/utils/mixpanel/Events/service_flow_events";
import { scheduleService } from "@/store/services/scheduleApi";
import { useCurrentCity } from "@/hooks/useCurrentCity";

function SelectDateTime({
  serviceFlowData,
  setServiceFlowData,
  type,
}: SelectedCurrentStepComponentProps) {
  const user = useSelector((state: any) => state.user.currentUser);
  const { handlePendingAction } = usePendingAction();
  const navigate = useNavigate();
  let { city } = useCurrentCity();
  city = city?.replaceAll("-", " ");
  const [isLoading, setIsLoading] = useState(false);
  const nextSixDays = getNextNDays(1, 7);
  const [dateAndTime, setDateAndTime] = useState({
    day: "",
    date: "",
    monthNumber: "",
    dayNumber: "",
    year: "",
    month: "",
    time: "",
  });
  const utm_parameters =
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");
  const isButtonDisabled = Object.values(serviceFlowData.steps).some(
    (value) => value === "",
  );
  const { registration_number, steps, store_id, store_address } =
    serviceFlowData;
  const { model, brand, year, km_driven, schedule, service } = steps;

  const handleBookAppointment = async () => {
    const payload = {
      model,
      brand,
      manufacturing_year: year,
      ...(registration_number && { registration_number }),
      min_km_range: Number(km_driven.split(",")[0]),
      max_km_range: Number(km_driven.split(",")[1]),
      package_id: Number(service.id),
      preferred_date: schedule.preferred_date,
      store_id: Number(store_id),
      preferred_time: schedule.preferred_time.toUpperCase(),
      ...utm_parameters,
    };

    if (!user) {
      handlePendingAction({
        actionType: "BOOK_SERVICE_APPOINTMENT",
        payload: [{ payload }],
        method: bookServiceAppointment,
      });
      navigate(`${location.pathname}${location.search}&login=true`, {
        replace: true,
        state: {
          source: `${type}-service-flow`,
        },
      });
      return;
    }
    await bookServiceAppointment({ payload });
  };

  const bookServiceAppointment = async ({ payload }: any) => {
    try {
      setIsLoading(true);
      const res = await scheduleService(payload);
      if (res.status === "OK") {
        track(SERVICE_REQUESTED, {
          request_date: payload.preferred_date.split("-").reverse().join("-"),
          request_slot: payload.preferred_time,
          service_Request_transaction_id: res.data.transaction_id,
          vehicle_brand: payload.brand,
          vehicle_model: payload.model,
          city_name: city,
          store_id: payload.store_id,
          store_name: res?.data?.store_name,
          store_spoc_number: res?.data?.store_spoc_number,
          store_map_link: res?.data?.store_map_link,
          store_spoc_name: res?.data?.store_spoc_name,
          kms_driven: `${payload.min_km_range}-${payload.max_km_range} KM`,
          service_type: service.name,
          reg_number: payload.registration_number,
          ct_date: new Date(
            `${dateAndTime.monthNumber}/${dateAndTime.dayNumber}/${dateAndTime.year}`,
          ),
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });

        navigate(
          `/service/service-requested?transactionId=${res.data.transaction_id}`,
          { replace: true },
        );
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <div className="gap-6 flex-start flex-col">
        {service && brand && model && (
          <div className="mt-4">
            <ServiceMiniCard
              service={service}
              vehicleName={`${brand} ${model}`}
            />
          </div>
        )}

        {store_address && (
          <div className="flex flex-col flex-start gap-2">
            <p className="text-start">Nearest Service Center</p>
            <p className="border-[1px] rounded-sm p-3 flex-center bg-neutral-100 capitalize">
              {serviceFlowData.store_address}
            </p>
          </div>
        )}

        <div className="flex flex-col gap-4 w-full">
          <p className="text-sm">Select Date</p>

          <div className="flex flex-wrap gap-3">
            {nextSixDays.map((date, index) => (
              <DateOrTime
                key={index}
                isSelected={dateAndTime.date === date.date}
                heading={date.date}
                subHeading={date.day}
                updateData={() => {
                  setDateAndTime((prev: any) => ({ ...prev, ...date }));
                  setServiceFlowData((prev: any) => ({
                    ...prev,
                    steps: {
                      ...prev.steps,
                      schedule: {
                        ...prev.steps.schedule,
                        preferred_date: `${date?.year}-${date?.monthNumber}-${date?.dayNumber}`,
                      },
                    },
                  }));
                }}
                data={date}
              />
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-4 w-full">
          <p className="text-sm">Select Time Slot</p>

          <div className="flex flex-wrap gap-3">
            {TIME_SLOTS.map((slot, index) => (
              <DateOrTime
                key={index}
                isSelected={dateAndTime.time === slot.timeOfDay}
                heading={slot.timeOfDay}
                subHeading={slot.time}
                updateData={() => {
                  setDateAndTime((prev: any) => ({
                    ...prev,
                    time: slot.timeOfDay,
                  }));
                  setServiceFlowData((prev: any) => ({
                    ...prev,
                    steps: {
                      ...prev.steps,
                      schedule: {
                        ...prev.steps.schedule,
                        preferred_time: slot.timeOfDay,
                      },
                    },
                  }));
                }}
                data={slot}
              />
            ))}
          </div>
        </div>
      </div>

      <Button
        variant="outline"
        onClick={handleBookAppointment}
        disabled={isButtonDisabled || isLoading}
        className={cn(
          "bg-primaryA2 border-none text-white text-base py-6 hover:bg-primaryA2 hover:text-white flex gap-2 mt-6 w-full",
          (dateAndTime.date === "" || dateAndTime.time === "") &&
            "bg-neutral50 text-neutral200 pointer-events-none",
        )}
      >
        {isLoading ? "Booking..." : "Book an appointment"}
        {isLoading && <LoaderIcon className="animate-spin" />}
      </Button>
    </div>
  );
}

export default SelectDateTime;
