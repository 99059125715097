import { inspectionChecklist } from "./data";
import { TickIcon } from "@/assets/Icons";

const InspectionChecklist = () => {
  return (
    <>
      <p className="font-semibold text-xl">
        Home Inspection <span className="text-primaryA2">Checklist</span>
      </p>
      <p className="text-neutral300 text-sm font-medium">
        Please keep these documents ready for a <br /> smooth experience
      </p>

      <div className="flex flex-col gap-4">
        {inspectionChecklist.map((inspection, index) => (
          <div key={index} className="flex items-center gap-4">
            <TickIcon />
            <p className="font-semibold text-sm">{inspection.inspectionText}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default InspectionChecklist;
