import firstOwner from "@/assets/svg/Filters/1stOwner.svg";
import secondOwner from "@/assets/svg/Filters/2ndOwner.svg";
import thirdOwner from "@/assets/svg/Filters/3rdOwner.svg";
import { useToast } from "@/components/ui/use-toast";
import { calculatePrice } from "@/store/services/scheduleApi";
import { useDispatch, useSelector } from "react-redux";
import { setEstimatedPrice } from "@/store/features/estimatedPriceSlice";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { track } from "@/utils/mixpanel/actions";
import { SELL_REQ_VEHICLE_DETAILS_SUBMITTED } from "@/utils/mixpanel/Events/sell_flow_events";

export type SearchParams = {
  step: PriceEstimateSteps;
  type?: PriceEstimateStepsType;
};

export type SelectedCurrentStepComponentProps = {
  priceEstimate: IPriceEstimate;
  setPriceEstimate: React.Dispatch<React.SetStateAction<IPriceEstimate>>;
  setSearchParams: (params: SearchParams) => void;
  type?: PriceEstimateStepsType;
};

export const initialManualState: IPriceEstimate = {
  steps: {
    brand: "",
    state: "",
    year: "",
    model: "",
    variant: "",
    km_driven: "",
    no_of_owner: "",
  },
  pin_code: "",
};

export const initialAutomaticState: IPriceEstimate = {
  steps: {
    km_driven: "",
    no_of_owner: "",
  },
  registration_number: "",
  pin_code: "",
};

export const priceEstimateSteps: {
  title: string;
  type: PriceEstimateSteps;
}[] = [
  {
    title: "Brand",
    type: "brand",
  },
  {
    title: "State",
    type: "state",
  },
  {
    title: "Year",
    type: "year",
  },
  {
    title: "Model",
    type: "model",
  },
  {
    title: "Variant",
    type: "variant",
  },
  {
    title: "Owner",
    type: "no_of_owner",
  },
  {
    title: "KMs Driven",
    type: "km_driven",
  },
];

export const Title: {
  [key in PriceEstimateSteps]: JSX.Element;
} = {
  brand: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Brand</span> of
      <br />
      your two-wheeler
    </p>
  ),
  state: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Registered State</span> of
      <br />
      your two-wheeler
    </p>
  ),
  year: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Year</span> of
      <br />
      your two-wheeler
    </p>
  ),
  model: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Model</span> of
      <br />
      your two-wheeler
    </p>
  ),
  variant: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Variant</span> of
      <br />
      your two-wheeler
    </p>
  ),
  km_driven: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">KMs driven</span> by
      <br />
      your two-wheeler
    </p>
  ),
  no_of_owner: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">No. of Owners</span> of
      <br />
      your two-wheeler
    </p>
  ),
};

export const allStates = [
  "Karnataka",
  "Tamil Nadu",
  "Kerala",
  "Maharashtra",
  "Delhi",
  "Uttar Pradesh",
  "Gujarat",
  "West Bengal",
  "Rajasthan",
  "Telangana",
  "Punjab",
  "Haryana",
  "Madhya Pradesh",
  "Andhra Pradesh",
  "Bihar",
  "Odisha",
  "Assam",
  "Jharkhand",
  "Chhattisgarh",
  "Uttarakhand",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Goa",
  "Meghalaya",
  "Manipur",
  "Mizoram",
  "Nagaland",
  "Arunachal Pradesh",
  "Sikkim",
  "Tripura",
];

export const kmDriven: {
  min_km: number;
  max_km: number;
  displayValue: string;
}[] = [
  {
    min_km: 0,
    max_km: 5000,
    displayValue: "0 - 5,000 KMs",
  },
  {
    min_km: 5000,
    max_km: 10000,
    displayValue: "5,000 - 10,000 KMs",
  },
  {
    min_km: 15000,
    max_km: 20000,
    displayValue: "15,000 - 20,000 KMs",
  },
  {
    min_km: 20000,
    max_km: 25000,
    displayValue: "20,000 - 25,000 KMs",
  },
  {
    min_km: 25000,
    max_km: 30000,
    displayValue: "25,000 - 30,000 KMs",
  },
  {
    min_km: 30000,
    max_km: 35000,
    displayValue: "30,000 - 35,000 KMs",
  },
  {
    min_km: 35000,
    max_km: -1,
    displayValue: "35,000+ KMs",
  },
];

export const noOfOwner: {
  displayValue: string;
  icon: any;
  value: string;
}[] = [
  {
    displayValue: "1st Owner",
    icon: firstOwner,
    value: "1",
  },
  {
    displayValue: "2nd Owner",
    icon: secondOwner,
    value: "2",
  },
  {
    displayValue: "3rd Owner",
    icon: thirdOwner,
    value: "3",
  },
];

export const getPriceEstimateSteps = ({
  type,
}: {
  type: PriceEstimateStepsType;
}) => {
  if (type === "automatic") {
    return priceEstimateSteps.filter((step) => step.type === "km_driven"); // Return  "KMs Driven" steps for automatic type
  } else {
    return priceEstimateSteps; // Return all steps for manual type
  }
};

/**
 * Parses search parameters based on the current step and type, and sets them using the provided function.
 * If the current step or type is invalid or missing, or if the type is "automatic" for a step where it should be "manual",
 * default search parameters with step "brand" and type "manual" are set.
 * If the current step is "km_driven" or "no_of_owner" and the type is "automatic",
 * the search parameters are set accordingly.
 *
 * @param {PriceEstimateSteps} options.currentStep - The current step in the price estimation process.
 * @param {PriceEstimateStepsType} options.type - The type of the current step.
 * @param {(params: SearchParams) => void} options.setSearchParamsWithType - A function to set the search parameters.
 */

export const parseSearchParams = ({
  currentStep,
  type,
  navigate,
  pin_code,
}: {
  currentStep: PriceEstimateSteps;
  type: PriceEstimateStepsType;
  navigate: any;
  pin_code: string;
}) => {
  const doesStepExists = priceEstimateSteps.some(
    (obj) => obj.type === currentStep,
  );
  const doesTypeExists = ["automatic", "manual"].includes(type);

  if (!doesStepExists || !doesTypeExists || !pin_code) {
    navigate("/sell-two-wheelers");
  }
};

export const usePriceEstimation = () => {
  const { toast } = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.currentUser);
  const [loading, setLoading] = useState(false);

  const getPriceEstimateHandle = async ({
    priceEstimate,
  }: {
    priceEstimate: IPriceEstimate;
  }) => {
    try {
      setLoading(true);
      const { steps, pin_code, registration_number } = priceEstimate;
      const { km_driven, no_of_owner, brand, model, state, variant, year } =
        steps;
      const utm_parameters =
        sessionStorage.getItem("utm_paramters") &&
        JSON.parse(sessionStorage.getItem("utm_paramters") || "");

      const payload = {
        pincode: Number(pin_code),
        min_km_range: Number(km_driven.split(",")[0]),
        max_km_range: Number(km_driven.split(",")[1]),
        no_of_owners: Number(no_of_owner),
        ...utm_parameters,
        ...(registration_number && { registration_number }),
        ...(state && { state }),
        ...(model && { model }),
        ...(variant && { variant }),
        ...(brand && { brand }),
        ...(year && {
          manufacturing_year: year,
        }),
      };
      const res = await calculatePrice(payload);
      dispatch(setEstimatedPrice(res.data));
      setLoading(false);
      navigate("/sell-two-wheelers/estimated-price");
      track(SELL_REQ_VEHICLE_DETAILS_SUBMITTED, {
        pincode: Number(pin_code),
        kms_driven: km_driven,
        no_of_owners: Number(no_of_owner),
        ...(registration_number && { registration_number }),
        ...(state && { state }),
        ...(model && { model }),
        ...(variant && { variant }),
        ...(brand && { brand }),
        ...(year && { year }),
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
    } catch (error: any) {
      setLoading(false);
      const errorMessage = JSON.parse(error.message);
      toast({
        title: errorMessage,
        variant: "destructive",
      });
    }
  };

  return { getPriceEstimateHandle, loading };
};
