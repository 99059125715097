import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
import { dealerOnboardingSteps } from "../data";
import DealerForm from "./DealerForm";
import { useEffect, useState } from "react";
import { isValidPhoneNumber } from "@/utils/validation";
import DealerFormSubmitted from "./DealerFormSubmitted";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { useSubmitDealerFormMutation } from "@/store/services/becomeADealer";
import { toast } from "@/components/ui/use-toast";

const DealerOnboarding = ({ user }: { user: IUser }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isFormButtonClicked, setIsFormButtonClicked] = useState(false);
  const [dealersFormData, setDealersFormData] = useState({
    name: "",
    phone_number: "",
    pincode: "",
  });

  const [submitDealerForm] = useSubmitDealerFormMutation();

  const DealerOnboarding = `${IMAGE_KIT_BASE_URL}/ik_becomeadealer/DealerOnboarding.webp`;
  const DealerOnboardingWeb = `${IMAGE_KIT_BASE_URL}/ik_becomeadealer/DealerOnboardingWeb.webp`;

  useEffect(() => {
    if (user) {
      setDealersFormData({
        ...dealersFormData,
        name: user ? user?.userName : "",
        phone_number: user ? user?.phoneNumber : "",
      });
    }
  }, [user]);

  const handleFormInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsFormButtonClicked(false);
    setDealersFormData({ ...dealersFormData, [e.target.name]: e.target.value });
    setErrorMsg("");
  };

  const validateDealersFormData = (): string => {
    // Ensure country code is present
    const ensureCountryCodePresent =
      dealersFormData.phone_number.slice(0, 3) === "+91"
        ? dealersFormData.phone_number.slice(3, 13)
        : dealersFormData.phone_number;

    if (!dealersFormData.name) return "Invalid name";
    if (!isValidPhoneNumber(ensureCountryCodePresent)) return "Invalid number";
    if (!dealersFormData.pincode) return "Invalid pincode";
    return "";
  };

  const handleSubmitButton = async () => {
    setIsFormButtonClicked(true);
    const error = validateDealersFormData();
    if (error) {
      setErrorMsg(error);
      return;
    }

    try {
      await submitDealerForm({
        user_name: dealersFormData.name,
        phone_number: dealersFormData.phone_number,
        query_type: "Dealership",
        pincode: dealersFormData.pincode,
      });

      setFormSubmitted(true);
    } catch (error) {
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    } finally {
      setIsFormButtonClicked(false);
    }
  };

  const parentEle: HTMLElement | null = document.getElementById("container");
  const parentHeight = parentEle?.offsetHeight;
  const { isDesktop } = useScreenDetector();

  return (
    <div
      className="flex justify-between 2xl:max-w-7xl w-full mx-auto"
      id="container"
    >
      <div className="flex flex-col justify-center w-full items-center">
        <Image
          transform="f-webp"
          src={DealerOnboarding as string}
          className="md:hidden object-cover w-full"
          alt="DealerOnboarding"
        />
        <div className="flex flex-col items-center justify-center md:py-6 bg-neutral900 w-full mt-[-1px] md:mt-0">
          <p className="text-white text-2xl md:text-3xl text-center">
            Where do you want to <br />
            <span className="text-primaryA2">open the store?</span>
          </p>

          <div className="flex gap-8 p-4 justify-center">
            {dealerOnboardingSteps.map((info, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row items-center text-center gap-4"
              >
                <span className="h-12 w-12 bg-accent rounded-full flex-center">
                  {info.icon}
                </span>
                <p className="font-medium text-sm text-white lg:text-base">
                  {info.text}
                </p>
              </div>
            ))}
          </div>

          <div className="p-4 mb-6 lg:mb-0 w-full max-w-lg">
            {!formSubmitted ? (
              <DealerForm
                dealersFormData={dealersFormData}
                handleFormInputChange={handleFormInputChange}
                handleSubmitButton={handleSubmitButton}
                isFormButtonClicked={isFormButtonClicked}
                errorMsg={errorMsg}
                user={user}
              />
            ) : (
              <DealerFormSubmitted dealersName={dealersFormData.name} />
            )}
          </div>
        </div>
      </div>

      <Image
        transform="f-webp"
        src={DealerOnboardingWeb as string}
        className="hidden lg:block lg:w-1/2 object-cover"
        alt="DealerOnboarding"
        style={{
          height: isDesktop ? parentHeight : "100%",
        }}
      />
    </div>
  );
};

export default DealerOnboarding;
