import { ComponentType, ReactElement, useEffect, useState } from "react";
import { SearchParams, SelectedCurrentStepComponentProps } from "../data";
import SelectBrand from "./ServiceFlowFormSteps/SelectBrand";
import SelectModel from "./ServiceFlowFormSteps/SelectModel";
import SelectYear from "./ServiceFlowFormSteps/SelectYear";
import SelectKMDriven from "./ServiceFlowFormSteps/SelectKMDriven";
import SelectService from "./ServiceFlowFormSteps/SelectService";
import SelectDateTime from "./ServiceFlowFormSteps/SelectDateTime";
import { useLocation } from "react-router-dom";
import { getModelAndVariant } from "@/store/services/scheduleApi";

type useServiceFlowStepsProps = {
  type: ServiceFlowStepsType;
  currentStep: ServiceFlowSteps;
  serviceFlowData: IServiceFlowFormData;
  setServiceFlowData: React.Dispatch<
    React.SetStateAction<IServiceFlowFormData>
  >;
  setSearchParams: (params: SearchParams) => void;
};

const useServiceFlowSteps = ({
  type,
  currentStep,
  serviceFlowData,
  setSearchParams,
  setServiceFlowData,
}: useServiceFlowStepsProps) => {
  const location = useLocation();
  const locationState = location.state as {
    brand: any;
    store_id: string;
    store_address: string;
  };
  const brand = locationState?.brand;

  const [models, setModels] = useState([]);

  const retrieveBrandAndStoreId = async () => {
    if (brand) {
      const res = await getModelAndVariant(brand.name);
      if (res.status === "OK") {
        setModels(res.data);
        setServiceFlowData({
          steps: {
            ...serviceFlowData.steps,
            brand: brand.name,
          },
          store_id: locationState?.store_id,
          store_address: locationState?.store_address,
        });
      }
    }
  };

  useEffect(() => {
    retrieveBrandAndStoreId();
  }, [brand]);

  const CommonPropsHOC = (
    Component: ComponentType<
      Omit<SelectedCurrentStepComponentProps, "currentStep">
    >,
  ): ReactElement => (
    <Component
      serviceFlowData={serviceFlowData}
      setServiceFlowData={setServiceFlowData}
      setSearchParams={setSearchParams}
      type={type}
    />
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case "brand":
        return (
          <SelectBrand
            serviceFlowData={serviceFlowData}
            setServiceFlowData={setServiceFlowData}
            setSearchParams={setSearchParams}
            type={type}
            setModels={setModels}
          />
        );
      case "year":
        return CommonPropsHOC(SelectYear);
      case "model":
        return (
          <SelectModel
            serviceFlowData={serviceFlowData}
            setServiceFlowData={setServiceFlowData}
            setSearchParams={setSearchParams}
            type={type}
            models={models}
          />
        );
      case "km_driven":
        return CommonPropsHOC(SelectKMDriven);
      case "service":
        return CommonPropsHOC(SelectService);
      case "schedule":
        return CommonPropsHOC(SelectDateTime);
      default:
        return (
          <SelectBrand
            serviceFlowData={serviceFlowData}
            setServiceFlowData={setServiceFlowData}
            setSearchParams={setSearchParams}
            type={type}
            setModels={setModels}
          />
        );
    }
  };

  return {
    renderCurrentStep,
  };
};

export default useServiceFlowSteps;
