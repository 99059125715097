import { DownArrowIcon, UpArrowIcon } from "@/assets/Icons";
import { useState } from "react";

const KnowMore = () => {
  const [knowMore, setKnowMore] = useState(false);
  return (
    <>
      <div
        onClick={() => setKnowMore(!knowMore)}
        className="flex items-center justify-center gap-3 cursor-pointer"
      >
        <p className="text-primaryA2">Know More</p>
        {!knowMore ? (
          <UpArrowIcon />
        ) : (
          <DownArrowIcon
            width={15}
            height={9}
            fill="paint0_linear_140_3093_3"
          />
        )}
      </div>

      {knowMore && (
        <div className="flex flex-col gap-3">
          <p className="text-sm font-semibold">More about DriveX</p>
          <p className="text-sm text-neutral300 font-medium">
            DriveX is India&apos;s first fully integrated platform for pre-owned
            two-wheelers. With over 100000 happy customers, DriveX is onset to
            revolutionize how India buys & sells two wheelers.
          </p>
        </div>
      )}
    </>
  );
};

export default KnowMore;
