import Brand from "@/components/Brand";
import { IBrand, useGetBrands } from "./data";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface IProps {
  handleViewAllBrands: () => void;
  store_id: string;
  store_address: string;
}

const Manual = ({ handleViewAllBrands, store_id, store_address }: IProps) => {
  const navigate = useNavigate();
  const { allBrands } = useSelector((state: any) => state.allBrands);
  const { getBrands } = useGetBrands();

  useEffect(() => {
    if (!allBrands) {
      getBrands();
    }
  }, []);

  if (!allBrands) return;

  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-semibold">Select your two-wheeler brand</p>
      <div className="grid place-content-center max-[300px]:flex flex-wrap grid-cols-3 gap-4">
        {allBrands.slice(0, 3).map((brand: IBrand, index: number) => {
          return (
            <Brand
              key={index}
              brand={brand}
              size
              className="w-full text-center"
              handleClick={() => {
                navigate("book-service?step=year&type=manual", {
                  state: {
                    brand,
                    store_id,
                    store_address,
                  },
                });
              }}
            />
          );
        })}
      </div>
      <p
        onClick={handleViewAllBrands}
        className="flex-center gap-1 text-base text-primaryA2 my-2"
      >
        View all brands
        <span className="text-primaryA2 text-3xl font-extralight mb-[1.5px]">
          &gt;
        </span>
      </p>{" "}
    </div>
  );
};

export default Manual;
