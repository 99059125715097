import { Button } from "@/components/ui/button";
import Image from "@/components/ui/image";
import { fallbackImage } from "@/utils/fallbackImage";

interface IProps {
  data: {
    vehicleId: string;
    vehicleName: string;
    vehicleType: string;
    imagePaths: string[];
    registrationNumber: string;
    price: { displayValue: string };
    manufactureYear: string | number;
    kmDriven: { displayValue: string };
  };
  handleAddToFloorClick: (item: any) => void;
}

const IncomingVehicleCard = ({ data, handleAddToFloorClick }: IProps) => {
  return (
    <div className="flex flex-col gap-4 bg-white border-b border-neutral50 pb-6">
      <div className="flex items-center gap-2">
        <Image
          className="w-[5.8rem] h-[3.8rem] object-cover rounded-lg"
          transform="f-webp"
          src={data.imagePaths[0] ?? fallbackImage(data?.vehicleType)}
          alt={data.vehicleName}
        />
        <div className="flex flex-col gap-1">
          <p className="text-lg font-medium text-[#2F2F2F] line-clamp-1 max-w-[16rem] text-wrap">
            {data.vehicleName}
          </p>
          <p className="text-neutral300 text-xs">
            {data?.registrationNumber}•{data?.kmDriven?.displayValue} KMs•
            {data?.manufactureYear}
          </p>
          <p className="text-base font-medium">₹ {data?.price?.displayValue}</p>
        </div>
      </div>

      <div className="w-full">
        <Button
          variant="outline"
          className="border-primaryA1 text-primaryA1 w-full hover:bg-white hover:text-primaryA1"
          onClick={() => handleAddToFloorClick(data)}
        >
          Add to Floor Stock
        </Button>
      </div>
    </div>
  );
};

export default IncomingVehicleCard;
