import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { SelectedCurrentStepComponentProps, noOfOwner } from "../data";
import Image from "@/components/ui/image";

function SelectOwner({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
}: SelectedCurrentStepComponentProps) {
  const handleOwnerClick = useCallback(
    (owner: string) => {
      setSearchParams({ step: "km_driven", type });
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          no_of_owner: owner,
        },
        pin_code: priceEstimate.pin_code,
      });
    },
    [type, priceEstimate],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {noOfOwner.map((owner) => {
        return (
          <div
            key={owner.displayValue}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center flex justify-between items-center",
              priceEstimate.steps.no_of_owner === owner.value
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleOwnerClick(owner.value)}
          >
            {owner.displayValue}
            <Image src={owner.icon} alt={owner.displayValue} />
          </div>
        );
      })}
    </div>
  );
}

export default SelectOwner;
