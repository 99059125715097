import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getAuthHeaders = () => ({
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
});

const bodApiBaseUrl = import.meta.env.VITE_STRAPI_API_BASE_URL;

export const bodApi = createApi({
  reducerPath: "bodApi",
  baseQuery: fetchBaseQuery({
    baseUrl: bodApiBaseUrl,
  }),
  endpoints: (builder) => ({
    getBod: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/board-of-directors`,
          headers: getAuthHeaders(),
          params: {
            ...params,
          },
        };
      },
    }),

    getDrivexForce: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/drivex-forces`,
          headers: getAuthHeaders(),
          params: {
            ...params,
          },
        };
      },
    }),

    getQualityRefurbishmentImages: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/quality-refurbishment-images`,
          headers: getAuthHeaders(),
          params: {
            ...params,
          },
        };
      },
    }),
  }),
});

export const {
  useGetBodQuery,
  useGetDrivexForceQuery,
  useGetQualityRefurbishmentImagesQuery,
} = bodApi;
