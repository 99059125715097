import { GradientCallIcon, GradientMailIcon } from "@/assets/Icons";
import { ContactUsSupportEmail, supportContactNumber } from "@/utils/constants";

type ContactUsOptionsType = {
  icon: React.ReactNode;
  text: string;
  action: string;
};

export const ContactUsOptions: ContactUsOptionsType[] = [
  {
    icon: <GradientCallIcon />,
    text: "Call Us",
    action: supportContactNumber,
  },
  {
    icon: <GradientMailIcon />,
    text: "Email Us",
    action: ContactUsSupportEmail,
  },
];
