import { sendToMetaConversionApi } from "@/store/services/meta";
import { encodeUserId } from "./encoder";

export const meta_conversion_api = async (conversionData: {
  phoneNumber: string;
  eventName: string;
  eventId: any;
  eventType?: string;
}) => {
  const hashedPhoneNumber = await encodeUserId(conversionData?.phoneNumber);
  const hashedEventId = await encodeUserId(`${conversionData?.eventId}`);
  const hasedAnonUserId = await encodeUserId(
    `${localStorage?.getItem("encodedUserId")}`,
  );

  sendToMetaConversionApi({
    data: [
      {
        event_name: conversionData.eventName,
        event_time: Math.floor(Date.now() / 1000),
        event_source_url: window.location.href,
        event_id: `${hashedEventId}`,
        action_source: "website",
        user_data: {
          ph: conversionData?.phoneNumber
            ? [`${hashedPhoneNumber}`]
            : [`${hasedAnonUserId}`],
          client_user_agent: window.navigator.userAgent,
        },
        custom_data: {
          event_type: conversionData.eventType,
        },
      },
    ],
  });
};
