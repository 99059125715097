import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import OurMission from "./OurMission";
import DrivingChange from "./DrivingChange";
import BoardOfDirectors from "./BoardOfDirectors";
import DrivexForce from "./DrivexForce";
import QualityRefurbishment from "./QualityRefurbishment";
import Footer from "@/components/Footer";
import SubmitQuery from "./SubmitQuery";
import {
  useGetBodQuery,
  useGetDrivexForceQuery,
  useGetQualityRefurbishmentImagesQuery,
} from "@/store/services/aboutUs";
import VideoPopup from "../../components/VideoPopup";

const AboutUs = () => {
  const { data: bod, isFetching: isFetchingBodData } = useGetBodQuery({
    params: {
      sort: "orderIndex",
      "populate[0]": "Director_image",
    },
  });
  const { data: forces, isFetching: isFetchingforcesData } =
    useGetDrivexForceQuery({
      params: {
        sort: "orderIndex",
        "populate[0]": "Image",
      },
    });
  const { data: qualityRefurbishment } = useGetQualityRefurbishmentImagesQuery({
    params: {
      "populate[0]": "Image",
    },
  });
  const [bodData, setBodData] = useState([]);
  const [forcesData, setForcesData] = useState([]);
  const [qualityRefurbishmentImages, setQualityRefurbishmentImages] = useState(
    [],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");

  useEffect(() => {
    if (bod && bod.data) {
      setBodData(bod.data);
    }
  }, [bod]);

  useEffect(() => {
    if (forces && forces.data) {
      setForcesData(forces.data);
    }
  }, [forces]);

  useEffect(() => {
    if (qualityRefurbishment && qualityRefurbishment.data) {
      setQualityRefurbishmentImages(qualityRefurbishment.data);
    }
  }, [qualityRefurbishment]);

  const handleVideoPopup = (video: string) => {
    setIsModalOpen(true);
    setVideoSource(video);
  };

  return (
    <>
      <div className="flex flex-col gap-4 bg-neutral30">
        <Navbar
          backgroundColor={"bg-neutral900"}
          showLocation={false}
          showSearchbar={false}
          showLinks={true}
        />

        {/* Our Mission */}
        <div className="flex flex-col lg:flex-row lg:justify-center lg:items-center gap-4 lg:gap-[10%] bg-neutral900 p-4 pt-[90px] ">
          <OurMission handleVideoPopup={handleVideoPopup} />
        </div>

        {/* Driving Change */}
        <div className="flex flex-col gap-4 p-4 max-w-5xl md:gap-12 m-auto w-full">
          <DrivingChange />
        </div>

        {/* Board of Directors */}
        {bodData && (
          <div className="flex flex-col max-w-5xl m-auto gap-8 p-4 w-full">
            <BoardOfDirectors
              boardOfDirectors={bodData}
              isFetchingBodData={isFetchingBodData}
            />
          </div>
        )}

        {/* Drivex Force */}
        {forcesData && (
          <div className="flex flex-col max-w-5xl md:m-auto gap-8 p-4 w-full">
            <DrivexForce
              drivexForcesData={forcesData}
              isFetchingforcesData={isFetchingforcesData}
            />
          </div>
        )}

        {/* Quality Refurbishment */}
        {qualityRefurbishmentImages && (
          <div className="flex flex-col gap-8 p-4 pt-14 bg-neutral900">
            <QualityRefurbishment
              qualityRefurbishmentImages={qualityRefurbishmentImages}
              handleVideoPopup={handleVideoPopup}
            />
          </div>
        )}

        <div className="p-4">
          <SubmitQuery />
        </div>

        <VideoPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          videoSource={videoSource}
        />

        {/* Footer */}
        <div className="flex flex-col gap-6 bg-neutral900 py-4">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
