import { validateIndianVehicleNumber } from "@/utils/validation";
import { useState } from "react";
import { vehicleInfo, verifyPincode } from "@/store/services/scheduleApi";
import Automatic from "./Automatic";
import Manual from "./Manual";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { LoaderIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import { CheckCircle, ErrorCircle } from "@/assets/Icons";
import Pincode from "./Pincode";
import { track } from "@/utils/mixpanel/actions";
import {
  SELL_REQ_PINCODE_ENTERED,
  SELL_REQ_VEHICLE_NUMBER_SUBMITTED,
} from "@/utils/mixpanel/Events/sell_flow_events";
import { errorMessages } from "./data";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import ErrorPopUpContent from "./ErrorPopUpContent";
import { setEstimatedPrice } from "@/store/features/estimatedPriceSlice";
import { useDispatch, useSelector } from "react-redux";
const Vahan = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const [vehicleDetails, setVehicleDetails] = useState({
    pincode: "",
    registrationNumber: "",
  });
  const [isPincodeServiceable, setIsPincodeServiceable] = useState<string>("");
  const [isValidVehicleRegistrationNumber, setIsVehicleRegistrationValid] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, maxLength } = e.target;

    let newValue = value;
    if (name === "pincode" && value.length > maxLength) {
      newValue = value.slice(0, maxLength);
    }

    if (name === "pincode" && newValue.length !== maxLength) {
      setIsPincodeServiceable("Invalid");
    }

    setVehicleDetails((prev) => ({
      ...prev,
      [name]: newValue.toUpperCase(),
    }));

    setIsVehicleRegistrationValid("");
  };

  const verifyVehicleDetails = async () => {
    try {
      const isValidVehicleNumber = validateIndianVehicleNumber(
        vehicleDetails.registrationNumber,
      );

      if (!isValidVehicleNumber) {
        setIsVehicleRegistrationValid("invalid");
        track(SELL_REQ_VEHICLE_NUMBER_SUBMITTED, {
          registration_number: vehicleDetails.registrationNumber,
          status: "Invalid",
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });
        return;
      }

      setIsLoading(true);
      const res = await vehicleInfo({
        registration_number: vehicleDetails.registrationNumber,
        pincode: vehicleDetails.pincode,
      });
      setIsLoading(false);

      if (res.status === "OK") {
        navigate("price-estimate?step=km_driven&type=automatic", {
          state: {
            vehicleDetails: {
              ...vehicleDetails,
              no_of_owner: res.data.no_of_owners,
            },
          },
        });
        track(SELL_REQ_VEHICLE_NUMBER_SUBMITTED, {
          registration_number: vehicleDetails.registrationNumber,
          status: "Success",
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      const errorMessage = JSON.parse(error.message);

      // If the error message indicates that vehicle info could not be fetched, navigate to the manual flow.
      if (errorMessages.includes(errorMessage)) {
        handleViewAllBrands();
      }

      setIsVehicleRegistrationValid("unserviceable");
      track(SELL_REQ_VEHICLE_NUMBER_SUBMITTED, {
        registration_number: vehicleDetails.registrationNumber,
        status: "Unfit",
        reason: errorMessage,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
    }
  };

  const handleInspection = async (type: string) => {
    dispatch(setEstimatedPrice(null));
    if (type === "pincodeFlow") {
      if (vehicleDetails.pincode.length < 6) {
        setIsPincodeServiceable("invalid");
        track(SELL_REQ_PINCODE_ENTERED, {
          pincode: vehicleDetails.pincode,
          status: "Invalid",
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });
        return;
      }

      if (vehicleDetails.pincode.length === 6) {
        setIsLoading(true);
        const res = await verifyPincode(vehicleDetails.pincode);
        setIsLoading(false);

        if (res.data.serviceable) {
          setIsPincodeServiceable("serviceable");
          track(SELL_REQ_PINCODE_ENTERED, {
            pincode: vehicleDetails.pincode,
            status: "Success",
            name: user?.userName,
            phone_number: user?.phoneNumber,
          });
        } else {
          setIsPincodeServiceable("unserviceable");
          track(SELL_REQ_PINCODE_ENTERED, {
            pincode: vehicleDetails.pincode,
            status: "Unserviceable",
            name: user?.userName,
            phone_number: user?.phoneNumber,
          });
        }
      }

      return;
    }

    verifyVehicleDetails();
  };

  const handleCloseSheet = () => {
    setIsPincodeServiceable("");
    setIsVehicleRegistrationValid("");
  };

  const handleViewAllBrands = () => {
    navigate("price-estimate?step=brand&type=manual", {
      state: {
        pin_code: vehicleDetails.pincode,
      },
    });
  };

  const handleModalOpen = () => {
    if (
      isPincodeServiceable === "unserviceable" ||
      isValidVehicleRegistrationNumber === "unserviceable"
    ) {
      return true;
    }

    return false;
  };

  const handleModalOnOpenChange = () => {
    setIsPincodeServiceable("");
    setIsVehicleRegistrationValid("");
  };

  const renderPincodeSuccessOrErrorIcon = (successOrError: string) => {
    switch (successOrError) {
      case "serviceable":
        return <CheckCircle />;
      case "invalid":
        return <ErrorCircle />;
      case "":
        return "";
    }
  };

  const renderErrorStates = () => {
    if (isPincodeServiceable === "invalid") {
      return "Invalid Pincode";
    }

    if (isValidVehicleRegistrationNumber === "invalid") {
      return "Invalid registration number";
    }

    return null;
  };

  return (
    <div className="flex flex-col bg-white w-full md:w-[40%] xl:w-[460px] p-5 rounded-md gap-4 relative">
      <Pincode
        renderPincodeSuccessOrErrorIcon={renderPincodeSuccessOrErrorIcon}
        handleChange={handleChange}
        isPincodeServiceable={isPincodeServiceable}
        vehicleDetails={vehicleDetails}
      />

      <Automatic
        handleChange={handleChange}
        isPincodeServiceable={isPincodeServiceable}
        isValidVehicleRegistrationNumber={isValidVehicleRegistrationNumber}
        vehicleDetails={vehicleDetails}
        renderPincodeSuccessOrErrorIcon={renderPincodeSuccessOrErrorIcon}
      />

      {(isPincodeServiceable === "invalid" ||
        isValidVehicleRegistrationNumber === "invalid") && (
        <p className="text-red600 text-sm">{renderErrorStates()}</p>
      )}

      <Button
        onClick={() =>
          handleInspection(
            isPincodeServiceable !== "serviceable"
              ? "pincodeFlow"
              : "registrationFlow",
          )
        }
        disabled={isLoading}
        variant="outline"
        className="bg-primaryA2 border-none py-6 text-white hover:bg-primaryA2 hover:text-white flex gap-2"
      >
        {vehicleDetails.pincode.length === 6 &&
        isPincodeServiceable === "serviceable"
          ? "Get Price Estimate"
          : "Proceed"}
        {isLoading && <LoaderIcon className="animate-spin" />}
      </Button>

      {/* // Manual view */}
      {isPincodeServiceable === "serviceable" && (
        <>
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="absolute px-3 font-medium text-neutral900 text-sm -translate-x-1/2 bg-white left-1/2">
              OR
            </span>
          </div>

          <Manual
            handleViewAllBrands={handleViewAllBrands}
            pin_code={vehicleDetails.pincode}
          />
        </>
      )}

      {/* This opens the bottom sheet for both automatic & manual if vehicle is inelgible or pincode is unserviceable */}
      {isMobile && (
        <Sheet open={handleModalOpen()} onOpenChange={handleModalOnOpenChange}>
          <SheetContent
            side="bottom"
            className="flex flex-col gap-4 p-4  rounded-t-lg"
          >
            <ErrorPopUpContent
              isMobile={true}
              isPincodeServiceable={isPincodeServiceable}
              isValidVehicleRegistrationNumber={
                isValidVehicleRegistrationNumber
              }
              handleCloseSheet={handleCloseSheet}
            />
          </SheetContent>
        </Sheet>
      )}

      {!isMobile && (
        <Dialog open={handleModalOpen()} onOpenChange={handleModalOnOpenChange}>
          <DialogContent className="flex flex-col gap-4 p-4 items-center text-center rounded-t-lg">
            <ErrorPopUpContent
              isPincodeServiceable={isPincodeServiceable}
              isValidVehicleRegistrationNumber={
                isValidVehicleRegistrationNumber
              }
              handleCloseSheet={handleCloseSheet}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Vahan;
