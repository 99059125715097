import { Button } from "@/components/ui/button";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useNavigate } from "react-router-dom";

const SoldOut = () => {
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  return (
    <div className="flex flex-col gap-3">
      <p className="bg-neutral30 py-2 text-center text-xs rounded-md shadow-sm">
        This vehicle is sold out, <br /> but we have more options for you!
      </p>
      <Button
        onClick={() => navigate(`/${city}/buy-two-wheelers`)}
        variant="outline"
        className="bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white"
      >
        View More Bikes
      </Button>
    </div>
  );
};

export default SoldOut;
