export function calculatePercentageDifference(
  purchasePrice: number,
  salePrice: number,
) {
  if (salePrice < purchasePrice || !salePrice) {
    return "error";
  }

  const difference = salePrice - purchasePrice;
  const percentageDifference = (difference / purchasePrice) * 100;
  const result = parseInt(percentageDifference.toFixed(2)).toString(); // rounded to 2 decimal places
  return result;
}
