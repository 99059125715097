import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";

type objectOfStrings = {
  [key: string]: string;
};
interface IProps {
  selectedKey: string;
  selectedFilters: objectOfStrings;
  item: { value: string | number; name: string; count: number };
}

const SingleCheckboxFilter = ({
  selectedKey,
  selectedFilters,
  item,
}: IProps) => {
  return (
    <div
      className={cn(
        "border rounded-full flex-center border-neutral300 w-[20px] h-[20px]",
        item.value.toString() === selectedFilters[selectedKey] &&
          "border-orange-500",
      )}
    >
      <Checkbox
        showIndicator={false}
        checked={
          item.value.toString() === selectedFilters[selectedKey] ? true : false
        }
        className="rounded-full border-none w-[13px] h-[13px]"
      />
    </div>
  );
};

export default SingleCheckboxFilter;
