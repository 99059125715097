import { DriveXFilledIcon } from "@/assets/Icons";
import { cn } from "@/lib/utils";
import React from "react";

interface Iprops {
  differenceData: {
    icon: React.ReactNode;
    heading: React.ReactNode;
  }[];
  isVehicleAssured: boolean;
}

const DriveDifference = ({ differenceData, isVehicleAssured }: Iprops) => {
  return (
    <div className="flex flex-col p-4 gap-4">
      <p
        className={cn(
          "flex items-center text-xl gap-1",
          isVehicleAssured && "text-accent",
        )}
      >
        {isVehicleAssured ? (
          <>
            <DriveXFilledIcon size={24} color="#684499" />
            Assured
          </>
        ) : (
          <>
            DriveX <span className="text-primaryA2">Difference</span>
          </>
        )}
      </p>

      <div className="flex gap-4 flex-wrap flex-1 justify-center md:justify-start">
        {differenceData.map((item, index) => (
          <div
            key={index}
            className="flex flex-start flex-col gap-3 p-3 max-[300px]:w-[100%] w-[47%] md:w-[20%] lg:w-[15%] border-neutral50 border-[1px] rounded-sm shadow-sm"
          >
            {React.cloneElement(item.icon as React.ReactElement, {
              oneStopColor: isVehicleAssured ? "#64469B" : "#E63C32",
              twoStopColor: isVehicleAssured ? "#64469B" : "#E63C32",
            })}
            <p className="font-semibold text-base max-w-32">{item.heading}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DriveDifference;
