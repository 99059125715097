import { cn } from "@/lib/utils";
import React from "react";

const VehicleCard = ({
  vehicle,
  index,
  totalCards,
}: {
  vehicle: any;
  index: number;
  totalCards: number;
}) => {
  // Calculate the offset for stacked appearance
  const offset = `${index * 10}px`;

  return (
    <div
      className="absolute w-full flex gap-2 rounded-lg bg-white p-3 shadow-[0_0_10px_rgba(0,0,0,0.1),_0_8px_5px_rgba(0,0,0,0.1)] select-none"
      style={{
        top: offset,
        width: `calc(100% - ${index * 20}px)`,
        zIndex: totalCards - index,
      }}
    >
      <img
        src={vehicle?.imagePaths[0]}
        alt={vehicle?.vehicleName}
        className="w-[6rem] h-[4rem] object-cover rounded-md"
      />

      <div className="flex flex-col justify-evenly">
        <p className="text-base font-medium text-[#2F2F2F] w-[11rem] truncate">
          {vehicle?.vehicleName}
        </p>
        <p className="text-neutral300 text-xs">
          {vehicle?.registrationNumber}•
          {`${vehicle?.kmDriven?.displayValue} KMs`}•{vehicle?.manufactureYear}
        </p>
      </div>
    </div>
  );
};

const StackedCards = ({ data }: { data: any[] }) => {
  // Calculate total height based on number of cards
  const containerHeight = `${3 * 2 + 80}px`;
  const isDataMoreThanTwo = data.length > 2;

  return (
    <>
      <div className="py-2">
        <div
          className={cn(
            "relative flex flex-col items-center mb-4",
            isDataMoreThanTwo && "mb-8",
          )}
          style={{ height: containerHeight }}
        >
          {data.slice(0, 3).map((vehicle, index) => (
            <VehicleCard
              key={vehicle.vehicleId}
              vehicle={vehicle}
              index={index}
              totalCards={data.length}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default StackedCards;
