export const isValidPhoneNumber = (phoneNumber: string) => {
  const pattern = /^[6-9]\d{9}$/;

  if (!phoneNumber) {
    return false;
  }

  if (pattern.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};

export function validateIndianVehicleNumber(vehicleNumber: string) {
  const regex1 = /^[A-Z]{2}[0-9]{2}[A-HJ-NP-Z]{1,2}[0-9]{4}$/;
  const regex2 = /^[0-9]{2}BH[0-9]{4}[A-HJ-NP-Z]{1,2}$/;

  if (regex1.test(vehicleNumber) || regex2.test(vehicleNumber)) {
    return true;
  } else {
    return false;
  }
}

export const isValidEmail = (email: string) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/;

  if (!email) {
    return true;
  }

  if (pattern.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const PRODUCTLIST_PATH_REGEX = /^\/[^/]+\/buy-two-wheelers$/;
