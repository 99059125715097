import { ColouredCallIcon, ColouredMailIcon } from "@/assets/Icons";
import { ContactUsSupportEmail, supportContactNumber } from "@/utils/constants";

interface ContactusDetailsDataType {
  icon: JSX.Element;
  heading: string;
  data: string;
}

export const ContactusDetailsData: ContactusDetailsDataType[] = [
  {
    icon: <ColouredCallIcon />,
    heading: "Phone Number",
    data: supportContactNumber,
  },
  {
    icon: <ColouredMailIcon />,
    heading: "Email Address",
    data: ContactUsSupportEmail,
  },
];

interface QueryType {
  option: string;
}

export const QueryTypeData: QueryType[] = [
  {
    option: "Sell",
  },
  {
    option: "Buy",
  },
  {
    option: "Dealership",
  },
  {
    option: "Support",
  },
];
