// SEARCH RESULT
export const SEARCH_RESULT_CLICK_EVENT = "SEARCH_RESULT";

// PRODUCT LISTING
export const PRODUCT_CARD_CLICK_EVENT = "PRODUCT_CARD";

// TEST RIDE
export const TEST_RIDE_CLICK_EVENT = "TEST_RIDE";

// TEST RIDE SCHEDULED
export const TEST_RIDE_SCHEDULED_EVENT = "TEST_RIDE_SCHEDULED";

// WHATSAPP TOUR
export const WHATSAPP_TOUR_CLICK_EVENT = "WHATSAPP_TOUR";
