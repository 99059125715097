import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { X as CloseIcon } from "lucide-react";
import { Slider } from "@/components/ui/slider";
import { emiPlan } from "@/pages/Home/data";
import { useState } from "react";
import {
  DEFAULT_DOWN_PAYMENT_VALUE,
  DEFAULT_TENURE_VALUE,
  MAXIMUM_TENURE,
  MAX_RATE_OF_INTEREST,
  MINIMUM_DOWN_PAYMENT,
  MINIMUM_TENURE,
  MIN_RATE_OF_INTEREST,
  RATE_OF_INTEREST_DEFAULT_VALUE,
} from "@/utils/constants";
import { emi_calculator } from "@/utils/emi";
import Image from "../ui/image";

interface IProps {
  openEmi: boolean;
  setOpenEmi: React.Dispatch<React.SetStateAction<any>>;
  price: { value: number };
}

const Emi = ({ openEmi, setOpenEmi, price }: IProps) => {
  const defaultDownPayment = (DEFAULT_DOWN_PAYMENT_VALUE / 100) * price?.value;
  const [downPaymentValue, setDownPaymentValue] = useState(defaultDownPayment);
  const [tenureValue, setTenureValue] = useState(DEFAULT_TENURE_VALUE);
  const [rateOfInterestValue, setRateOfInterestValue] = useState(
    RATE_OF_INTEREST_DEFAULT_VALUE,
  );

  return (
    <Sheet open={openEmi} onOpenChange={() => setOpenEmi(false)}>
      <SheetContent
        side="bottom"
        className="flex flex-col gap-4 p-4 pt-6 rounded-t-lg"
      >
        <div className="w-[100px] h-1 bg-neutral30 absolute top-2 left-1/2 transform -translate-x-1/2"></div>
        <SheetHeader className="mb-6 mt-2">
          <div className="flex justify-between items-center">
            <div className="flex-center gap-3">
              <p className="text-xl">EMI Calculator</p>
            </div>

            <SheetClose>
              <CloseIcon size={20} />
            </SheetClose>
          </div>
        </SheetHeader>

        <div className="flex flex-col gap-6 border p-4 rounded-md divide-y">
          <div className="flex flex-col gap-8">
            <div className="border-0 rounded-md w-full p-4 py-5 bg-neutral30 flex-center flex-col gap-3">
              <p>Monthly EMI</p>
              <p className="text-primaryA2 text-3xl">
                ₹
                {emi_calculator(
                  price?.value - downPaymentValue,
                  rateOfInterestValue,
                  tenureValue / 12,
                ).toLocaleString("en-IN")}
              </p>
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex-between items-center">
                <p>Down Payment</p>
                <p className="text-primaryA2">
                  ₹{Math.round(downPaymentValue).toLocaleString("en-IN")}
                </p>
              </div>
              <Slider
                min={(MINIMUM_DOWN_PAYMENT / 100) * price?.value}
                max={price?.value}
                value={[downPaymentValue]}
                defaultValue={[
                  (DEFAULT_DOWN_PAYMENT_VALUE / 100) * price?.value,
                ]}
                onValueChange={(value) => setDownPaymentValue(value[0])}
                step={100}
                rangeClassName="bg-orange-500 bg-accent"
                thumbClassName="bg-accent border-transparent"
              />
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex-between items-center">
                <p>Rate of Interest</p>
                <p className="text-primaryA2">{rateOfInterestValue}%</p>
              </div>
              <Slider
                min={MIN_RATE_OF_INTEREST}
                max={MAX_RATE_OF_INTEREST}
                value={[rateOfInterestValue]}
                defaultValue={[RATE_OF_INTEREST_DEFAULT_VALUE]}
                onValueChange={(value) => setRateOfInterestValue(value[0])}
                step={0.1}
                rangeClassName="bg-orange-500 bg-accent"
                thumbClassName="bg-accent border-transparent"
              />
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex-between items-center">
                <p>Tenure</p>
                <p className="text-primaryA2">{tenureValue} months</p>
              </div>
              <Slider
                min={MINIMUM_TENURE}
                max={MAXIMUM_TENURE}
                value={[tenureValue]}
                defaultValue={[DEFAULT_TENURE_VALUE]}
                onValueChange={(value) => setTenureValue(value[0])}
                step={1}
                rangeClassName="bg-orange-500 bg-accent"
                thumbClassName="bg-accent border-transparent"
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 p-4 pb-0">
            <p className="text-center">Finance Partners:</p>

            <div className="flex gap-2">
              {emiPlan.map((emi, index) => (
                <div
                  key={index}
                  className={`flex p-3 rounded-md border ${emi.background_color}`}
                >
                  <Image
                    transform="f-webp"
                    className="w-[4rem]"
                    src={emi.img as string}
                    alt=""
                  />
                </div>
              ))}
            </div>

            <p className="text-center text-xs text-neutral300">
              *The monthly EMI shown here is approximate. Rate of interest &
              Tenure is at the discretion of the financier & may subject to
              change depending on the customer credit profile.
            </p>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default Emi;
