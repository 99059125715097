import { TimeIcon } from "@/assets/Icons";
import Pill from "../Pill";
import { cn } from "@/lib/utils";
import { Sheet, SheetContent } from "../ui/sheet";
import WhatsIncluded from "@/pages/Services/WhatsIncluded";
import { useState } from "react";
import { pluralize } from "@/utils/pluralize";

type IProps = {
  service: IService;
  showDescription?: boolean;
  rightHalf: any;
  showPill?: boolean;
  className: string;
};

const ServiceCard = ({
  service,
  showDescription,
  rightHalf,
  showPill,
  className,
}: IProps) => {
  const [openWhatsIncluded, setOpenWhatsIncluded] = useState(false);

  const handleBookCTA = () => {
    setOpenWhatsIncluded(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div
        key={service?.id as number}
        className={cn(
          "flex flex-col gap-3 p-4 bg-white rounded-md w-full",
          className,
        )}
      >
        <div className="flex justify-between">
          <div className="flex flex-col gap-2">
            <p>{service.name}</p>
            <p className="text-sm text-primaryA2">
              Starts at&nbsp;{`₹${service.price}*`}
            </p>
            {showPill && (
              <Pill
                className="bg-neutral30 border-none"
                leftHalf={<TimeIcon />}
                name={`${service.service_hours} ${pluralize(parseInt(service.service_hours), "hour")}`}
              />
            )}
          </div>

          {rightHalf}
        </div>

        {showDescription && (
          <p className="text-xs text-neutral300">{service.description}</p>
        )}

        <div className="flex justify-between items-center">
          <span
            onClick={(e) => {
              e.stopPropagation();
              setOpenWhatsIncluded(true);
            }}
            className="text-xs text-accent underline underline-offset-[2px]"
          >
            See what&apos;s included
          </span>

          {service.availability === "EXCLUSIVE" && (
            <p className="text-[9px] text-neutral300">
              # Only at BTM Layout and Chennai Store
            </p>
          )}
        </div>
      </div>

      <Sheet
        open={openWhatsIncluded}
        onOpenChange={() => setOpenWhatsIncluded(false)}
      >
        <SheetContent
          side="bottom"
          className="flex flex-col gap-4 p-4 rounded-t-lg"
          onClick={(e) => {
            e.stopPropagation();
            setOpenWhatsIncluded(false);
          }}
        >
          <WhatsIncluded
            service={service}
            setOpenWhatsIncluded={setOpenWhatsIncluded}
            handleBookCTA={handleBookCTA}
          />
        </SheetContent>
      </Sheet>
    </>
  );
};

export default ServiceCard;
