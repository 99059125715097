import { EnquiryType } from "@/utils/constants";

//TODO: move all the endpoints to rtk query once schedule api is shifted to api.drivex
const baseUrl = import.meta.env.VITE_SCHEDULE_API_BASE_URL;
const backstageBaseUrl = import.meta.env.VITE_SCHEDULE_BACKSTAGE_API_BASE_URL;

async function fetchData(
  url: string,
  method: string,
  payload?: object,
  useHeaders: boolean = true,
) {
  const headers: Record<string, string> = useHeaders
    ? {
        "Content-Type": "application/json",
      }
    : {};
  const options: RequestInit = {
    method,
    credentials: "include",
    headers,
  };
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

export async function buyVehicle(payload: object) {
  return fetchData(`${baseUrl}/schedule/buy/`, "POST", payload);
}

export async function verifyPincode(pincode: string) {
  return fetchData(
    `${backstageBaseUrl}/schedule/sell/pincode?pincode=${pincode}`,
    "GET",
    undefined,
    false,
  );
}

export async function sellVehicle(payload: object) {
  const response = await fetchData(
    `${baseUrl}/schedule/sell/`,
    "POST",
    payload,
  );

  if (response.status === "error") {
    throw new Error(JSON.stringify(response.message));
  }
  return response;
}

export async function scheduleService(payload: object) {
  const response = await fetchData(
    `${baseUrl}/schedule/services/`,
    "POST",
    payload,
  );

  if (response.status === "error") {
    throw new Error(JSON.stringify(response.message));
  }
  return response;
}

export async function scheduledTransactionDetails(transactionId: number) {
  return fetchData(
    `${baseUrl}/schedule/buy/${transactionId}`,
    "GET",
    undefined,
    false,
  );
}

export async function scheduledSellerTransactionDetails(transactionId: number) {
  return fetchData(
    `${baseUrl}/schedule/sell/${transactionId}`,
    "GET",
    undefined,
    false,
  );
}

export async function scheduledServiceTransactionDetails(
  transactionId: number,
) {
  return fetchData(
    `${baseUrl}/schedule/services/${transactionId}`,
    "GET",
    undefined,
    false,
  );
}

export async function checkUpcomingTestRide(payload: object) {
  return fetchData(`${baseUrl}/schedule/buy/exists/`, "POST", payload);
}

export async function vehicleInfo(payload: object) {
  const response = await fetchData(
    `${backstageBaseUrl}/vehicle/info/`,
    "POST",
    payload,
  );

  if (response.status === "error") {
    throw new Error(JSON.stringify(response.message));
  }
  return response;
}

export async function getModelAndVariant(brandName: string) {
  return fetchData(
    `${backstageBaseUrl}/vehicle/variants?brand=${brandName}`,
    "GET",
    undefined,
    false,
  );
}

export async function getAllBrands() {
  return fetchData(
    `${backstageBaseUrl}/vehicle/brand-list`,
    "GET",
    undefined,
    false,
  );
}

export async function calculatePrice(payload: object) {
  const response = await fetchData(
    `${baseUrl}/vehicle/calculate-pricing`,
    "POST",
    payload,
  );

  if (response.status === "error") {
    throw new Error(JSON.stringify(response.message));
  }
  return response;
}

export async function scheduleWhatsappTour(payload: object) {
  return fetchData(`${baseUrl}/schedule/whatsapp-tour/`, "POST", payload);
}

export async function enquiry(payload: object, enquiryType: EnquiryType) {
  return fetchData(
    `${baseUrl}/schedule/enquiry/?enquiry_type=${enquiryType}`,
    "POST",
    payload,
  );
}

export async function checkWhatsappTourExists(payload: object) {
  return fetchData(
    `${baseUrl}/schedule/whatsapp-tour/exists/`,
    "POST",
    payload,
  );
}

export async function addToWishList(vehicleId: number) {
  return fetchData(`${baseUrl}/wishlist/`, "POST", { vehicle_id: vehicleId });
}

export async function removeFromWishList(vehicleId: number) {
  return fetchData(`${baseUrl}/wishlist/${vehicleId}`, "DELETE");
}

export async function getWishListedVehicle(compact?: string) {
  const data = await fetchData(`${baseUrl}/wishlist/?${compact}`, "GET");
  if (data.status === "OK") {
    return data?.data;
  }
}

export async function submitFeedback(payload: object) {
  return await fetchData(`${baseUrl}/feedback/enquiry/`, "POST", payload);
}
