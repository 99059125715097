import { whyDrivexData } from "../data";

const WhyDrivex = () => {
  return (
    <div className="flex flex-col gap-4 lg:items-center">
      <p className="text-xl flex gap-1 lg:py-[10px] ">
        Why <span className="text-primaryA2">DriveX</span>
      </p>

      <div className="flex gap-4 flex-wrap lg:flex-nowrap flex-1 justify-center md:justify-start">
        {whyDrivexData.map((item, index) => (
          <div
            key={index}
            className="bg-white flex flex-start flex-col gap-3 p-3 max-[300px]:w-[100%] w-[47%] lg:w-[25%] border-neutral50 border-[1px] rounded-md shadow-sm"
          >
            {item.icon}
            <p className="font-semibold text-base max-w-32">{item.heading}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyDrivex;
