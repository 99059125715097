import Navbar from "@/components/Navbar";
import JoinTheMobility from "./JoinTheMobility";
import EligibilityCriteria from "./EligibilityCriteria";
import { useGetDealerTestimonialsQuery } from "@/store/services/becomeADealer";
import ProcessSteps from "@/components/ProcessSteps";
import { dealerAdvantageSteps, DealersPageFAQsData } from "./data";
import Testimonials from "@/components/Testimonials/Testimonials";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import DealerOnboarding from "./DealerOnboarding";
import FAQs from "@/components/FAQs";
import { track } from "@/utils/mixpanel/actions";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";
import SubmitQuery from "../AboutUs/SubmitQuery";
import Footer from "@/components/Footer";
import KnowMore from "./KnowMore";
import NetworkOfDealers from "./NetworkOfDealers";
import { useRef, useState } from "react";
import { useGetAllStoresQuery } from "@/store/services/dealer";

const Dealer = () => {
  const { isDesktop } = useScreenDetector();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const { citiesData: locationData } = useSelector(
    (state: any) => state.location,
  );
  const [selectedCity, setSelectedCity] = useState("");
  const DealersFormRef = useRef<HTMLDivElement>(null);
  const EligibilityCriteriaRef = useRef<HTMLDivElement>(null);

  const { data: testimonialsData } = useGetDealerTestimonialsQuery({
    params: {
      sort: "orderIndex",
      "populate[0]": "image",
    },
  });

  const {
    data: storesData,
    isFetching: isStoresFetching,
    isError: isStoresError,
  } = useGetAllStoresQuery({
    cityName: selectedCity,
  });

  const DealersAdvantage = `${IMAGE_KIT_BASE_URL}/ik_becomeadealer/DealersAdvantage.webp`;
  const DealersAdvantageWeb = `${IMAGE_KIT_BASE_URL}/ik_becomeadealer/DealersAdvantageWeb.webp`;

  const handleSelectValueChange = (value: string) => {
    setSelectedCity(value);
  };

  const handleApplyNowClick = () => {
    DealersFormRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleCheckEligibilityClick = () => {
    EligibilityCriteriaRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Become a Dealer Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };
  return (
    <>
      <div className="flex flex-col gap-4 bg-white">
        <Navbar
          backgroundColor={"bg-neutral900"}
          showLocation={false}
          showSearchbar={false}
          showLinks={true}
        />

        {/* Join The Mobility */}
        <div className="p-4 pt-[90px] bg-neutral900">
          <JoinTheMobility
            handleApplyNowClick={handleApplyNowClick}
            handleCheckEligibilityClick={handleCheckEligibilityClick}
          />
        </div>

        {/* Eligibility Criteria */}
        <div
          className="flex flex-col gap-4 p-4 bg-white"
          ref={EligibilityCriteriaRef}
        >
          <EligibilityCriteria handleApplyNowClick={handleApplyNowClick} />
        </div>

        {/* Testimonials */}
        {testimonialsData && (
          <div className="content-div bg-white flex flex-col gap-6 p-4 my-5">
            <Testimonials
              data={testimonialsData}
              heading={
                <>
                  Road to Reach{" "}
                  <span className="text-primaryA2">Your Goals</span>{" "}
                </>
              }
            />
          </div>
        )}

        {/* DriveX Advantage */}
        <div className="flex flex-col bg-neutral900">
          <ProcessSteps
            stepsData={dealerAdvantageSteps}
            media={isDesktop ? DealersAdvantageWeb : DealersAdvantage}
            heading={
              <>
                The <span className="text-primaryA2">DriveX Advantage</span>
              </>
            }
            buttonText="Apply Now"
            handleCTAClick={handleApplyNowClick}
          />
        </div>
      </div>

      <div className="flex flex-col gap-4 bg-neutral30 py-4">
        {/* Network Of Dealers */}
        <div className="flex flex-col gap-6 p-4">
          <NetworkOfDealers
            selectedCity={selectedCity}
            storesData={storesData}
            isStoresFetching={isStoresFetching}
            isStoresError={isStoresError}
            locationData={locationData}
            handleSelectValueChange={handleSelectValueChange}
          />
        </div>

        {/* Dealer Onboarding Form */}
        <div className="lg:bg-neutral900" ref={DealersFormRef}>
          <DealerOnboarding user={user} />
        </div>

        {/* Submit Query */}
        <div className="p-4">
          <SubmitQuery />
        </div>

        {/* FAQs */}
        <div className="content-div flex flex-col gap-6 p-4">
          <FAQs content={DealersPageFAQsData} handleFAQClick={handleFAQClick} />
        </div>

        {/* Know More */}
        <div className="flex flex-col gap-10 p-4 max-w-4xl mx-auto w-full">
          <KnowMore />
        </div>
      </div>

      {/* Footer */}
      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </>
  );
};

export default Dealer;
