import React from "react";
import { areaRequirements, documentsChecklist } from "./data";
import { Button } from "@/components/ui/button";

const EligibilityCriteria = ({
  handleApplyNowClick,
}: {
  handleApplyNowClick: () => void;
}) => {
  return (
    <>
      <div className="flex flex-col gap-4">
        <p className="text-2xl font-semibold md:text-center">
          Eligibility <span className="text-primaryA2">Criteria</span>
        </p>
        <p className="text-sm text-neutral300 font-medium md:text-center">
          Trust and transparency sets the foundation for our
          <br className="md:hidden" /> business
        </p>

        <div className="flex flex-col md:flex-row max-w-5xl w-full mx-auto  md:my-4 gap-6">
          <div className="flex flex-col gap-2 bg-neutral30 p-4 rounded-lg w-full">
            <p className="text-lg font-semibold">
              Area <span className="text-primaryA2">Requirements</span>
            </p>
            <p className="text-neutral300 text-sm font-medium">
              Please find necessary area requirements
              <br /> for eligibility below
            </p>

            <div className="flex flex-col gap-2 mt-3">
              {areaRequirements.map((data, index) => (
                <div key={index} className="flex justify-between">
                  <p className="text-sm font-medium">{data.title}</p>
                  <p className="text-sm font-medium">{data.size} sq. ft</p>
                </div>
              ))}
            </div>
            <hr className="border-neutral100 my-2" />
            <div className="flex justify-between">
              <p className="text-sm font-medium">Minimum area</p>
              <p className="text-sm font-medium text-primaryA2">2000 sq. ft</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 bg-neutral30 p-4 rounded-lg w-full">
            <p className="text-lg font-semibold">
              Documents <span className="text-primaryA2">Checklist</span>
            </p>
            <p className="text-neutral300 text-sm font-medium">
              Documents are not mandatory while applying. <br />
              Keep them ready for the later stages
            </p>

            <div className="flex flex-col gap-2 mt-4">
              {documentsChecklist.map((document, index) => (
                <div key={index} className="flex items-center gap-4">
                  <p className="text-sm font-medium">{document.icon}</p>
                  <p className="text-sm font-medium">{document.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <Button
          onClick={() => handleApplyNowClick()}
          variant="outline"
          className="border-2 bg-primaryA2 hover:bg-primaryA2 hover:text-white text-white py-[20px] font-semibold md:max-w-[12rem] mx-auto w-full mb-4 flex-center"
        >
          Apply Now
        </Button>
      </div>
    </>
  );
};

export default EligibilityCriteria;
