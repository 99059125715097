import { cn } from "@/lib/utils";
import { FC, ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

const VehicleListLayout: FC<LayoutProps> = ({ children, className = "" }) => {
  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 mt-4 max-w-7xl mx-auto w-full",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default VehicleListLayout;
