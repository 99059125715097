import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { X as CloseIcon } from "lucide-react";
import { sortContent } from "./data.tsx";
import { TickIcon } from "@/assets/Icons.tsx";

interface typeOfSortSelection {
  id: number;
  sortText: string;
  sort: string;
  sortBy: string;
}

interface Iprops {
  openType: string;
  selectedSortType: typeOfSortSelection | null;
  setOpenType: React.Dispatch<React.SetStateAction<string>>;
  handleSortClick: (arg1: typeOfSortSelection) => void;
}

const SortSheet = ({
  openType,
  setOpenType,
  selectedSortType,
  handleSortClick,
}: Iprops) => {
  return (
    <Sheet
      open={openType === "sort" ? true : false}
      onOpenChange={() => setOpenType("")}
    >
      <SheetContent
        side="bottom"
        className="flex flex-col gap-4 p-4 pt-6 rounded-t-lg"
      >
        <div className="w-[100px] h-1 bg-neutral30 absolute top-2 left-1/2 transform -translate-x-1/2"></div>
        <SheetHeader className="mb-6 mt-2">
          <div className="flex justify-between items-center">
            <div className="flex-center gap-3">
              <p className="text-base font-semibold">Sort</p>
            </div>

            <SheetClose>
              <CloseIcon onClick={() => setOpenType("")} size={20} />
            </SheetClose>
          </div>
        </SheetHeader>
        <div className="flex flex-col gap-4">
          {sortContent.map((content, index) => (
            <div
              key={index}
              className="flex justify-between items-center border-b last:border-0 py-2"
              onClick={() => handleSortClick(content)}
            >
              <p
                className={`text-base font-semibold ${selectedSortType?.id === index ? "text-primaryA2" : ""}`}
              >
                {content.sortText}
              </p>
              {selectedSortType?.id === index ? <TickIcon /> : null}
            </div>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SortSheet;
