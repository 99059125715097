import { cn } from "@/lib/utils";

const DateOrTime = ({ isSelected, heading, subHeading, updateData }: any) => {
  return (
    <div
      onClick={updateData}
      className={cn(
        "max-[300px]:w-[45%] w-[30%] px-1 py-2 gap-1 flex flex-col border-[1px] rounded-lg text-center",
        isSelected && "border-orange-500  bg-neutral30",
      )}
    >
      <p className={cn("text-sm", isSelected && "text-primaryA2")}>{heading}</p>

      <p
        className={cn(
          "text-neutral300 text-xs",
          isSelected && "text-primaryA2",
        )}
      >
        {subHeading}
      </p>
    </div>
  );
};

export default DateOrTime;
