import { cn } from "@/lib/utils";
import { useCallback, useEffect } from "react";
import {
  SelectedCurrentStepComponentProps,
  kmDriven,
  usePriceEstimation,
} from "../data";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useSelector } from "react-redux";
import { usePendingAction } from "@/hooks/usePendingAction";

function SelectKMDriven({
  priceEstimate,
  setPriceEstimate,
  type,
}: SelectedCurrentStepComponentProps) {
  const { getPriceEstimateHandle, loading } = usePriceEstimation();
  const { handlePendingAction } = usePendingAction();
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const location = useLocation();

  const isButtonDisabled = Object.values(priceEstimate.steps).some(
    (value) => value === "",
  );

  const locationState = location.state as {
    vehicleDetails?: {
      pincode: string;
      registrationNumber: string;
      no_of_owner: string;
    };
  };
  const vehicleDetails = locationState?.vehicleDetails;

  const getPinCodeAndRegistrationNumber = async () => {
    if (vehicleDetails) {
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          no_of_owner: vehicleDetails.no_of_owner,
        },
        pin_code: vehicleDetails.pincode,
        ...(type === "automatic" && {
          registration_number: vehicleDetails.registrationNumber,
        }),
      });
    }
  };

  useEffect(() => {
    getPinCodeAndRegistrationNumber();
  }, [vehicleDetails]);

  const handleKMDrivenClick = useCallback(
    (km_driven: { min_km: number; max_km: number; displayValue: string }) => {
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          km_driven: `${km_driven.min_km},${km_driven.max_km}`,
        },
        pin_code: priceEstimate.pin_code,
        ...(type === "automatic" && {
          registration_number: priceEstimate.registration_number,
        }),
      });
    },
    [type, priceEstimate],
  );

  const handleDoneClick = () => {
    if (!user) {
      handlePendingAction({
        actionType: "GET_PRICE_ESTIMATE",
        payload: [{ priceEstimate }],
        method: getPriceEstimateHandle,
      });
      navigate(`${location.pathname}/${location.search}&login=true`, {
        replace: true,
        state: {
          source: `${type}-sell-flow`,
        },
      });
      return;
    }
    getPriceEstimateHandle({ priceEstimate });
  };

  return (
    <div className="mt-4 flex flex-col gap-4 overflow-auto max-h-[55dvh] mb-12">
      {kmDriven.map((km_driven) => {
        return (
          <div
            key={km_driven.displayValue}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              priceEstimate.steps.km_driven ===
                `${km_driven.min_km},${km_driven.max_km}`
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleKMDrivenClick(km_driven)}
          >
            {km_driven.displayValue}
          </div>
        );
      })}

      <Button
        variant="default"
        className="bg-primaryA2 border-none text-white font-bold mx-4 absolute bottom-4 right-0 left-0"
        onClick={handleDoneClick}
        disabled={isButtonDisabled || loading}
        id="calculate-price-button"
      >
        {loading ? "Calculating Price..." : "Calculate Price"}
      </Button>
    </div>
  );
}

export default SelectKMDriven;
