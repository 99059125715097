import { createSlice } from "@reduxjs/toolkit";
import { addToWishList, removeFromWishList } from "../services/scheduleApi";
import { track } from "@/utils/mixpanel/actions";
import { VEHICLE_WISHLISTED } from "@/utils/mixpanel/Events/wishlist_clicked_events";
import { meta_conversion_api } from "@/utils/metaPixels";

const initialState = {
  wishList: [],
};

export const addOrRemoveWishlist = async (
  wishList: number[],
  product: IVehicleInfo,
  location: string,
  toast: any,
  city: string,
  phoneNumber: string,
  dispatch: React.Dispatch<React.SetStateAction<any>>,
) => {
  const getPageSource = () => {
    switch (location) {
      case `/${city}/buy-two-wheelers`:
        return "ProductList";
      case `/${city}/buy-two-wheelers/${product.vehicleId}`:
        return "ProductDetail";
      case `/wishlist`:
        return "WishList";
      default:
        return "StorePage";
    }
  };

  const mixpanelTracking = (wishlist_status: string) => {
    track(VEHICLE_WISHLISTED, {
      vehicle_brand: product.brand,
      vehicle_model: product.modelName,
      store_location: product.storeName,
      vehicle_id: product.vehicleId,
      wishlist_status: wishlist_status,
      page: getPageSource(),
    });
  };

  if (wishList.includes(product?.vehicleId)) {
    const updatedWishList = wishList.filter(
      (id: number) => id !== product?.vehicleId,
    );
    dispatch(setIsWishListed(updatedWishList));

    await removeFromWishList(product?.vehicleId);
    mixpanelTracking("removed");
    toast({
      title: (
        <>
          Removed from wishlist.{" "}
          <a className="underline" href={`/${city}/buy-two-wheelers`}>
            Explore other options here
          </a>
        </>
      ),
      variant: "default",
      duration: 3000,
    });
  } else {
    const updatedWishList = [...wishList, product?.vehicleId];
    dispatch(setIsWishListed(updatedWishList));

    await addToWishList(product?.vehicleId);
    mixpanelTracking("added");
    meta_conversion_api({
      eventName: "Add to wishlist",
      eventId: product?.vehicleId,
      phoneNumber,
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "Add to Wishlist");

    toast({
      title: (
        <>
          Added!{" "}
          <a className="underline" href={"/wishlist"}>
            Explore your wishlist here
          </a>
        </>
      ),
      variant: "success",
      duration: 3000,
    });
  }
};

const wishListSlice = createSlice({
  name: "wishList",
  initialState,
  reducers: {
    setIsWishListed: (state, action) => {
      state.wishList = action?.payload;
    },
  },
});

export const { setIsWishListed } = wishListSlice.actions;

export default wishListSlice.reducer;
