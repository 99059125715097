import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Common function to get headers
const getAuthHeaders = () => ({
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
});

const mediaApiBaseUrl = import.meta.env.VITE_STRAPI_API_BASE_URL;

export const mediaApi = createApi({
  reducerPath: "mediaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: mediaApiBaseUrl,
  }),
  endpoints: (builder) => ({
    getAllMedia: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/medias`,
          params: {
            ...params,
          },
          headers: getAuthHeaders(),
        };
      },
    }),
  }),
});

export const { useGetAllMediaQuery } = mediaApi;
