import Navbar from "@/components/Navbar";
import InspectionChecklist from "./InspectionChecklist";
import { Button } from "@/components/ui/button";
import { Calendar, MapPinIcon, TimerIcon } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { scheduledSellerTransactionDetails } from "@/store/services/scheduleApi";
import { convertToDDMMYYYY, getTimeSlot } from "@/utils/date";
import InspectionDetailMiniCard from "../../components/InspectionDetailMiniCard/InspectionDetailMiniCard";
import { IInspectionDetail } from "../Sell/data";
import Confetti from "react-confetti";
import MiniProductCardSkeletonLoader from "@/components/SkeletonLoaders/MiniProductCardSkeletonLoader";
import Chips from "@/components/SkeletonLoaders/Chips";
import { removeReturningCustomerData } from "@/utils/returningCustomer";
import { ClockFilled } from "@/assets/Icons";

type ExtraProps = {
  date: string;
  time: "MORNING" | "AFTERNOON" | "EVENING";
};

const InspectionScheduled = () => {
  const [transactionDetails, setTransactionDetails] = useState<
    IInspectionDetail & ExtraProps
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const transactionId = searchParams.get("transactionId") || "";

  useEffect(() => {
    if (transactionDetails) return;
    setIsFetching(true);

    const getTransactionDeatils = async () => {
      const res = await scheduledSellerTransactionDetails(
        parseInt(transactionId),
      );

      if (res.status === "OK") {
        setTransactionDetails(res.data);
        setIsFetching(false);

        removeReturningCustomerData({
          vehicleDetail: { res },
        });
        localStorage.removeItem("estimatedPrice");
      }
    };
    getTransactionDeatils();
  }, [transactionDetails, transactionId]);

  return (
    <>
      <Navbar
        backgroundColor="bg-neutral30"
        showLocation={false}
        showSearchbar={false}
      />
      <div className="flex flex-col gap-4 bg-neutral30 h-screen pt-[90px] p-4">
        <div className="flex flex-col items-center text-center gap-4">
          <ClockFilled />
          <p className="text-2xl font-semibold">Home Inspection Scheduled</p>
          <p className="font-medium text-sm">
            Our executive will confirm your Home Inspection <br /> within the
            next 12 hours
          </p>
        </div>

        <div className="w-full ">
          <Confetti numberOfPieces={200} recycle={false} gravity={0.2} />
        </div>

        <div className="flex flex-col gap-2.5 bg-white p-4 rounded-lg shadow-sm max-w-[60rem] mx-auto w-full">
          {isFetching ? (
            <div className="flex flex-col item gap-3">
              <MiniProductCardSkeletonLoader />
              {Array.from({ length: 3 }, (_, index) => (
                <Chips className="h-[13px] w-full max-w-[295px]" key={index} />
              ))}
            </div>
          ) : (
            <>
              {transactionDetails && (
                <InspectionDetailMiniCard
                  productInfo={transactionDetails}
                  showEdit={false}
                  showPriceEstimation={true}
                />
              )}
              <div className="flex gap-2">
                <MapPinIcon size="20" />
                <p className="text-sm font-semibold">
                  {transactionDetails?.pincode}
                </p>
              </div>
              <div className="flex gap-2">
                <Calendar size="20" />
                <p className="text-sm font-semibold">
                  {transactionDetails &&
                    convertToDDMMYYYY(transactionDetails.date)}
                </p>
              </div>
              <div className="flex gap-2">
                <TimerIcon size="20" />
                {transactionDetails?.time && (
                  <p className="text-sm font-semibold">
                    {transactionDetails?.time} (
                    {getTimeSlot(transactionDetails?.time)})
                  </p>
                )}
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-sm max-w-[60rem] mx-auto w-full">
          <InspectionChecklist />
        </div>

        <Button
          variant="outline"
          className="bg-primaryA2 border-none text-white sm:w-[408px] py-6 mb-8 font-semibold text-base w-full max-w-[60rem] mx-auto"
          onClick={() => navigate("/")}
        >
          Go to Home
        </Button>
      </div>
    </>
  );
};

export default InspectionScheduled;
