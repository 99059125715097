import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { useProductDetailQuery } from "@/store/services/product";
import ProductMiniCard from "@/components/ProductMiniCard";
import { useSelector } from "react-redux";
import DateOrTime from "@/components/DateOrTime";
import { buyVehicle } from "@/store/services/scheduleApi";
import { getNextNDays } from "@/utils/date";
import { TIME_SLOTS } from "@/utils/constants";
import { track } from "@/utils/mixpanel/actions";
import { TEST_DRIVE_REQUESTED } from "@/utils/mixpanel/Events/test_drive_clicked_events";
import { algoliaConfigs, initialize_aloglia } from "@/utils/algolia/actions";
import aa from "search-insights";
import { TEST_RIDE_SCHEDULED_EVENT } from "@/utils/algolia/events";
import { Sheet, SheetContent, SheetHeader } from "@/components/ui/sheet";
import { meta_conversion_api } from "@/utils/metaPixels";
import useNavigateWithFallback from "@/hooks/useNavigateWithFallback";

const TestRide = () => {
  const [selectedSchedule, setSelectedSchedule] = useState({
    day: "",
    date: "",
    monthNumber: "",
    dayNumber: "",
    year: "",
    month: "",
    time: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const navigateWithFallback = useNavigateWithFallback();
  const params = useParams();
  const location = useLocation();
  const vehicleId = params.vehicleId;
  const { data: product = {} } = useProductDetailQuery({
    vehicleId,
  });
  const user = useSelector((state: any) => state.user.currentUser);
  const utm_parameters =
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");
  const productInfo = product?.data;
  const nextSixDays = getNextNDays(1, 7);
  const queryID = location?.state?.queryId;
  const objectID = location?.state?.objectId;
  const source = location?.state?.source;
  const url = window.location.href;
  const pdpURL = url.substring(0, url.lastIndexOf("/test-ride"));

  const handleScheduleTestRide = async () => {
    if (isLoading) return;

    setIsLoading(true);
    const res = await buyVehicle({
      store_id: productInfo?.storeId,
      vehicle_id: productInfo?.vehicleId,
      date: `${selectedSchedule.year}-${selectedSchedule.monthNumber}-${selectedSchedule.dayNumber}`,
      user_id: user?.id,
      user_name: user?.userName,
      phone_number: user?.phoneNumber,
      ...utm_parameters,
      time: selectedSchedule.time.toLocaleUpperCase(),
    });
    setIsLoading(false);
    if (res.status === "OK") {
      track(TEST_DRIVE_REQUESTED, {
        ...(source && { source: source }),
        scheduled_date: `${selectedSchedule.year}-${selectedSchedule.monthNumber}-${selectedSchedule.dayNumber}`,
        requested_slot: selectedSchedule.time,
        vehicle_brand: productInfo?.brand,
        vehicle_model: productInfo?.modelName,
        vehicle_name: productInfo?.vehicleName,
        phone_number: user?.phoneNumber,
        name: user?.userName,
        store_name: productInfo?.storeName,
        store_location: productInfo?.storeMapLink,
        vehicle_url: pdpURL,
        ct_date: new Date(
          `${selectedSchedule.monthNumber}/${selectedSchedule.dayNumber}/${selectedSchedule.year}`,
        ),
      });

      if (objectID && queryID) {
        aa(
          "purchasedObjectIDsAfterSearch",
          algoliaConfigs({
            eventName: `${TEST_RIDE_SCHEDULED_EVENT}:${productInfo?.vehicleName}`,
            objectData: [{ queryID: `${queryID}` }],
            queryID: queryID,
            objectIDs: objectID,
            eventType: "conversion",
            positions: undefined,
            index: "reachX-search-engine-dev",
          }),
        );
      }

      meta_conversion_api({
        eventName: "Lead",
        eventId: vehicleId,
        eventType: "Test Ride",
        phoneNumber: user?.phoneNumber,
      });

      // eslint-disable-next-line
      // @ts-ignore
      window.fbq?.("track", "Lead");

      navigate(
        `/${vehicleId}/test-ride-requested?transactionId=${res.data.transaction_id}`,
        {
          replace: true,
        },
      );
    }
  };

  useEffect(() => {
    initialize_aloglia();
  }, []);

  return (
    <Sheet open={true}>
      <SheetContent
        side="right"
        className="bg-neutral30 flex flex-col w-full h-full gap-6 p-0 sm:max-w-[30rem] overflow-y-scroll"
      >
        <SheetHeader className="flex flex-row items-center gap-4 border-b-[1px] p-4">
          <LeftIcon className="mt-[7px]" onClick={navigateWithFallback} />
          <p>Schedule a test drive</p>
        </SheetHeader>

        {productInfo && <ProductMiniCard productInfo={productInfo} />}

        <div className="bg-white p-4 m-4 mt-0 flex flex-col justify-between grow rounded-sm">
          <div className="gap-6 flex-center flex-col">
            <div className="flex flex-col gap-4">
              <p>DriveX Location</p>
              <p className="border-[1px] rounded-sm p-3 flex-center">
                {productInfo?.storeAddress}
              </p>
            </div>

            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm">Select Date</p>

              <div className="flex flex-wrap gap-3">
                {nextSixDays.map((date, index) => (
                  <DateOrTime
                    key={index}
                    isSelected={selectedSchedule.date === date.date}
                    heading={date.date}
                    subHeading={date.day}
                    updateData={() =>
                      setSelectedSchedule((prev: any) => ({ ...prev, ...date }))
                    }
                    data={date}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm">Select Time Slot</p>

              <div className="flex flex-wrap gap-3">
                {TIME_SLOTS.map((slot, index) => (
                  <DateOrTime
                    key={index}
                    isSelected={selectedSchedule.time === slot.timeOfDay}
                    heading={slot.timeOfDay}
                    subHeading={slot.time}
                    updateData={() =>
                      setSelectedSchedule((prev) => ({
                        ...prev,
                        time: slot.timeOfDay,
                      }))
                    }
                    data={slot}
                  />
                ))}
              </div>
            </div>
          </div>

          <Button
            variant="outline"
            onClick={handleScheduleTestRide}
            disabled={isLoading}
            className={cn(
              "w-full bg-primaryA2 border-none text-white text-base py-6 hover:bg-primaryA2 hover:text-white flex gap-2 mt-6",
              (selectedSchedule.date === "" || selectedSchedule.time === "") &&
                "bg-neutral50 text-neutral200 pointer-events-none",
            )}
          >
            Schedule Test Drive
            {isLoading && <LoaderIcon className="animate-spin" />}
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default TestRide;
