import { useCallback, useEffect, useRef, useState } from "react";
import {
  DialogClose,
  DialogContent,
  DialogHeader,
} from "@/components/ui/dialog";
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon,
} from "lucide-react";
import { useGesture } from "@use-gesture/react";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { Helmet } from "react-helmet-async";
import { DOUBLE_CLICK_TIMEOUT } from "./data";
import Image from "@/components/ui/image";
import { PinchZoomIcon } from "@/assets/Icons";

type IProps = {
  productDetail: IVehicleInfo;
  imagePaths: string[];
  setOpenImageViewer: React.Dispatch<React.SetStateAction<boolean>>;
  activeImage?: number;
};

const ImageViewer = ({
  productDetail,
  imagePaths,
  setOpenImageViewer,
  activeImage = 0,
}: IProps) => {
  const { isMobile } = useScreenDetector();
  const [crop, setCrop] = useState({ x: 0, y: 0, scale: 1 });
  const [currentIndex, setCurrentIndex] = useState(activeImage);
  const [dragEnabled, setDragEnabled] = useState(false);
  const [isDoubleClick, setIsDoubleClick] = useState(false);

  const imageRef = useRef<HTMLImageElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const zoomPercentage = crop.scale * 100;

  const handleDoubleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (isDoubleClick) return;
      e.preventDefault();
      const newScale = crop.scale === 1 ? 2 : 1;
      const newCrop = { x: 0, y: 0, scale: newScale };
      setCrop(newCrop);
      setDragEnabled(newScale > 1);
      setIsDoubleClick(true); // Enable quick transition effect for zooming. This aligns with the CSS transition "transform 0.3s" for smooth scaling.

      const time = setTimeout(() => {
        setIsDoubleClick(false); // Reset flag after DOUBLE_CLICK_TIMEOUT ms to disable quick transition, ensuring the transition effect only applies immediately after a double click.
      }, DOUBLE_CLICK_TIMEOUT);

      return () => {
        clearTimeout(time); // Ensure timeout is cleared on component unmount to prevent memory leaks
      };
    },
    [crop.scale, isDoubleClick],
  );

  const handleButtonClick = () => {
    setIsDoubleClick(true); // Temporarily disable double-click functionality
    setTimeout(() => setIsDoubleClick(false), DOUBLE_CLICK_TIMEOUT); // Re-enable after DOUBLE_CLICK_TIMEOUT ms
  };

  useGesture(
    {
      onDrag: ({ offset: [dx, dy], dragging, pinching }) => {
        if (dragging && !pinching) {
          setCrop((crop) => ({ ...crop, x: dx, y: dy }));
        }
      },
      onPinch: ({ offset: [d] }) => {
        setCrop((crop) => ({ ...crop, scale: d }));
      },
      onPinchEnd: () => {
        repositionImageWithinBounds();
        if (crop.scale > 1) {
          setDragEnabled(true);
        }
      },
      onDragEnd: repositionImageWithinBounds,
    },
    {
      drag: {
        from: () => [crop.x, crop.y],
        enabled: dragEnabled,
      },
      pinch: {
        angleBounds: { min: 0 },
        pinchOnWheel: true,
        scaleBounds: { min: 1, max: 4 },
        scaleOnWheel: true,
        scaleOnPinch: true,
      },
      target: imageRef,
      eventOptions: { passive: false },
    },
  );

  useEffect(() => {
    if (crop.scale <= 1) {
      setCrop({ x: 0, y: 0, scale: 1 });
      setDragEnabled(false);
    }
  }, [crop.scale]);

  // Reposition image within bounds after drag or zoom
  function repositionImageWithinBounds() {
    const newCrop = { ...crop };
    const imageBounds = imageRef.current?.getBoundingClientRect();
    const containerBounds = imageContainerRef.current?.getBoundingClientRect();
    const originalWidth = imageRef.current?.clientWidth;

    if (!imageBounds || !containerBounds || !originalWidth) return;

    const widthOverhang = (imageBounds.width - originalWidth) / 2;
    const originalHeight = imageRef.current?.clientHeight;
    const heightOverhang = (imageBounds?.height - originalHeight) / 2;

    if (imageBounds?.left > containerBounds?.left) {
      newCrop.x = widthOverhang; // Align image left edge with container if it overflows on the left
    } else if (imageBounds?.right < containerBounds?.right) {
      newCrop.x = -(imageBounds.width - containerBounds.width) + widthOverhang; // Align image right edge with container if it overflows on the right
    }

    if (imageBounds?.top > containerBounds?.top) {
      newCrop.y = heightOverhang; // Align image top edge with container if it overflows at the top
    } else if (imageBounds?.bottom < containerBounds?.bottom) {
      newCrop.y =
        -(imageBounds?.height - containerBounds?.height) + heightOverhang; // Align image bottom edge with container if it overflows at the bottom
    }

    setCrop(newCrop);
  }

  const goToPrevious = () => {
    handleButtonClick();
    setCrop({ x: 0, y: 0, scale: 1 });
    const newIndex = (currentIndex - 1 + imagePaths.length) % imagePaths.length;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    handleButtonClick();
    setCrop({ x: 0, y: 0, scale: 1 });
    const newIndex = (currentIndex + 1) % imagePaths.length;
    setCurrentIndex(newIndex);
  };

  return (
    <DialogContent className="h-dvh max-w-full p-2.5">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
        />
      </Helmet>
      <DialogHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-2 px-3 z-10">
        <div className="flex justify-between items-center text-sm">
          <div className="flex gap-4">
            <DialogClose
              onClick={() => setOpenImageViewer(false)}
              className="focus:outline-none"
            >
              <LeftIcon />
            </DialogClose>

            <div className="flex flex-col gap-1 items-start">
              <p>{productDetail.vehicleName}</p>
              <div className="flex">
                <p className="text-orange-500">
                  ₹{productDetail?.price?.displayValue}
                </p>
              </div>
            </div>
          </div>
          <span className="text-xs text-gray-400">
            {currentIndex + 1}/{imagePaths.length}
          </span>
        </div>
      </DialogHeader>

      {zoomPercentage > 100 && (
        <div className="absolute top-20 right-6 z-10 bg-neutral700 text-white px-2 py-1.5 rounded-md text-sm">
          {Math.round(zoomPercentage)}%
        </div>
      )}

      <div className="w-full flex flex-col justify-center items-center h-full">
        <div
          ref={imageContainerRef}
          onDoubleClick={handleDoubleClick}
          className="relative w-full rounded-md max-w-3xl"
        >
          <Image
            ref={imageRef}
            style={{
              left: crop.x,
              top: crop.y,
              transform: `scale(${crop.scale})`,
              touchAction: "none",
              transition: isDoubleClick
                ? `transform ${DOUBLE_CLICK_TIMEOUT}ms`
                : "none",
            }}
            src={imagePaths[currentIndex]}
            alt={`Slide ${currentIndex}`}
            className="h-[calc(100dvh-200px)] sm:h-[70dvh] object-contain w-full relative"
          />
          {zoomPercentage === 100 && (
            <>
              <button
                onClick={goToPrevious}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white rounded-sm shadow-sm p-1 border-[1.5px] border-primaryA1"
              >
                <LeftIcon color="#F47823" />
              </button>
              <button
                onClick={goToNext}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white rounded-sm shadow-sm p-1 border-[1.5px] border-primaryA1"
              >
                <RightIcon color="#F47823" />
              </button>
            </>
          )}
        </div>

        {isMobile && (
          <div className="flex justify-center items-center mt-4">
            <PinchZoomIcon />
            <p className="text-base text-gray-400 pl-1.5">Pinch out to zoom</p>
          </div>
        )}
      </div>
    </DialogContent>
  );
};

export default ImageViewer;
