interface EmptyOrderedVehicleListProps {
  heading?: string;
  description?: string;
  cta?: string;
}

export const EmptyOrderedVehicleListData = ({
  heading,
  description,
  cta,
}: EmptyOrderedVehicleListProps) => {
  if (heading) {
    if (heading === "buy") {
      return "Your Dream Bike Awaits";
    } else if (heading === "sell") {
      return "Ready to Sell? Start Here!";
    } else {
      return "You have no service requests";
    }
  }

  if (description) {
    if (description === "buy") {
      return (
        <>
          Explore our collection and schedule <br /> your test drive now
        </>
      );
    } else if (description === "sell") {
      return (
        <>
          Schedule a free home inspection for <br /> your two-wheeler today
        </>
      );
    } else {
      return "Schedule a bike service today!";
    }
  }

  if (cta) {
    if (cta === "buy") {
      return "Browse Collection";
    } else if (cta === "sell") {
      return "Schedule Now";
    } else {
      return "Book a service";
    }
  }
};
