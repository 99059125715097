import InputWithIcon from "@/components/InputWithIcon";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

interface IProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  renderPincodeSuccessOrErrorIcon: (arg1: string) => ReactNode;
  isValidVehicleRegistrationNumber: string;
  vehicleDetails: { store_id: string; registrationNumber: string };
}

const Automatic = ({
  handleChange,
  isValidVehicleRegistrationNumber,
  vehicleDetails,
  renderPincodeSuccessOrErrorIcon,
}: IProps) => {
  return (
    <div className="grid w-full items-center gap-1.5">
      <label htmlFor="terms" className="text-sm font-semibold leading-2">
        Enter vehicle registration number
      </label>

      <InputWithIcon
        parentClassName={cn(
          isValidVehicleRegistrationNumber === "invalid" && "bg-red100",
        )}
        className={cn(
          "py-6 bg-neutral30",
          isValidVehicleRegistrationNumber === "invalid" && "bg-red100",
        )}
        type="text"
        name="registrationNumber"
        placeholder="eg. TN 58 AH 34XXX"
        value={vehicleDetails.registrationNumber}
        handleChange={handleChange}
        icon={renderPincodeSuccessOrErrorIcon(isValidVehicleRegistrationNumber)}
      />
    </div>
  );
};

export default Automatic;
