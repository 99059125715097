import { useEffect, useState } from "react";
import { LogoutIcon } from "@/assets/Icons.tsx";
import UserDetails from "@/components/Sidebar/UserDetails";
import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import { Dialog } from "@/components/ui/dialog";
import EditProfile from "./EditProfile/index.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import BuySellTab from "./BuySellTab";
import EmptyOrderedVehicleList from "./EmptyOrderedVehicleList.tsx";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "@/store/services/authApi.tsx";
import ShowOrderedVehicle from "./ShowOrderedVehicle/index.tsx";
import { useVehicleOrdersQuery } from "@/store/services/ordersApi.ts";
import { track } from "@/utils/mixpanel/actions.ts";
import { LOGOUT_CLICKED } from "@/utils/mixpanel/Events/auth_clicked_events.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll.tsx";
import Chips from "@/components/SkeletonLoaders/Chips/index.tsx";
import MiniProductCardSkeletonLoader from "@/components/SkeletonLoaders/MiniProductCardSkeletonLoader/index.tsx";
import { setCurrentUser } from "@/store/features/userSlice.ts";

const Profile = () => {
  const { city } = useCurrentCity();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [containerRef, isVisible] = useInfiniteScroll({
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });
  const user = useSelector((state: any) => state.user.currentUser);
  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const [isTabSelected, setIsTabSelected] = useState(
    location?.state?.text ? "sell" : "buy",
  );
  const [buyPage, setBuyPage] = useState(1);
  const [sellPage, setSellPage] = useState(1);
  const [servicePage, setServicePage] = useState(1);
  const [orderedVehicleData, setOrderedVehicleData] = useState<any>([]);
  const { data: buyData, isFetching: isFetchingBuyData } =
    useVehicleOrdersQuery({
      tab: "BUY",
      page: buyPage,
    });

  const { data: sellData, isFetching: isFetchingSellData } =
    useVehicleOrdersQuery({
      tab: "SELL",
      page: sellPage,
    });

  const { data: serviceData, isFetching: isFetchingServiceData } =
    useVehicleOrdersQuery({
      tab: "SERVICE",
      page: servicePage,
    });

  const hasMoreBuyData = buyPage >= buyData?.data?.totalPages;
  const hasMoreSellData = sellPage >= sellData?.data?.totalPages;
  const hasMoreServiceData = servicePage >= serviceData?.data?.totalPages;

  const getPageByTab = () => {
    if (isTabSelected === "buy") {
      return buyPage;
    } else if (isTabSelected === "sell") {
      return sellPage;
    } else {
      return servicePage;
    }
  };

  useEffect(() => {
    if ((buyPage || sellPage || servicePage) > 1) {
      if (isTabSelected === "buy") {
        setOrderedVehicleData([...orderedVehicleData, ...buyData.data.orders]);
        return;
      } else if (isTabSelected === "sell") {
        setOrderedVehicleData([...orderedVehicleData, ...sellData.data.orders]);
        return;
      }

      setOrderedVehicleData([
        ...orderedVehicleData,
        ...serviceData.data.orders,
      ]);
      return;
    }

    setOrderedVehicleData(
      isTabSelected === "buy"
        ? buyData?.data?.orders
        : isTabSelected === "sell"
          ? sellData?.data?.orders
          : serviceData?.data?.orders,
    );
  }, [buyData, sellData, serviceData]);

  useEffect(() => {
    if (isTabSelected === "buy") {
      setBuyPage(1);
    } else if (isTabSelected === "sell") {
      setSellPage(1);
    } else {
      setServicePage(1);
    }
  }, [isTabSelected]);

  useEffect(() => {
    if (isVisible && isTabSelected === "buy" && !hasMoreBuyData) {
      setBuyPage((prev) => prev + 1);
    } else if (isVisible && isTabSelected === "sell" && !hasMoreSellData) {
      setSellPage((prev) => prev + 1);
    } else if (
      isVisible &&
      isTabSelected === "services" &&
      !hasMoreServiceData
    ) {
      setServicePage((prev) => prev + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  const handleEditClick = () => {
    setOpenProfileEdit(true);
  };

  const handleLogout = async () => {
    await logout();
    dispatch(setCurrentUser(null));
    localStorage.removeItem("encodedUserId");
    localStorage.removeItem("isUserLoggedIn");
    track(LOGOUT_CLICKED, {
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate("/");
  };

  const handleButtonClick = () => {
    if (isTabSelected === "buy") {
      navigate(`/${city}/buy-two-wheelers`);
      return;
    } else if (isTabSelected === "sell") {
      navigate("/sell-two-wheelers");
      return;
    }

    navigate("/service");
  };

  const handleOrderedVehicleCardClick = (Id: number, isAvailable?: boolean) => {
    if (isTabSelected === "buy") {
      if (!isAvailable) return;

      navigate(`/${city}/buy-two-wheelers/${Id}`);
      return;
    } else if (isTabSelected === "sell") {
      navigate(`/inspectionscheduled?transactionId=${Id}`);
      return;
    }

    navigate(`/service/service-requested?transactionId=${Id}`);
  };

  return (
    <div className="flex flex-col gap-6 bg-neutral30 sm:items-center min-h-screen p-4">
      <div className="flex justify-between sm:w-[50%] lg:w-[40%]">
        <div className="flex gap-3" onClick={() => navigate(-1)}>
          <LeftIcon />
          <p>Account</p>
        </div>

        <div className="flex items-center gap-3" onClick={handleLogout}>
          <p className="text-primaryA2">Logout</p>
          <LogoutIcon />
        </div>
      </div>

      <div className="flex gap-4 py-2 sm:w-[50%] lg:w-[40%]">
        {user && (
          <UserDetails
            user={user}
            updateEditButton={"true"}
            handleEditClick={handleEditClick}
          />
        )}
      </div>

      {openProfileEdit && (
        <Dialog open={openProfileEdit}>
          <EditProfile setOpenProfileEdit={setOpenProfileEdit} />
        </Dialog>
      )}

      <div className="flex bg-white py-3 justify-center rounded-md sm:w-[50%] lg:w-[33%]">
        <BuySellTab
          isTabSelected={isTabSelected}
          setIsTabSelected={setIsTabSelected}
          setOrderedVehicleData={setOrderedVehicleData}
          buyData={buyData}
          sellData={sellData}
          serviceData={serviceData}
          isFetchingData={isFetchingBuyData}
        />
      </div>

      {(isFetchingBuyData || isFetchingSellData || isFetchingServiceData) &&
      getPageByTab() === 1 ? (
        Array.from({ length: 4 }, (_, index) => (
          <div key={index} className="flex flex-col item gap-3 bg-white p-4">
            <Chips className="h-[30px] w-full max-w-[295px] rounded-md" />
            <Chips className="h-[13px] w-full max-w-[295px]" />
            <MiniProductCardSkeletonLoader />
            <Chips className="h-[13px] w-full max-w-[295px]" />
            <Chips className="h-[13px] w-full max-w-[295px]" />
          </div>
        ))
      ) : orderedVehicleData && orderedVehicleData.length > 0 ? (
        orderedVehicleData.map((vehicle: IVehicleInfo, index: number) => {
          if (orderedVehicleData.length === index + 1)
            return (
              <>
                <ShowOrderedVehicle
                  key={index}
                  productInfo={vehicle}
                  isTabSelected={isTabSelected}
                  handleOrderedVehicleCardClick={handleOrderedVehicleCardClick}
                  containerRef={containerRef}
                />

                <div className="h-20 w-full flex items-center justify-center">
                  {(isFetchingBuyData ||
                    isFetchingSellData ||
                    isFetchingServiceData) &&
                  (!hasMoreBuyData ||
                    !hasMoreSellData ||
                    !hasMoreServiceData) ? (
                    <LoaderIcon className="animate-spin" color="#F47823" />
                  ) : (
                    <div className="text-primaryA2 text-sm">No more data</div>
                  )}
                </div>
              </>
            );
          else
            return (
              <ShowOrderedVehicle
                key={index}
                productInfo={vehicle}
                isTabSelected={isTabSelected}
                handleOrderedVehicleCardClick={handleOrderedVehicleCardClick}
              />
            );
        })
      ) : (
        <EmptyOrderedVehicleList
          isTabSelected={isTabSelected}
          handleButtonClick={handleButtonClick}
        />
      )}
    </div>
  );
};

export default Profile;
