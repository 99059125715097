import { TIME_SLOTS } from "./constants";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getNextNDays(start: number, end: number) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const today = new Date();
  const result = [];

  for (let i = start; i < end; i++) {
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + i);

    const dayOfWeek = days[nextDate.getDay()];
    const month = months[nextDate.getMonth()];
    const date = nextDate.getDate();
    const monthNumber = months.indexOf(month) + 1;

    result.push({
      day: dayOfWeek,
      date: `${month} ${date}`,
      monthNumber: monthNumber < 10 ? `0${monthNumber}` : monthNumber,
      dayNumber: date < 10 ? `0${date}` : date,
      year: nextDate.getFullYear(),
      month,
    });
  }

  return result;
}

// Converts YYYY-MM-DD to DD month YYYY i.e: 2024-03-04 -> 04 Mar 2024
export function convertToDDMMYYYY(inputDate: string) {
  const [year, month, day] = inputDate.split("-");
  const formattedMonth = months[parseInt(month) - 1];
  return `${day} ${formattedMonth} ${year}`;
}

// Converts UTC to DD-MM-YYYY
export function convertUTCToDDMMYYYY(dateString: string) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}

// Converts the date format from DD-MM-YYYY to DD/MM/YYYY
export const convertDateToSlashFormat = (date: string) =>
  date.replace(/-/g, "/");

export const getCurrentYear = new Date().getFullYear();

export const getPreviousNYears = (numberOfYears: number) => {
  const result = [];
  const lastYear = getCurrentYear - numberOfYears; // Calculate the last year based on the current year

  for (let year = getCurrentYear; year >= lastYear; year--) {
    result.push(year);
  }

  return result;
};

export const getTimeSlot = (time: string) => {
  return TIME_SLOTS.filter((t) => t.timeOfDay.toUpperCase() === time)[0]?.time;
};

// Formats a UTC date string to a more readable format "DD Mon YYYY"
export const formatUTCDate = (dateString: Date) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

// Returns the date in the format of "YYYY-MM-DD HH:mm:ss.sssZ".
// since only the date is required, hence using slice to get only the date.
export const getCurrentDate = () => {
  const date = new Date().toISOString().slice(0, 10);
  return date;
};

// Returns the date and time in the format of "YYYY-MM-DD T HH:mm:ss.sssZ".
export const getCurrentDateAndTime = () => {
  return new Date().toISOString();
};

export const getDateAfterNDays = (numberOfDays: number) => {
  return new Date(
    new Date().getTime() + numberOfDays * 24 * 60 * 60 * 1000,
  ).toISOString();
};

export const getDecadeRange = () => {
  const currentYear = new Date().getFullYear();
  const full10Years = [];

  for (let i = -12; i <= 0; i++) {
    full10Years.push(currentYear + i);
  }

  return full10Years;
};
