import { getTransformedSrc } from "@/utils/transformedSrc";

type VideoProps = {
  src: string;
  transform?: string;
  className?: string;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

const Video: React.FC<VideoProps> = ({
  src,
  transform,
  className,
  ...props
}) => {
  const { transformedSrc } = getTransformedSrc(src, transform);

  return (
    <video
      src={transformedSrc}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline
      className={className}
      {...props}
    />
  );
};

export default Video;
