import { AscendingIcon, DescendingIcon } from "@/assets/Icons";

export const sortOptions = [
  {
    label: "Newest First",
    value: "newest",
    icon: (isActive: boolean) => (
      <DescendingIcon {...(isActive && { fill: "#F47823" })} />
    ),
  },
  {
    label: "Oldest First",
    value: "oldest",
    icon: (isActive: boolean) => (
      <AscendingIcon {...(isActive && { fill: "#F47823" })} />
    ),
  },
];

export const getSortedVehicles = (
  productData: IVehicleInfo[],
  sortOption: string,
) => {
  const sortedVehicles = [...productData];

  sortedVehicles.sort((a: any, b: any) => {
    if (sortOption === "newest") {
      // Sort from latest to oldest
      return (
        new Date(b.order_updated_at).getTime() -
        new Date(a.order_updated_at).getTime()
      );
    }

    // Sort from oldest to latest
    return (
      new Date(a.order_updated_at).getTime() -
      new Date(b.order_updated_at).getTime()
    );
  });

  return sortedVehicles;
};
