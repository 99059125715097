import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { SelectedCurrentStepComponentProps } from "../data";

type ExtraProps = {
  variants: any[];
};

function SelectVariant({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
  variants,
}: SelectedCurrentStepComponentProps & ExtraProps) {
  const handleVariantClick = useCallback(
    (variant: string) => {
      setSearchParams({ step: "no_of_owner", type });
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          variant,
        },
        pin_code: priceEstimate.pin_code,
      });
    },
    [type, priceEstimate],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {variants.map((variant) => {
        return (
          <div
            key={variant}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              priceEstimate.steps.variant === variant
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleVariantClick(String(variant))}
          >
            {variant}
          </div>
        );
      })}
    </div>
  );
}

export default SelectVariant;
