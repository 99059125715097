import React, { useState } from "react";
import { sortOptions } from "./data";
import { cn } from "@/lib/utils";
const Sort = ({
  handleSortClick,
}: {
  handleSortClick: (sortOption: string) => void;
}) => {
  const [isSelected, setIsSelected] = useState("");

  return (
    <div className="flex items-center gap-2 max-w-xl w-full">
      {sortOptions.map((sort) => {
        const isActive = sort.value === isSelected;
        return (
          <div
            key={sort.value}
            className={cn(
              "flex justify-center items-center gap-2 py-2 w-full bg-white rounded-md border border-neutral100",
              isActive && "border-primaryA1",
            )}
            onClick={() => {
              handleSortClick(sort.value);
              setIsSelected(sort.value);
            }}
          >
            <p
              className={cn(
                "text-xs font-semibold",
                isActive && "text-primaryA1",
              )}
            >
              {sort.label}
            </p>
            {sort.icon(isActive)}
          </div>
        );
      })}
    </div>
  );
};

export default Sort;
