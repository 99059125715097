import ScrollView from "@/components/ScrollView";
import StoreCardSkeletonLoader from "@/components/SkeletonLoaders/Store";
import StoreCard from "@/components/Store/StoreCard";
import { cn } from "@/lib/utils";
import { pluralize } from "@/utils/pluralize";
import SelectCity from "./SelectCity";
import { useNavigate } from "react-router-dom";
import { useScreenDetector } from "@/hooks/useScreenDetector";

interface IProps {
  selectedCity: string;
  storesData: {
    data?: {
      stores: IStore[];
    };
  };
  isStoresFetching: boolean;
  isStoresError?: boolean;
  locationData: any;
  handleSelectValueChange: (value: string) => void;
}

const NetworkOfDealers = ({
  selectedCity,
  storesData,
  isStoresFetching,
  isStoresError,
  locationData,
  handleSelectValueChange,
}: IProps) => {
  const { isMobile } = useScreenDetector();
  const navigate = useNavigate();
  const stores: IStore[] = storesData?.data?.stores ?? [];
  const storesCount = stores?.length;

  const isCitySelected = selectedCity !== "";

  const handleStoreCardClick = (stores: { city: string; slug: string }) => {
    navigate(`/${stores?.city}/${stores?.slug}`);
  };
  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-center gap-6 md:gap-[18rem] md:my-10">
        <div className="flex md:flex-col md:justify-center md:gap-6 justify-between items-center">
          <p className="text-xl md:text-3xl md:text-center font-semibold">
            Our Expanding <br />
            <span className="text-primaryA2">Network of Dealers</span>
          </p>

          <SelectCity
            locationData={locationData}
            handleSelectValueChange={handleSelectValueChange}
          />
        </div>
      </div>

      {isCitySelected && (
        <p className="text-base md:text-center font-semibold">
          {storesCount} {pluralize(storesCount, "store")} in{" "}
          <span className="text-primaryA2">{selectedCity}</span>
        </p>
      )}

      {isCitySelected && (
        <div className="md:px-[4rem]">
          <ScrollView
            data={stores}
            className={cn("px-4 pt-4", isMobile && "px-0")}
            isFetching={isStoresFetching}
            isError={isStoresError}
            renderItem={(store) => {
              return (
                <div
                  key={store.store_id}
                  className={cn(
                    "min-w-[85%] sm:min-w-[300px] pb-1",
                    stores?.length > 1 && "mr-4",
                  )}
                >
                  <StoreCard
                    store={store}
                    handleStoreClick={handleStoreCardClick}
                  />
                </div>
              );
            }}
            SkeletonLoader={() => {
              return (
                <div className="min-w-[350px] mr-4">
                  <StoreCardSkeletonLoader />
                </div>
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default NetworkOfDealers;
