import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allBrands: null,
};

const allBrandsSlice = createSlice({
  name: "allBrands",
  initialState,
  reducers: {
    setCurrentBrands: (state, action) => {
      state.allBrands = action?.payload;
    },
  },
});

export const { setCurrentBrands } = allBrandsSlice.actions;

export default allBrandsSlice.reducer;
