import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { X as CloseIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface IProps {
  confirmWhatsappTour: boolean;
  setConfirmWhatsappTour: React.Dispatch<React.SetStateAction<any>>;
  handleProceed: () => void;
}

const ConfirmWhatsappTour = ({
  confirmWhatsappTour,
  setConfirmWhatsappTour,
  handleProceed,
}: IProps) => {
  return (
    <Sheet
      open={confirmWhatsappTour}
      onOpenChange={() => setConfirmWhatsappTour(false)}
    >
      <SheetContent
        side="bottom"
        className="flex flex-col gap-5 p-4 pt-6 rounded-t-lg"
      >
        <div className="w-[100px] h-1 bg-neutral30 absolute top-2 left-1/2 transform -translate-x-1/2"></div>
        <SheetHeader className="mb-2 mt-2">
          <div className="flex justify-between items-center">
            <div className="flex-center gap-3">
              <p className="text-xl">Connecting You Now</p>
            </div>

            <SheetClose>
              <CloseIcon size={20} />
            </SheetClose>
          </div>
        </SheetHeader>

        <p className="text-neutral300 text-sm ">
          Proceed to WhatsApp to connect with DriveX Executive
        </p>

        <Button
          onClick={handleProceed}
          variant="outline"
          className={cn(
            "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
          )}
        >
          Proceed
        </Button>
      </SheetContent>
    </Sheet>
  );
};

export default ConfirmWhatsappTour;
