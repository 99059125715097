import { ArrowStraightUpRightIcon } from "@/assets/Icons";
import { Link } from "react-router-dom";
import { BoardOfDirectorsLoader } from "@/components/SkeletonLoaders/AboutUs";
import Image from "@/components/ui/image";

type IProps = {
  boardOfDirectors: any;
  isFetchingBodData: boolean;
};

const BoardOfDirectors = ({ boardOfDirectors, isFetchingBodData }: IProps) => {
  const skeletonLoader = Array.from({ length: 8 }, (_, index) => (
    <BoardOfDirectorsLoader key={index} />
  ));
  return (
    <>
      <p className="text-xl sm:text-center font-semibold">
        Board of <span className="text-primaryA2">Directors</span>
      </p>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-[100%]">
        {isFetchingBodData
          ? skeletonLoader
          : boardOfDirectors?.map((director: any, index: number) => (
              <div key={index} className="flex flex-col gap-4">
                <Image
                  className="object-cover w-[10.5rem] lg:w-[14rem] lg:h-[15rem] h-[10.5rem] rounded-lg"
                  src={
                    director?.attributes?.Director_image?.data?.attributes
                      ?.url as string
                  }
                  alt={director?.attributes?.Name}
                />
                <div className="flex flex-col gap-1.5">
                  <p className="text-sm font-medium">
                    {director?.attributes?.Name}
                  </p>
                  <p className="text-xs font-medium text-primaryA2">
                    {director?.attributes?.Position}
                  </p>
                  <p className="text-xs text-neutral300 font-normal">
                    {director?.attributes?.Company}
                  </p>
                  <div className="flex justify-between items-center">
                    <Link
                      to={director?.attributes?.Linkedin_url}
                      target="_blank"
                      className="text-xs font-medium text-primaryB1 underline"
                    >
                      LinkedIn
                    </Link>
                    <ArrowStraightUpRightIcon />
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default BoardOfDirectors;
