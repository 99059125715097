import React, { useState } from "react";
import { Sheet, SheetContent, SheetHeader, SheetClose } from "../ui/sheet";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { CrossIcon } from "@/assets/Icons";
import { editVehicleFormFields } from "@/DmxPages/StoreDetail/data";
import { Input } from "../ui/input";
import { formatToIndianNumber } from "@/utils/numerics";
import { Button } from "../ui/button";
import { useEditVehicleDetailsMutation } from "@/store/services/Dmx/storeVehicles";
import { useToast } from "../ui/use-toast";
import { cn } from "@/lib/utils";
import { track } from "@/utils/mixpanel/actions";
import { DX_EDIT_DETAILS } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { useSelector } from "react-redux";

type EditVehicleDetailsModalProps = {
  open: boolean;
  onClose: () => void;
  vehicleDetails: any;
  refetchStoreVehicle: () => void;
};

function EditVehicleDetailsModal({
  open,
  onClose,
  vehicleDetails,
  refetchStoreVehicle,
}: EditVehicleDetailsModalProps) {
  const user = useSelector((state: any) => state.user.currentUser);
  const role = localStorage.getItem("role");
  const store = useSelector((state: any) => state.store.store);
  const { isMobile } = useScreenDetector();
  const { toast } = useToast();
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});
  const [formData, setFormData] = useState({
    sellingPrice: vehicleDetails?.price.displayValue || "",
    kmDriven: vehicleDetails?.kmDriven.displayValue || "",
  });

  const [editVehicleDetails, { isLoading }] = useEditVehicleDetailsMutation();

  const isVehicleAssured = vehicleDetails?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  const isDisabled = Object.values(formData).some((value) => value === "");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, "");

    const { name, value } = e.target;

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }

    const kmDriven = (name === "kmDriven" && value?.replaceAll(/,/g, "")) || "";

    if (Number(kmDriven) < vehicleDetails?.kmDriven?.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven: "KMs driven cannot be less than the last recorded value",
      }));
    }

    if (Number(kmDriven) > vehicleDetails?.kmDriven?.value + 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven:
          "KMs driven cannot exceed 100 kilometers more than the last recorded value.",
      }));
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleEditVehicleDetails = async () => {
    const payload = {
      kms_driven: Number(formData.kmDriven.replace(/,/g, "")),
      sales_price: Number(formData.sellingPrice.replace(/,/g, "")),
    };

    const res: any = await editVehicleDetails({
      vehicle_id: vehicleDetails?.vehicleId,
      payload,
    });

    if (res?.data?.status === "OK") {
      track(DX_EDIT_DETAILS, {
        role: role,
        user_id: user?.id,
        vehicle_name: vehicleDetails?.vehicleName,
        vehicle_id: vehicleDetails?.vehicleId,
        old_sale_price: vehicleDetails?.price?.displayValue,
        new_sale_price: formData?.sellingPrice,
        old_km_driven: vehicleDetails?.kmDriven?.displayValue,
        new_km_driven: formData?.kmDriven,
        inventory_type: isVehicleAssured,
        store_name: vehicleDetails?.storeName,
      });

      toast({
        title: "Vehicle details updated successfully",
        variant: "success",
      });
      refetchStoreVehicle();
    }

    if (res?.error?.data?.status === "error") {
      toast({
        title: res?.error?.data?.message,
        variant: "destructive",
      });
    }

    onClose();
  };

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className={cn(
          "flex flex-col gap-4 p-4 rounded-t-lg h-1/2",
          !isMobile && "h-dvh",
        )}
      >
        <SheetHeader>
          <div className="flex justify-between items-center">
            <p className="text-lg">Edit Bike Details</p>

            <SheetClose onClick={onClose} className="outline-none">
              <CrossIcon size={26} oneStopColor="#000" twoStopColor="#000" />
            </SheetClose>
          </div>
        </SheetHeader>

        <hr className=" border-t-[1px] border-neutral50 w-full" />

        <div className="flex flex-col gap-5">
          {editVehicleFormFields.map((field) => {
            let value = formData[field.name as keyof typeof formData];
            if (field.type === "number") {
              value = formatToIndianNumber(value);
            }
            return (
              <div key={field.name} className="flex flex-col gap-2">
                <div className="flex gap-0.5">
                  <label htmlFor={field.name} className="text-sm font-semibold">
                    {field.label}
                  </label>
                  {field.required && <p className="text-red-600">*</p>}
                </div>

                <Input
                  className={cn(
                    "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                    errors[field.name] && "bg-red-100",
                  )}
                  name={field.name}
                  placeholder={field.placeholder}
                  required={field.required}
                  value={value}
                  onChange={handleInputChange}
                  maxLength={10}
                  disabled={field.disabled === store?.partner_type}
                />
                {typeof errors[field.name] === "string" && (
                  <p className="text-red-500 text-sm">
                    {errors[field.name] as string}
                  </p>
                )}
              </div>
            );
          })}
        </div>

        <div className="bg-white border-t absolute bottom-0 right-0 left-0 w-full py-2 px-3 flex justify-between gap-2 items-center">
          <Button
            variant="outline"
            className="flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="outline"
            className="bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white"
            disabled={
              isDisabled ||
              isLoading ||
              Object.values(errors).some((error) => error !== false)
            }
            onClick={handleEditVehicleDetails}
          >
            Save
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default EditVehicleDetailsModal;
