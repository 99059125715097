import { cn } from "@/lib/utils";
import Image from "../ui/image";

interface IProps {
  brand: {
    img: unknown;
    name: string;
    availability?: string;
  };
  className?: string;
  size?: boolean;
  handleClick: () => void;
}

const Brand = ({ brand, className, size, handleClick }: IProps) => {
  return (
    <div
      data-test-id="brands"
      onClick={handleClick}
      className={cn(
        "flex flex-col gap-2 p-4 w-48 bg-neutral30  rounded-md items-center",
        className,
      )}
    >
      <Image
        transform="f-webp,w-100,h-100,c-at_max"
        className="rounded-full w-12 h-12 object-contain bg-white"
        src={brand.img as string}
        alt={`${brand.name}`}
      />
      <p>{brand.name}</p>
      {brand.availability && (
        <p
          className={cn(
            "text-neutral300 whitespace-nowrap",
            size ? "text-sm" : "text-md",
          )}
        >
          {brand.availability}
        </p>
      )}
    </div>
  );
};

export default Brand;
