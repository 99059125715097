import { Button } from "@/components/ui/button";
import Estimation from "./Estimation";
import InspectionDetailMiniCard from "../../../components/InspectionDetailMiniCard/InspectionDetailMiniCard";
import { IInspectionDetail } from "../../Sell/data";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { track } from "@/utils/mixpanel/actions";
import { SELL_REQ_INSPECTION_INITIATED } from "@/utils/mixpanel/Events/sell_flow_events";

const FinalPriceEstimation = ({
  estimatedPrice,
}: {
  estimatedPrice: IInspectionDetail;
}) => {
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.user.currentUser);

  const bookInspectionHandle = () => {
    const {
      brand,
      model,
      state,
      variant,
      manufacturing_year,
      pincode,
      registration_number,
      no_of_owners,
      min_km_range,
      max_km_range,
    } = estimatedPrice;

    navigate("/sell-two-wheelers/bike-inspection", { replace: true });

    track(SELL_REQ_INSPECTION_INITIATED, {
      pincode,
      kms_driven: `${min_km_range}-${max_km_range} KM`,
      no_of_owners,
      ...(registration_number && {
        registration_number,
      }),
      state,
      model,
      variant,
      brand,
      manufacturing_year,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-md mt-6 w-full md:w-[40%] xl:w-[460px] relative">
      <InspectionDetailMiniCard productInfo={estimatedPrice} />
      <Estimation estimatedPrice={estimatedPrice} />

      <Button
        variant="outline"
        className="flex gap-3 bg-primaryA2 border-none text-white text-base font-semibold py-6"
        onClick={bookInspectionHandle}
      >
        Book Free Home Inspection
      </Button>
    </div>
  );
};

export default FinalPriceEstimation;
