import { SelectedCurrentStepComponentProps } from "../../data";
import ServiceCard from "@/components/ServiceCard";
import Pill from "@/components/Pill";
import { TimeIcon } from "@/assets/Icons";
import { useCallback } from "react";
import { cn } from "@/lib/utils";
import { useGetServicePackagesBasedOnStoreQuery } from "@/store/services/services";
import { pluralize } from "@/utils/pluralize";

function SelectService({
  serviceFlowData,
  setSearchParams,
  setServiceFlowData,
  type,
}: SelectedCurrentStepComponentProps) {
  const { data } = useGetServicePackagesBasedOnStoreQuery({
    storeId: serviceFlowData.store_id,
  });
  const servicePackages = data?.data;

  const handleServicePackageClick = useCallback(
    (servicePackage: IService) => {
      setSearchParams({ step: "schedule", type });
      setServiceFlowData({
        steps: {
          ...serviceFlowData.steps,
          service: servicePackage,
        },
        store_id: serviceFlowData.store_id,
        store_address: serviceFlowData.store_address,
        ...(type === "automatic" && {
          registration_number: serviceFlowData.registration_number,
        }),
      });
    },
    [type, serviceFlowData],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {servicePackages?.map((servicePackage: IService) => (
        <div
          key={servicePackage.id}
          onClick={() => handleServicePackageClick(servicePackage)}
        >
          <ServiceCard
            service={servicePackage}
            rightHalf={
              <Pill
                className="bg-neutral30 h-fit border-none"
                leftHalf={<TimeIcon />}
                name={`${servicePackage.service_hours} ${pluralize(parseInt(servicePackage.service_hours), "hour")}`}
              />
            }
            className={cn(
              "gap-6 border",
              Number(serviceFlowData.steps.service.id) === servicePackage.id
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
          />
        </div>
      ))}
    </div>
  );
}

export default SelectService;
