import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Image from "@/components/ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { PDP_IMAGE_GALLERY_OPENED_EVENT } from "@/utils/mixpanel/Events/pdp_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { useSelector } from "react-redux";

interface Iprops {
  isVehicleAvailable: boolean;
  imagePaths: string[];
  setOpenCarouselImgPreview: React.Dispatch<React.SetStateAction<boolean>>;
  productDetail: IVehicleInfo;
}

const CarouselSection = ({
  isVehicleAvailable,
  imagePaths,
  setOpenCarouselImgPreview,
  productDetail,
}: Iprops) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const { isMobile } = useScreenDetector();
  const imagesToShow = isMobile ? 2 : 3;

  const handleViewAll = () => {
    setOpenCarouselImgPreview(true);
    track(PDP_IMAGE_GALLERY_OPENED_EVENT, {
      store_location: productDetail.storeName,
      city_name: productDetail.city,
      vehicle_id: productDetail.vehicleId,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <Carousel className="overflow-hidden px-4 pt-4 md:px-0 md:pt-0">
      {imagePaths && (
        <CarouselContent className="flex gap-1 px-3 md:flex-col">
          {imagePaths?.slice(0, imagesToShow).map((imgItem, index) => (
            <CarouselItem key={index} className="basis-auto pl-2 h-[88px]">
              <Image
                transform="w-0.2,h-0.2"
                src={imgItem as string}
                alt=""
                width="105px"
                className={cn(
                  "h-full rounded object-cover",
                  !isVehicleAvailable && "grayscale",
                )}
                fetchPriority="high"
                onClick={handleViewAll}
              />
            </CarouselItem>
          ))}
          <CarouselItem className="basis-auto pl-2 h-[88px]">
            <div
              className="flex justify-center items-center h-full w-[105px] rounded border relative overflow-hidden"
              onClick={handleViewAll}
            >
              <Image
                transform="w-0.2,h-0.2"
                src={imagePaths[imagesToShow] as string}
                alt=""
                width="105px"
                className={cn(
                  "h-full rounded object-cover blur-[6px]",
                  !isVehicleAvailable && "grayscale",
                )}
                fetchPriority="high"
              />
              <p className="absolute top-1/2 -translate-y-1/2 text-base text-white text-center">
                View All
              </p>
            </div>
          </CarouselItem>
        </CarouselContent>
      )}
    </Carousel>
  );
};

export default CarouselSection;
