import { formatUTCDate } from "@/utils/date";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import Image from "../ui/image";

function BlogHeader({ blog }: { blog: IBlog }) {
  return (
    <>
      <div className="w-full bg-neutral30">
        <p className="text-accent py-4 px-4 xl:px-0 max-w-7xl mx-auto w-full">
          Blogs / <Link to={"/blogs"}>Latest Blogs</Link> /{" "}
          <span className="text-black">
            {blog?.attributes?.title.slice(0, 10)}...
          </span>
          {blog?.attributes?.publishedAt === null && (
            <span className="border px-4 py-1 rounded-md mx-4 text-accent">
              Draft
            </span>
          )}
        </p>
      </div>

      <div className="mt-4 px-4 lg:px-0 w-full max-w-screen-lg mx-auto flex justify-center">
        <Image
          transform="f-webp"
          src={
            blog?.attributes?.cover?.data?.attributes?.url ??
            "https://ik.imagekit.io/drivex/og_image_productlist.jpg"
          }
          alt={`${blog?.attributes?.title} cover`}
          className="lg:w-[700px] lg:h-[400px] object-cover rounded-xl"
        />
      </div>

      <div className="flex flex-col max-w-screen-lg mx-auto w-full px-4 lg:px-0 mt-6 gap-3">
        <span className="text-accent text-sm font-semibold">
          {blog?.attributes?.collection?.data?.attributes?.name}
        </span>

        <h1 className="text-[32px] leading-10 text-neutral900 font-semibold">
          {blog?.attributes?.title}
        </h1>

        <div className="flex items-center gap-2 mt-2">
          <Avatar className="w-10 h-10">
            <AvatarImage
              src={
                blog?.attributes?.authorsBio?.data?.attributes?.avatar?.data
                  ?.attributes?.url
              }
              alt={blog?.attributes?.authorsBio?.data?.attributes?.name}
            />
            <AvatarFallback>
              {blog?.attributes?.authorsBio?.data?.attributes?.name
                ?.split(" ")
                ?.slice(0, 2)
                .map((word) => word[0])
                .join("") ?? "CN"}
            </AvatarFallback>
          </Avatar>
          <div>
            <h6 className="text-neutral900 text-base font-semibold">
              {blog?.attributes?.authorsBio?.data?.attributes?.name}
            </h6>
            <h6 className="text-neutral300 text-sm">
              {formatUTCDate(blog?.attributes?.publishedAt)} &bull; 2 mins read
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogHeader;
