import { Skeleton } from "@/components/ui/skeleton";

const PendingActionCardLoader = () => {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="h-[170px] rounded-xl shadow-sm bg-neutral-200" />
    </div>
  );
};

export default PendingActionCardLoader;
