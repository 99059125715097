import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { payment_mode, sellReserveFormSteps } from "../data";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { CheckCircle, EditIcon } from "@/assets/Icons";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { DropdownOpenDelay } from "@/utils/constants";
import { deepCopy } from "@/utils/object";
import FlowStepper from "@/components/FlowStepper";
import useSellReserveFlowSteps from "./useSellReserveFlowSteps";
import { Options } from "react-lottie";
import { useLottie } from "@/hooks/useLottie";
import dmxErrorAnimation from "@/assets/lottie/dmxError.json";

type Props = {
  formData: any;
  handleInputChange: any;
  errors: any;
  partnerType: "fofo" | "coco";
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  orderDetails: any;
  handleDownload: () => void;
  productDetail: IVehicleInfo;
  isLoading: boolean;
  isOtpVerified: boolean;
  otp: string;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  modelName: string;
  commonEventProperties: any;
  finalSellingPrice: number;
  AmountToBeCollected: number;
  dynamicFields: any;
  setDynamicFields: React.Dispatch<React.SetStateAction<any>>;
  setIsOtpVerified: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: dmxErrorAnimation,
};

const Sold = ({
  formData,
  handleInputChange,
  errors,
  partnerType,
  orderDetails,
  setFormData,
  handleDownload,
  productDetail,
  isLoading,
  otp,
  setOtp,
  modelName,
  commonEventProperties,
  finalSellingPrice,
  AmountToBeCollected,
  dynamicFields,
  setDynamicFields,
  setIsOtpVerified,
  isOtpVerified,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { Lottie } = useLottie();
  const type = searchParams.get("type");
  const step = searchParams.get("step");
  const getAllPaymentFields =
    step === "payment" &&
    dynamicFields?.filter((f: { name: string }) =>
      f.name.startsWith("payment "),
    );
  const lastPaymentField =
    getAllPaymentFields?.[getAllPaymentFields.length - 1];

  useEffect(() => {
    if (step === "pricing") {
      const tempDynamicFields = [...dynamicFields];
      const bookingAmountField = tempDynamicFields.find(
        (field) => field.name === "booking_amount",
      );

      if (type === "sell") {
        bookingAmountField.required = false;
      } else {
        bookingAmountField.required = true;
      }

      setDynamicFields(tempDynamicFields);
    }
  }, [type]);

  useEffect(() => {
    if (orderDetails?.order_status === "COMPLETED") {
      navigate(location.pathname);
    }
  }, [orderDetails]);

  const addPaymentField = () => {
    const paymentFields = dynamicFields.filter((field: { name: string }) =>
      field.name.startsWith("payment "),
    );

    const lastPaymentFieldIndex = dynamicFields.findIndex(
      (field: { name: string }) =>
        field.name.startsWith("payment ") &&
        field.name === paymentFields?.[paymentFields.length - 1].name,
    );

    const newProofOfPayment = deepCopy(payment_mode);

    newProofOfPayment.label = `Proof Of Payment ${paymentFields.length + 1}`;
    newProofOfPayment.name = `payment ${paymentFields.length + 1}`;

    const updatedFields = [...dynamicFields];
    updatedFields.splice(lastPaymentFieldIndex + 1, 0, newProofOfPayment); // Insert after the specified index
    setDynamicFields(updatedFields); // Update state
  };

  const isCurrentStepComplete = (currentStep: string) => {
    if (["customer_details", "pricing", "payment"].includes(currentStep)) {
      if (currentStep === "pricing") {
        if (type === "sell") return true;
      }

      return dynamicFields
        .filter(
          (val: { required: boolean; stepType: string | null }) =>
            val.stepType === currentStep && val.required,
        )
        .every(
          (field: { required: boolean; name: string }) => formData[field.name],
        );
    }

    if (
      currentStep === "documents" &&
      ["RESERVED", "COMPLETED", "IN_REVIEW"].includes(
        orderDetails?.order_status,
      )
    )
      return true;
  };

  const handlePillClick = (step: { type: any; title?: string }) => {
    if (!isCurrentStepComplete(step.type)) return;

    setSearchParams({ type: type || "", step: step.type });
  };

  const renderCustomComponent = (field: any) => {
    if (field?.name?.includes("payment ")) {
      return renderMultiMode(field);
    } else {
      return null;
    }
  };

  const handleDelete = (fieldName: string) => {
    const indexToDelete = dynamicFields.findIndex(
      (field: { name: string }) =>
        field.name.startsWith("payment ") && field.name === fieldName,
    );
    const payment = dynamicFields[indexToDelete];

    setDynamicFields((prevFields: any) => {
      const updatedFields = [...prevFields];
      updatedFields.splice(indexToDelete, 1);
      return updatedFields;
    });

    setFormData((prevFormData: any) => {
      const updatedFormData = { ...prevFormData };
      delete updatedFormData[payment.name];
      return updatedFormData;
    });
  };

  const renderMultiMode = (field: any) => {
    const paymentReferenceIdFieldIndex = field.fields.findIndex(
      (field: any) => field.name === "payment_reference_id",
    );

    const financeDetailsFieldIndex = field.fields.findIndex(
      (field: any) => field.name === "finance_details",
    );

    const proofOfPaymentFieldIndex = field.fields.findIndex(
      (field: any) => field.name === "proof_of_payment",
    );

    return (
      <div className="flex flex-col gap-2">
        <div className="flex w-full gap-2">
          <Input
            className={cn(
              "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
              errors[field.fields[0].name] && "bg-red-100",
            )}
            name={field.fields[0].name}
            placeholder={field.fields[0].placeholder}
            type={field.fields[0].type}
            required={field.fields[0].required}
            value={formData[field.name]?.amount || ""}
            onChange={(e) =>
              handleInputChange(
                {
                  target: {
                    name: e.target.name,
                    value: e.target.value,
                  },
                },
                field?.name,
              )
            }
            disabled={partnerType === field.fields[0].disabled}
            maxLength={field.fields[0].maxLength}
          />

          <Select
            onValueChange={(value) =>
              handleInputChange &&
              setTimeout(() => {
                handleInputChange(
                  {
                    target: {
                      name: field.fields[1].name,
                      value,
                    },
                  },
                  field?.name,
                );
              }, DropdownOpenDelay)
            }
            value={formData[field.name]?.[field.fields[1].name]}
          >
            <SelectTrigger
              className={cn(
                "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                errors[field?.name]?.[field?.fields[1]?.name] && "bg-red-100",
              )}
              name={field.name}
            >
              <SelectValue placeholder="Select a Mode" />
            </SelectTrigger>
            <SelectContent
              ref={(ref) => {
                if (!ref) return;
                ref.ontouchstart = (e) => e.preventDefault();
              }}
            >
              {field.fields[1].options?.map((option: any) => (
                <SelectItem
                  key={option.value}
                  className="focus:bg-neutral30"
                  value={option.value}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <Input
          className={cn(
            "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
            errors[field?.name]?.[
              field.fields[paymentReferenceIdFieldIndex]?.name
            ] && "bg-red-100",
          )}
          name={field.fields[paymentReferenceIdFieldIndex]?.name}
          placeholder={field.fields[paymentReferenceIdFieldIndex]?.placeholder}
          type={field.fields[paymentReferenceIdFieldIndex]?.type}
          required={field.fields[paymentReferenceIdFieldIndex]?.required}
          value={formData[field.name]?.payment_reference_id || ""}
          onChange={(e) =>
            handleInputChange(
              {
                target: {
                  name: e.target.name,
                  value: e.target.value,
                },
              },
              field?.name,
            )
          }
          maxLength={field.fields[paymentReferenceIdFieldIndex]?.maxLength}
        />

        {formData[field.name]?.[field.fields[1].name] === "finance" &&
          financeDetailsFieldIndex && (
            <Select
              onValueChange={(value) =>
                handleInputChange &&
                setTimeout(() => {
                  handleInputChange(
                    {
                      target: {
                        name: field.fields[financeDetailsFieldIndex].name,
                        value,
                      },
                    },
                    field?.name,
                  );
                }, DropdownOpenDelay)
              }
              value={
                formData[field.name]?.[
                  field.fields[financeDetailsFieldIndex]?.name
                ]
              }
            >
              <SelectTrigger
                className={cn(
                  "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
                  errors[field?.name]?.[
                    field?.fields[financeDetailsFieldIndex]?.name
                  ] && "bg-red-100",
                )}
                name={field.name}
              >
                <SelectValue placeholder="Financier" />
              </SelectTrigger>
              <SelectContent
                ref={(ref) => {
                  if (!ref) return;
                  ref.ontouchstart = (e) => e.preventDefault();
                }}
              >
                {field.fields[financeDetailsFieldIndex]?.options?.map(
                  (option: any) => (
                    <SelectItem
                      key={option.value}
                      className="focus:bg-neutral30"
                      value={option.value}
                    >
                      {option.label}
                    </SelectItem>
                  ),
                )}
              </SelectContent>
            </Select>
          )}

        <>
          <div
            className={cn(
              "bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 cursor-pointer",
              errors[field?.name]?.[
                field?.fields[proofOfPaymentFieldIndex]?.name
              ] && "bg-red-100",
            )}
            onClick={() =>
              document
                .getElementById(
                  `file-input-${field?.name}-${field?.fields[proofOfPaymentFieldIndex]?.name}`,
                )
                ?.click()
            }
          >
            {formData[field.name]?.["proof_of_payment"] ? (
              <div className="flex gap-2 items-center px-2 justify-between">
                {" "}
                <div className="flex gap-2">
                  <CheckCircle />{" "}
                  <span className="text-sm">
                    {formData[field.name]?.["proof_of_payment"]?.name}
                  </span>
                </div>
                <EditIcon size={20} fill="#D1372E" />
              </div>
            ) : (
              field?.fields[proofOfPaymentFieldIndex]?.placeholder
            )}
          </div>
          <input
            id={`file-input-${field?.name}-${field?.fields[proofOfPaymentFieldIndex]?.name}`}
            className="hidden"
            name={field?.fields[proofOfPaymentFieldIndex]?.name}
            type="file"
            onChange={(e) =>
              handleInputChange(
                {
                  target: {
                    name: field?.fields[proofOfPaymentFieldIndex]?.name, // Update to use the correct field name
                    value: e.target.value,
                    files: e.target.files,
                  },
                },
                field?.name,
              )
            }
          />
        </>

        {field.name.includes("payment ") &&
          field.name === lastPaymentField.name && (
            <div
              onClick={() => addPaymentField()}
              className="flex flex-center p-2.5 w-full border-2 rounded-md border-primaryA1 text-primaryA2 border-dashed text-xs"
            >
              + Add another payment
            </div>
          )}
      </div>
    );
  };

  const { renderCurrentStep } = useSellReserveFlowSteps({
    type,
    currentStep: step,
    dynamicFields,
    renderCustomComponent,
    formData,
    errors,
    handleInputChange,
    partnerType,
    orderDetails,
    handleDownload,
    productDetail,
    isLoading,
    otp,
    setOtp,
    modelName,
    commonEventProperties,
    finalSellingPrice,
    AmountToBeCollected,
    setIsOtpVerified,
    isOtpVerified,
    getAllPaymentFields,
    lastPaymentField,
    handleDelete,
  });

  return (
    <>
      <div
        className={cn(
          "flex flex-col gap-5 mt-[85px] mb-[70px] overflow-x-hidden",
          step === "payment" && "mb-[135px]",
          step === "error" && "flex-center h-full mt-[-100px]",
        )}
      >
        {step === "error" ? (
          <div className="flex flex-col gap-2 items-center">
            {Lottie && (
              <Lottie options={defaultOptions} height={150} width={200} />
            )}
            <p className="text-center mt-[-25px] z-10">
              Oops! Something went wrong.
              <br />
              Please contact DriveX executives <br /> to complete the sale order
            </p>
          </div>
        ) : (
          <FlowStepper
            onPillClick={(step) => {
              handlePillClick(step);
            }}
            steps={sellReserveFormSteps}
            activeStep={step || ""}
            isStepComplete={(step: string) => isCurrentStepComplete(step)}
            renderActiveStep={renderCurrentStep}
            showNavbar={false}
            customPillStyles="mx-2"
          />
        )}
      </div>
    </>
  );
};

export default Sold;
