import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  estimatedPrice: null,
};

const estimatedPriceSlice = createSlice({
  name: "estimatedPrice",
  initialState,
  reducers: {
    setEstimatedPrice: (state, action) => {
      state.estimatedPrice = action?.payload;
      localStorage.setItem("estimatedPrice", JSON.stringify(action?.payload));
    },
  },
});

export const { setEstimatedPrice } = estimatedPriceSlice.actions;

export default estimatedPriceSlice.reducer;
