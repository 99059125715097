import React from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

interface ContainerProps {
  content: {
    type: string;
    children: {
      text: string;
      type: "text";
    }[];
  };
}

const TextModifiers = {
  bold: ({ children }: { children?: React.ReactNode }) => (
    <strong>{children}</strong>
  ),
  italic: ({ children }: { children?: React.ReactNode }) => (
    <span className="italic">{children}</span>
  ),
  code: ({ children }: { children?: React.ReactNode }) => (
    <code className="text-neutral300 bg-neutral100 p-1 rounded-md">
      {children}
    </code>
  ),
};

const blocks = {
  paragraph: ({ children }: { children?: React.ReactNode }) => (
    <p className="text-neutral300">{children}</p>
  ),
  link: ({ children, url }: { children?: React.ReactNode; url?: string }) => (
    <a
      href={url}
      className="text-neutral300 underline"
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ),

  list: ({
    children,
    format,
  }: {
    children?: React.ReactNode;
    format?: "ordered" | "unordered";
  }) => {
    if (format === "ordered") {
      return <ol className="list-decimal text-neutral300 pl-4">{children}</ol>;
    }
    return <ul className="list-disc text-neutral300 pl-4">{children}</ul>;
  },
};

const FooterComponent: React.FC<ContainerProps> = ({ content }) => {
  if (!Array.isArray(content)) {
    return null;
  }

  return (
    <BlocksRenderer
      content={content}
      modifiers={TextModifiers}
      blocks={blocks}
    />
  );
};

export default FooterComponent;
