import { Button } from "@/components/ui/button";
import ScooterImage from "../../assets/svg/UserProfile/ScooterImage.svg";
import { EmptyOrderedVehicleListData } from "./data";
import Image from "@/components/ui/image";

interface IProps {
  isTabSelected: string;
  handleButtonClick: () => void;
}

const EmptyOrderedVehicleList = ({
  isTabSelected,
  handleButtonClick,
}: IProps) => {
  return (
    <div className="flex flex-col items-center gap-5 mt-[10%] p-4 text-center">
      <Image src={ScooterImage} alt="scooter" />
      <p className="font-semibold text-lg">
        {EmptyOrderedVehicleListData({ heading: isTabSelected })}
      </p>

      <p className="text-neutral300 font-medium text-base">
        {EmptyOrderedVehicleListData({ description: isTabSelected })}
      </p>

      <Button
        variant="outline"
        className="flex gap-3 bg-primaryA2 border-none text-white px-8 py-6"
        onClick={handleButtonClick}
      >
        <p className="font-semibold text-sm">
          {EmptyOrderedVehicleListData({ cta: isTabSelected })}
        </p>
      </Button>
    </div>
  );
};

export default EmptyOrderedVehicleList;
