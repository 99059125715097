import { IMAGE_KIT_BASE_URL } from "@/utils/constants";

const TVS_Credit = `${IMAGE_KIT_BASE_URL}/ik_homepage/TVS_Credit.png`;

interface inspectionChecklistType {
  img: unknown;
  inspectionText: string;
}

export const inspectionChecklist: inspectionChecklistType[] = [
  { img: TVS_Credit, inspectionText: "RC - Registration Certificate" },
  { img: TVS_Credit, inspectionText: "Insurance Copy" },
  { img: TVS_Credit, inspectionText: "Loan Documents (if applicable)" },
  { img: TVS_Credit, inspectionText: "PAN Card" },
  { img: TVS_Credit, inspectionText: "Aadhar Card" },
];
