import Navbar from "@/components/Navbar";
import HeroGIFBanner from "./components/HeroGIFBanner";
import Footer from "@/components/Footer";

const Terms = () => {
  return (
    <section className="bg-neutral30">
      <Navbar showSearchbar={false} backgroundColor="bg-neutral900" />

      <div className="pt-[70px] relative">
        <HeroGIFBanner title="Terms &amp; Conditions" />
      </div>

      <div className="p-4 text-justify font-normal max-w-7xl mx-auto w-full lg:w-[70%]">
        <section className="mt-10">
          <div className="mt-4 space-y-6">
            <p>
              DriveX Mobility Private Limited (the &quot;Company&quot;) operates
              a website https://www.drivex.in/ including its mobile
              applications, web applications (&quot;Website&quot;) from where
              users can purchase pre-owned two wheelers (“Services”).
            </p>
            <p>
              These terms and conditions shall govern the use or access to the
              Website and Services.
            </p>
            <p>
              These Terms of Service (as defined below) constitute a binding and
              enforceable legal contract between the Company and the user of the
              website (“you, your or user”).
            </p>
            <p>
              Capitalized terms, unless defined herein, shall have the meaning
              ascribed to them under the Privacy Policy.
            </p>
            <p>
              No information provided on the Website shall be considered a
              substitute for your independent investigation. These Terms of
              Service are collectively an electronic record for the purpose of
              the Information Technology Act, 2000 (“IT Act”) and the rules made
              thereunder. These Terms of Service do not require any digital or
              electronic signature. You must not modify the paper or digital
              copies of any materials. You have printed, or downloaded from, our
              Websites in any way, and You must not use any illustrations,
              photographs, video or audio sequences or any graphics separately
              from any accompanying text.
            </p>
            <p>
              By using our Website, you confirm that you accept these Terms of
              Service and that you agree to comply with them. If you do not
              agree to these below mentioned Terms, you must not access this
              Website.
            </p>
            <p>
              DriveX Mobility Private Limited (the “Company” or “We”) is a
              private limited company incorporated under the Companies Act, 2013
              and is engaged in the business of trading in pre-owned vehicle(s)
              and providing services incidental and ancillary thereto.
            </p>
            <p>
              The Website is operated by the Company and you acknowledge that
              all your transactions on this Website are with the Company and
              with no other person or entity. Please read the Terms of Service
              to acquaint yourself with the practices of the Company, with
              regard to your use of the domain and subdomains of our Website.
            </p>
            <p>
              In the course of providing the Services, the Website may provide
              links or direct you to other websites or domains or subdomains
              which are not operated by us. It is acknowledged by you that upon
              being redirected to or opening a domain/subdomain which is not
              owned by/licensed to us, you will be bound by the terms of use of
              that third party domain or subdomain. The Company shall not be
              liable to any claims arising out of your use or access of said
              domain or subdomain. We also hereby clarify that the Products/
              Services mentioned on the Website are subject to availability. The
              Company reserves the right to modify, amend and/or alter the said
              Service(s) based on its sole discretion and no such Services shall
              be deemed to be any offer or acceptance by the Company unless the
              same are accepted by the Company separately in writing through its
              authorized representatives.
            </p>
            <p>
              For specific price information, terms applicable, program and
              product information, please contact our customer support team at
              08069575175 or e-mail us at support@drivex.in.
            </p>
            <p>
              By using or visiting these buyer terms and conditions, you
              (‘Customer’, you, your or ‘user’) signify your understanding and
              agreement to these terms and conditions (the “Terms of Service”).
              If you do not agree to any of these Terms of Service, please do
              not use the services.
            </p>
            <p>
              Although we may attempt to notify you when any changes are made to
              these Terms of Service, you should periodically review the most
              up-to-date version. The Company may, in its sole discretion,
              modify or revise these Terms of Service and policies at any time,
              and you agree to be bound by such modifications or revisions.
              Nothing in these Terms of Service shall be deemed to confer any
              third-party rights or benefits.
            </p>
            <p>
              To avail the Services of the Company, you would be required to:
              <ul className="list-decimal pl-8 space-y-2">
                <li>
                  Share your contact details with the Company for correspondence
                  and communication related to the Services.
                </li>
                <li>
                  Meet as per mutually agreed date, time, and place for taking
                  test drive/delivery of the vehicle agreed upon.
                </li>
                <li>
                  Specifically acknowledge that you will be making the payment
                  of the vehicle and taking delivery thereof of the vehicle on
                  “as is where is” basis.
                </li>
                <li>
                  Pay costs for any other Services availed from the Company.
                </li>
                <li>
                  Agree that the photos on the Website are to the best of manual
                  ability and that if you wish to, you can view the vehicle in
                  person to gauge the actual condition of the bike.
                </li>
                <li>
                  Agree to pay the Company a non-refundable token amount for the
                  selected vehicle post the inspection.
                </li>
                <li>
                  Provide the following documents with the requisite buyer
                  transaction form to the Company with the token amount for
                  authentication and identification purposes:
                  <ul className="list-disc pl-6">
                    <li>Proof of possession of the Aadhaar;</li>
                    <li>Pan;</li>
                    <li>Voter ID;</li>
                    <li>Bank Account Statement;</li>
                    <li>Photograph;</li>
                  </ul>
                </li>
                <li>
                  Acknowledge that in the event a loan is availed from any
                  partners of the Company, the processing time for such a loan
                  shall be up to 15 working days from the date of application
                  for such a loan.
                </li>
                <li>
                  Acknowledge that in case of loan defaults, loan rejections due
                  to discrepancies at the buyer’s end or incorrect /
                  insufficient information furnished or non-cooperation by the
                  buyer for verification/ validation of documents/ premises,
                  etc. on account of the buyer, the complete token amount might
                  be forfeited subject to the discretion of the Company or its
                  partners.
                </li>
                <li>
                  Acknowledge that any misbehaviour or improper conduct by the
                  buyer shall be unacceptable and might lead to cancellation of
                  the transaction and potential legal actions.
                </li>
                <li>
                  The buyer agrees that the Company shall not be liable for any
                  refurbishment of the vehicle owing to the company policies.
                </li>
              </ul>
            </p>
            <p>
              The Company shall not entertain any request for cancellation/
              return post the successful delivery of the vehicle on basis of the
              condition of the vehicle.
            </p>
            <p>
              The Website is operated by the Company and you acknowledge that
              all your transactions on this Website are with the Company and
              with no other person or entity. Please read the Terms of Service
              to acquaint yourself with the practices of the Company, with
              regard to your use of the domain and subdomains of our Website.
            </p>
            <p>
              The information contained in this website is for general
              information purposes only. The information is provided by
              drivex.in, a property of DriveX Mobility Pvt Ltd.While we
              endeavour to keep the information up to date and correct, we make
              no representations or warranties of any kind, express or implied,
              about the completeness, accuracy, reliability, suitability or
              availability with respect to the website or the information,
              products, services, or related graphics contained on the website
              for any purpose. Any reliance you place on such information is
              therefore strictly at your own risk.
            </p>
            <p>
              In no event will we be liable for any loss or damage including
              without limitation, indirect or consequential loss or damage, or
              any loss or damage whatsoever arising from loss of data or profits
              arising out of, or in connection with, the use of this website.
              Through this website you are able to link to other websites which
              are not under the control of{" "}
              <span className="font-semibold">DriveX Mobility Pvt Ltd</span>. We
              have no control over the nature, content and availability of those
              sites. The inclusion of any links does not necessarily imply a
              recommendation or endorse the views expressed within them. Every
              effort is made to keep the website up and running smoothly.
              However,{" "}
              <span className="font-semibold">DriveX Mobility Pvt Ltd</span>{" "}
              takes no responsibility for, and will not be liable for, the
              website being temporarily unavailable due to technical issues
              beyond our control.
            </p>
          </div>
        </section>
        <section className="mt-10">
          <h5 className="text-lg font-semibold">Return Policy</h5>
          <div className="mt-4 space-y-6">
            <p>
              <ul className="list-disc ml-6">
                <li>
                  3 day return is a unique proposition offered to buyers who are
                  buying vehicles from the Company.
                </li>
                <li>
                  Any vehicle purchased by any customer under this offer is
                  eligible for a return to the same to the Company within 3 days
                  from the date of purchase.
                </li>
                <li>
                  For purchases with Finance Assist, the 3 day return policy is
                  up to the discretion of the Financier.
                </li>
                <li>
                  Buyer will be paid 100% of the agreed sale price as agreed in
                  the buyer transaction form.
                </li>
                <li>
                  To avail the 3 day return offer, the buyer will have to raise
                  a return request to the company via cso@drivex.in/08069575175.
                  The vehicle will have to be dropped at the company’s parking
                  yard as suggested by the representative.
                </li>
                <li>
                  To be eligible for a return, the vehicle should not have
                  clocked more than 500 km from the date of delivery.
                </li>
                <li>
                  All original documents must be handed over to the Company’s
                  representative including:
                  <ul className="list-disc pl-6">
                    <li>
                      Original RC (If not returned, Rs. 5000/- will be
                      additionally deducted).
                    </li>
                    <li>RTO documents with seller signatures.</li>
                    <li>
                      Bank NOC + Form 35 (In case the vehicle purchased has an
                      active loan).
                    </li>
                    <li>
                      Clearance certificate from RTO states that no ownership
                      transfer request is processed and the bike has no active
                      challans.
                    </li>
                    <li>
                      Proof of payment to the Company at the time of purchase.
                    </li>
                  </ul>
                </li>
                <li>
                  3 day return to be processed if the agreed sales price is
                  based on our team&apos;s fair market value evaluation.
                </li>
                <li>
                  The vehicle can be eligible for the 3 day Return if there is
                  no physical damage to the vehicle. The re-inspection will
                  happen at the place agreed by our team and the report will be
                  shared with the buyer for transparency.
                </li>
                <li>
                  Payment will be processed after adherence to all the Terms of
                  Service mentioned above.
                </li>
                <li>
                  Payment will be processed within 10 working days from the
                  accepted date of the return.
                </li>
              </ul>
            </p>
            <p>
              This 3 day return policy is not applicable if the delivered
              vehicle is defective, deficient, spurious, not of features as
              advertised/ agreed to, or if delivered late from stated delivery
              (if such delay for whatsoever reasons is not communicated in
              advance to the customer), unless in case of a force majeure, where
              the customers are eligible for a full refund.
            </p>
            <p>
              DriveX enables its customer to return the two- wheeler bought by
              them from DriveX Mobility if they are not satisfied with the
              performance of the same. The warranty is valid only :
            </p>
            <ul className="list-disc ml-6">
              <li>
                If the particular the bike/ scooter has been driven for a
                maximum of 100km post delivery
              </li>
              <li>
                Not damaged due to any accident, lack of care etc when the
                two-wheeler was in the possession of the customer
              </li>
              <li>
                The said two-wheeler has been purchased with the help of
                financiers
              </li>
            </ul>
          </div>
        </section>
        <section className="mt-10">
          <h5 className="text-lg font-semibold">
            Terms and Conditions (For Two-Wheeler Service Campaign)
          </h5>
          <div className="mt-4 space-y-6">
            <p>
              <ul className="list-disc ml-6">
                <li>
                  This offer provides a complimentary check-up service for
                  two-wheeler vehicles. It includes basic inspection only.
                </li>
                <li>
                  Customers can enjoy a 50% discount on labour costs for routine
                  service jobs on their vehicles.
                </li>
                <li>
                  Customers can enjoy a 10% discount on the DriveX Engine Oil.
                </li>
                <li>
                  The Service support offered may be limited to specific makes
                  and models of vehicles.
                </li>
                <li>
                  Customers within a 3-mile radius of the store can benefit from
                  free pick-up and drop-off services for their vehicles.
                </li>
                <li>
                  This offer is only valid for Bangalore & Chennai COCO outlets.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="mt-10">
          <h5 className="text-lg font-semibold">Ancillary Services</h5>
          <div className="mt-4 space-y-6">
            <p>
              The Company may offer value-added services with or without its
              partners such as transfer of ownership of the vehicle (“RC
              Transfer”) services, motor insurance, consumer finance facility
              along with credit protection insurance cover, third-party warranty
              services, Vehicle health tracking services, Vehicle refurbishing
              services, etc.
            </p>
          </div>
        </section>
        <section className="mt-10">
          <h5 className="text-lg font-semibold">Third Party Services</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>
                The Services may include services, content, documents, and
                information owned by, licensed to, or otherwise made available
                by a third party (“Third Party Services”) or contain links to
                Third Party Services. You understand that Third Party Services
                are the responsibility of the third party that created or
                provided it and acknowledge that use of such Third-Party
                Services is solely at their own risk.
              </li>
              <li>
                The Company makes no representations and hereby expressly
                excludes all warranties and liabilities arising out of or
                pertaining to such Third-Party Services, including their
                accuracy or completeness. Further, all intellectual property
                rights in and to Third Party Services are the property of the
                respective third parties.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Limitation of Liability</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>
                Company does not hold any liability for any occurrence of any
                mishap while using our Services resulting in any material or
                human damage. You understand and agree that Company shall not be
                liable to you for any direct, indirect, incidental, special,
                consequential or exemplary damages, including without limitation
                damages for loss of profits, goodwill, use, data or other
                intangible losses (even if Company had been advised of the
                possibility of such damages), resulting from or relating to the
                Websites, whether based on warranty, contract, tort, or any
                other legal theory.
              </li>
              <li>
                Notwithstanding anything to the contrary contained herein or
                elsewhere, Company’s total liability for any user’s claim which
                may arise out of availing our services through the use or access
                of the Website shall be limited up to the amount paid by the
                user at the time of availing the products and services giving
                rise to such claim.
              </li>
              <li>
                All claims/complaints arising from and in connection with the
                use of our Services shall be promptly submitted or reported to
                the Company and/or its business associates/channel partners
                within 30 days of the consumption of such Services. Any claim or
                complaint that is submitted/reported after the expiry of such 30
                days may be rejected, and the claimant will forfeit the right to
                claim any damage, cost or compensation.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Term and Termination</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>
                These Terms of Service shall remain in effect unless terminated
                in accordance with the terms hereunder.
              </li>
              <li>
                The Company may terminate your access to or use of the Services,
                or any portion thereof, immediately and at any point, at its
                sole discretion, if the user violates or breaches any of its
                obligations, responsibilities, or covenants under these Terms of
                Service.
              </li>
              <li>
                Upon termination, these Terms of Service shall terminate, except
                for those clauses that expressly or are intended to survive
                termination or expiry.
              </li>
              <li>
                Notwithstanding anything to the contrary contained in the Terms,
                upon termination of your access to or use of the Services, all
                amounts or outstanding monies due by you in relation to your use
                of or access to the Services shall become immediately payable.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Disclaimers & Warranties</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>The use of the Services is at your sole risk.</li>
              <li>
                To the extent permitted by applicable law, the Services are
                provided on an “as is” and “as available” basis. The Company
                does not warrant that operation of the Services will be
                uninterrupted or error free or that the functions contained in
                the Services will meet your requirements.
              </li>
              <li>
                To the fullest extent permissible under applicable law, the
                Company expressly disclaims all warranties of any kind, express
                or implied, arising out of the Services, including warranties of
                merchantability, fitness for a particular purpose, satisfactory
                quality, accuracy, title and non-infringement, compatibility,
                applicability, usability, appropriateness, and any warranty that
                may arise out of course of performance, course of dealing, or
                usage of trade.
              </li>
              <li>
                You hereby accept full responsibility for any consequences that
                may arise from your use of the Services, and expressly agree and
                acknowledge that the Company shall have absolutely no liability
                with respect to the same.
              </li>
              <li>
                To the fullest extent permissible by law, the Company, its
                affiliates, and its related parties each disclaim all liability
                to you for any loss or damage arising out of or due to:
                <ul className="list-disc ml-6">
                  <li>
                    Your use of, inability to use, or availability or
                    unavailability of the Services, including any Third Party
                    Services;
                  </li>
                  <li>
                    The occurrence or existence of any defect, interruption, or
                    delays in the operation or transmission of information to,
                    from, or through the Services, communications failure,
                    theft, destruction or unauthorised access to the Company’s
                    records, programs, services, server, or other infrastructure
                    relating to the Services; or
                  </li>
                  <li>
                    The failure of the Services to remain operational for any
                    period of time.
                  </li>
                </ul>
              </li>
              <li>
                Notwithstanding anything to the contrary contained herein,
                neither the Company nor any of its affiliates or related parties
                shall have any liability to you or any third party for any
                indirect, incidental, special or consequential damages or any
                loss of revenue or profits arising under, directly or
                indirectly, or relating, in any manner whatsoever, to these
                Terms of Service. To the maximum extent permitted by law, you
                agree to waive, release, discharge, and hold harmless the
                Company, its affiliated and subsidiary companies, its parent
                companies, and each of their directors, officers, employees, and
                agents, from any and all claims, losses, damages, liabilities,
                expenses and causes of action arising out of the Services.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Indemnity</h5>
          <div className="mt-4 space-y-6">
            <p>
              You shall indemnify, defend at the Company’s option, and hold the
              Company, its parent companies, subsidiaries, affiliates, and their
              officers, associates successors, assigns, licensors, employees,
              directors, agents, and representatives, harmless from and against
              any claim, demand, lawsuits, judicial proceeding, losses,
              liabilities, damages and costs (including, without limitation,
              from all damages, liabilities, settlements, costs and attorneys’
              fees) due to or arising out of your use of the Services, including
              any violation of these Terms or any infringement by you of any
              third party right or on account of any third party who may use
              your account with the Company.
            </p>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Data Usage</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>
                You agree that the Company and any third-party service providers
                it engages, may, in accordance with its privacy policy, collect
                and use your information and technical data and related
                information.
              </li>
              <li>
                The Company may use information and data pertaining to your use
                of the Services for analytics, trends’ identification, and
                purposes of statistics to further enhance the effectiveness and
                efficiency of the Website and transfer the same to its group
                companies and service providers in furtherance of your access to
                these Services. You provide your consent to such use and sharing
                of your information.
              </li>
              <li>
                Subject to applicable laws, the Company may be directed by law
                enforcement agencies or the government and related bodies to
                disclose data in relation to users in connection with criminal
                proceedings. You understand and agree that in such instances,
                the Company shall have the right to share such data with
                relevant agencies or bodies.
              </li>
              <li>
                DriveX Mobility Pvt Ltd collects information about its customers
                and other visitors of the website. This information is limited
                to the kinds of information that can be found in Government
                websites : Registration number, Make & Model, Year of
                Manufacture and Colour of the bike. DriveX uses this information
                to provide website users and customers with better experience.
                DriveX does not sell this information to anyone and only share
                it with third parties who are facilitating the delivery of
                DriveX services.
              </li>
            </ul>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Service Fee/Charges</h5>
          <div className="mt-4 space-y-6">
            <p>
              The Company reserves the right to charge convenience fee for the
              Services and non-payment may result in denial of Services and/or
              action under applicable law.
            </p>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">1 Year Warranty</h5>
          <div className="mt-4 space-y-6">
            <p>
              At DriveX Mobility Pvt Ltd, we ensure the quality of the two-
              wheelers sold to our customers. Further, in order to protect our
              customers from unforeseen circumstances, DriveX provides a 1 year
              or 12,000 kms warranty on all the two-wheelers sold by DriveX
              Mobility Pvt Ltd.
              <ul className="list-disc ml-6">
                <li>
                  This warranty is only valid if the customer has availed 3 free
                  services with DriveX within stipulated limits and also after
                  thorough inspection it is proved beyond reasonable doubt that
                  all necessary care was taken of the bike / scooter by the
                  customer.
                </li>
                <li>
                  Warranty Eligibility - Two Wheeler has to be less than 7 years
                  old or run less than 70,000 kms.
                </li>
                <li>Enrolment under this warranty policy is chargeable.</li>
              </ul>
            </p>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">Modification</h5>
          <div className="mt-4 space-y-6">
            <p>
              The Company reserves the right at any time to add, modify or
              discontinue, temporarily or permanently, the Services (or any part
              thereof) with or without cause. The Company shall not be liable
              for any such addition, modification, suspension or discontinuation
              of the Services.
            </p>
          </div>
        </section>

        <section className="mt-10">
          <h5 className="text-lg font-semibold">
            Jurisdiction, Governing Laws and Dispute Resolution
          </h5>
          <div className="mt-4 space-y-6">
            <p>
              These Terms of Service shall be governed by and construed and
              enforced in accordance with the laws of India. Subject to other
              provisions in this Clause, courts in Coimbatore and Chennai shall
              have exclusive jurisdiction over all issues arising out of these
              Terms of Service or the use of the Services.
            </p>
          </div>
        </section>

        <section className="mt-10 mb-10">
          <h5 className="text-lg font-semibold">Miscellaneous Provisions</h5>
          <div className="mt-4 space-y-6">
            <ul className="list-disc ml-6">
              <li>
                Modification – The Company reserves the right at any time to
                modify these Terms of Service and to add new or additional terms
                or conditions on use of the Services. Such modifications and
                additional terms and conditions will be communicated to you and,
                unless expressly rejected, (in which these Terms of Service
                shall terminate), will be effective immediately and will be
                incorporated into these Terms of Service. In the event you
                refuse to accept such changes, these Terms of Service will
                terminate.
              </li>
              <li>
                Severability - If any provision of these Terms of Service is
                determined by any court or other competent authority to be
                unlawful or unenforceable, the other provisions of these Terms
                of Service will continue in effect. If any unlawful or
                unenforceable provision would be lawful or enforceable if part
                of it were deleted, that part will be deemed to be deleted, and
                the rest of the provision will continue in effect (unless that
                would contradict the clear intention of the clause, in which
                case the entirety of the relevant provision will be deemed to be
                deleted).
              </li>
              <li>
                Assignment - You shall not license, sell, transfer or assign
                your rights, obligations, or covenants under these Terms of
                Service in any manner without the Company’s prior written
                consent. The Company may grant or withhold this consent in its
                sole discretion and subject to any conditions it deems
                appropriate. The Company may assign its rights to any of its
                affiliates, subsidiaries, or parent companies, or to any
                successor in interest of any business associated with the
                Services without any prior notice to you.
              </li>
              <li>
                Notices - All notices, requests, demands, and determinations for
                the Company under these Terms of Service (other than routine
                operational communications) shall be sent to cso@drivex.in
              </li>
              <li>
                Third Party Rights - No third party shall have any rights to
                enforce any terms contained herein.
              </li>
            </ul>
          </div>
        </section>

        <section className="my-10">
          <h5 className="text-lg font-semibold">Offers</h5>
          <div className="mt-4 space-y-6">
            <p>
              Silver Chaturthi offer (10g free silver coin) on deliveries till
              7th Sep 24 is applicable only in COCO (Company owned company
              operated stores).
            </p>
          </div>
        </section>

        <section className="my-10">
          <h5 className="text-lg font-semibold">Communication policy</h5>
          <div className="mt-4 space-y-6">
            <p>
              You hereby authorize and give consent to DRIVEX MOBILITY PRIVATE
              LIMITED (“DMPL”) to receive, either through itself or through any
              third-party service provider, from time-to-time various
              information, alerts, SMS, other messages or calls or commercial
              communication, and other services on the aforesaid listed
              telephone numbers, whether these numbers are registered with
              National Do Not Call Registry/ listed in National Customer
              Preference Register or not. You also confirm that by sending any
              of such messages, calls, You will not hold DMPL or its third-party
              service provider liable or institute complaint under the Telecom
              Commercial Communications Customer Preference (TRAI) Regulations,
              2010 or such other applicable regulations including any amendment
              thereof, as may be applicable from time to time. It will be auto
              renewed every month and if you want to stop this service please
              write an email to support@drivex.in and call our support number:
              +91 08069575175.
            </p>
          </div>
        </section>
      </div>

      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </section>
  );
};

export default Terms;
