import { IS_CLIENT_SIDE } from "@/utils/constants";
import { useState, useEffect } from "react";
import { LottieProps } from "react-lottie";

export function useLottie() {
  const [LottieComponent, setLottieComponent] =
    useState<React.ComponentType<LottieProps> | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (IS_CLIENT_SIDE) {
      const loadLottie = async () => {
        const { default: Lottie } = await import("react-lottie");
        setLottieComponent(() => Lottie);
        setLoading(false);
      };

      loadLottie();
    }
  }, []);

  return { Lottie: LottieComponent, loading };
}
