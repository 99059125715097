import { useEffect, useRef } from "react";

/** custom hook that just provides a boolean flag to indicate whether the current render is the first render
or not i.e when the component was mounted. This will allow useEffect to run only when the dependency values
change and not when component mounts */
function useDidMount() {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
}

export default useDidMount;
