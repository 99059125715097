import { cn } from "@/lib/utils";
import { getPreviousNYears } from "@/utils/date";
import { useCallback } from "react";
import { SelectedCurrentStepComponentProps } from "../data";
import { PROCUREMENT_YEAR_LIMIT } from "@/utils/constants";

function SelectYear({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
}: SelectedCurrentStepComponentProps) {
  const handleYearClick = useCallback(
    (year: string) => {
      setSearchParams({ step: "model", type });
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          year,
        },
        pin_code: priceEstimate.pin_code,
      });
    },
    [type, priceEstimate],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {getPreviousNYears(PROCUREMENT_YEAR_LIMIT).map((year) => {
        return (
          <div
            key={year}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              Number(priceEstimate.steps.year) === year
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleYearClick(String(year))}
          >
            {year}
          </div>
        );
      })}
    </div>
  );
}

export default SelectYear;
