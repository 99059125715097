import InputWithIcon from "@/components/InputWithIcon";
import { cn } from "@/lib/utils";
import { ReactNode } from "react";

interface IProps {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isPincodeServiceable: string;
  renderPincodeSuccessOrErrorIcon: (arg1: string) => ReactNode;
  vehicleDetails: { pincode: string; registrationNumber: string };
}

const Pincode = ({
  isPincodeServiceable,
  handleChange,
  renderPincodeSuccessOrErrorIcon,
  vehicleDetails,
}: IProps) => {
  return (
    <div className="grid w-full items-center gap-1.5">
      <label htmlFor="terms" className="text-sm font-semibold leading-2">
        Enter your pincode
      </label>

      <InputWithIcon
        parentClassName={cn(isPincodeServiceable === "invalid" && "bg-red100")}
        className={cn(
          "py-6 bg-neutral30",
          isPincodeServiceable === "invalid" && "bg-red100",
        )}
        type="number"
        name="pincode"
        placeholder="eg. 560095"
        maxLength={6}
        value={vehicleDetails.pincode}
        handleChange={handleChange}
        icon={renderPincodeSuccessOrErrorIcon(isPincodeServiceable)}
      />
    </div>
  );
};

export default Pincode;
