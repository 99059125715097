import InspectionDetailMiniCard from "@/components/InspectionDetailMiniCard/InspectionDetailMiniCard";
import ProductMiniCard from "@/components/ProductMiniCard";
import { Button } from "@/components/ui/button";
import { IInspectionDetail } from "@/pages/Sell/data";

/**
 * Renders a card with instructions to complete the user action.
 * Handles Incomplete Test Rides, WhatsApp Tours, and Inspections.
 */
const IncompleteUserAction = ({
  data,
  handleCompleteNow,
  handleDismiss,
  getHeadingText,
  user,
}: {
  data: any;
  handleCompleteNow: (data: {
    type: string;
    data: IInspectionDetail & IVehicleInfo;
  }) => void;
  handleDismiss: (data: any) => void;
  getHeadingText: () => string;
  user: IUser;
}) => {
  return (
    <>
      <h1 className="text-white font-semibold text-base">
        {data && (
          <span className="text-primaryA2">
            Hi {user?.userName}! <br />
          </span>
        )}
        {data && `Let’s schedule your ${getHeadingText()}`}
      </h1>
      <div className="flex flex-col gap-4 rounded-md bg-white p-4 lg:w-[30rem] mt-2">
        <p className="text-neutral300 text-sm font-medium">
          Complete your scheduling process for the <br /> {getHeadingText()}.
        </p>

        {data.type === "test-ride" || data.type === "whatsapp-tour" ? (
          <ProductMiniCard className="px-0" productInfo={data.data} />
        ) : (
          <InspectionDetailMiniCard productInfo={data.data} />
        )}

        <span className="border-b-2 border-neutral50"></span>

        <div className="flex flex-col gap-3">
          <Button
            onClick={() => handleCompleteNow(data)}
            variant="outline"
            className="bg-primaryA2 border-none text-white text-base py-6 hover:bg-primaryA2 hover:text-white"
          >
            Complete Now
          </Button>

          <p
            onClick={() => handleDismiss(data)}
            className="text-neutral300 text-center text-sm font-medium"
          >
            Dismiss
          </p>
        </div>
      </div>
    </>
  );
};

export default IncompleteUserAction;
