import React from "react";
import { cn } from "@/lib/utils.ts";

interface ScrollViewProps<T> {
  data: T[] | undefined;
  renderItem: (item: T, index: number) => React.ReactNode;
  SkeletonLoader: React.ComponentType;
  className?: string;
  skeletonCount?: number;
  isFetching: boolean;
  isError?: boolean;
}

const ScrollView = <T,>({
  data,
  renderItem,
  SkeletonLoader,
  className,
  skeletonCount = 3,
  isFetching,
  isError = false,
}: ScrollViewProps<T>) => {
  if (isError) return null;

  return (
    <div
      className={cn(
        "flex-start w-full overflow-x-auto no-scrollbar",
        className,
      )}
    >
      {data && !isFetching
        ? data.map((item, index) => renderItem(item, index))
        : Array.from({ length: skeletonCount }).map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
    </div>
  );
};

export default ScrollView;
