import {
  AlertToast,
  DefaultToast,
  DestructiveToast,
  SuccessToast,
} from "@/assets/Icons";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";

const iconComponents = {
  default: <DefaultToast />,
  success: <SuccessToast />,
  alert: <AlertToast />,
  destructive: <DestructiveToast />,
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({
        id,
        title,
        description,
        action,
        duration = 1500,
        ...props
      }) {
        const IconComponent =
          (props.variant && iconComponents[props.variant]) || null;
        return (
          <Toast key={id} duration={duration} {...props}>
            <div className="grid gap-1">
              <div className="flex items-center">
                {IconComponent && <div className="mr-2">{IconComponent}</div>}
                {title && <ToastTitle>{title}</ToastTitle>}
              </div>
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
