import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productListSessionState: null,
};

const productListingSlice = createSlice({
  name: "productListing",
  initialState,
  reducers: {
    setProductListSessionState: (state, action) => {
      state.productListSessionState = action?.payload;
    },
  },
});

export const { setProductListSessionState } = productListingSlice.actions;

export default productListingSlice.reducer;
