import { Skeleton } from "@/components/ui/skeleton";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";

const StoreCardSkeletonLoader = ({
  showActions = false,
  showImage = false,
}: {
  showActions?: boolean;
  showImage?: boolean;
}) => {
  const { isDesktop } = useScreenDetector();
  return (
    <div
      className={cn(
        "border border-neutral-100 rounded-lg shadow-sm bg-white p-4",
        isDesktop && showImage && "flex w-fit gap-4 flex-center",
      )}
    >
      {showImage && (
        <Skeleton
          className={cn(
            "h-[220px] mb-4 w-full rounded-xl shadow-sm bg-neutral-200",
            isDesktop && "w-[400px] mb-0",
          )}
        />
      )}

      <div className="w-full">
        <Skeleton className="w-full h-6 rounded-xl shadow-sm bg-neutral-200 mb-2.5" />
        <Skeleton className="w-full h-8 rounded-xl shadow-sm bg-neutral-200" />

        <p className="border border-neutral-100 w-full my-4" />

        <div className="flex justify-between items-center">
          <div className="flex flex-col w-full gap-2">
            <Skeleton className="w-2/3 h-6 rounded-xl shadow-sm bg-neutral-200" />
            <Skeleton className="w-1/2 h-6 rounded-xl shadow-sm bg-neutral-200" />
          </div>

          {!showActions && (
            <Skeleton className="w-2/3 h-10 rounded-xl shadow-sm bg-neutral-200" />
          )}
        </div>

        {showActions && (
          <div className="flex gap-2 mt-2">
            <Skeleton className="w-full h-10 rounded-xl shadow-sm bg-neutral-200" />
            <Skeleton className="w-full h-10 rounded-xl shadow-sm bg-neutral-200" />
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreCardSkeletonLoader;
