import { Helmet } from "react-helmet-async";

interface IProps {
  metaData:
    | {
        seoTitle?: string;
        seoDescription?: string;
        seoImage?: string;
      }
    | undefined;
}

const SEO = ({ metaData }: IProps) => {
  const url = import.meta.env.VITE_URL;
  if (!metaData) return;
  return (
    <Helmet>
      <title>{metaData.seoTitle ?? ""}</title>
      <meta name="title" content={metaData.seoTitle ?? ""} />
      <meta name="description" content={metaData.seoDescription ?? ""} />

      {/* Facebook Meta Tags*/}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaData.seoTitle ?? ""} />
      <meta property="og:description" content={metaData.seoDescription ?? ""} />
      <meta property="og:image" content={metaData.seoImage ?? ""} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="drivex.in" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:title" content={metaData.seoTitle ?? ""} />
      <meta
        name="twitter:description"
        content={metaData.seoDescription ?? ""}
      />
      <meta name="twitter:image" content={metaData.seoImage ?? ""} />
    </Helmet>
  );
};

export default SEO;
