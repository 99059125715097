import { getDateAfterNDays } from "./date";

export const getReturningCustomerData = ({
  vehicleDetail,
}: {
  vehicleDetail: any;
}) => {
  let returningCustomerData =
    JSON.parse(localStorage.getItem("returningCustomerData") as string)?.data ||
    [];

  returningCustomerData = returningCustomerData?.filter((item: any) => {
    if (item.type === "schedule-inspection") {
      return item?.data?.vehicle_id !== vehicleDetail?.data?.vehicle_id;
    }

    return item?.data?.vehicleId !== vehicleDetail?.data?.vehicleId;
  });

  // Remove last element from array if returningCustomerData already has 5 elements
  if (returningCustomerData.length >= 5) {
    returningCustomerData.pop();
  }

  // Add item to the start of the array & update localstorage
  returningCustomerData.unshift(vehicleDetail);
  localStorage.setItem(
    "returningCustomerData",
    JSON.stringify({
      data: returningCustomerData,
      version: "1.0",
    }),
  );
};

export const removeReturningCustomerData = ({
  vehicleDetail,
}: {
  vehicleDetail: any;
}) => {
  let returningCustomerData =
    JSON.parse(localStorage.getItem("returningCustomerData") as string)?.data ||
    [];

  returningCustomerData = returningCustomerData?.filter((item: any) => {
    return item?.data?.vehicleId !== vehicleDetail?.data?.vehicleId;
  });

  localStorage.setItem(
    "returningCustomerData",
    JSON.stringify({
      data: returningCustomerData,
      version: "1.0",
    }),
  );
};

export const removeExpiredCustomerData = () => {
  let returningCustomerData =
    JSON.parse(localStorage.getItem("returningCustomerData") as string)?.data ||
    [];

  returningCustomerData = returningCustomerData?.filter((item: any) => {
    return item?.date_time <= getDateAfterNDays(7);
  });

  localStorage.setItem(
    "returningCustomerData",
    JSON.stringify({
      data: returningCustomerData,
      version: "1.0",
    }),
  );
};
