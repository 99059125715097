import React, { useCallback } from "react";
import { Sheet, SheetContent, SheetHeader, SheetClose } from "../ui/sheet";
import { CrossIcon, DownloadIcon } from "@/assets/Icons";
import Image from "../ui/image";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { LoaderIcon } from "lucide-react";
import { track } from "@/utils/mixpanel/actions";
import { DX_DOWNLOAD_QR_CODE } from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { useSelector } from "react-redux";

type VehicleQRModalProps = {
  open: boolean;
  vehicleDetails: IVehicleInfo;
  setClose: () => void;
  QRCodeUrl: string;
};

function VehicleQRModal({
  open,
  vehicleDetails,
  setClose,
  QRCodeUrl,
}: VehicleQRModalProps) {
  const role = localStorage.getItem("role");
  const user = useSelector((state: any) => state.user.currentUser);
  const { isMobile } = useScreenDetector();

  const downloadQRCode = useCallback(async () => {
    track(DX_DOWNLOAD_QR_CODE, {
      role: role,
      user_id: user?.id,
      vehicle_name: vehicleDetails.vehicleName,
      vehicle_id: vehicleDetails.vehicleId,
      store_name: vehicleDetails.storeName,
    });

    const response = await fetch(QRCodeUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${vehicleDetails.vehicleName}-QRCode.png`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }, [QRCodeUrl, vehicleDetails.vehicleName]);

  return (
    <Sheet open={open} onOpenChange={setClose}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="flex flex-col gap-4 p-4 rounded-t-lg"
      >
        <SheetHeader>
          <div className="flex justify-between items-center">
            <p className="text-xl">Vehicle QR Code</p>

            <SheetClose onClick={setClose} className="outline-none">
              <CrossIcon size={26} oneStopColor="#000" twoStopColor="#000" />
            </SheetClose>
          </div>
        </SheetHeader>

        <hr className=" border-t-[1px] border-neutral50 w-full" />

        <div className="flex flex-col items-center gap-4">
          <p className="text-neutral500 text-sm">
            {vehicleDetails.vehicleName}
          </p>

          {!QRCodeUrl ? (
            <LoaderIcon className="animate-spin" color="#F47823" />
          ) : (
            <Image
              src={QRCodeUrl}
              alt={`QR Code for ${vehicleDetails.vehicleName}`}
              id="qrCodeImage"
              width={200}
              height={200}
            />
          )}
        </div>

        <div
          className="flex justify-between items-center gap-2 bg-neutral30 p-2.5 rounded-lg cursor-pointer"
          onClick={downloadQRCode}
        >
          <span className="text-neutral700 text-sm">Download</span>
          <DownloadIcon size={24} />
        </div>
      </SheetContent>
    </Sheet>
  );
}

export default VehicleQRModal;
