import { Input } from "@/components/ui/input";
import SelectOptions from "./SelectOptions";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { LoaderIcon } from "lucide-react";

interface IProps {
  handleFormInputChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  formData: {
    name: string;
    phone_number: string;
    email: string;
    type_of_query: string;
    query: string;
  };
  handleSubmitButton: () => void;
  handleSelectValueChange: (value: string) => void;
  errorMsg: string;
  user: {
    userName: string;
    phoneNumber: string;
    email: string;
  };
  isFeedbackButtonClicked: boolean;
}

const ContactUsForm = ({
  handleFormInputChange,
  handleSubmitButton,
  handleSelectValueChange,
  isFeedbackButtonClicked,
  formData,
  errorMsg,
  user,
}: IProps) => {
  return (
    <>
      <div className="flex gap-0.5">
        <label htmlFor="name" className="text-sm font-semibold">
          Name
        </label>
        <p className="text-red-600">*</p>
      </div>
      <Input
        className={cn(
          "bg-neutral30 py-4 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
          isFeedbackButtonClicked &&
            (formData.name === "" || errorMsg === "Invalid name") &&
            "bg-red-100",
        )}
        name="name"
        onChange={handleFormInputChange}
        value={formData.name}
        placeholder="Enter Name"
        type="text"
        required
        disabled={user?.userName ? true : false}
      />
      {isFeedbackButtonClicked &&
        (formData.name === "" || errorMsg === "Invalid name") && (
          <p className="text-sm">Please Enter Your Name</p>
        )}

      <div className="flex gap-0.5">
        <label htmlFor="phone_number" className="text-sm font-semibold">
          Phone Number
        </label>
        <p className="text-red-600">*</p>
      </div>
      <Input
        className={cn(
          "bg-neutral30 py-4 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
          isFeedbackButtonClicked &&
            (formData.phone_number === "" || errorMsg === "Invalid number") &&
            "bg-red-100",
        )}
        name="phone_number"
        onChange={handleFormInputChange}
        value={formData.phone_number}
        placeholder="Enter your Phone Number"
        type="tel"
        required
        disabled={user?.phoneNumber ? true : false}
      />
      {isFeedbackButtonClicked &&
        (formData.phone_number === "" || errorMsg === "Invalid number") && (
          <p className="text-sm">Please Enter Correct Number</p>
        )}

      <label htmlFor="email" className="text-sm font-semibold">
        Email
      </label>
      <Input
        className={cn(
          "bg-neutral30 py-4 border-none focus-visible:ring-0 focus-visible:ring-offset-0",
          errorMsg === "Invalid email" && "bg-red-100",
        )}
        name="email"
        onChange={handleFormInputChange}
        value={formData.email}
        placeholder="Enter Email Address"
        type="email"
        disabled={user?.email ? true : false}
      />
      {errorMsg === "Invalid email" && (
        <p className="text-sm">Please enter correct email</p>
      )}

      <div className="flex gap-0.5">
        <label htmlFor="select" className="text-sm font-semibold">
          Type of Query
        </label>
        <p className="text-red-600">*</p>
      </div>

      <SelectOptions
        errorMsg={errorMsg}
        handleSelectValueChange={handleSelectValueChange}
      />
      {isFeedbackButtonClicked &&
        (formData.type_of_query === "" ||
          errorMsg === "Invalid TypeOfQuery") && (
          <p className="text-sm">Please select your query</p>
        )}

      <label htmlFor="text" className="text-sm font-semibold">
        Describe your Query
      </label>
      <Textarea
        className="bg-neutral30 h-24 mb-2"
        name="query"
        onChange={handleFormInputChange}
        value={formData.query}
        placeholder="Enter Query"
        maxLength={350}
      />

      <Button
        onClick={handleSubmitButton}
        variant="outline"
        className={cn(
          "w-full bg-primaryA2 border-none text-white hover:bg-primaryA2 flex gap-2 hover:text-white text-base py-6",
        )}
      >
        Submit{" "}
        {isFeedbackButtonClicked && errorMsg === "" && (
          <LoaderIcon className="animate-spin" />
        )}
      </Button>
    </>
  );
};

export default ContactUsForm;
