import { ClockFilled } from "@/assets/Icons";
import { cn } from "@/lib/utils";

const PendingMessage = ({
  orderDetails,
  className,
  description = "Our representatives are validating all the details. You will be able to get the invoice and delivery note in a while.",
}: {
  orderDetails: any;
  className?: string;
  description?: string;
}) => {
  const IN_REVIEW_DESCRIPTION = description;
  const RESERVED_DESCRIPTION =
    "We’re generating your booking receipt. It’ll be ready shortly!";

  const getDescription = () => {
    switch (orderDetails?.order_status) {
      case "RESERVED":
        return RESERVED_DESCRIPTION;
      case "IN_REVIEW":
        return IN_REVIEW_DESCRIPTION;
      default:
        return IN_REVIEW_DESCRIPTION;
    }
  };

  return (
    <div
      className={cn(
        "px-2 py-4 flex gap-2 border rounded-md bg-violet50 border-violet100 items-center",
        className,
      )}
    >
      <ClockFilled size={30} fill="#64459B" />
      <p className="text-sm text-violet500">{getDescription()}</p>
    </div>
  );
};

export default PendingMessage;
