import { getOwnerShipStatus } from "@/common/product";
import { cn } from "@/lib/utils";
import Image from "../ui/image";

const ProductMiniCard = ({
  productInfo,
  className,
  containerRef,
  handleOrderedVehicleCardClick,
}: {
  productInfo: IVehicleInfo;
  className?: string;
  containerRef?: React.FC<any>;
  handleOrderedVehicleCardClick?: (
    vehicleId: number,
    isAvailable?: boolean,
  ) => void;
}) => {
  return (
    <div
      ref={containerRef}
      className={cn("flex gap-4 items-center px-4", className)}
      onClick={() =>
        handleOrderedVehicleCardClick &&
        handleOrderedVehicleCardClick(
          productInfo?.vehicleId,
          productInfo?.isAvailable,
        )
      }
    >
      <>
        <Image
          className="w-[92px] h-[64px] rounded-lg object-cover"
          src={productInfo?.imagePaths?.[0]}
          alt="Vehicle"
        />

        <div className="flex flex-col gap-1">
          <p className="text-sm">{productInfo.vehicleName}</p>
          <p className="text-xs text-neutral300">
            {productInfo?.kmDriven?.displayValue} {productInfo?.kmDriven?.unit}s
            • {getOwnerShipStatus(productInfo.ownerShip)}•{" "}
            {productInfo.manufactureYear}
          </p>
          <p className="text-sm text-primaryA2">
            ₹{productInfo?.price?.displayValue}
          </p>
        </div>
      </>
    </div>
  );
};

export default ProductMiniCard;
