import React from "react";
import { Button } from "../ui/button";
import { MapPinIcon } from "lucide-react";
import { CallIcon } from "@/assets/Icons";
import { ensureHttpsProtocol } from "@/utils/storeLocation";
import { track } from "@/utils/mixpanel/actions";
import {
  STORE_CALL_STORE_CLICKED_EVENT,
  STORE_DIRECTIONS_CLICKED_EVENT,
} from "@/utils/mixpanel/Events/store_clicked_events";
import { truncate } from "@/utils/truncate";
import { enquiry } from "@/store/services/scheduleApi";
import { meta_conversion_api } from "@/utils/metaPixels";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { EnquiryType, IS_CLIENT_SIDE } from "@/utils/constants";
import { cn } from "@/lib/utils";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import Image from "../ui/image";

function StoreCard({
  store,
  handleStoreClick,
  showActions = false,
  showImage,
}: {
  store: IStore;
  handleStoreClick?: (store: IStore) => void;
  showActions?: boolean;
  showImage?: boolean;
}) {
  const navigate = useNavigate();
  const { isDesktop } = useScreenDetector();
  const user = useSelector(
    (state: { user: { currentUser: IUser } }) => state.user.currentUser,
  );
  const utm_parameters =
    IS_CLIENT_SIDE &&
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");
  const imageExists = store.image_url && showImage;

  const trackCallStoreClickedEvent = () => {
    track(STORE_CALL_STORE_CLICKED_EVENT, {
      store_location: store.store_name,
      store_name: store.store_name,
      source: "Store Page",
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleStoreEnquiry = (event: React.MouseEvent) => {
    event.preventDefault();

    if (!user) {
      navigate(`${location.pathname}?login=true`, {
        state: {
          source: "store-enquiry",
        },
      });
      trackCallStoreClickedEvent();
      return;
    }

    enquiry(
      {
        store_id: store.store_id,
        ...utm_parameters,
      },
      EnquiryType.STORE_ENQUIRY,
    );

    meta_conversion_api({
      eventName: "Lead",
      eventId: store.store_id,
      eventType: "Store Enquiry",
      phoneNumber: user?.phoneNumber,
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "Lead");

    window.location.href = `tel:${store?.spoc_number.replaceAll("-", "")}`;

    trackCallStoreClickedEvent();
  };

  const handleDirectionsClick = (
    event: React.MouseEvent,
    { source }: { source: string },
  ) => {
    event.stopPropagation();
    const storeURL = ensureHttpsProtocol(store?.map_link);
    window.open(storeURL, "_blank");
    track(STORE_DIRECTIONS_CLICKED_EVENT, {
      store_name: store.store_name,
      city_name: store.city,
      source,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <Link to={`/${store?.city}/${store?.slug}`}>
      <div
        className={cn(
          "border border-neutral-100 rounded-lg shadow-sm bg-white p-4",
          isDesktop && imageExists && "flex w-fit gap-4 flex-center",
        )}
        onClick={() => handleStoreClick && handleStoreClick(store)}
      >
        {imageExists && (
          <Image
            className={cn(
              `rounded-md mb-4 h-full ${isDesktop ? "max-w-[300px]" : "w-full"}`,
              isDesktop && "mb-0",
            )}
            src={store.image_url}
            alt={store.store_name}
          />
        )}
        <div>
          <h4 className="text-base font-semibold text-neutral900 capitalize line-clamp-1">
            {store.store_name}
          </h4>
          <h6 className="text-sm font-medium text-neutral300 pt-1 capitalize line-clamp-2">
            {truncate(store?.address, 80)}
          </h6>

          <p className="border border-neutral-100 w-full my-4" />

          <div className="flex justify-between items-center">
            <p className="flex flex-col text-sm font-medium text-neutral900">
              <span>Open Hours: </span>
              <span>10:00 AM - 08:00 PM</span>
            </p>

            {!showActions && (
              <Button
                onClick={(event) =>
                  handleDirectionsClick(event, { source: "Store Card" })
                }
                variant="secondary"
                className="flex bg-white text-base border border-primaryA1 p-2 gap-1.5"
              >
                <p className="text-sm text-primaryA2">Directions</p>
                <MapPinIcon color="#F47823" size={18} />
              </Button>
            )}
          </div>

          {showActions && (
            <div className="flex mt-4 gap-4">
              <Button
                onClick={handleStoreEnquiry}
                variant="default"
                className="flex bg-primaryA2 text-base border border-primaryA1 p-2 gap-1.5 w-full"
              >
                <p className="text-sm text-white">Call store</p>
                <CallIcon color="#F47823" size={18} />
              </Button>
              <Button
                onClick={(event) =>
                  handleDirectionsClick(event, { source: "Store Page" })
                }
                variant="secondary"
                className="flex bg-white text-base border border-primaryA1 p-2 gap-1.5 w-full"
              >
                <p className="text-sm text-primaryA2">Directions</p>
                <MapPinIcon color="#F47823" size={18} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

export default StoreCard;
