import {
  BikeIcon,
  CartIcon,
  ChartLineUpIcon,
  GlobalIcon,
  HandShakeIcon,
  MegaphoneIcon,
  SellIcon,
  SparkleIcon,
  StoreIcon,
  TickIcon,
} from "@/assets/Icons";
import { CalendarCheck, FileText } from "lucide-react";

interface DealerBenefits {
  icon: React.ReactNode;
  title: string | React.ReactNode;
}

export const dealerBenefits: DealerBenefits[] = [
  {
    icon: <ChartLineUpIcon size={21} oneStopColor="#FFF" twoStopColor="#FFF" />,
    title: (
      <>
        Attractive
        <br /> margins
      </>
    ),
  },
  {
    icon: <GlobalIcon />,
    title: (
      <>
        Marketing
        <br /> Support
      </>
    ),
  },
  {
    icon: <HandShakeIcon />,
    title: (
      <>
        Multichannel
        <br /> Revenue
      </>
    ),
  },
];

interface AreaRequirements {
  title: string;
  size: number;
}

export const areaRequirements: AreaRequirements[] = [
  {
    title: "Frontage",
    size: 35,
  },
  {
    title: "Showroom",
    size: 1200,
  },
  {
    title: "Workshop",
    size: 800,
  },
];

interface DocumentsChecklist {
  icon: React.ReactNode;
  text: string;
}

export const documentsChecklist: DocumentsChecklist[] = [
  {
    icon: <TickIcon />,
    text: "Dealer KYC",
  },
  {
    icon: <TickIcon />,
    text: "Bank reference letter",
  },
  {
    icon: <TickIcon />,
    text: "Networth Statement",
  },
  {
    icon: <TickIcon />,
    text: "Rent Agreement",
  },
  {
    icon: <TickIcon />,
    text: "GST Certificate",
  },
];

interface DealerAdvantageStepsData {
  index: React.ReactNode;
  heading: string;
  description: string;
}

export const dealerAdvantageSteps: DealerAdvantageStepsData[] = [
  {
    index: <ChartLineUpIcon size={25} url="paint_dealer_advantage" />,
    heading: "Higher Margins, Higher Profits",
    description: "We offer the best-in-industry margins and better profits",
  },
  {
    index: <BikeIcon size={25} />,
    heading: "Effortless Procurement",
    description:
      "We ensure simplified procurement for a high-quality inventory",
  },
  {
    index: <MegaphoneIcon size={25} />,
    heading: "Increased Visibility",
    description: "Reach more audiences with expert marketing support",
  },
  {
    index: <SparkleIcon size={25} />,
    heading: "Comprehensive Services",
    description:
      "Get value-added services in retail finance, insurance and inventory funding",
  },
];

interface mediaPageCTA {
  index: React.ReactNode;
  heading: string;
  ctaType: string;
}

export const mediaPage: mediaPageCTA[] = [
  {
    index: <CartIcon />,
    heading: "Buy your favourite two wheeler",
    ctaType: "Buy",
  },
  {
    index: <SellIcon />,
    heading: "Sell your old two-wheeler with ease",
    ctaType: "Sell",
  },
];

interface DealerOnboardingStepsData {
  icon: React.ReactNode;
  text: React.ReactNode;
}

export const dealerOnboardingSteps: DealerOnboardingStepsData[] = [
  {
    icon: <CalendarCheck color="#FFF" size={20} />,
    text: (
      <>
        Book an <br /> Appointment
      </>
    ),
  },
  {
    icon: <FileText color="#FFF" size={20} />,
    text: (
      <>
        Application & <br /> Verification
      </>
    ),
  },
  {
    icon: <StoreIcon size={20} />,
    text: (
      <>
        Security <br /> Deposit
      </>
    ),
  },
];

export const DealersPageFAQsData = [
  {
    heading: "What is the investment required to become a DriveX dealer?",
    description:
      "You can become an esteemed dealer of DriveX with an initial investment of 10 lakhs and an additional working capital of 10-15 lakhs to kick-start your entrepreneurial dream.",
  },
  {
    heading: "Why should I become a DriveX franchise dealer?",
    description:
      "The pre-owned two-wheeler segment is experiencing rapid growth, surpassing the new two-wheeler segment by 1.8 times. DriveX is an excellent choice to make the most of this market growth as we offer maximum ROI on a minimum investment.",
  },
  {
    heading:
      "I am from a non-auto background. Can I apply for a DriveX dealership?",
    description:
      "Yes. You do not need a background in automobiles to become a DriveX dealer. Applicants from diverse professional backgrounds are welcome.",
  },
  {
    heading:
      "What support does DriveX provide to its dealerships for sales and service?",
    description:
      "DriveX provides its dealers with a multi-brand, multi-segment inventory, technology-backed retail support, & lead generation support with marketing support.",
  },
  {
    heading: "What is the store space required to start the business?",
    description:
      "To begin a DriveX dealership, you will need approximately 800-1000 sq. ft. for sales operations and 1000-1200 sq. ft. for service facilities.",
  },
  {
    heading: "Can I start with only sales?",
    description:
      "Yes, you can start with only sales as DriveX operates in a 3S model, where you can start with sales and then add service and spare parts to your business verticals.",
  },
  {
    heading: "Can I start with only service?",
    description:
      "No. You can start with sales and later add service and spare parts, but you cannot start with service.",
  },
  {
    heading:
      "How are DriveX two-wheelers different from that of the local market?",
    description:
      "DriveX-certified two-wheelers are meticulously refurbished by experts in an advanced technical center, where they undergo 100+ quality checkpoints. We also give a 1-year warranty and 3 free services to ensure quality.",
  },
  {
    heading: "What is the dealership onboarding process?",
    description:
      "After an initial interview by the management, completion of the necessary documentation, and approval, Drivex will issue a formal Letter of Intent (LOI) to commence operations.",
  },
];
