import { useCallback } from "react";
import { cn } from "@/lib/utils";
import { SelectedCurrentStepComponentProps, allStates } from "../data";

function SelectState({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
}: SelectedCurrentStepComponentProps) {
  const handleStateClick = useCallback(
    (state: string) => {
      setSearchParams({ step: "year", type });
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          state,
        },
        pin_code: priceEstimate.pin_code,
      });
    },
    [type, priceEstimate],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {allStates.map((state) => {
        return (
          <div
            key={state}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              priceEstimate.steps.state === state
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleStateClick(String(state))}
          >
            {state}
          </div>
        );
      })}
    </div>
  );
}

export default SelectState;
