import Navbar from "@/components/Navbar";
import HeroSection from "./HeroSection";
import { useEffect } from "react";
import { meta_conversion_api } from "@/utils/metaPixels";
import { useSelector } from "react-redux";
import { generateMetaInfo } from "../../../hyperion/seoData";
import { useLocation } from "react-router-dom";
import SEO from "@/components/SEO";
import ServicePackages from "./ServicePackages";
import WhyDrivex from "./WhyDrivex";
import ProcessSteps from "@/components/ProcessSteps";
import SellNow from "@/components/SellNow";
import { steps } from "./data";
import ContactUsDetails from "../ContactUs/ContactUsDetails";
import FAQs from "@/components/FAQs";
import { serviceFAQsData } from "@/components/FAQs/data";
import Footer from "@/components/Footer";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { QualityServiceSteps } from "../Home/data";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
import { track } from "@/utils/mixpanel/actions";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";

const ServiceImage = `${IMAGE_KIT_BASE_URL}/ik_servicepage/ServiceImage.webp`;
const ServiceImageWeb = `${IMAGE_KIT_BASE_URL}/ik_servicepage/ServiceImageWeb.webp`;
const QualityService = `${IMAGE_KIT_BASE_URL}/ik_homepage/QualityService.webp`;
const QualityServiceWeb = `${IMAGE_KIT_BASE_URL}/ik_homepage/QualityServiceWeb.webp`;

const Services = () => {
  const { pathname } = useLocation();
  const { isMobile, isDesktop } = useScreenDetector();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const pageSource = getPageSource(pathname);
  const metaData = generateMetaInfo({ path: pathname });

  useEffect(() => {
    meta_conversion_api({
      eventName: "ViewContent",
      eventId: new Date().getTime(),
      phoneNumber: user?.phoneNumber,
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "ViewContent");
  }, []);

  const handleBookServiceCTA = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: pageSource,
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  return (
    <>
      <SEO metaData={metaData} />
      <Navbar
        backgroundColor={"bg-neutral900"}
        showLocation
        showSearchbar={false}
      />

      {/* Hero Section */}
      <div className="flex flex-col gap-8 px-4 py-4 bg-neutral900 pt-[90px]">
        <HeroSection />
      </div>

      {/* Service packages */}
      <div className="flex flex-col gap-8 px-4 py-4 bg-neutral30 pt-[25px] lg:py-[50px]">
        <ServicePackages />
      </div>

      {/* Quality Service */}
      <div className="content-div flex flex-col bg-neutral900">
        <ProcessSteps
          stepsData={QualityServiceSteps}
          media={isDesktop ? QualityServiceWeb : QualityService}
          heading={
            <>
              {" "}
              <span className="text-primaryA2">Quality Service</span> <br />
              at Your Convenience
            </>
          }
          type={"grid"}
          buttonText="Book a Service Now"
          handleCTAClick={handleBookServiceCTA}
        />
      </div>

      {/* Why Drivex */}
      <div
        className={cn(
          "flex flex-col gap-8 px-4 bg-neutral30 py-[25px]  lg:pb-[50px]",
          !isMobile && "items-center",
        )}
      >
        <WhyDrivex />
      </div>

      <div className="content-div flex flex-col bg-neutral900">
        <ProcessSteps
          stepsData={steps}
          media={isDesktop ? ServiceImageWeb : ServiceImage}
          heading={
            <>
              A simple <span className="text-primaryA2">3-step process</span>
            </>
          }
          buttonText="Book a service"
          handleCTAClick={handleBookServiceCTA}
        />
      </div>

      <div
        data-test-id="sellNow"
        data-color="bg-white"
        className="content-div flex flex-col gap-4 pt-[25px] sm:p-3 mx-4"
      >
        <SellNow />
      </div>

      {isMobile && (
        <div className="flex flex-col gap-8 p-4 bg-neutral30 pt-[25px] mt-[20px]">
          <ContactUsDetails />
        </div>
      )}

      <div className="content-divflex flex-col gap-6 p-4 bg-neutral30">
        <FAQs content={serviceFAQsData} handleFAQClick={handleFAQClick} />
      </div>

      <div
        data-test-id="footer"
        data-color="bg-neutral900"
        className="content-div bg-neutral900 flex flex-col gap-6 py-4"
      >
        <Footer />
      </div>
    </>
  );
};

export default Services;
