import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { SelectedCurrentStepComponentProps } from "../../data";

type ExtraProps = {
  models: any[];
};

function SelectModel({
  serviceFlowData,
  setServiceFlowData,
  setSearchParams,
  type,
  models,
}: SelectedCurrentStepComponentProps & ExtraProps) {
  const modelList = models.map((data) => data.model_name);

  const handleModelClick = useCallback(
    (model: string) => {
      setSearchParams({ step: "km_driven", type });
      setServiceFlowData({
        steps: {
          ...serviceFlowData.steps,
          model,
        },
        store_id: serviceFlowData.store_id,
        store_address: serviceFlowData.store_address,
      });
    },
    [type, serviceFlowData],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {modelList.map((model) => {
        return (
          <div
            key={model}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              serviceFlowData.steps.model === model
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleModelClick(model)}
          >
            {model}
          </div>
        );
      })}
    </div>
  );
}

export default SelectModel;
