import { TickIconFilled } from "@/assets/Icons";

interface IProps {
  formData: {
    name: string;
  };
}

const FeedbackSubmitted = ({ formData }: IProps) => {
  return (
    <div className="flex flex-col gap-4 p-2 text-center items-center">
      <TickIconFilled size={50} oneStopColor="#5FA74E" twoStopColor="#5FA74E" />
      <p className="font-semibold text-lg">
        Hi {formData.name}, your query has been recorded successfully!
      </p>
      <p className="text-sm font-medium text-neutral300">
        Our DriveX executive will contact you shortly with further information
        on your query.
      </p>
    </div>
  );
};

export default FeedbackSubmitted;
