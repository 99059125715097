import { validateIndianVehicleNumber } from "@/utils/validation";
import { useEffect, useState } from "react";
import Automatic from "./Automatic";
import Manual from "./Manual";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { LoaderIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router";
import { CheckCircle, ErrorCircle } from "@/assets/Icons";
import ServiceCenter from "./ServiceCenter";
import { track } from "@/utils/mixpanel/actions";
import { errorMessages } from "./data";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import ErrorPopUpContent from "./ErrorPopUpContent";
import { useSelector } from "react-redux";
import { useGetAllStoresQuery } from "@/store/services/dealer";
import Location from "@/components/Location";
import { useGetVehicleInfoMutation } from "@/store/services/services";
import {
  SERVICE_CENTER_SELECTED,
  SERVICE_REQ_VEH_NUM_SUBMITTED,
} from "@/utils/mixpanel/Events/service_flow_events";

const ServiceInfo = () => {
  const [vehicleInfo] = useGetVehicleInfoMutation();
  const [vehicleDetails, setVehicleDetails] = useState({
    store_id: "",
    store_address: "",
    registrationNumber: "",
  });
  const [serviceFlowType, setServiceFlowType] = useState("Proceed");
  const [isValidVehicleRegistrationNumber, setIsVehicleRegistrationValid] =
    useState<string>("");
  const [isServiceCenterSelected, setIsServiceCenterSelected] =
    useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const navigate = useNavigate();
  const { isMobile } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  let { location } = useSelector((state: any) => state.location);
  location = location?.replaceAll("-", " ");
  const { data: storesData } = useGetAllStoresQuery({
    cityName: location,
    category: "SERVICES",
  });
  const stores: IStore[] = storesData?.data?.stores;
  const store = stores?.find(
    (store) => store?.store_id === Number(vehicleDetails?.store_id),
  );
  const store_address = store?.address;

  useEffect(() => {
    setVehicleDetails({
      store_id: "",
      store_address: "",
      registrationNumber: "",
    });
    setServiceFlowType("Proceed");
  }, [location]);

  const handleChange = (
    value: { target: { name: any; value: any } } | string | any,
    type?: any,
  ) => {
    if (!location) {
      setOpenLocation(true);
      return;
    }

    const name = value.target ? value.target.name : type;

    if (name === "store_id") {
      const store = stores.find((store) => store.store_id === parseInt(value));
      track(SERVICE_CENTER_SELECTED, {
        city_name: store?.city,
        store_id: store?.store_id,
        store_name: store?.store_name,
        service_center_phone_no: store?.spoc_number,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
    }

    setVehicleDetails((prev) => ({
      ...prev,
      [name]: value.target ? value.target.value : value,
    }));
    setIsVehicleRegistrationValid("");
    setIsServiceCenterSelected("");
  };

  const verifyVehicleDetails = async () => {
    try {
      const isValidVehicleNumber = validateIndianVehicleNumber(
        vehicleDetails.registrationNumber,
      );

      if (!isValidVehicleNumber) {
        track(SERVICE_REQ_VEH_NUM_SUBMITTED, {
          regNumber: vehicleDetails.registrationNumber,
          status: "Invalid (incorrect input)",
          store_name: store?.store_name,
          store_id: store?.store_id,
          city_name: store?.city,
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });
        setIsVehicleRegistrationValid("invalid");
        return;
      }

      setIsLoading(true);
      const res: any = await vehicleInfo({
        registration_number: vehicleDetails.registrationNumber,
      }).unwrap();
      setIsLoading(false);
      if (res.status === "OK") {
        track(SERVICE_REQ_VEH_NUM_SUBMITTED, {
          regNumber: vehicleDetails.registrationNumber,
          status: "Success",
          store_name: store?.store_name,
          store_id: store?.store_id,
          city_name: store?.city,
          name: user?.userName,
          phone_number: user?.phoneNumber,
        });

        navigate("book-service?step=km_driven&type=automatic", {
          state: {
            vehicleDetails: {
              ...vehicleDetails,
              brand: res.data.brand,
              model: res.data.model,
              year: res.data.manufacturing_year,
              store_address,
            },
          },
        });
      }
    } catch (error: any) {
      setIsLoading(false);
      const errorMessage = error.data.message;

      // If the error message indicates that vehicle info could not be fetched, navigate to the manual flow.
      if (errorMessages.includes(errorMessage)) {
        handleViewAllBrands();
      }

      track(SERVICE_REQ_VEH_NUM_SUBMITTED, {
        regNumber: vehicleDetails.registrationNumber,
        status: "Unfit (Doesn't fit >2016 criteria)",
        store_name: store?.store_name,
        store_id: store?.store_id,
        city_name: store?.city,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });

      setIsVehicleRegistrationValid("unserviceable");
    }
  };

  const handleInspection = async (type: string) => {
    if (serviceFlowType === "Proceed") {
      setServiceFlowType(type);
      return;
    }

    if (
      vehicleDetails.store_id === "" ||
      vehicleDetails.registrationNumber === ""
    ) {
      if (vehicleDetails.store_id === "") {
        setIsServiceCenterSelected("invalid");
      }

      if (vehicleDetails.registrationNumber === "") {
        setIsVehicleRegistrationValid("invalid");
      }

      return;
    }

    verifyVehicleDetails();
  };

  const handleCloseSheet = () => {
    setIsVehicleRegistrationValid("");
    setServiceFlowType("Proceed");
  };

  const handleViewAllBrands = () => {
    navigate("book-service?step=brand&type=manual", {
      state: {
        store_id: vehicleDetails.store_id,
        store_address,
      },
    });
  };

  const handleModalOpen = () => {
    if (isValidVehicleRegistrationNumber === "unserviceable") {
      return true;
    }

    return false;
  };

  const handleModalOnOpenChange = () => {
    setIsVehicleRegistrationValid("");
  };

  const renderPincodeSuccessOrErrorIcon = (successOrError: string) => {
    switch (successOrError) {
      case "serviceable":
        return <CheckCircle />;
      case "invalid":
        return <ErrorCircle />;
      case "":
        return "";
    }
  };

  const renderErrorStates = (type: string) => {
    if (
      isValidVehicleRegistrationNumber === "invalid" &&
      type === "registration"
    ) {
      return "Invalid registration number";
    }

    if (isServiceCenterSelected === "invalid" && type === "service") {
      return "Select a service center";
    }

    return null;
  };

  return (
    <div className="flex flex-col bg-white w-full md:w-[40%] xl:w-[460px] p-5 rounded-md gap-4 relative">
      <Dialog open={openLocation} onOpenChange={() => setOpenLocation(false)}>
        <Location setOpenLocation={setOpenLocation} />
      </Dialog>

      <ServiceCenter
        handleChange={handleChange}
        stores={stores}
        location={location}
        setOpenLocation={setOpenLocation}
        key={location}
      />

      {isServiceCenterSelected === "invalid" && (
        <p className="text-red600 text-sm">{renderErrorStates("service")}</p>
      )}

      {serviceFlowType === "Book" && (
        <Automatic
          handleChange={handleChange}
          isValidVehicleRegistrationNumber={isValidVehicleRegistrationNumber}
          vehicleDetails={vehicleDetails}
          renderPincodeSuccessOrErrorIcon={renderPincodeSuccessOrErrorIcon}
        />
      )}

      {isValidVehicleRegistrationNumber === "invalid" && (
        <p className="text-red600 text-sm">
          {renderErrorStates("registration")}
        </p>
      )}

      <Button
        onClick={() =>
          handleInspection(
            vehicleDetails.store_id === "" && serviceFlowType === "Proceed"
              ? "Proceed"
              : "Book",
          )
        }
        disabled={isLoading}
        variant="outline"
        className="bg-primaryA2 border-none py-6 text-white hover:bg-primaryA2 hover:text-white flex gap-2"
      >
        {serviceFlowType === "Proceed" ? "Proceed" : "Book a Service"}
        {isLoading && <LoaderIcon className="animate-spin" />}
      </Button>

      {vehicleDetails.store_id !== "" && serviceFlowType === "Book" && (
        <>
          <div className="inline-flex items-center justify-center w-full">
            <hr className="w-full h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="absolute px-3 font-medium text-neutral900 text-sm -translate-x-1/2 bg-white left-1/2">
              OR
            </span>
          </div>
          <Manual
            handleViewAllBrands={handleViewAllBrands}
            store_id={vehicleDetails?.store_id}
            store_address={store_address || ""}
          />
        </>
      )}

      {/* This opens the bottom sheet for both automatic & manual if vehicle is inelgible or pincode is unserviceable */}
      {isMobile && (
        <Sheet open={handleModalOpen()} onOpenChange={handleModalOnOpenChange}>
          <SheetContent
            side="bottom"
            className="flex flex-col gap-4 p-4  rounded-t-lg"
          >
            <ErrorPopUpContent
              isMobile={true}
              isValidVehicleRegistrationNumber={
                isValidVehicleRegistrationNumber
              }
              handleCloseSheet={handleCloseSheet}
            />
          </SheetContent>
        </Sheet>
      )}
      {!isMobile && (
        <Dialog open={handleModalOpen()} onOpenChange={handleModalOnOpenChange}>
          <DialogContent className="flex flex-col gap-4 p-4 items-center text-center rounded-t-lg">
            <ErrorPopUpContent
              isValidVehicleRegistrationNumber={
                isValidVehicleRegistrationNumber
              }
              handleCloseSheet={handleCloseSheet}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default ServiceInfo;
