import { cn } from "@/lib/utils";
import { useCallback, useEffect } from "react";
import { SelectedCurrentStepComponentProps } from "../../data";
import { useLocation } from "react-router-dom";
import { kmDriven } from "@/pages/Sell/ManualSellFlow/data";

function SelectKMDriven({
  serviceFlowData,
  setServiceFlowData,
  type,
  setSearchParams,
}: SelectedCurrentStepComponentProps) {
  const location = useLocation();

  const locationState = location.state as {
    vehicleDetails?: {
      store_id: string;
      store_address: string;
      registrationNumber: string;
      brand: string;
      model: string;
      year: string;
    };
  };
  const initialData = locationState?.vehicleDetails;

  const getServiceCenterAndRegistrationNumber = async () => {
    if (initialData) {
      setServiceFlowData({
        steps: {
          ...serviceFlowData.steps,
          brand: initialData.brand,
          model: initialData.model,
          year: initialData.year,
        },
        store_id: initialData.store_id,
        store_address: initialData.store_address,
        ...(type === "automatic" && {
          registration_number: initialData.registrationNumber,
        }),
      });
    }
  };

  useEffect(() => {
    getServiceCenterAndRegistrationNumber();
  }, [initialData]);

  const handleKMDrivenClick = useCallback(
    (km_driven: { min_km: number; max_km: number; displayValue: string }) => {
      setSearchParams({ step: "service", type });
      setServiceFlowData({
        steps: {
          ...serviceFlowData.steps,
          km_driven: `${km_driven.min_km},${km_driven.max_km}`,
        },
        store_id: serviceFlowData.store_id,
        store_address: serviceFlowData.store_address,
        ...(type === "automatic" && {
          registration_number: serviceFlowData.registration_number,
        }),
      });
    },
    [type, serviceFlowData],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {kmDriven.map((km_driven) => {
        return (
          <div
            key={km_driven.displayValue}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              serviceFlowData.steps.km_driven ===
                `${km_driven.min_km},${km_driven.max_km}`
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleKMDrivenClick(km_driven)}
          >
            {km_driven.displayValue}
          </div>
        );
      })}
    </div>
  );
}

export default SelectKMDriven;
