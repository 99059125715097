import { useState } from "react";
import Accordion from "../Accordion";
import FAQComponent from "../FooterContainer/FooterComponent";

const FAQs = ({
  content,
  handleFAQClick,
  city,
}: {
  content?: { heading: string; description: string | object }[];
  city?: string;
  handleFAQClick?: (heading: string) => void;
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number, heading: string) => {
    setOpenIndex(openIndex === index ? null : index);
    if (handleFAQClick) handleFAQClick(heading);
  };

  if (!content) {
    return;
  }

  return (
    <section className="flex flex-col max-w-[60rem] mx-auto w-full gap-y-4 bg-transparent pl-2 pr-2 ">
      <h2 className="text-neutral900 text-[1.4rem] text-center pb-8">
        {city
          ? `FAQs About Used Bikes in ${city}`
          : "Frequently Asked Questions"}
      </h2>
      <div className="p-1">
        {content.map((faq: any, index) => (
          <Accordion
            key={index}
            heading={faq.heading}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index, faq.heading)}
          >
            {typeof faq.description === "string" ? (
              <p className="text-neutral300 pb-4">{faq.description}</p>
            ) : (
              <FAQComponent content={faq.description} />
            )}
          </Accordion>
        ))}
      </div>
    </section>
  );
};

export default FAQs;
