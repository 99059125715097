import { useEffect } from "react";
import { IInspectionDetail } from "../../Sell/data";
import { getReturningCustomerData } from "@/utils/returningCustomer";
import { getCurrentDateAndTime } from "@/utils/date";

const Estimation = ({
  estimatedPrice,
}: {
  estimatedPrice: IInspectionDetail;
}) => {
  const { min_price, max_price } = estimatedPrice;
  const isPriceAvailable = min_price && max_price;

  useEffect(() => {
    getReturningCustomerData({
      vehicleDetail: {
        type: "schedule-inspection",
        date_time: getCurrentDateAndTime(), // sends the current date and time
        version: "v1.0-schedule-inspection",
        data: { ...estimatedPrice },
      },
    });
  }, [estimatedPrice]);

  if (!isPriceAvailable) {
    return (
      <>
        <p className="text-xl font-semibold text-center p-4">Stay Tuned ✨</p>

        <p className="text-sm text-neutral300 text-center">
          We&apos;re enhancing our pricing engine. Book a home inspection to get
          the best value for your two-wheeler!
        </p>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2 items-center bg-neutral30 p-6 rounded-lg">
        <p className="font-semibold text-base sm:whitespace-nowrap">
          Your two-wheeler is valued b/w
        </p>
        <p className={"text-2xl whitespace-nowrap text-primaryA2"}>
          ₹{parseInt(String(min_price))?.toLocaleString()} - ₹
          {parseInt(String(max_price))?.toLocaleString()}
        </p>
      </div>
      <p className="text-sm text-neutral300 text-center">
        Note: Price range is approximate. The final offering price will be
        provided after our expert inspect the two-wheeler after a home
        inspection.
      </p>
    </>
  );
};

export default Estimation;
