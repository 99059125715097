import { DriveXFilledIcon, MapPin, PhoneIcon } from "@/assets/Icons";
import { getOwnerShipStatus } from "@/common/product";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ensureHttpsProtocol } from "@/utils/storeLocation";
import TestRide from "./TestRide";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { useEffect, useState } from "react";
import Emi from "@/components/Emi";
import { emi_calculator } from "@/utils/emi";
import {
  EnquiryType,
  IS_CLIENT_SIDE,
  MAXIMUM_TENURE,
  MINIMUM_DOWN_PAYMENT,
  RATE_OF_INTEREST_DEFAULT_VALUE,
} from "@/utils/constants";
import { track } from "@/utils/mixpanel/actions";
import {
  PDP_VEHICLE_ENQUIRY_CLICKED_EVENT,
  PDP_STORE_LOCATION_CLICKED_EVENT,
} from "@/utils/mixpanel/Events/pdp_clicked_events";
import { enquiry } from "@/store/services/scheduleApi";
import { meta_conversion_api } from "@/utils/metaPixels";
import { usePendingAction } from "@/hooks/usePendingAction";
import { useSelector } from "react-redux";
import SoldOut from "./SoldOut";
import { cn } from "@/lib/utils";
import DmxVehicleInfo from "@/DmxPages/StoreDetail/DmxVehicleInfo";
import UpdateVehicleDetail from "@/DmxPages/StoreDetail/UpdateVehicleDetail";

interface IProps {
  isVehicleAvailable: boolean;
  productInfo: IVehicleInfo;
  testRideDetails?: {
    date: string;
    exists: boolean;
  } | null;
  user?: IUser;
  whatsappTourExists?: {
    exists: boolean;
  } | null;
  fetchUpcomingWhatsappTour?: () => void;
  showEmi?: boolean;
  showCallStore?: boolean;
  showStoreName?: boolean;
  showPrice?: boolean;
  orderDetails?: any;
  storeOrderDetails?: React.Dispatch<React.SetStateAction<any>>;
  openQRModal?: boolean;
  setOpenQRModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showDmxVehicleInfo?: boolean;
  showTestRide?: boolean;
  showUpdateVehicleDetail?: boolean;
  setOpenEditVehicleModal?: React.Dispatch<React.SetStateAction<boolean>>;
  handleDownload?: () => void;
  refetchGetVehicleOrderDetail?: () => void;
}

const ProductInfo = ({
  isVehicleAvailable,
  productInfo,
  testRideDetails,
  user,
  whatsappTourExists,
  fetchUpcomingWhatsappTour,
  showEmi = true,
  showCallStore = true,
  showStoreName = true,
  showPrice = true,
  orderDetails,
  storeOrderDetails,
  openQRModal = false,
  setOpenQRModal = () => {},
  showDmxVehicleInfo = false,
  showTestRide = true,
  showUpdateVehicleDetail = false,
  setOpenEditVehicleModal,
  handleDownload,
  refetchGetVehicleOrderDetail,
}: IProps) => {
  const [openEmi, setOpenEmi] = useState(false);
  const { isDesktop } = useScreenDetector();
  const { handlePendingAction } = usePendingAction();
  const pendingAction = useSelector(
    (state: any) => state.pendingActions.pendingAction,
  );
  const store = useSelector((state: any) => state.store.store);
  const location = useLocation();
  const navigate = useNavigate();
  const storeURL = ensureHttpsProtocol(productInfo?.storeMapLink);
  const utm_parameters =
    IS_CLIENT_SIDE &&
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");
  const openCallStore = pendingAction?.openCallStore;

  useEffect(() => {
    if (!openCallStore || !user) return;

    handleEnquiryAndTrack();
    handlePendingAction({ actionType: "CLEAR_PENDING_ACTION" });
  }, [openCallStore, user]);

  const trackPdpVehicleEnquiry = () => {
    track(PDP_VEHICLE_ENQUIRY_CLICKED_EVENT, {
      vehicle_id: productInfo.vehicleId,
      vehicle_brand: productInfo.brand,
      vehicle_model: productInfo.modelName,
      vehicle_name: productInfo.vehicleName,
      store_name: productInfo.storeName,
      store_location: productInfo.storeMapLink,
      store_phone_number: productInfo.storePhoneNumber,
      source: "PDP",
      vehicle_url: window.location.href,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleStoreLocation = () => {
    track(PDP_STORE_LOCATION_CLICKED_EVENT, {
      store_name: productInfo.storeName,
      vehicle_id: productInfo.vehicleId,
      city_name: productInfo.city,
      name: user?.userName,
      phone_number: user?.phoneNumber,
      source: "PDP",
    });
  };

  const handleEnquiryAndTrack = () => {
    enquiry(
      {
        store_id: productInfo.storeId,
        vehicle_id: productInfo.vehicleId,
        name: user?.userName,
        phone_number: user?.phoneNumber,
        ...utm_parameters,
      },
      EnquiryType.VEHICLE_ENQUIRY,
    );

    meta_conversion_api({
      eventName: "Lead",
      eventId: productInfo?.vehicleId,
      eventType: "Vehicle Enquiry",
      phoneNumber: user?.phoneNumber ?? "",
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "Lead");

    trackPdpVehicleEnquiry();

    window.location.href = `tel:${productInfo?.storePhoneNumber.replaceAll("-", "")}`;
  };

  const handleVehicleEnquiry = () => {
    if (!user) {
      trackPdpVehicleEnquiry();
      handlePendingAction({ actionType: "OPEN_CALL_STORE" });
      navigate(`${location.pathname}?login=true`, {
        state: {
          source: "pdp-vehicle-enquiry",
        },
      });
      return;
    }

    handleEnquiryAndTrack();
  };

  return (
    <section className="md:mx-4 h-full xl:min-w-[400px] md:border-[1px] md:shadow-sm md:rounded-md md:py-4 relative">
      <div className="flex flex-col gap-4 p-4 pt-1">
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-xl font-semibold">{productInfo.vehicleName}</h1>
          {productInfo?.drivexAssured && (
            <div className="flex gap-2 items-center p-2 py-1.5 bg-accent rounded">
              <DriveXFilledIcon />
              <p className="text-white text-xs font-medium">Assured</p>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-between">
          <p className="text-neutral300 text-base font-semibold">
            {productInfo?.registrationNumber &&
              `${productInfo?.registrationNumber} • `}
            {productInfo?.kmDriven?.displayValue} {productInfo?.kmDriven?.unit}s
            • {getOwnerShipStatus(productInfo.ownerShip)}•{" "}
            {productInfo.manufactureYear}
          </p>
        </div>
        {(showPrice || showEmi) && (
          <div
            className={cn("border-y leading-10 py-2 flex-between items-center")}
          >
            {showPrice && (
              <p className="text-xl text-primaryA2">
                ₹{productInfo?.price?.displayValue}
              </p>
            )}

            {showEmi && (
              <p
                className="text-[15px] text-right text-neutral300"
                onClick={() => setOpenEmi(true)}
              >
                EMI starts with &nbsp;
                <span className="underline underline-offset-[2px] text-primaryA2 decoration-orange-500 text-base font-semibold">
                  ₹
                  {emi_calculator(
                    productInfo?.price?.value -
                      (MINIMUM_DOWN_PAYMENT / 100) * productInfo?.price?.value,
                    RATE_OF_INTEREST_DEFAULT_VALUE,
                    MAXIMUM_TENURE / 12,
                  ).toLocaleString("en-IN")}
                  /m
                </span>
              </p>
            )}
          </div>
        )}

        {showEmi && (
          <Emi
            openEmi={openEmi}
            setOpenEmi={setOpenEmi}
            price={productInfo?.price}
          />
        )}

        <div className="flex flex-between items-center gap-2">
          {showStoreName && (
            <Link
              to={storeURL}
              target="_blank"
              onClick={handleStoreLocation}
              className="max-w-[70%] cursor-pointer"
            >
              <div className="flex items-center text-xs text-neutral300 gap-1">
                <MapPin colorOne="#919191" colorTwo="#919191" size={18} />
                <p className="font-semibold text-sm capitalize leading-5 overflow-hidden text-ellipsis whitespace-nowrap">
                  {productInfo?.storeName}
                </p>
                <span className="text-base">&gt;</span>
              </div>
            </Link>
          )}

          {showCallStore && (
            <div
              className="text-sm flex gap-1 items-center"
              onClick={handleVehicleEnquiry}
            >
              <PhoneIcon />
              <p className="text-primaryA2 underline underline-offset-[2px] decoration-orange-500 whitespace-nowrap">
                Call store
              </p>{" "}
            </div>
          )}
        </div>

        <div className="hidden lg:block">
          {isVehicleAvailable && showTestRide && (
            <TestRide
              testRideDetails={testRideDetails}
              vehicleDetails={productInfo}
              whatsappTourExists={whatsappTourExists}
              fetchUpcomingWhatsappTour={fetchUpcomingWhatsappTour}
              whatsAppIconPaintUrl="whatsapp_paint_url_product_info"
            />
          )}
        </div>

        {showDmxVehicleInfo && isDesktop && (
          <DmxVehicleInfo
            store={store}
            productDetail={productInfo}
            orderDetails={orderDetails}
            storeOrderDetails={storeOrderDetails}
            openQRModal={openQRModal}
            setOpenQRModal={setOpenQRModal}
            handleDownload={handleDownload}
          />
        )}

        {showUpdateVehicleDetail && setOpenEditVehicleModal && (
          <UpdateVehicleDetail
            productDetail={productInfo}
            className="static"
            setOpenEditVehicleModal={setOpenEditVehicleModal}
            orderStatus={orderDetails?.order_status}
            refetchGetVehicleOrderDetail={refetchGetVehicleOrderDetail}
          />
        )}

        {isDesktop && !isVehicleAvailable && <SoldOut />}
      </div>
    </section>
  );
};

export default ProductInfo;
