import aa, { InsightsEvent } from "search-insights";

export const initialize_aloglia = () => {
  aa("init", {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    apiKey: import.meta.env.VITE_ALGOLIA_API_KEY,
  });
};

export const algoliaConfigs = ({
  eventName,
  eventType,
  objectData,
  objectIDs,
  positions,
  queryID,
  index,
}: InsightsEvent) => {
  const encodedUserId = localStorage.getItem("encodedUserId") || "";
  return {
    userToken: encodedUserId,
    authenticatedUserToken: !encodedUserId.startsWith("anon-")
      ? encodedUserId
      : "",
    index,
    eventName,
    queryID: `${queryID}`,
    objectIDs: [`${objectIDs}`],
    positions,
    eventType,
    objectData,
  };
};
