import Brand from "@/components/Brand";
import { useCallback, useEffect } from "react";
import { cn } from "@/lib/utils";
import { SelectedCurrentStepComponentProps } from "../data";
import { getModelAndVariant } from "@/store/services/scheduleApi";
import { useSelector } from "react-redux";
import { IBrand, useGetBrands } from "../../HeroSection/Vahan/data";
import { useLocation } from "react-router-dom";

type ExtraProps = {
  setModels: any;
};

function SelectBrand({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
  setModels,
}: SelectedCurrentStepComponentProps & ExtraProps) {
  const location = useLocation();
  const locationState = location.state as { pin_code?: string };
  const pin_code = locationState?.pin_code;
  const { allBrands } = useSelector((state: any) => state.allBrands);
  const { getBrands } = useGetBrands();

  useEffect(() => {
    if (!allBrands) {
      getBrands();
    }
  }, []);

  const retrievePinCode = () => {
    if (pin_code) {
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
        },
        pin_code,
      });
    }
  };

  useEffect(() => {
    retrievePinCode();
  }, [pin_code]);

  const handleBrandClick = useCallback(
    async (brandName: string) => {
      const res = await getModelAndVariant(brandName);
      if (res.status === "OK") {
        setModels(res.data);
        setSearchParams({ step: "state", type });
        setPriceEstimate({
          steps: {
            ...priceEstimate.steps,
            brand: brandName,
          },
          pin_code: priceEstimate.pin_code,
        });
      }
    },
    [type, priceEstimate],
  );

  if (!allBrands) return;

  return (
    <div className="grid max-[250px]:flex flex-wrap max-[400px]:grid-cols-2 grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
      {allBrands.map((brand: IBrand, index: number) => (
        <Brand
          key={index}
          brand={brand}
          className={cn(
            "w-full border text-center",
            priceEstimate.steps.brand === brand.name
              ? "border-primaryA1"
              : "bg-white",
          )}
          handleClick={() => handleBrandClick(brand.name)}
        />
      ))}
    </div>
  );
}

export default SelectBrand;
