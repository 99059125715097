import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { FilledDocument } from "@/assets/Icons";
import { useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";

const Paymentbreakdown = ({ finalSellingPrice, amountData }: any) => {
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const type = searchParams.get("type");

  return (
    <Accordion
      className="border rounded-lg px-3 py-0"
      type="single"
      collapsible
    >
      <AccordionItem value="item-1">
        <AccordionTrigger className="flex items-center py-2">
          <div className="flex gap-2 items-center">
            <FilledDocument size={30} />

            <div className="flex flex-col items-start gap-0.5">
              <p className="text-sm">
                {type === "sell" && step === "documents"
                  ? "Total Sale Amount"
                  : "Total Amount to be collected"}{" "}
              </p>
              <p className="text-lg font-medium">
                ₹{finalSellingPrice?.toLocaleString("en-IN")}
              </p>
            </div>
          </div>
        </AccordionTrigger>
        <AccordionContent className="flex flex-col gap-2 py-2">
          {amountData.map((amount: any, index: number) => (
            <div key={index} className="flex justify-between">
              <label
                className={cn(
                  "text-neutral300",
                  amount?.label === "Discount" && "text-[#5FA74E]",
                )}
              >
                {amount?.label}
              </label>
              <label
                className={cn(amount?.label === "Discount" && "text-[#5FA74E]")}
              >
                {amount?.label === "Discount" ? "-₹" : "₹"}
                {parseInt(amount?.value ?? 0).toLocaleString("en-IN")}
              </label>
            </div>
          ))}

          <div className="flex justify-between border-t py-2 items-center">
            <p className="">
              {type === "sell" && step === "documents"
                ? "Total Amount Received"
                : "Balance Amount Receivable"}{" "}
            </p>
            <p className="text-xl font-medium">
              ₹{finalSellingPrice?.toLocaleString("en-IN")}
            </p>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default Paymentbreakdown;
