import { Link, useNavigate } from "react-router-dom";
import { budgetData, budgetType } from "./data.tsx";
import { track } from "@/utils/mixpanel/actions.ts";
import { HOME_BUDGET_CLICKED } from "@/utils/mixpanel/Events/home_clicked_events.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import { useSelector } from "react-redux";

const ShopByBudget = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const pageSource = getPageSource("/");
  const { city } = useCurrentCity();

  const handleShopByBudgetClick = (budget: budgetType) => {
    navigate(`/${city}/buy-two-wheelers?f=price:${budget.value}`, {
      state: { from: "home" },
    });
    track(HOME_BUDGET_CLICKED, {
      budget_range: budget.value,
      page: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <>
      <p className="text-xl md:text-[28px] md:text-center">
        Shop by <span className="text-primaryA2">Budget</span>
      </p>

      <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg mx-auto w-full">
        {budgetData.map((budget, index) => (
          <div
            data-test-id="budget-filter"
            onClick={() => handleShopByBudgetClick(budget)}
            key={index}
            className="flex flex-col w-[47%] md:w-[15%] p-4 bg-neutral30 rounded-md"
          >
            <Link to="" className="flex flex-col gap-2">
              <p className="text-neutral300">{budget.comparison}</p>
              <p className="text-primaryA2">{budget.budget}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default ShopByBudget;
