export const LOGIN_BOTTOMSHEET = "login_bottomsheet";
export const LOGIN_PH_NO_ENTERED = "login_ph_no_entered";
export const LOGIN_USER_NAME_ENTERED = "login_user_name_entered";
export const OTP_SENT_SUCCESSFULLY = "otp_sent_successfully";
export const OTP_VERIFIED_SUCCESSFULLY = "otp_verified_successfully";
export const OTP_RESEND_REQUEST = "otp_resend_request";
export const OTP_VERIFICATION_FAILED = "otp_verification_failed";
export const LOGOUT_CLICKED = "logout_clicked";
export const LOGIN_BOTTOMSHEET_OPENED = "login_bottomsheet_opened";
export const ACCOUNT_OPENED = "account_opened";
