import { Input } from "@/components/ui/input";

interface IProps {
  handleFormInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formData: {
    full_name: string;
    email: string;
  };
  user: {
    userName: string;
    phoneNumber: string;
    email: string;
  };
  emailValidation: string;
}

const Form = ({ formData, handleFormInput, user, emailValidation }: IProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="font-semibold">Full Name</p>
      <div className="flex items-center pr-4 rounded-md bg-neutral30 border-none">
        <Input
          className="bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus:outline-none"
          name="full_name"
          type="text"
          onChange={handleFormInput}
          value={formData.full_name}
          autoFocus
        />
      </div>

      <p className="font-semibold">Phone Number</p>
      <div className="flex items-center pr-4 rounded-md bg-neutral30 border-none">
        <Input
          className="bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0"
          name="phone_number"
          placeholder={user?.phoneNumber}
          disabled
        />
      </div>

      <p className="font-semibold">Email</p>
      <div className="flex items-center pr-4 rounded-md bg-neutral30 border-none">
        <Input
          className={`bg-neutral30 py-6 border-none focus-visible:ring-0 focus-visible:ring-offset-0 ${emailValidation && "bg-red-100"}`}
          name="email"
          type="email"
          onChange={handleFormInput}
          value={formData.email}
        />
      </div>
      {emailValidation && <p className="text-sm">{emailValidation}</p>}
    </div>
  );
};

export default Form;
