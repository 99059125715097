import { useSearchParams } from "react-router-dom";
import Paymentbreakdown from "../Paymentbreakdown";
import { DeleteIcon } from "@/assets/Icons";

const Payment = ({
  dynamicFields,
  renderCustomComponent,
  formData,
  errors,
  finalSellingPrice,
  amountData,
  getAllPaymentFields,
  lastPaymentField,
  handleDelete,
}: any) => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  return (
    <div className="flex flex-col gap-4 my-4 font-medium">
      <p className="font-medium">Please collect the payment & add proof</p>

      {type === "sell" ? (
        <Paymentbreakdown
          finalSellingPrice={finalSellingPrice}
          amountData={amountData}
        />
      ) : (
        <p className="text-3xl font-medium">
          ₹ {parseInt(formData?.booking_amount ?? 0)?.toLocaleString("en-IN")}
        </p>
      )}

      {dynamicFields
        .filter((field: { stepType: string }) => field.stepType === "payment")
        .map((field: any, index: number) => {
          return (
            <div key={index} className="flex flex-col gap-5 overflow-auto">
              <div className="flex flex-col gap-2 relative">
                {field.label && (
                  <div className="flex justify-between items-center">
                    <label
                      htmlFor={field.name}
                      className="text-sm font-semibold flex gap-0.5"
                    >
                      {field.label}
                      {field.required && <p className="text-red-600">*</p>}
                    </label>

                    {getAllPaymentFields?.length > 1 &&
                      lastPaymentField?.name === field?.name && (
                        <div
                          onClick={() => handleDelete(lastPaymentField?.name)}
                        >
                          <DeleteIcon />
                        </div>
                      )}
                  </div>
                )}
                {renderCustomComponent(field)}
              </div>

              {typeof errors[field.name] === "string" && (
                <span className="text-xs text-red-400">
                  {errors[field.name]}
                </span>
              )}
              {field.description && (
                <p className="text-neutral300 text-sm">{field.description}</p>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Payment;
