import { LeftArrowIcon, LogoutIcon } from "@/assets/Icons";
import UserDetails from "@/components/Sidebar/UserDetails";
import { setCurrentUser } from "@/store/features/userSlice";
import { logout } from "@/store/services/authApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StoreDetail from "./StoreDetail";

const DealerProfile = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    await logout();
    dispatch(setCurrentUser(null));
    localStorage.removeItem("encodedUserId");
    localStorage.removeItem("isUserLoggedIn");
    localStorage.removeItem("role");

    navigate("/");
  };
  return (
    <div className="flex flex-col gap-6 p-4 sm:items-center bg-white h-screen">
      <div className="flex justify-between items-center sm:w-[50%] lg:w-[40%]">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <LeftArrowIcon size={20} oneStopColor="#000" twoStopColor="#000" />
          <p>Account</p>
        </div>

        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={handleLogout}
        >
          <p className="text-primaryA2">Logout</p>
          <LogoutIcon />
        </div>
      </div>

      <div className="flex gap-4 py-2 sm:w-[50%] lg:w-[40%]">
        {user && (
          <UserDetails
            user={user}
            updateEditButton={"true"}
            showEditButton={false}
          />
        )}
      </div>

      <StoreDetail />
    </div>
  );
};

export default DealerProfile;
