export function VerticalGradient({
  width = 157,
  height = 668,
}: {
  width?: number | string;
  height?: number | string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x={width}
        width={height}
        height={width}
        transform={`rotate(90 ${width} 0)`}
        fill="url(#paint0_linear_5246_6422)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5246_6422"
          x1="534.738"
          y1="100.5"
          x2="534.738"
          y2="-1.17269e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A0A0A" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function HorizontalGradient({
  width = 360,
  height = 100,
}: {
  width?: number | string;
  height?: number | string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={width}
        height={height}
        fill="url(#paint0_linear_5246_6421)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5246_6421"
          x1="380"
          y1="100.5"
          x2="380"
          y2="-1.17269e-06"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A0A0A" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
