import { getPageSource } from "@/utils/mixpanel/pageSource";
import { BuySellCTA } from "./data";
import { track } from "@/utils/mixpanel/actions";
import {
  HAMBURGER_BUY_CLICKED_EVENT,
  HAMBURGER_SELL_CLICKED_EVENT,
  HAMBURGER_SERVICE_CLICKED_EVENT,
} from "@/utils/mixpanel/Events/hamburger_clicked_events";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";

interface IProps {
  setOpenSidebar: React.Dispatch<React.SetStateAction<any>>;
  navigate: any;
}

const BuySell = ({ setOpenSidebar, navigate }: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const pageSource = getPageSource("/hamburger");
  const { city } = useCurrentCity();
  const handleClick = (index: number) => {
    if (index === 0) {
      track(HAMBURGER_SERVICE_CLICKED_EVENT, {
        page: pageSource,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
      navigate("/service");
      setOpenSidebar(false);
      return;
    }

    if (index === 1) {
      track(HAMBURGER_BUY_CLICKED_EVENT, {
        page: pageSource,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
      navigate(`/${city}/buy-two-wheelers`);
      setOpenSidebar(false);
      return;
    }

    if (index === 2) {
      track(HAMBURGER_SELL_CLICKED_EVENT, {
        page: pageSource,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
      navigate("/sell-two-wheelers");
      setOpenSidebar(false);
    }
  };
  return (
    <>
      {BuySellCTA.map((content, index) => (
        <div
          data-test-id={`sidebar-${content.heading.toLowerCase()}`}
          key={index}
          className="flex gap-4 bg-neutral30 px-4 py-4 rounded-md cursor-pointer items-center"
          onClick={() => handleClick(index)}
        >
          <div className="flex items-center justify-center bg-primaryA2 w-8 h-8 rounded-sm">
            {content.icon}
          </div>
          <div>
            <p className="font-semibold text-sm">{content.heading}</p>
            <p className="text-neutral300 font-semibold text-xs">
              {content.text}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default BuySell;
