import { getAllBrands } from "@/store/services/scheduleApi";
import { useDispatch } from "react-redux";
import { setCurrentBrands } from "@/store/features/allBrandsSlice";
import { useRef } from "react";

export const errorMessages = [
  "Could not fetch vehicle info",
  "Could not fetch vehicle info due to value mismatch error",
];

export type IBrand = {
  img: unknown;
  name: string;
};

export const useGetBrands = () => {
  const dispatch = useDispatch();
  const dataFetchedRef = useRef(false);

  const getBrands = async () => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    const res = await getAllBrands();
    if (res.status === "OK") {
      const transformedArray = res.data.map(
        (brand: { display_name: string; icon_url: string }) => {
          return {
            name: brand.display_name || "",
            img: brand.icon_url,
          };
        },
      );
      dispatch(setCurrentBrands(transformedArray));
    }
  };

  return { getBrands };
};
