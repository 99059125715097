import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import errorImg from "@/assets/svg/ErrorPage/error404.svg";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import Image from "@/components/ui/image";

interface IProps {
  setSelectedFilters: React.Dispatch<React.SetStateAction<object | any>>;
  defaultFilters: { [key: string]: string };
  pathLocation: object | any;
}

const NoVehiclesFound = ({
  setSelectedFilters,
  defaultFilters,
  pathLocation,
}: IProps) => {
  const navigate = useNavigate();
  const { isDesktop } = useScreenDetector();
  return (
    <div className="flex justify-between gap-4 border-[1px] border-neutral50 shadow-sm rounded-[8px] py-4 px-6 items-center bg-white">
      <div className="flex flex-col gap-3">
        <p className="text-base font-semibold lg:text-xl">
          We couldn&apos;t find vehicles {!isDesktop && <br />} matching your
          criteria
        </p>
        <Button
          onClick={() => {
            setSelectedFilters(defaultFilters), navigate(pathLocation);
          }}
          variant="outline"
          className="bg-primaryA2 border-none text-white text-xs w-fit py-3 px-4 hover:bg-primaryA2 hover:text-white lg:text-base"
        >
          View all bikes
        </Button>
      </div>

      <Image
        className="errorImg w-[80px] lg:w-[100px] lg:h-[100px] h-[80px]"
        src={errorImg as string}
        alt="No Results Found"
      />
    </div>
  );
};

export default NoVehiclesFound;
