import AvailabilityData from "@/components/AvailabilityData";
import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";

interface IProps {
  handleVideoPopup: (video: string) => void;
}

const OurMission = ({ handleVideoPopup }: IProps) => {
  const video = "https://www.youtube.com/embed/yFVadpzPMek?autoplay=1&rel=0";

  const OurMissionImage = `${IMAGE_KIT_BASE_URL}/ik_aboutus/ourMissionImage.png`;
  const OurMissionImageDesktop = `${IMAGE_KIT_BASE_URL}/ik_aboutus/ourMissionImageDesktop.png`;
  return (
    <>
      <div className="flex flex-col gap-4">
        <p className="text-neutral300 text-center lg:text-left text-sm font-semibold">
          Our Mission
        </p>

        <h1 className="text-white text-center lg:text-left font-medium text-2xl">
          To provide a{" "}
          <span className="text-primaryA2">
            simplified, credible, <br className="hidden md:block" /> and
            hassle-free
          </span>{" "}
          mobility <br className="hidden md:block" /> ecosystem.
        </h1>

        <Image
          transform="q-90,f-webp"
          onClick={() => handleVideoPopup(video)}
          className="sm:hidden cursor-pointer sm:self-center rounded-lg"
          src={OurMissionImage}
          alt="Our Mission"
        />

        <div className="lg:flex justify-around hidden lg:justify-start lg:gap-10 my-4 text-white">
          <AvailabilityData />
        </div>
      </div>

      <Image
        transform="q-90,f-webp"
        onClick={() => handleVideoPopup(video)}
        className="cursor-pointer hidden sm:block lg:mb-8 rounded-lg"
        src={OurMissionImageDesktop}
        alt="Our Mission Desktop"
      />
      <div className="flex justify-around lg:hidden lg:justify-start lg:gap-10 my-4 text-white">
        <AvailabilityData />
      </div>
    </>
  );
};

export default OurMission;
