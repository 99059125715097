import { Skeleton } from "@/components/ui/skeleton";

const MiniProductCardSkeletonLoader = () => {
  return (
    <div className="flex gap-4">
      <Skeleton className="h-[60px] w-[80px] rounded-xl shadow-sm bg-neutral-200" />
      <div className="flex flex-col gap-2 justify-center">
        <Skeleton className="h-[13px] w-[200px] rounded-md shadow-sm bg-neutral-200" />
        <Skeleton className="h-[13px] w-[200px] rounded-md shadow-sm bg-neutral-200" />
        <Skeleton className="h-[13px] w-[200px] rounded-md shadow-sm bg-neutral-200" />
      </div>
    </div>
  );
};

export default MiniProductCardSkeletonLoader;
