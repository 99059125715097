import { redirectionRoutes } from "@/utils/url";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedirectionHandler = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname.replace(/\/$/, "");
    const rule = redirectionRoutes.find((r) => r.from === currentPath);
    const isDealerMode = localStorage.getItem("role");

    if (rule && currentPath !== rule.to) {
      navigate(rule.to, { replace: true });
    }

    if (isDealerMode && !location.pathname.includes("dealer")) {
      navigate("/dealer/vehicles", { replace: true });
    }
  }, [location.pathname]);

  return null;
};

export default RedirectionHandler;
