import VehiclePendingActionCard from "@/components/VehiclePendingActionCard";
import PendingActionCardLoader from "@/components/SkeletonLoaders/PendingActionCardLoader";
import IncomingVehiclesListing from "./IncomingVehiclesListing";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pluralize } from "@/utils/pluralize";

export interface IVehicleOrder {
  vehicleId: string | number;
  imagePaths: string[];
  storeId: string | number;
  store_type: string;
  kmDriven: { displayValue: string | null; unit: string; value: number | null };
  manufactureYear: string | number;
  purchase_price: { displayValue: string | null } | null;
  price: { displayValue: string | null } | null;
  ownerShip: number;
  vehicleName: string;
  registrationNumber: string;
  storeName: string;
  vehicleType: string;
}

interface IProps {
  incomingVehicleData: {
    orders: IVehicleOrder[];
  };
  isIncomingVehiclesFetching: boolean;
  refetchIncomingVehicles: () => void;
  refetchVehiclesData: () => void;
}

const DeliveryApproval = ({
  incomingVehicleData,
  refetchIncomingVehicles,
  refetchVehiclesData,
  isIncomingVehiclesFetching,
}: IProps) => {
  const navigate = useNavigate();
  const data = incomingVehicleData?.orders;
  const [openIncomingVehiclesListing, setOpenIncomingVehiclesListing] =
    useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const list = searchParams.get("list");
  const isIncomingVehiclesListing = list === "incoming-vehicles";

  useEffect(() => {
    searchParams.delete("id");
    searchParams.delete("list");
    setSearchParams(searchParams);
  }, []);

  const handleAddToFloorStock = () => {
    setOpenIncomingVehiclesListing(true);
    navigate("?list=incoming-vehicles");
  };

  const handleCardClick = (item: any) => {
    setOpenIncomingVehiclesListing(true);
    navigate(`?list=incoming-vehicles&id=${item?.vehicleId}`, {
      state: item,
    });
  };

  const handleBackButtonClick = () => {
    setOpenIncomingVehiclesListing(false);
    searchParams.delete("list");
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  if (data?.length <= 0) return null;

  return (
    <>
      {isIncomingVehiclesFetching ? (
        <PendingActionCardLoader />
      ) : (
        data?.length > 0 && (
          <VehiclePendingActionCard
            heading={
              <p className="text-base font-medium">
                {data?.length} {pluralize(data?.length, "Vehicle")} Delivered
              </p>
            }
            data={data}
            handleCardClick={handleCardClick}
            onCTAClick={handleAddToFloorStock}
            CTA={<p className="text-primaryA2 text-sm">Add to Floor Stock</p>}
          />
        )
      )}

      {data?.length > 0 && isIncomingVehiclesListing && (
        <Sheet
          open={openIncomingVehiclesListing}
          onOpenChange={handleBackButtonClick}
        >
          <SheetContent
            side="right"
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="h-dvh w-screen overflow-y-auto p-0"
          >
            <IncomingVehiclesListing
              incomingVehicleData={data}
              refetchIncomingVehicles={refetchIncomingVehicles}
              refetchVehiclesData={refetchVehiclesData}
              onClose={handleBackButtonClick}
            />
          </SheetContent>
        </Sheet>
      )}
    </>
  );
};

export default DeliveryApproval;
