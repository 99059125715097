import { Skeleton } from "../../ui/skeleton";

const ProductCardSkeletonLoader = () => {
  return (
    <div className="flex flex-col gap-3">
      <Skeleton className="h-[240px] rounded-xl shadow-sm bg-neutral-200" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[170px] rounded-xl shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-[240px] rounded-xl shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-[100px] rounded-xl shadow-sm bg-neutral-200" />
      </div>
    </div>
  );
};

export default ProductCardSkeletonLoader;
