import { cn } from "@/lib/utils";
import motorcycle from "@/assets/svg/Filters/motorcycle.svg";
import scooter from "@/assets/svg/Filters/scooter.svg";
import Image from "@/components/ui/image";

type objectOfStrings = {
  [key: string]: string;
};
interface IProps {
  handleFilterSelection: (
    arg1: string,
    arg2: string | number,
    arg3: string,
    arg4: number,
  ) => void;
  selectedKey: string;
  selectedFilters: objectOfStrings;
  item: { value: string | number; name: string; count: number };
  index: number;
  getOwnershipImage: (ar1: number) => any;
}

const NonCheckboxMultiFilter = ({
  handleFilterSelection,
  selectedKey,
  selectedFilters,
  item,
  index,
  getOwnershipImage,
}: IProps) => {
  return (
    <div
      onClick={() =>
        handleFilterSelection(
          selectedKey,
          item.value ? item.value : item.name,
          "multi",
          item.count,
        )
      }
      className={cn(
        "rounded-lg p-5 flex-between items-center text-sm border border-neutral50",
        selectedFilters[selectedKey]
          .split(",")
          .includes(item.value ? item.value.toString() : item.name) &&
          "bg-neutral30 border-orange-500 lg:text-black",
      )}
    >
      <div className="flex gap-2">
        <p key={index}>{item.name}</p>
        <p className="text-neutral200">{item.count}</p>
      </div>

      {selectedKey === "ownership" && (
        <Image src={getOwnershipImage(index)} alt={item.name} />
      )}

      {selectedKey === "bikeType" && (
        <Image
          src={item.name === "Scooter" ? scooter : motorcycle}
          alt={item.name}
        />
      )}
    </div>
  );
};

export default NonCheckboxMultiFilter;
