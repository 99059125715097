import { track } from "@/utils/mixpanel/actions";
import { LOCATION_CITY_CLICKED } from "@/utils/mixpanel/Events/location_clicked_events";
import { useSelector } from "react-redux";

type state = React.Dispatch<React.SetStateAction<any>>;

interface IProps {
  remainingLocations: {
    location: string;
  }[];
  dispatch: state;
  setLocation: state;
  setOpenLocation: state;
}

type locationItemType = { location: string };

const MoreCities = ({
  remainingLocations,
  dispatch,
  setLocation,
  setOpenLocation,
}: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);

  const handleClick = (locationItem: locationItemType) => {
    track(LOCATION_CITY_CLICKED, {
      city_name: locationItem.location,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    dispatch(setLocation(locationItem.location));
    setOpenLocation(false);
  };

  return (
    <div className="flex flex-col gap-3 overflow-auto">
      <p className="text-sm text-neutral300 ">More Cities</p>

      <div className="flex flex-col lg:flex-row lg:flex-wrap gap-3 lg:gap-4 overflow-auto">
        {remainingLocations.map((locationItem, index) => (
          <p
            onClick={() => handleClick(locationItem)}
            key={index}
            className="border-b lg:min-w-[15%] lg:border lg:border-lightgray lg:rounded-md lg:text-center leading-10"
          >
            {locationItem.location.replaceAll("-", " ")}
          </p>
        ))}
      </div>
    </div>
  );
};

export default MoreCities;
