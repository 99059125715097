import { Skeleton } from "@/components/ui/skeleton";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const ImagePreviewSkeleton = () => {
  return (
    <>
      <Skeleton className="w-[105px] h-[80px] rounded-xl shadow-sm bg-neutral-200" />
      <Skeleton className="w-[105px] h-[80px] rounded-xl shadow-sm bg-neutral-200" />
      <Skeleton className="w-[105px] h-[80px] rounded-xl shadow-sm bg-neutral-200" />
    </>
  );
};

const CardDetailSkeleton = () => {
  return (
    <>
      <Skeleton className="h-4 w-[120px] rounded-xl shadow-sm bg-neutral-200" />
      <Skeleton className="h-2 w-[240px] rounded-xl shadow-sm bg-neutral-200" />
      <Skeleton className="h-4 w-[120px] rounded-xl shadow-sm bg-neutral-200" />

      <div className="flex-between">
        <Skeleton className="h-2 w-[150px] rounded-xl shadow-sm bg-neutral-200" />
        <Skeleton className="h-2 w-[80px] rounded-xl shadow-sm bg-neutral-200" />
      </div>
    </>
  );
};

const ProductDetailSkeletonLoader = () => {
  const { isDesktop } = useScreenDetector();
  return (
    <>
      {isDesktop ? (
        <div className="flex gap-4 p-4">
          <div className="flex flex-col gap-3">
            <ImagePreviewSkeleton />
          </div>

          <div>
            <Skeleton className="h-[400px] w-[630px] shadow-sm bg-neutral-200" />
          </div>

          <div className="flex flex-col gap-5 px-4">
            <CardDetailSkeleton />

            <Skeleton className="h-10 w-full rounded-sm shadow-sm bg-neutral-200" />
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <Skeleton className="h-[320px] shadow-sm bg-neutral-200" />

          <div className="flex gap-3 px-4">
            <ImagePreviewSkeleton />
          </div>

          <div className="flex flex-col gap-3 px-4">
            <CardDetailSkeleton />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetailSkeletonLoader;
