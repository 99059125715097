import Navbar from "@/components/Navbar";
import HeroGIFBanner from "./components/HeroGIFBanner";
import Footer from "@/components/Footer";
import HelpCard from "./components/HelpCard";

const PrivacyPolicy = () => {
  return (
    <section className="bg-neutral30">
      <Navbar showSearchbar={false} backgroundColor="bg-neutral900" />

      <div className="pt-[70px] relative">
        <HeroGIFBanner title="Privacy Policy" />
      </div>
      <div className="p-4 text-justify font-normal max-w-7xl mx-auto w-full flex flex-col lg:flex-row lg:gap-20">
        <div className="lg:w-[70%]">
          <section className="mt-10">
            <h5 className="text-lg font-semibold">Introduction</h5>
            <div className="mt-4 space-y-6">
              <p>Last updated on 08 August 2024</p>
              <p>
                Your Privacy is of paramount importance to us. We are committed
                to safeguarding your privacy and protecting your Personal Data
                that is with us. This Privacy Notice outlines the details of the
                Personal Data we collect and process, how we handle it and the
                purposes for which we use it. Please read the following
                carefully to understand our practices regarding your Personal
                Data.
              </p>
              <p>
                Throughout this document, the terms “we”, “us”, “our” &amp;
                “ours” refer to Drive X Mobility Pvt Ltd (hereinafter referred
                to as “DriveX”). And the terms “you”, “your” &amp; “yours” refer
                to YOU (the individual whose Personal Data we are referring to).
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              What Personal Data Do We Collect, Store and Process?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                Categories of Personal Data that we collect, store and process
                are as follows:
              </p>
              <ol className="flex flex-col gap-2 list-[lower-roman] ml-8">
                <li className="pl-3">
                  Identity &amp; Contact Data (for e.g., name, last name, email
                  address, address proof, contact number, language, occupation,
                  physical address with pin code, date of birth, gender.)
                </li>
                <li className="pl-3">
                  Personal Identification Number (for e.g., PAN Card No, Aadhaar
                  No, Voter ID, Driving license, Vehicle Registration No)
                </li>
                <li className="pl-3">
                  Financial Account Details (for e.g., Bank account Details)
                </li>
                <li className="pl-3">
                  Educational &amp; Professional Data (for e.g., Education,
                  Profession, Employment Type, work experience, Performance
                  History.)
                </li>
                <li className="pl-3">
                  Log data (IP address, browser version, device information, on
                  page analytic and other statistics)
                </li>
                <li className="pl-3">
                  Mobile Application data (Operating System, Application usage
                  analytics and statistics)
                </li>
                <li className="pl-3">
                  Business information (for e.g. dealership name, dealership
                  code, business type, vehicle type, vehicle images)
                </li>
                <li className="pl-3">
                  Communications details (for e.g., communication done through
                  emails)
                </li>
                <li className="pl-3">
                  Business information (for e.g. dealership name, dealership
                  code, business type, vehicle type)
                </li>
              </ol>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              Where Do We Collect Your Personal Data From?
            </h5>
            <div className="mt-4 space-y-6">
              <p>We collect your Personal Data in the following ways:</p>
              <ol className="flex flex-col gap-2 list-[lower-roman] ml-8">
                <li className="pl-3">
                  When you visit our website or social media pages and fill the
                  registration form or use the contact us facility to reach us
                </li>
                <li className="pl-3">
                  When you interact with us via our websites or use services on
                  our websites including customer support
                </li>
                <li className="pl-3">When you use our mobile application</li>
                <li className="pl-3">
                  When you interact with our marketing team and or our dealers
                </li>
                <li className="pl-3">When you apply for a job at Drive X</li>
                <li className="pl-3">
                  When we onboard you as an employee and during your subsequent
                  interactions with us as an employee
                </li>
              </ol>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              How Do We Use Your Personal Data?
            </h5>
            <div className="mt-4 space-y-6">
              <p>We use your Personal Data for the following purposes:</p>
              <ol className="flex flex-col gap-2 list-[upper-alpha] ml-8">
                <li className="pl-3">
                  If you express interest in our services or are a potential
                  customer, we collect and share your personal data with our
                  sales and marketing team, who in turn will contact you to
                  provide information on products and services
                </li>
                <li className="pl-3">
                  If you are an existing customer, we collect and use your
                  personal data for the following purposes:
                  <ol className="flex flex-col gap-2 list-[lower-roman] ml-8 mt-2">
                    <li className="pl-3">
                      to authenticate your identity and facilitate buying and
                      selling process
                    </li>
                    <li className="pl-3">
                      to verify the ownership of vehicle and to facilitate
                      vehicle transfer and insurance transfer
                    </li>
                    <li className="pl-3">
                      to authenticate your account or information on our
                      applications or portal that you use
                    </li>
                    <li className="pl-3">
                      to provide you with our products and services
                    </li>
                    <li className="pl-3">
                      to communicate with you regarding existing products and
                      services availed by you, including notifications of any
                      alerts or updates
                    </li>
                    <li className="pl-3">
                      to evaluate, develop, and improve our services
                    </li>
                    <li className="pl-3">
                      to manage sales and refunds process, if any
                    </li>
                    <li className="pl-3">
                      for market and product analysis and market research
                    </li>
                    <li className="pl-3">
                      to send you information about our other products or
                      services which may be of interest to you
                    </li>
                    <li className="pl-3">
                      to obtain feedback and handle enquiries and complaints
                    </li>
                    <li className="pl-3">
                      to comply with legal or regulatory requirements
                    </li>
                  </ol>
                </li>
                <li className="pl-3">
                  If you are a potential employee or an existing employee, we
                  collect and process your personal data for the purpose of
                  processing your application or for employment purposes
                </li>
                <li className="pl-3">
                  If you are a vendor, we collect your personal data for the
                  onboarding process, billing, and communication purposes
                </li>
              </ol>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              On What Legal Grounds Do We Process Your Personal Data?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                We process your Personal Data by relying on one or more of the
                following legal grounds:
              </p>
              <ol className="flex flex-col gap-2 list-[lower-roman] ml-8">
                <li className="pl-3">
                  You have consented to us for processing your personal data for
                  specified reasons
                </li>
                <li className="pl-3">
                  You have voluntarily provided your personal data to us for
                  specified reasons. (e.g., to facilitate the vehicle buying or
                  selling process or to communicate with you regarding existing
                  products and services you have availed yourself of)
                </li>
                <li className="pl-3">
                  The processing is necessary for compliance with legal
                  obligations we may have towards other stakeholders, such as
                  law enforcement agencies, government agencies, regulators,
                  etc. Where the processing is based on your consent, you have
                  the right to withdraw your consent at any point in time. Upon
                  receipt of your request to withdraw your consent, the
                  consequences of withdrawal will be communicated to you. In
                  many cases, upon such a withdrawal, we may not be able to
                  continue offering our products and services to you. You may
                  withdraw consent by contacting us using the details specified
                  in the &apos;Contact Us&apos; section
                </li>
              </ol>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              Who Do We Share Your Personal Data With?
            </h5>
            <div className="mt-4 space-y-6">
              <p>We may disclose your Personal Data to:</p>
              <ol className="flex flex-col gap-2 list-[lower-roman] ml-8">
                <li className="pl-3">
                  Our affiliates or group companies for operational and business
                  purposes
                </li>
                <li className="pl-3">Our dealers</li>
                <li className="pl-3">
                  Third Party Service Providers who work for us or provide
                  services or products to us
                </li>
              </ol>
              <p>
                We may also share your Personal Data under the following
                circumstances:
              </p>
              <ol className="flex flex-col gap-2 list-[lower-roman] ml-8">
                <li className="pl-3">
                  To respond to court orders, or legal process, or to establish
                  our legal rights or defend against legal claims
                </li>
                <li className="pl-3">
                  if we are acquired by or merged with another company
                </li>
              </ol>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              How Do We Secure Your Personal Data?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                We are committed to protecting your Personal Data in our
                custody. We take reasonable steps to ensure that appropriate
                physical, technical, and managerial safeguards are in place to
                protect your Personal Data from unauthorized access, alteration,
                transmission, and deletion. We train our employees about the
                importance of maintaining the privacy and security of your
                Personal Data. We ensure that the third parties with whom we
                share your Personal Data under appropriate contracts; take
                appropriate security measures to protect your Personal Data in
                line with our policies.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              How Long Do We Keep Your Personal Data?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                We retain your Personal Data for as long as it is required to
                fulfil the purposes outlined in this Privacy Notice and for
                legal or regulatory reasons.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              How Do We Use Cookies and other Tracking Mechanisms?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                We use cookies and other tracking mechanisms on our website to
                collect data about you. We use the data collected from cookies
                and trackers to analyze trends and statistics. This will help us
                optimize and customize your website experience and to provide
                better website functionalities.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              What Are Your Privacy Rights?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                You have certain rights regarding your Personal Data that is
                with us, and we commit to provide you with them.
              </p>
              <ul className="flex flex-col list-disc gap-2 ml-8">
                <li className="pl-3">
                  <p className="font-semibold">Right to Access Information:</p>
                  You have the right to get confirmation and access to your
                  Personal Data that is with us along with other supporting
                  information
                </li>
                <li className="pl-3">
                  <p className="font-semibold">Right to Correction:</p> You have
                  the right to ask us to correct, complete, update your personal
                  data which is stored with us.
                </li>
                <li className="pl-3">
                  <p className="font-semibold">Right to Erasure:</p> You have
                  the right to ask us to erase your Personal Data that is with
                  us under certain circumstances.
                </li>
                <li className="pl-3">
                  <p className="font-semibold">Right to nominate:</p> You have
                  the right to nominate, any other individual, who shall, in the
                  event of your death/ incapacity exercise your rights with
                  respect to the Data Privacy.
                </li>
                <li className="pl-3">
                  <p className="font-semibold">Right of grievance redressal:</p>{" "}
                  You have the right to readily available means of grievance
                  redressal in respect of any act or omission committed by us in
                  relation to your personal data or your rights guaranteed under
                  this act.
                </li>
                <li className="pl-3">
                  <p className="font-semibold">Right to withdraw consent:</p>{" "}
                  You have the right to withdraw your consent at any point of
                  time. However, in some circumstances, we may have to limit the
                  products and services provided to you due to withdrawal of
                  your consent.
                </li>
              </ul>
              <p>
                If you wish to make a request to exercise any of your rights,
                you can contact us using the details in the{" "}
                <span className="font-semibold">&apos;Contact Us&apos;</span>{" "}
                section of this Privacy Notice.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">Links to Other Websites</h5>
            <div className="mt-4 space-y-6">
              <p>
                Our website may contain links to websites of other
                organisations. This Privacy Notice does not cover how that
                organisation processes Personal Data. We encourage you to read
                the Privacy Notices of the other websites you visit for your own
                privacy and personal security.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              How Do We Keep This Notice Up to Date?
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                We regularly review and update our Privacy Notice to ensure it
                is up-to-date and accurate. Any changes we may make to this
                Privacy Notice in the future will be posted on this page. When
                we post changes to this Privacy Notice, we will revise the “last
                updated” date.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">Limitation of Liability</h5>
            <div className="mt-4 space-y-6">
              <p>
                To the extent permissible under the law, we shall not be liable
                for any indirect, incidental, special, consequential, or
                exemplary damages, including but not limited to, damages for
                loss of profits, goodwill, data, information, or other
                intangible losses (even if we have been advised of the
                possibility of such damages), arising out of this Privacy
                Policy.
              </p>
            </div>
          </section>
          <section className="mt-10">
            <h5 className="text-lg font-semibold">
              Governing Law, Jurisdiction and Dispute Resolution
            </h5>
            <div className="mt-4 space-y-6">
              <p>
                This Privacy Policy shall be construed and governed by the laws
                of India without regard to principles of conflict of laws.
                However, if a dispute resolution mechanism is available under a
                separate statute relevant to the Privacy laws, that mechanism
                shall be the primary method for resolving the dispute under this
                notice. In the absence of such a mechanism, all disputes,
                differences, claims, controversies and the like, which may arise
                between the parties to this Privacy Policy, including
                construction, meaning or operation or effect of the same shall
                be referred to arbitration of a sole arbitrator to be nominated
                by the MCCI Arbitration, Mediation and Conciliation Centre
                (MAMC), at the request of the authorized signatory of either
                Drive X Mobility Pvt Ltd or yourself, as the case may be, and
                such arbitration shall be conducted in accordance with the
                provisions of the Arbitration and Conciliation Act, 1996, or its
                statutory amendments, and in accordance with the MAMC&apos;s
                Rules of Arbitration and Conciliation or its amendments, in
                English language, and the seat of arbitration shall be at
                Chennai. The Award passed by the Arbitrator shall be final and
                binding on both the Parties.
              </p>
              <p>
                For any interim relief, the courts in Bangalore shall have
                exclusive jurisdiction.
              </p>
            </div>
          </section>
          <section className="my-10">
            <h5 className="text-lg font-semibold">How Do You Contact Us?</h5>
            <div className="mt-4 space-y-6">
              <p>
                For any further queries and complaints related to privacy or
                exercising your rights, you could reach our Data Privacy Office
                at the following address:{" "}
                <span className="font-medium underline">privacy@drivex.in</span>
              </p>
              <ul className="flex flex-col">
                <li>
                  <span className="font-medium underline">
                    Business Address:
                  </span>{" "}
                  No. 32/5, &apos;The Work Address&apos; Teresa Building, 1st
                  Floor, Rupena Agrahara, Hosur Road, Bangalore 560 068.
                </li>
              </ul>
            </div>
          </section>
        </div>

        <div className="my-10 lg:w-[30%]">
          <HelpCard className="lg:sticky lg:top-[100px]" />
        </div>
      </div>
      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </section>
  );
};

export default PrivacyPolicy;
