export function Shadow() {
  return (
    <svg
      width={858}
      height={530}
      viewBox="0 0 858 530"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="url(#hero_gradient)" d="M0 0h858v530H0z" />
      <defs>
        <linearGradient
          id="hero_gradient"
          x1={15.5}
          y1={39.5}
          x2={685.5}
          y2={317}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity={0.87} />
          <stop offset={0.262} stopOpacity={0.73} />
          <stop offset={0.535} stopOpacity={0.503} />
          <stop offset={0.894} stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  );
}
