async function fetchData(
  url: string,
  method: string,
  payload?: object,
  useHeaders: boolean = true,
) {
  const headers: Record<string, string> = useHeaders
    ? {
        "Content-Type": "application/json",
      }
    : {};
  const options: RequestInit = {
    method,
    headers,
  };
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  const response = await fetch(url, options);
  const data = await response.json();
  return data;
}

export async function sendToMetaConversionApi(payload: object) {
  return fetchData(
    `${import.meta.env.VITE_META_PIXEL_BASE_URL}/${import.meta.env.VITE_META_PIXEL_ID}/events?access_token=${import.meta.env.VITE_META_ACCESS_TOKEN}`,
    "POST",
    payload,
  );
}
