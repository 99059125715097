import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ChevronLeft as LeftIcon, LoaderIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { useDispatch, useSelector } from "react-redux";
import DateOrTime from "@/components/DateOrTime";
import { sellVehicle } from "@/store/services/scheduleApi";
import InspectionDetailMiniCard from "../../components/InspectionDetailMiniCard/InspectionDetailMiniCard";
import { IInspectionDetail } from "./data";
import { convertDateToSlashFormat, getNextNDays } from "@/utils/date";
import { TIME_SLOTS } from "@/utils/constants";
import { setEstimatedPrice } from "@/store/features/estimatedPriceSlice";
import { useToast } from "@/components/ui/use-toast";
import { track } from "@/utils/mixpanel/actions";
import { SELL_REQ_INSPECTION_REQUESTED } from "@/utils/mixpanel/Events/sell_flow_events";

const ScheduleInspection = () => {
  const [selectedSchedule, setSelectedSchedule] = useState({
    day: "",
    date: "",
    monthNumber: "",
    dayNumber: "",
    year: "",
    month: "",
    time: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const user = useSelector((state: any) => state.user.currentUser);

  const estimatedPrice =
    useSelector(
      (state: any) => state.estimatedPrice.estimatedPrice as IInspectionDetail,
    ) ?? JSON.parse(localStorage.getItem("estimatedPrice") || "");

  const {
    brand,
    max_km_range,
    min_km_range,
    no_of_owners,
    variant,
    manufacturing_year,
    model,
    pincode,
    state,
    registration_number,
    min_price,
    max_price,
    vehicle_id,
  } = estimatedPrice;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const nextSixDays = getNextNDays(1, 7);
  const source = location?.state?.source;
  const utm_parameters =
    sessionStorage.getItem("utm_paramters") &&
    JSON.parse(sessionStorage.getItem("utm_paramters") || "");

  const isButtonDisabled = Object.values(selectedSchedule).some(
    (value) => value === "",
  );

  const handleScheduleinspection = async () => {
    try {
      setIsLoading(true);
      const payload = {
        model,
        brand,
        manufacturing_year,
        variant,
        min_km_range,
        max_km_range,
        no_of_owners,
        pincode,
        state,
        date: `${selectedSchedule.year}-${selectedSchedule.monthNumber}-${selectedSchedule.dayNumber}`,
        user_id: user?.id,
        user_name: user?.userName,
        phone_number: user?.phoneNumber,
        vehicle_id,
        ...utm_parameters,
        time: selectedSchedule.time.toLocaleUpperCase(),
        ...(registration_number && { registration_number }),
        ...(min_price && { min_price }),
        ...(max_price && { max_price }),
      };
      const res = await sellVehicle(payload);
      setIsLoading(false);
      if (res.status === "OK") {
        navigate(
          `/inspectionscheduled?transactionId=${res.data.transaction_id}`,
          { replace: true },
        );
        track(SELL_REQ_INSPECTION_REQUESTED, {
          ...(source && { source: source }),
          request_date: convertDateToSlashFormat(res.data.date),
          request_slot: res.data.time,
          sell_request_id: res.data.transaction_id,
          vehicle_brand: res.data.brand,
          vehicle_model: res.data.model,
          pincode: res.data.pincode,
          km_driven: `${res.data.min_km_range} - ${res.data.max_km_range} KM`,
          no_of_owners: res.data.no_of_owners,
          name: user?.userName,
          phone_number: user?.phoneNumber,
          ct_date: new Date(
            `${selectedSchedule.monthNumber}/${selectedSchedule.dayNumber}/${selectedSchedule.year}`,
          ),
        });
        dispatch(setEstimatedPrice(null));
      }
    } catch (error: any) {
      setIsLoading(false);
      const errorMessage = JSON.parse(error.message);
      toast({
        title: errorMessage,
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={true}>
      <DialogContent className="bg-neutral30 flex flex-col h-full overflow-y-scroll gap-6 p-0">
        <div
          onClick={() => navigate("/sell-two-wheelers")}
          className="flex gap-4 border-b-[1px] p-4"
        >
          <LeftIcon />
          <p>Schedule a home inspection</p>
        </div>

        <div className="px-4">
          <InspectionDetailMiniCard
            productInfo={estimatedPrice}
            showEdit={false}
            showPriceEstimation={true}
          />
        </div>

        <div className="bg-white p-4 m-4 mt-0 flex flex-col justify-between grow rounded-sm">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm">Select Date</p>

              <div className="flex flex-wrap gap-3">
                {nextSixDays.map((date, index) => (
                  <DateOrTime
                    key={index}
                    isSelected={selectedSchedule.date === date.date}
                    heading={date.date}
                    subHeading={date.day}
                    updateData={() =>
                      setSelectedSchedule((prev: any) => ({ ...prev, ...date }))
                    }
                    data={date}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 w-full">
              <p className="text-sm">Select Time Slot</p>

              <div className="flex flex-wrap gap-3">
                {TIME_SLOTS.map((slot, index) => (
                  <DateOrTime
                    key={index}
                    isSelected={selectedSchedule.time === slot.timeOfDay}
                    heading={slot.timeOfDay}
                    subHeading={slot.time}
                    updateData={() =>
                      setSelectedSchedule((prev) => ({
                        ...prev,
                        time: slot.timeOfDay,
                      }))
                    }
                    data={slot}
                  />
                ))}
              </div>
            </div>
          </div>

          <Button
            variant="default"
            onClick={handleScheduleinspection}
            className={cn(
              "w-full bg-primaryA2 border-none text-white text-base py-6 hover:bg-primaryA2 hover:text-white flex gap-2 mt-6",
            )}
            disabled={isLoading || isButtonDisabled}
          >
            Schedule Inspection
            {isLoading && <LoaderIcon className="animate-spin" />}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScheduleInspection;
