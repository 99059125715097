import { useEffect, useLayoutEffect, useState } from "react";
import Hotjar from "@hotjar/browser";
import { useDispatch, useSelector } from "react-redux";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import {
  BOTTOM_NAVIGATION_HEIGHT,
  IS_CLIENT_SIDE,
} from "../../utils/constants.ts";
import { encodeUserId } from "../../utils/encoder.ts";
import flagsmith from "flagsmith";
import Session from "supertokens-web-js/recipe/session";
import { useLocation } from "react-router";
import { fetchUserDetailsAndUpdateState } from "../../store/services/authApi.tsx";
import { setCurrentUser } from "../../store/features/userSlice.ts";
import { supertokenInit } from "../../utils/supertoken/index.ts";
import {
  identify,
  mixpanelInit,
  register,
} from "../../utils/mixpanel/actions.ts";
import { setProductListSessionState } from "@/store/features/productListingSlice.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkAllValuesWithType } from "@/utils/object.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";

function InitializeConfigs() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { location: selectedLocation } = useSelector(
    (state: any) => state.location,
  );
  const { isMobile } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  const [, setIsUserLoggedIn] = useState(isUserLoggedIn); // This is required to trigger a re-render incase acess token gets expired or user removes it manually
  const { productListSessionState } = useSelector(
    (state: any) => state.productList,
  );
  const [searchParams] = useSearchParams();
  const { handleLocationSelection } = useCurrentCity();
  const navigate = useNavigate();

  // Adjusts root element's margin based on bottom navigation and device type.
  useEffect(() => {
    const targetElement = document.getElementById("bottom-navigation");
    const rootElement: HTMLElement | any = document.getElementById("root");
    targetElement && isMobile
      ? (rootElement.style.marginBottom = BOTTOM_NAVIGATION_HEIGHT)
      : (rootElement.style.marginBottom = "0px");
  }, [location.pathname, isMobile]);

  useLayoutEffect(() => {
    mixpanelInit();
  }, []);

  useEffect(() => {
    IS_CLIENT_SIDE &&
      import("clevertap-web-sdk").then((ct) =>
        ct?.default?.init(
          import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID,
          import.meta.env.VITE_CLEVERTAP_REGION,
        ),
      );
    Hotjar.init(
      import.meta.env.VITE_HOTJAR_ID,
      import.meta.env.VITE_HOTJAR_SNIPPET_VERSION,
    );
    supertokenInit();

    handleLocationSelection({
      pathname: location.pathname,
    });
  }, []);

  useEffect(() => {
    const updateUserIdAndAnalytics = async () => {
      const anonymouseUserId = localStorage.getItem("encodedUserId") as string;
      IS_CLIENT_SIDE &&
        import("clevertap-web-sdk").then((ct) =>
          ct?.default?.onUserLogin.push({
            Site: {
              Name: user?.userName,
              Email: user?.email,
              Phone: user?.phoneNumber,
            },
          }),
        );
      if (isUserLoggedIn) {
        const encodedUserId = await encodeUserId(user?.id);
        localStorage.setItem("encodedUserId", encodedUserId);
        identify({
          user_id: encodedUserId,
          email: user?.email,
          phone_number: user?.phoneNumber,
          name: user?.userName,
        });
        flagsmith.identify(encodedUserId, {
          user_id: encodedUserId,
          email: user?.email,
          name: user?.userName,
          phone_number: user?.phoneNumber,
          location: selectedLocation,
        });
      } else {
        flagsmith.identify(anonymouseUserId, {
          user_id: anonymouseUserId,
          location: selectedLocation,
        });
        if (!anonymouseUserId?.startsWith("anon-")) {
          const anonRandomUUID = `anon-${window?.crypto?.randomUUID()}`;
          localStorage.setItem("encodedUserId", anonRandomUUID);
        }
      }
      // sending these extra properites to every event
      register({
        user_id: localStorage.getItem("encodedUserId") as string,
        is_user_logged_in: isUserLoggedIn ? true : false,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
    };

    updateUserIdAndAnalytics();
  }, [user, selectedLocation, isUserLoggedIn]);

  //TODO: remove this once auth.drivex.dev is shifted to api.drivex
  useEffect(() => {
    fetchUserDetailsAndUpdateState(dispatch, setCurrentUser);

    // If there are 2 or more consecutive forward slashes in the url, the below line will remove all & keep one slash
    if (location.pathname.match(/\/{2,}/g)) {
      navigate(location.pathname.replace(/\/{2,}/g, "/"), { replace: true });
    }

    if (productListSessionState) {
      if (!location.pathname.includes("/buy-two-wheelers")) {
        dispatch(setProductListSessionState(null));
      }
    }

    const does_utm_parameters_exist =
      sessionStorage.getItem("utm_paramters") &&
      JSON.parse(sessionStorage.getItem("utm_paramters") || "");

    function extractAndStoreUTMParameters() {
      // Extracting UTM parameters from the query string
      const utm_medium = searchParams.get("utm_medium");
      const utm_source = searchParams.get("utm_source");
      const utm_campaign = searchParams.get("utm_campaign");
      const utm_term = searchParams.get("utm_term");
      const utm_content = searchParams.get("utm_content");

      const utm_parameters = {
        utm_medium: utm_medium ?? undefined,
        utm_source: utm_source ?? undefined,
        utm_campaign: utm_campaign ?? undefined,
        utm_term: utm_term ?? undefined,
        utm_content: utm_content ?? undefined,
      };

      if (checkAllValuesWithType(utm_parameters, undefined)) return;

      sessionStorage.setItem("utm_paramters", JSON.stringify(utm_parameters));
    }

    async function doesSessionExist() {
      const session = await Session.doesSessionExist();

      if (!session) {
        localStorage.removeItem("isUserLoggedIn");
        localStorage.removeItem("role");
        setIsUserLoggedIn(null);
      } else if (session && !isUserLoggedIn) {
        const value = JSON.stringify(true);
        localStorage.setItem("isUserLoggedIn", value);
        setIsUserLoggedIn(value);
      }
    }

    if (!does_utm_parameters_exist) {
      extractAndStoreUTMParameters();
    }
    doesSessionExist();
  }, [location.pathname]);

  return null;
}

export default InitializeConfigs;
