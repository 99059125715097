import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Common function to get headers
const getAuthHeaders = () => ({
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_API_TOKEN}`,
});

const apiBaseUrl = import.meta.env.VITE_STRAPI_API_BASE_URL;

export const footerContainerApi = createApi({
  reducerPath: "footerContainerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseUrl,
  }),
  endpoints: (builder) => ({
    getFooterContentByCity: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/footer-containers`,
          params: {
            ...params,
          },
          headers: getAuthHeaders(),
        };
      },
    }),
    getAllFaqs: builder.query({
      query: ({ params }) => {
        return {
          url: `/cms/faqs`,
          params: {
            ...params,
          },
          headers: getAuthHeaders(),
        };
      },
    }),
  }),
});

export const { useGetFooterContentByCityQuery, useGetAllFaqsQuery } =
  footerContainerApi;
