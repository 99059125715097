import { Button } from "@/components/ui/button";
import ScooterImage from "../../assets/svg/UserProfile/ScooterImage.svg";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import Image from "@/components/ui/image";

interface IProps {
  navigate: (param: string) => void;
}

const EmptyWishlist = ({ navigate }: IProps) => {
  const { city } = useCurrentCity();
  return (
    <div className="flex flex-col items-center gap-5 mt-[5%] py-4 text-center">
      <Image src={ScooterImage} alt="" />
      <p className="font-semibold text-lg">Your wishlist is empty</p>
      <p className="text-neutral300 font-medium text-base">
        Add two-wheelers to your wishlist <br /> & book a test drive
      </p>
      <Button
        variant="outline"
        className="flex gap-3 bg-primaryA2 border-none text-white px-6 py-5"
        onClick={() => navigate(`/${city}/buy-two-wheelers`)}
      >
        Explore all
      </Button>
    </div>
  );
};

export default EmptyWishlist;
