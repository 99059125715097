import { ComponentType, ReactElement, useEffect, useState } from "react";
import SelectBrand from "./ManualFormSteps/SelectBrand";
import SelectKMDriven from "./ManualFormSteps/SelectKMDriven";
import SelectModel from "./ManualFormSteps/SelectModel";
import SelectOwner from "./ManualFormSteps/SelectOwner";
import SelectVariant from "./ManualFormSteps/SelectVariant";
import SelectYear from "./ManualFormSteps/SelectYear";
import { SearchParams } from "./data";
import SelectState from "./ManualFormSteps/SelectState";
import { useLocation } from "react-router-dom";
import { getModelAndVariant } from "@/store/services/scheduleApi";
import { IInspectionDetail } from "../data";
import { useSelector } from "react-redux";

type PriceEstimateStepsProps = {
  priceEstimate: IPriceEstimate;
  setPriceEstimate: React.Dispatch<React.SetStateAction<IPriceEstimate>>;
  setSearchParams: (params: SearchParams) => void;
  currentStep: PriceEstimateSteps;
  type: PriceEstimateStepsType;
};

function usePriceEstimateSteps({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  currentStep,
  type,
}: PriceEstimateStepsProps) {
  const location = useLocation();
  const locationState = location.state as { brand?: any; pin_code: string };
  const brand = locationState?.brand;
  const pin_code = locationState?.pin_code;

  const [models, setModels] = useState([]);
  const [variants, setVariants] = useState([]);

  const { estimatedPrice }: { estimatedPrice: IInspectionDetail } = useSelector(
    (state: any) => state.estimatedPrice,
  );

  const updatePriceEstimateStepsOnEdit = async () => {
    if (type === "automatic") {
      setPriceEstimate({
        steps: {
          km_driven: `${estimatedPrice.min_km_range},${estimatedPrice.max_km_range}`,
          no_of_owner: String(estimatedPrice.no_of_owners),
        },
        registration_number: estimatedPrice.registration_number,
        pin_code: String(estimatedPrice.pincode),
      });
    } else {
      const res = await getModelAndVariant(estimatedPrice.brand);
      if (res.status === "OK") {
        setModels(res.data);
        const model = res.data.find(
          (item: any) => item.model_name === estimatedPrice.model,
        );
        const variants = model.variants.filter(
          (variant: string) => variant !== "",
        );
        setVariants(variants);
        setPriceEstimate({
          steps: {
            brand: estimatedPrice.brand,
            state: estimatedPrice.state,
            year: estimatedPrice.manufacturing_year,
            model: estimatedPrice.model,
            variant: estimatedPrice.variant,
            km_driven: `${estimatedPrice.min_km_range},${estimatedPrice.max_km_range}`,
            no_of_owner: String(estimatedPrice.no_of_owners),
          },
          pin_code: String(estimatedPrice.pincode),
        });
      }
    }
  };

  useEffect(() => {
    if (!estimatedPrice) return;
    updatePriceEstimateStepsOnEdit();
  }, [estimatedPrice]);

  const retrieveBrandAndPinCode = async () => {
    if (brand) {
      const res = await getModelAndVariant(brand.name);
      if (res.status === "OK") {
        setModels(res.data);
        setPriceEstimate({
          steps: {
            ...priceEstimate.steps,
            brand: brand.name,
          },
          pin_code,
        });
      }
    }
  };

  useEffect(() => {
    retrieveBrandAndPinCode();
  }, [brand, pin_code]);

  const CommonPropsHOC = (
    Component: ComponentType<Omit<PriceEstimateStepsProps, "currentStep">>,
  ): ReactElement => (
    <Component
      priceEstimate={priceEstimate}
      setPriceEstimate={setPriceEstimate}
      setSearchParams={setSearchParams}
      type={type}
    />
  );

  const renderCurrentStep = () => {
    switch (currentStep) {
      case "brand":
        return (
          <SelectBrand
            priceEstimate={priceEstimate}
            setPriceEstimate={setPriceEstimate}
            setSearchParams={setSearchParams}
            type={type}
            setModels={setModels}
          />
        );
      case "state":
        return CommonPropsHOC(SelectState);
      case "year":
        return CommonPropsHOC(SelectYear);
      case "model":
        return (
          <SelectModel
            priceEstimate={priceEstimate}
            setPriceEstimate={setPriceEstimate}
            setSearchParams={setSearchParams}
            type={type}
            models={models}
            setVariants={setVariants}
          />
        );
      case "variant":
        return (
          <SelectVariant
            priceEstimate={priceEstimate}
            setPriceEstimate={setPriceEstimate}
            setSearchParams={setSearchParams}
            type={type}
            variants={variants}
          />
        );
      case "km_driven":
        return CommonPropsHOC(SelectKMDriven);
      case "no_of_owner":
        return CommonPropsHOC(SelectOwner);
      default:
        return (
          <SelectBrand
            priceEstimate={priceEstimate}
            setPriceEstimate={setPriceEstimate}
            setSearchParams={setSearchParams}
            type={type}
            setModels={setModels}
          />
        );
    }
  };

  return {
    renderCurrentStep,
  };
}

export default usePriceEstimateSteps;
