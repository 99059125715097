import { cn } from "@/lib/utils";

interface IProps {
  isTabSelected: string;
  setIsTabSelected: React.Dispatch<React.SetStateAction<string>>;
  setOrderedVehicleData: React.Dispatch<React.SetStateAction<string>>;
  buyData: any;
  sellData: any;
  serviceData: any;
  isFetchingData: boolean;
}

const BuySellTab = ({
  isTabSelected,
  setIsTabSelected,
  setOrderedVehicleData,
  buyData,
  sellData,
  serviceData,
  isFetchingData,
}: IProps) => {
  return (
    <>
      <div
        className={`w-[32%] py-3 rounded-md cursor-pointer ${isTabSelected === "buy" && "bg-primaryA2 text-white"}`}
        onClick={() => {
          setIsTabSelected("buy");
          setOrderedVehicleData(buyData?.data?.orders);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <p className="font-semibold">Buy</p>
          {buyData?.data?.totalCounts > 0 && (
            <p
              className={cn(
                "h-5 w-5 flex items-center justify-center bg-white text-black text-xs rounded-full",
                isTabSelected !== "buy" && "bg-primaryA2 text-white",
              )}
            >
              {!isFetchingData && buyData?.data?.totalCounts}
            </p>
          )}
        </div>
      </div>

      <div
        className={`w-[32%] py-3 rounded-md cursor-pointer ${isTabSelected === "sell" && "bg-primaryA2 text-white"}`}
        onClick={() => {
          setIsTabSelected("sell");
          setOrderedVehicleData(sellData?.data?.orders);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <p className="font-semibold">Sell</p>
          {sellData?.data?.totalCounts > 0 && (
            <p
              className={cn(
                "h-5 w-5 flex items-center justify-center bg-white text-black text-xs rounded-full",
                isTabSelected !== "sell" && "bg-primaryA2 text-white",
              )}
            >
              {!isFetchingData && sellData?.data?.totalCounts}
            </p>
          )}
        </div>
      </div>

      <div
        className={`w-[32%] py-3 rounded-md cursor-pointer ${isTabSelected === "services" && "bg-primaryA2 text-white"}`}
        onClick={() => {
          setIsTabSelected("services");
          setOrderedVehicleData(serviceData?.data?.orders);
        }}
      >
        <div className="flex items-center justify-center gap-1">
          <p className="font-semibold">Services</p>
          {serviceData?.data?.totalCounts > 0 && (
            <p
              className={cn(
                "h-5 w-5 flex items-center justify-center bg-white text-black text-xs rounded-full",
                isTabSelected !== "services" && "bg-primaryA2 text-white",
              )}
            >
              {!isFetchingData && serviceData?.data?.totalCounts}
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default BuySellTab;
