import { useScreenDetector } from "@/hooks/useScreenDetector";
import { ContactusDetailsData } from "./data";
import { cn } from "@/lib/utils";
import { ContactUsSupportEmail, supportContactNumber } from "@/utils/constants";

const ContactUsDetails = () => {
  const { isMobile } = useScreenDetector();

  const handleClick = (data: string) => {
    const URL =
      data === supportContactNumber
        ? `tel:${supportContactNumber}`
        : `mailto:${ContactUsSupportEmail}`;
    window.open(URL, "_blank");
  };
  return (
    <>
      <p className="font-semibold text-xl">Contact us</p>

      <div
        className={cn(
          "flex flex-col gap-4 p-4 bg-white rounded-md",
          !isMobile && "flex-row px-8 gap-44",
        )}
      >
        {ContactusDetailsData.map((data, index) => (
          <div key={index} className="flex gap-4 items-center">
            {data.icon}
            <div className="flex flex-col gap-1">
              <p className="text-neutral300 text-sm font-semibold">
                {data.heading}:
              </p>
              <p
                onClick={() => handleClick(data.data)}
                className="text-sm font-semibold underline cursor-pointer"
              >
                {data.data}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactUsDetails;
