import { cn } from "@/lib/utils";
import { useCallback } from "react";
import { SelectedCurrentStepComponentProps } from "../data";

type ExtraProps = {
  models: any[];
  setVariants: any;
};

function SelectModel({
  priceEstimate,
  setPriceEstimate,
  setSearchParams,
  type,
  models,
  setVariants,
}: SelectedCurrentStepComponentProps & ExtraProps) {
  const modelList = models.map((data) => data.model_name);

  // Function to get variants of a model by its name
  function updateVariantsByModelName(modelName: string) {
    const model = models.find((item) => item.model_name === modelName);
    const allVariants = model.variants.filter(
      (variant: string) => variant !== "",
    );
    const variants = allVariants.length > 0 ? allVariants : [model.model_name];
    setVariants(variants);
  }

  const handleModelClick = useCallback(
    (model: string) => {
      setSearchParams({ step: "variant", type });
      setPriceEstimate({
        steps: {
          ...priceEstimate.steps,
          model,
        },
        pin_code: priceEstimate.pin_code,
      });
      updateVariantsByModelName(model);
    },
    [type, priceEstimate],
  );

  return (
    <div className="mt-4 flex flex-col gap-4">
      {modelList.map((model) => {
        return (
          <div
            key={model}
            className={cn(
              "border px-4 py-2.5 rounded-md text-center",
              priceEstimate.steps.model === model
                ? "border-primaryA1 text-primaryA1 bg-neutral30"
                : "bg-white",
            )}
            onClick={() => handleModelClick(String(model))}
          >
            {model}
          </div>
        );
      })}
    </div>
  );
}

export default SelectModel;
