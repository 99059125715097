import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useGetStoresQuery } from "@/store/services/Dmx/storeVehicles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStore } from "@/store/features/storeSlice.ts";
import { DropdownOpenDelay } from "@/utils/constants";

const StoreDetail = () => {
  const dispatch = useDispatch();
  const selectedDmxStore = useSelector((state: any) => state.store.store);
  const { data: stores } = useGetStoresQuery({});
  const storesData = stores?.data?.stores;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  useEffect(() => {
    if (storesData && storesData.length > 0) {
      const storedStore = JSON.parse(
        localStorage.getItem("current_store_dmx") || "null",
      );
      if (!storedStore) {
        // If no store data is present in localStorage, select the first store
        handleStoreChange(storesData[0].store_id);
      }
    }
  }, [storesData]);

  const handleStoreChange = (value: string) => {
    const selectedStore = storesData?.find(
      (store: { store_id: number }) => store.store_id === Number(value),
    );

    dispatch(setStore(selectedStore));
  };
  return (
    <div className="flex flex-col gap-3 sm:w-[50%] lg:w-[40%]">
      <div className="flex justify-between items-center">
        <p className="text-neutral300 text-sm font-medium">Store Name</p>
        <Select
          open={isDropdownOpen}
          onOpenChange={(val) =>
            setTimeout(() => setIsDropdownOpen(val), DropdownOpenDelay)
          }
          onValueChange={(value) =>
            handleStoreChange && handleStoreChange(value)
          }
          value={
            selectedDmxStore?.store_id ??
            (storesData?.[0]?.store_id as unknown as string)
          }
        >
          <SelectTrigger className="flex gap-3 border border-neutral30 bg-white relative max-w-[180px] text-primaryA1">
            <SelectValue placeholder="Select a store" />
          </SelectTrigger>
          <SelectContent>
            {storesData?.map((store: any) => (
              <SelectItem
                key={store.store_id}
                className="focus:bg-neutral30"
                value={store.store_id}
              >
                {store.store_name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div className="flex justify-between items-center">
        <p className="text-neutral300 text-sm font-medium">Store Number</p>
        <p className="text-neutral900 text-sm font-semibold">
          {selectedDmxStore?.spoc_number}
        </p>
      </div>
    </div>
  );
};

export default StoreDetail;
