import * as Sentry from "@sentry/browser";

//TODO: move this to rtk query once auth.drivex.dev is shifted to api.drivex
export async function getCurrentUser() {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_SUPERTOKEN_API_DOMAIN}${import.meta.env.VITE_SUPERTOKEN_API_BASE_PATH}/me`,
      {
        method: "GET",
        credentials: "include",
      },
    );
    const data = await response.json();
    return data.user;
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function updateCurrentUser(formData: {
  email: string;
  full_name: string;
}) {
  try {
    await fetch(
      `${import.meta.env.VITE_SUPERTOKEN_API_DOMAIN}${import.meta.env.VITE_SUPERTOKEN_API_BASE_PATH}/me`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email || undefined,
          name: formData.full_name,
        }),
        credentials: "include",
      },
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}

export async function fetchUserDetailsAndUpdateState(
  dispatch: any,
  setCurrentUser: (arg1: object | null) => void,
) {
  try {
    const data = await getCurrentUser();
    if (data) {
      dispatch(
        setCurrentUser({
          ...data,
        }),
      );
    } else {
      dispatch(setCurrentUser(null));
    }
  } catch (error) {
    dispatch(setCurrentUser(null));
    Sentry.captureException(error);
  }
}

export async function logout() {
  try {
    await fetch(
      `${import.meta.env.VITE_SUPERTOKEN_API_DOMAIN}${import.meta.env.VITE_SUPERTOKEN_API_BASE_PATH}/logout`,
      {
        method: "POST",
        credentials: "include",
      },
    );
  } catch (error) {
    Sentry.captureException(error);
  }
}
