import { useEffect } from "react";
import {
  Outlet,
  Navigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

const PrivateRoutes = () => {
  const location = useLocation();
  const isUserLoggedIn = localStorage.getItem("isUserLoggedIn");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (isUserLoggedIn && searchParams.get("login")) {
      searchParams.delete("login");
      setSearchParams(searchParams);
    }
  }, [isUserLoggedIn]);

  return isUserLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={`${location.pathname}?login=true`} />
  );
};

export default PrivateRoutes;
