import React, { useEffect } from "react";
import VehicleListLayout from "../VehicleListLayout";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { LOAD_MORE_DELAY } from "@/utils/constants";
import { LoaderIcon } from "lucide-react";

interface FlatListProps<T> {
  data: T[];
  renderItem: ({
    item,
    index,
    containerRef,
  }: {
    item: T;
    index: number;
    containerRef?: any;
  }) => JSX.Element;
  keyExtractor: (item: T) => string;
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  isListEmpty: boolean;
  loadingMore: boolean;
  hasMore: boolean;
  isFetching: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setLoadingMore: React.Dispatch<React.SetStateAction<boolean>>;
  ListEmptyComponent?: React.ComponentType | JSX.Element; // Component or element to render if data is empty
  isLoading?: boolean; // Prop to indicate loading state
  ListLoadingComponent?: React.ComponentType | JSX.Element; // Component or element to render during loading
  noMoreDataMessage?: string;
}

function FlatList<T>({
  data,
  renderItem,
  ListEmptyComponent,
  isListEmpty,
  isLoading = false,
  ListLoadingComponent,
  loadingMore,
  setLoadingMore,
  hasMore,
  isFetching,
  setPage,
  keyExtractor,
  noMoreDataMessage = "We’re refueling, check back soon for new rides!",
}: FlatListProps<T>) {
  const [containerRef, isVisible] = useInfiniteScroll({
    root: null,
    rootMargin: "0px",
    threshold: 0,
  });

  useEffect(() => {
    if (isFetching) return;

    if (isVisible && hasMore && !loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        setPage((prevPageNumber: number) => prevPageNumber + 1);
        setLoadingMore(false);
      }, LOAD_MORE_DELAY);
    }
  }, [isVisible]);

  if (isLoading && ListLoadingComponent) {
    return <>{ListLoadingComponent}</>;
  }

  if (isListEmpty && ListEmptyComponent) {
    return <>{ListEmptyComponent}</>;
  }

  return (
    <>
      <VehicleListLayout>
        {data?.map((item, index) => {
          if (index === data.length - 1) {
            return (
              <React.Fragment key={keyExtractor(item)}>
                {renderItem({ item, index, containerRef })}
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={keyExtractor(item)}>
                {renderItem({ item, index })}
              </React.Fragment>
            );
          }
        })}
      </VehicleListLayout>

      <div className="h-20 w-full flex items-center justify-center">
        {loadingMore && hasMore && (
          <LoaderIcon className="animate-spin" color="#F47823" />
        )}
        {!hasMore && (
          <div className="text-primaryA2 text-sm">{noMoreDataMessage}</div>
        )}
      </div>
    </>
  );
}

export default FlatList;
