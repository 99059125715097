import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";

type objectOfStrings = {
  [key: string]: string;
};
interface IProps {
  selectedData: any;
  handleFilterSelection: any;
  selectedKey: string;
  selectedFilters: objectOfStrings;
}

const MultiFilterAccordion = ({
  selectedData,
  handleFilterSelection,
  selectedKey,
  selectedFilters,
}: IProps) => {
  // This checks if all the models of a particular brand are selected or not in make model filter category
  const isAllSelected = (arr1: string[], arr2: string[]) =>
    arr2.every((value) => arr1.includes(value));

  return (
    <Accordion type="multiple">
      <div className="flex flex-col gap-0 text-left">
        {Object.keys(selectedData).map((brand, index) => (
          <div key={index} className="flex flex-col gap-4 px-3 rounded-md">
            <AccordionItem value={`${index}`}>
              <AccordionTrigger className="pt-1">
                <p className="text-left text-sm text-neutral300 font-semibold flex-center gap-3">
                  <Checkbox
                    onClick={() =>
                      handleFilterSelection(
                        selectedKey,
                        selectedData[brand]
                          .map((item: { name: string }) => item.name)
                          .join(","),
                        "multi",
                        selectedData[brand].reduce(
                          (acc: number, currVal: { count: number }) =>
                            acc + currVal.count,
                          0,
                        ),
                        selectedData[brand].length > 0 &&
                          isAllSelected(
                            selectedFilters[
                              selectedKey as keyof typeof selectedFilters
                            ].split(","),
                            selectedData[brand].map(
                              (item: { name: string }) => item.name,
                            ),
                          ),
                      )
                    }
                    checked={
                      selectedData[brand].length > 0 &&
                      isAllSelected(
                        selectedFilters[
                          selectedKey as keyof typeof selectedFilters
                        ].split(","),
                        selectedData[brand].map(
                          (item: { name: string }) => item.name,
                        ),
                      )
                    }
                    className="border-neutral300 w-[20px] h-[20px] data-[state=checked]:bg-primaryA2"
                  />{" "}
                  <span>{brand}</span>
                </p>
              </AccordionTrigger>
              <AccordionContent>
                <ul className="text-sm flex flex-col gap-4">
                  {selectedData[brand].map(
                    (item: { name: string; count: number }, idx: number) => (
                      <li
                        onClick={() =>
                          handleFilterSelection(
                            selectedKey,
                            item.name,
                            "multi",
                            item.count,
                          )
                        }
                        className="text-sm flex-between ml-8"
                        key={idx}
                      >
                        <p
                          className={cn(
                            "flex items-center gap-3 text-neutral300",
                            selectedFilters[
                              selectedKey as keyof typeof selectedFilters
                            ]
                              .split(",")
                              .includes(item.name) && "lg:text-black",
                          )}
                        >
                          <Checkbox
                            checked={
                              selectedFilters[
                                selectedKey as keyof typeof selectedFilters
                              ]
                                .split(",")
                                .includes(item.name)
                                ? true
                                : false
                            }
                            className="border-neutral300 w-[20px] h-[20px] data-[state=checked]:bg-primaryA2"
                          />{" "}
                          {item.name}
                        </p>
                        <p className="text-neutral200">{item.count}</p>
                      </li>
                    ),
                  )}
                </ul>
              </AccordionContent>
            </AccordionItem>
          </div>
        ))}
      </div>
    </Accordion>
  );
};

export default MultiFilterAccordion;
