import { AnimatedDownArrow } from "@/assets/Icons";
import React from "react";

interface AccordionProps {
  heading: string;
  isOpen: boolean;
  onToggle: () => void;
  children: React.ReactNode;
  footerContainer?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  heading,
  isOpen,
  onToggle,
  children,
  footerContainer = false,
}) => {
  return (
    <div
      className={` ${
        footerContainer
          ? "bg-transparent"
          : "bg-white border-neutral-100 flex flex-col border-2 rounded-md mb-4 w-full"
      }`}
    >
      <button
        onClick={onToggle}
        className={`${
          footerContainer
            ? "flex items-center px-4 py-4 font-medium transition-all"
            : "flex justify-between items-center w-full px-4 py-4 font-medium transition-all"
        }`}
        aria-expanded={isOpen}
        aria-controls="accordion-content"
      >
        {footerContainer ? (
          <h2 className="text-sm text-left font-semibold pr-2 text-neutral700">
            {heading}
          </h2>
        ) : (
          <h3 className="text-lg text-left font-semibold w-4/5">{heading}</h3>
        )}
        <AnimatedDownArrow
          isOpen={isOpen}
          size={footerContainer ? 28 : 32}
          duration={500}
          className="hover:opacity-80"
        />
      </button>
      <div
        id="accordion-content"
        className="overflow-hidden transition-all duration-300 w-full"
        style={{
          maxHeight: isOpen ? "none" : "0",
          opacity: isOpen ? 1 : 0,
        }}
        aria-hidden={!isOpen}
      >
        <div className={`px-4 pb-4 ${isOpen ? "mt-2" : ""}`}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
