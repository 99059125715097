import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DropdownOpenDelay } from "@/utils/constants";
import { useState } from "react";

interface IProps {
  locationData: any;
  handleSelectValueChange: (value: string) => void;
}

const SelectCity = ({ locationData, handleSelectValueChange }: IProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <>
      <Select
        open={isDropdownOpen}
        onOpenChange={(val) =>
          setTimeout(() => setIsDropdownOpen(val), DropdownOpenDelay)
        }
        onValueChange={(value) => handleSelectValueChange(value)}
      >
        <SelectTrigger className="max-w-[10rem] text-base data-[placeholder]:text-primaryA1 text-primaryA1 bg-white focus-visible:ring-0 focus-visible:ring-offset-0">
          <SelectValue placeholder="Select City" />
        </SelectTrigger>
        <SelectContent>
          {locationData?.map((option: { location: string }, index: number) => (
            <SelectItem
              key={index}
              className="focus:bg-neutral30 text-black"
              value={option.location}
            >
              {option.location}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default SelectCity;
