import React from "react";
import { SellingBenefits } from "../../pages/Home/data.tsx";
import { Button } from "../ui/button.tsx";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import { useNavigate } from "react-router-dom";
import { HOME_SELL_CLICKED_EVENT } from "@/utils/mixpanel/Events/home_clicked_events.ts";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import { track } from "@/utils/mixpanel/actions.ts";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants.ts";
import Image from "../ui/image.tsx";
import { useSelector } from "react-redux";

const SellBike = `${IMAGE_KIT_BASE_URL}/ik_homepage/SuzukiWeb.png`;

const Index = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const { isDesktop } = useScreenDetector();
  const navigate = useNavigate();
  const pageSource = getPageSource("/");

  const handleSellNow = () => {
    navigate("/sell-two-wheelers");
    track(HOME_SELL_CLICKED_EVENT, {
      page: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };
  return (
    <section className="flex flex-col lg:flex-row justify-between max-w-7xl mx-auto w-full bg-[#F6F9F8] rounded-md overflow-hidden">
      <div className="flex flex-col justify-evenly lg:pl-3">
        <p className="text-2xl md:text-4xl font-semibold p-4">
          Selling Your Two-Wheeler? <br />{" "}
          <span className="text-primaryA2">We Make It An Easy Ride!</span>
        </p>

        <div className="grid grid-cols-2 gap-2 p-4">
          {SellingBenefits.map((benefit, index) => (
            <div
              key={index}
              className="flex flex-col gap-2 w-full bg-white p-4 rounded-md"
            >
              {benefit.icon}
              <div className="flex flex-col gap-1">
                <p className="text-neutral900 text-base font-semibold">
                  {benefit.heading}
                </p>
                <p className="text-neutral300 font-medium text-sm">
                  {benefit.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <Button
          data-test-id="home-sell-now-cta"
          variant="outline"
          className="bg-primaryA2 border-none text-white py-5 font-bold md:max-w-52 md:mx-auto md:px-10 m-4"
          onClick={handleSellNow}
        >
          Sell Now
        </Button>
      </div>
      {isDesktop && (
        <Image
          transform="f-webp,w-512,h-507"
          className="object-cover hidden lg:block max-w-[40%] object-left"
          src={SellBike as string}
          alt="SellBike"
        />
      )}
    </section>
  );
};

export default Index;
