import { DialogClose, DialogContent } from "@/components/ui/dialog";
import { ChevronLeft as LeftIcon } from "lucide-react";
import { useEffect, useState } from "react";
import PopularCities from "./PopularCities";
import MoreCities from "./MoreCities";
import { setLocation, setCitiesData } from "@/store/features/locationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { PRODUCTLIST_PATH_REGEX } from "@/utils/validation";
import { useLazyGetCitiesQuery } from "@/store/services/cities";
import { INITIAL_CITIES_COUNT } from "@/utils/constants";

interface IProps {
  setOpenLocation: (value: boolean) => void;
}

const Location = ({ setOpenLocation }: IProps) => {
  const { location, citiesData: locationData } = useSelector(
    (state: any) => state.location,
  );

  const { isDesktop } = useScreenDetector();
  const [getCities] = useLazyGetCitiesQuery({});
  const [remainingLocations, setRemainingLocations] = useState(
    locationData?.slice(INITIAL_CITIES_COUNT, locationData?.length),
  );

  const dispatch = useDispatch();
  const pathLocation = useLocation();
  const navigate = useNavigate();

  const handleGetCities = async () => {
    const res = await getCities({});
    dispatch(setCitiesData(res?.data?.data));
    setRemainingLocations(
      res?.data?.data?.slice(INITIAL_CITIES_COUNT, res?.data?.data?.length),
    );
  };

  useEffect(() => {
    if (locationData?.length === 0) {
      handleGetCities();
    }
  }, [locationData, isDesktop]);

  const handleDialogueClick = () => {
    if (!location && pathLocation.pathname === "/city/buy-two-wheelers") {
      navigate("/");
    } else {
      setOpenLocation(false);
    }
  };

  // This effect navigates to the product list page of the selected location if the current path matches the product list path regex
  useEffect(() => {
    if (location && PRODUCTLIST_PATH_REGEX.test(pathLocation.pathname)) {
      navigate(`/${location}/buy-two-wheelers${pathLocation.search}`, {
        replace: true,
      });
    }
  }, [location]);

  return (
    <DialogContent
      data-test-id="city-select-dialog"
      className="sm:max-w-[425px] flex flex-col sm:h-[calc(100%-10rem)] h-full gap-7 lg:max-w-4xl lg:h-fit"
    >
      <div
        data-test-id="location-modal-back-button"
        className="flex gap-2"
        onClick={handleDialogueClick}
      >
        <DialogClose>
          <LeftIcon />
        </DialogClose>
        <p>Select Your City</p>
      </div>

      <PopularCities
        locationData={locationData}
        dispatch={dispatch}
        setLocation={setLocation}
        setOpenLocation={setOpenLocation}
      />

      <MoreCities
        remainingLocations={remainingLocations}
        dispatch={dispatch}
        setLocation={setLocation}
        setOpenLocation={setOpenLocation}
      />
    </DialogContent>
  );
};

export default Location;
