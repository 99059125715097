// This hook provides functionality to manage and validate the current city based on the URL path and updates the Redux store accordingly.

import { setCitiesData, setLocation } from "@/store/features/locationSlice";
import { useLazyGetCitiesQuery } from "@/store/services/cities";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useCurrentCity = () => {
  const { location, citiesData: locationData } = useSelector(
    (state: any) => state.location,
  );
  const [getCities] = useLazyGetCitiesQuery({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Utility function to normalize paths or city names
  const normalizePath = (path: string) =>
    decodeURIComponent(path)
      .replaceAll(/\s+/g, " ") // Replace multiple spaces with a single space
      .replaceAll(/%20| /g, "-") // Replace spaces and %20 with -
      .trim()
      .replace(/\/$/, "")
      .toLowerCase();

  // Function to handle location selection based on the URL path
  const handleLocationSelection = async ({
    pathname,
    setOpenLocation,
  }: {
    pathname: string;
    setOpenLocation?: (value: boolean) => void;
  }) => {
    // Early return if location is already set and data is loaded
    if (location && locationData.length > 0) return;

    const cityFromPath = normalizePath(pathname.split("/")[1]);

    let isLocationValid = false;
    let matchedCityName = "";

    // Fetch cities data if not loaded and check validity
    const res = await getCities({});
    const matchedCity = res?.data?.data.find(
      (data: ICitiesData) => data.location.toLowerCase() === cityFromPath,
    );
    isLocationValid = !!matchedCity;
    if (matchedCity) {
      matchedCityName = matchedCity.location; // Use the exact city name from the backend
    }
    dispatch(setCitiesData(res?.data?.data));

    // Set location in Redux or trigger location selection modal
    if (matchedCityName && isLocationValid) {
      dispatch(setLocation(matchedCityName)); // Dispatch the exact city name
      if (normalizePath(pathname) === `/${normalizePath(matchedCityName)}`) {
        navigate(`/${matchedCityName}/buy-two-wheelers`, {
          replace: true,
        });
      }
    } else {
      setOpenLocation?.(true);
    }
  };

  return { city: location ?? "city", handleLocationSelection };
};
