import { Skeleton } from "@/components/ui/skeleton";

export const BoardOfDirectorsLoader = () => {
  return (
    <div className="flex flex-col gap-3 bg-white rounded-xl shadow-sm overflow-hidden">
      <Skeleton className="h-44 w-full shadow-sm bg-neutral-200" />
      <div className="flex flex-col gap-4 p-4">
        <Skeleton className="h-4 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-[10rem] shadow-sm bg-neutral-200" />
      </div>
    </div>
  );
};

export const DrivexForcesLoader = () => {
  return (
    <div className="flex gap-2 w-[20rem] sm:w-[100%] bg-white rounded-xl shadow-sm overflow-hidden">
      <Skeleton className="h-30 w-[10rem] shadow-sm bg-neutral-200" />
      <div className="flex flex-col gap-2 p-2">
        <Skeleton className="h-3 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-3 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-3 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-3 w-[10rem] shadow-sm bg-neutral-200" />
        <Skeleton className="h-3 w-[10rem] shadow-sm bg-neutral-200" />
      </div>
    </div>
  );
};
