import { useNavigate } from "react-router-dom";
import { ClockCounterClockwise, TrendUpIcon } from "@/assets/Icons";
import { track } from "@/utils/mixpanel/actions";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import {
  POPULAR_SEARCH_CLICKED,
  RECENT_SEARCH_CLICKED,
} from "@/utils/mixpanel/Events/search_clicked_events";
import { useSelector } from "react-redux";
import { useCurrentCity } from "@/hooks/useCurrentCity";

interface Iprops {
  data: { vehicleName: string; modelName: string }[] | string[] | any;
  type: string;
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopularOrRecentSearches = ({
  data,
  type = "popular",
  setOpenSearch,
}: Iprops) => {
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const { location } = useSelector((state: any) => state.location);
  const pageSource = getPageSource("/search");
  const POPULAR_OR_RECENT_SEARCH_EVENT =
    type === "popular" ? POPULAR_SEARCH_CLICKED : RECENT_SEARCH_CLICKED;

  const handlePopularOrRecentSearchesClick = (
    searchTerm: object | string | any,
  ) => {
    track(POPULAR_OR_RECENT_SEARCH_EVENT, {
      url: pageSource,
      search_term: type === "recent" ? searchTerm?.vehicleName : searchTerm,
      city_name: location,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setOpenSearch(false);
    navigate(
      `/${city}/buy-two-wheelers?f=makeModel:${encodeURIComponent(type === "recent" ? searchTerm?.modelName : searchTerm)}`,
      {
        replace: true,
      },
    );
  };

  return (
    <div className="flex flex-col gap-3">
      <p>{type === "recent" ? "Recent" : "Popular"} Searches</p>

      <div
        data-test-id="popular-search-component"
        className="flex flex-wrap gap-3 justify-start"
      >
        {data &&
          data.map((vehicle: object | string | any, index: number) => (
            <div
              onClick={() => handlePopularOrRecentSearchesClick(vehicle)}
              key={index}
              className="flex gap-2 p-3 bg-neutral30 rounded-md justify-center text-sm cursor-pointer"
            >
              <p className="text-neutral300">
                {type === "recent" ? vehicle?.vehicleName : vehicle}
              </p>
              {type === "recent" ? <ClockCounterClockwise /> : <TrendUpIcon />}
            </div>
          ))}
      </div>
    </div>
  );
};

export default PopularOrRecentSearches;
