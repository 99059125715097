import { Link } from "react-router-dom";
import { IInspectionDetail } from "../../pages/Sell/data";
import { noOfOwner } from "../../pages/Sell/ManualSellFlow/data";
import Image from "../ui/image";

const InspectionDetailMiniCard = ({
  productInfo,
  showEdit = true,
  showPriceEstimation = false,
  profilePage = false,
  containerRef,
  handleOrderedVehicleCardClick,
}: {
  productInfo: IInspectionDetail;
  showEdit?: boolean;
  showPriceEstimation?: boolean;
  profilePage?: boolean;
  containerRef?: React.FC<any>;
  handleOrderedVehicleCardClick?: (transaction_id: number | undefined) => void;
}) => {
  const {
    max_km_range,
    no_of_owners,
    model,
    min_price,
    max_price,
    registration_number,
    icon_url,
  } = productInfo;
  const isPriceAvialable = min_price && max_price;

  const getOwner = () => {
    return noOfOwner.filter((owner) => Number(owner.value) === no_of_owners)[0]
      ?.displayValue;
  };

  const vehicleDetails = `${max_km_range} KMs, ${getOwner()}${registration_number ? ", " + registration_number : ""}`;
  return (
    <div
      ref={containerRef}
      className="flex items-center justify-between"
      onClick={() =>
        profilePage &&
        handleOrderedVehicleCardClick &&
        handleOrderedVehicleCardClick(productInfo?.transaction_id)
      }
    >
      <div className="flex gap-4">
        <Image
          src={icon_url}
          alt="sell-icon"
          className="w-[46px] h-[46px] object-contain rounded-full bg-white"
        />
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-base">
            {profilePage ? productInfo?.vehicleName : model}
          </p>
          <p className="text-neutral300 font-semibold text-xs">
            {vehicleDetails}
          </p>
          {showPriceEstimation && (
            <p className="text-xs text-primaryA2">
              {isPriceAvialable &&
                `₹${parseInt(String(min_price))?.toLocaleString()} - ₹
        ${parseInt(String(max_price))?.toLocaleString()}`}
            </p>
          )}
        </div>
      </div>
      {showEdit && (
        <Link
          to={
            productInfo.registration_number
              ? "/sell-two-wheelers/price-estimate?step=km_driven&type=automatic"
              : "/sell-two-wheelers/price-estimate?step=brand&type=manual"
          }
        >
          <p className="text-primaryA2">Edit</p>
        </Link>
      )}
    </div>
  );
};

export default InspectionDetailMiniCard;
