import { X as CloseIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface IProps {
  isMobile?: boolean;
  isPincodeServiceable: string;
  isValidVehicleRegistrationNumber: string;
  handleCloseSheet: () => void;
}

const ErrorPopUpContent = ({
  isMobile = false,
  isPincodeServiceable,
  isValidVehicleRegistrationNumber,
  handleCloseSheet,
}: IProps) => {
  return (
    <>
      <div className={cn("mb-4 mt-2", !isMobile && "mb-0")}>
        <div className="flex justify-between items-center">
          <p className="text-lg">
            {isValidVehicleRegistrationNumber === "unserviceable"
              ? "Sorry! Vehicle not Eligible"
              : "Sorry! Pin code not serviceable"}
          </p>

          {isMobile && (
            <div onClick={handleCloseSheet}>
              <CloseIcon size={20} />
            </div>
          )}
        </div>
      </div>

      <p className="text-sm text-neutral300">
        {isPincodeServiceable === "unserviceable"
          ? `We're expanding rapidly to new cities, Stay tuned for updates`
          : "Your vehicle doesn't meet our criteria at this time. Please check back later for updates."}
      </p>

      <Button
        onClick={handleCloseSheet}
        variant="outline"
        className="py-6 bg-primaryA2 border-none md:w-[200px] text-white text-base font-semibold hover:bg-primaryA2"
      >
        Okay
      </Button>
    </>
  );
};

export default ErrorPopUpContent;
