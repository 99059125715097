import React from "react";

interface CardProps {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
}

function CardComponent({ onClick, children, className }: CardProps) {
  return (
    <div
      className={`bg-white rounded-xl shadow-md overflow-hidden cursor-pointer transition duration-300 ease-in-out hover:scale-[1.02] ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default CardComponent;
