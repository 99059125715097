import { BlocksContent, BlocksRenderer } from "@strapi/blocks-react-renderer";
import Image from "../ui/image";

const BlogContent = ({ content }: { content: BlocksContent }) => {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => (
          <p className="text-neutral900">{children}</p>
        ),
        code: ({ children }) => (
          <code className="text-neutral900">{children}</code>
        ),
        heading: ({ children, level }) => {
          const Heading = `h${level}` as const;
          const headingStyles = {
            1: "text-3xl",
            2: "text-2xl text-accent",
            3: "text-xl",
            4: "text-lg",
            5: "text-md",
            6: "text-sm",
          };
          const className = `text-neutral9000 ${headingStyles[level]}`;
          return <Heading className={className}>{children}</Heading>;
        },
        list: ({ children, format }) => {
          const List = format === "ordered" ? "ol" : "ul";
          return <List className="text-neutral9000">{children}</List>;
        },
        "list-item": ({ children }) => (
          <li className="text-neutral9000">{children}</li>
        ),
        quote: ({ children }) => (
          <blockquote className="text-neutral900 p-4 border-l-4 border-blue-500 bg-blue-100 italic">
            {children}
          </blockquote>
        ),
        image: ({ image, plainText }) => (
          <Image
            transform="f-webp"
            src={image.url}
            alt={plainText || ""}
            className="rounded-lg shadow-sm w-full object-cover"
          />
        ),
        link: ({ url, children }) => (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 transition-colors duration-300"
          >
            {children}
          </a>
        ),
      }}
      modifiers={{
        bold: ({ children }) => <strong>{children}</strong>,
        italic: ({ children }) => <span className="italic">{children}</span>,
        code: ({ children }) => (
          <code className="text-neutral900 bg-neutral100 p-1 rounded-md">
            {children}
          </code>
        ),
      }}
    />
  );
};

export default BlogContent;
