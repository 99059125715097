export function getPageSource(url: string) {
  switch (url) {
    case "/":
      return "HomePage";
    case "/buy-two-wheelers":
      return "ProductList";
    case "/hamburger":
      return "Hamburger";
    case "/sell-two-wheelers":
      return "Sell";
    case "/search":
      return "Search";
    case "/service":
      return "Service";
    default:
      return url;
  }
}
