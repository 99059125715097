import Image from "@/components/ui/image";
import { cn } from "@/lib/utils";
import { truncate } from "@/utils/truncate";
import { useSearchParams } from "react-router-dom";

const StoryAvatars = ({ avatar, setStartIndexOfCarousel, index }: any) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <>
      <li
        key={avatar.attributes.profile.id}
        className="flex flex-col items-center"
        onClick={() => {
          setStartIndexOfCarousel(index);
          setSearchParams(
            {
              story: avatar.attributes.profile.id,
              id: avatar.attributes.stories[0].uid,
            },
            {
              preventScrollReset: true,
            },
          );
        }}
      >
        <div className="w-[65px] h-[65px] p-[2.5px] bg-primaryA2 rounded-full">
          <div className="rounded-full block transform transition hover:rotate-6">
            <Image
              transform="w-100,h-100"
              src={avatar.attributes.profile.avatar.data.attributes.url}
              className={cn(
                `w-full h-full p-[2.5px] bg-neutral900 md:bg-white rounded-full`,
              )}
              alt=""
            />
          </div>
        </div>

        <span
          className={cn(
            "text-sm  overflow-hidden text-white overflow-ellipsis truncate md:text-black md:pt-[5px] md:text-base",
          )}
        >
          {truncate(avatar.attributes.profile.name || "", 10)}
        </span>
      </li>
    </>
  );
};

export default StoryAvatars;
