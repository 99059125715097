export function getOwnerShipStatus(ownerShip: number) {
  switch (ownerShip) {
    case 1:
      return "1st Owner";
    case 2:
      return "2nd Owner";
    case 3:
      return "3rd Owner";
    default:
      return "Unknown";
  }
}
