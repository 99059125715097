import {
  ColouredCalendar,
  CircularTick,
  ArrowsCounterClockwiseIcon,
  ThreeUsersIcon,
  SealCheckIcon,
  CurrencyInrIcon,
  RupeeIcon,
  WrenchIcon,
  LightningIcon,
} from "@/assets/Icons";

export const sellingSteps = [
  {
    icon: (
      <LightningIcon
        size={22}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="service_paint_lightning"
      />
    ),
    step: (
      <>
        Express <br /> Service
      </>
    ),
  },
  {
    icon: (
      <WrenchIcon
        size={22}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="service_paint_wrench"
      />
    ),
    step: (
      <>
        Expert <br /> Technicians
      </>
    ),
  },
  {
    icon: <RupeeIcon size={22} />,
    step: (
      <>
        Economical <br /> Pricing
      </>
    ),
  },
];

// Interface for representing estimated price information of a vehicle
export interface IInspectionDetail {
  registration_number?: string;
  min_price?: number | null;
  max_price?: number | null;
  icon_url: string;
  model: string;
  variant: string;
  no_of_owners: number;
  location_info: string;
  min_km_range: number;
  max_km_range: number;
  brand: string;
  manufacturing_year: string;
  state: string;
  pincode: number;
  vehicleName?: string;
  transaction_id?: number;
  vehicle_id?: number;
}

export type SearchParams = {
  step: ServiceFlowSteps;
  type?: ServiceFlowStepsType;
};

export type SelectedCurrentStepComponentProps = {
  serviceFlowData: IServiceFlowFormData;
  setServiceFlowData: React.Dispatch<
    React.SetStateAction<IServiceFlowFormData>
  >;
  setSearchParams: (params: SearchParams) => void;
  type?: ServiceFlowStepsType;
};

export const initialManualState: IServiceFlowFormData = {
  steps: {
    brand: "",
    year: "",
    model: "",
    km_driven: "",
    service: {
      id: 0,
      name: "",
      price: "",
      service_hours: "",
      description: "",
      included_services: [],
      excluded_services: [],
      image_url: "",
    },
    schedule: {
      preferred_date: "",
      preferred_time: "",
    },
  },
  store_id: "",
  store_address: "",
  registration_number: "",
};

export const serviceFlowSteps: {
  title: string;
  type: ServiceFlowSteps;
}[] = [
  {
    title: "Brand",
    type: "brand",
  },
  {
    title: "Year",
    type: "year",
  },
  {
    title: "Model",
    type: "model",
  },
  {
    title: "KMs Driven",
    type: "km_driven",
  },
  {
    title: "Service",
    type: "service",
  },
  {
    title: "Schedule",
    type: "schedule",
  },
];

export const Title: {
  [key in ServiceFlowSteps]: JSX.Element;
} = {
  brand: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Make</span> of
      <br />
      your two-wheeler
    </p>
  ),
  year: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Year</span> of
      <br />
      your two-wheeler
    </p>
  ),
  model: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Model</span> of
      <br />
      your two-wheeler
    </p>
  ),
  km_driven: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">KMs driven</span> by
      <br />
      your two-wheeler
    </p>
  ),
  service: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Select the <span className="text-primaryA2">Service</span> for
      <br />
      your two-wheeler
    </p>
  ),
  schedule: (
    <p className="text-lg text-neutral-900 font-semibold leading-6">
      Book a service appointment
    </p>
  ),
};

export const getServiceFlowSteps = ({
  type,
  currentStep,
}: {
  type: ServiceFlowStepsType;
  currentStep: ServiceFlowSteps;
}) => {
  if (
    type === "automatic" &&
    (currentStep === "km_driven" ||
      currentStep === "service" ||
      currentStep === "schedule")
  ) {
    return serviceFlowSteps.filter(
      (step) =>
        step.type === "km_driven" ||
        step.type === "service" ||
        step.type === "schedule",
    );
  } else {
    return serviceFlowSteps;
  }
};

export const parseSearchParams = ({
  currentStep,
  type,
  navigateWithFallback,
  store_id,
}: {
  currentStep: ServiceFlowSteps;
  type: ServiceFlowStepsType;
  navigateWithFallback: any;
  store_id: string;
}) => {
  const doesStepExists = serviceFlowSteps.some(
    (obj) => obj.type === currentStep,
  );
  const doesTypeExists = ["automatic", "manual"].includes(type);

  if (!doesStepExists || !doesTypeExists || !store_id) {
    navigateWithFallback();
  }
};
export const servicePackages = [
  {
    id: 1,
    heading: "Essential Maintenance",
    subHeading: "Starts at ₹2,000*",
    time: "2",
    description: `Perfect option for routine maintenance to ensure your motorbike's reliability and safety on the road.`,
  },
  {
    id: 2,
    heading: "Performance Tune-Up",
    subHeading: "Starts at ₹5,000*",
    time: "4",
    description: `Get ready to experience enhanced power, responsiveness, and handling with this package.`,
  },
  {
    id: 3,
    heading: "Premium Restoration",
    subHeading: "Starts at ₹8,000*",
    time: "8",
    description: `Trust us to breathe new life into your motorbike and turn heads wherever you ride.`,
  },
];

export const whyDrivexData = [
  {
    icon: <ThreeUsersIcon oneStopColor="#E63C32" twoStopColor="#F47823" />,
    heading: <>Expertise and Experience</>,
  },
  {
    icon: (
      <ArrowsCounterClockwiseIcon
        oneStopColor="#E63C32"
        twoStopColor="#F47823"
      />
    ),
    heading: "Comprehensive Services",
  },
  {
    icon: <SealCheckIcon oneStopColor="#E63C32" twoStopColor="#F47823" />,
    heading: <>Quality Assurance</>,
  },
  {
    icon: <CurrencyInrIcon oneStopColor="#E63C32" twoStopColor="#F47823" />,
    heading: <>Transparent Pricing</>,
  },
];

export const steps = [
  {
    index: <ColouredCalendar size={30} />,
    heading: "Choose Service Center",
    description: "Find the service center that is closest to you.",
  },
  {
    index: (
      <CurrencyInrIcon
        size={32}
        oneStopColor="#E63C32"
        twoStopColor="#F47823"
      />
    ),
    heading: "Share Details. Pick Package",
    description: "Enter ride details and Choose a service package.",
  },
  {
    index: <CircularTick />,
    heading: "Book and Visit",
    description: "Choose a time, book a slot, and drop by for service.",
  },
];
