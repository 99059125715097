import { brandData } from "@/pages/Home/data.tsx";
import Brand from "../../components/Brand";
import { track } from "@/utils/mixpanel/actions";
import { useNavigate } from "react-router-dom";
import { SEARCH_BRAND_CLICKED } from "@/utils/mixpanel/Events/search_clicked_events";
import { getPageSource } from "@/utils/mixpanel/pageSource";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";

type IProps = {
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>;
};

const PopularBrands = ({ setOpenSearch }: IProps) => {
  const user = useSelector((state: any) => state.user.currentUser);
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const pageSource = getPageSource("/search");
  const handleBrandClick = (brandName: string) => {
    track(SEARCH_BRAND_CLICKED, {
      url: pageSource,
      brand: brandName,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    setOpenSearch(false);
    navigate(`/${city}/buy-two-wheelers?q=${encodeURIComponent(brandName)}`, {
      replace: true,
    });
  };
  return (
    <div className="overflow-auto flex flex-col gap-3">
      <p>Popular Brands</p>

      <div
        data-test-id="popular-brand-component"
        className="flex gap-3 overflow-auto w-fit"
      >
        {brandData.map((brand, index) => (
          <Brand
            key={index}
            brand={brand}
            className="w-32"
            size
            handleClick={() => handleBrandClick(brand.name)}
          />
        ))}
      </div>
    </div>
  );
};

export default PopularBrands;
