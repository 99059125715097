import React, { useState, useEffect, useRef, Key } from "react";
import "./StoryViewer.css";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { MapPin } from "@/assets/Icons";
import { Button } from "@/components/ui/button";
import { X as CloseIcon } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOwnerShipStatus } from "@/common/product";
import {
  MAXIMUM_TENURE,
  MINIMUM_DOWN_PAYMENT,
  RATE_OF_INTEREST_DEFAULT_VALUE,
  STORY_PROGRESS_BAR_UPDATE_INTERVAL,
} from "@/utils/constants";
import { emi_calculator } from "@/utils/emi";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import useDidMount from "@/hooks/useDidMount";
import { track } from "@/utils/mixpanel/actions";
import {
  HOME_STORY_CLOSED,
  HOME_STORY_LINK_CLICKED,
} from "@/utils/mixpanel/Events/home_clicked_events";
import Image from "@/components/ui/image";
import Video from "@/components/ui/video";

interface IProps {
  carouselApi: any;
  current: number;
  count: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  indexOfCurrentCarousel: number;
  currentStory: IStory;
  currentStories: IStoryItem[];
  indexOfStory: number;
  index: number;
  track_properties: IStoryEvents;
}

const StoryViewer = ({
  carouselApi,
  current,
  count,
  setCurrent,
  indexOfCurrentCarousel,
  currentStory,
  indexOfStory,
  currentStories,
  index,
  track_properties,
}: IProps) => {
  const [progress, setProgress] = useState(
    Array(currentStories?.length).fill(0),
  );
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { city } = useCurrentCity();
  const story = parseInt(searchParams.get("story") || "");
  const id = searchParams.get("id");
  const didMount = useDidMount();
  const [currentIndex, setCurrentIndex] = useState(indexOfStory || 0);

  useEffect(() => {
    if (didMount || !currentStories) return;

    setProgress(Array(currentStories?.length).fill(0));
  }, [currentStories?.length, story]);

  useEffect(() => {
    if (index !== indexOfCurrentCarousel) return;

    startTimer();

    return () => clearTimer();
  }, [currentIndex, current, id]);

  useEffect(() => {
    if (current === 0) return;

    setSearchParams(
      {
        story: currentStory?.attributes?.profile?.id,
        id: currentStories?.[currentIndex]?.uid,
      },
      {
        preventScrollReset: true,
      },
    );
  }, [current, currentIndex]);

  useEffect(() => {
    if (didMount) return;

    setCurrentIndex(0);
  }, [story]);

  const startTimer = () => {
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[currentIndex] = 0;
      return newProgress;
    });
    clearTimer();
    timerRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        if (newProgress[currentIndex] >= 100) {
          handleNext();
          return newProgress;
        }
        newProgress[currentIndex] += 1;
        return newProgress;
      });
    }, STORY_PROGRESS_BAR_UPDATE_INTERVAL);
  };

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const handleNext = () => {
    setProgress((prevProgress) => {
      const newProgress = [...prevProgress];
      newProgress[currentIndex] = 100; // fill the progress for the current story
      return newProgress;
    });

    if (currentIndex < currentStories?.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSearchParams(
        {
          story: currentStory?.attributes?.profile?.id,
          id: currentStories?.[currentIndex + 1]?.uid,
        },
        {
          preventScrollReset: true,
        },
      );
    } else {
      if (current < count) {
        carouselApi.scrollTo(indexOfCurrentCarousel + 1);
        setCurrentIndex(0);
      } else {
        // after last story in last carousel
        setCurrent(0);
        searchParams.delete("story");
        searchParams.delete("id");
        setSearchParams(searchParams, {
          preventScrollReset: true,
        });
      }
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setProgress((prevProgress) => {
        const newProgress = [...prevProgress];
        newProgress[currentIndex] = 0; // fill the progress for the current story
        return newProgress;
      });
    }
  };

  const renderStory = () => {
    const currentStoryItem = currentStories?.[currentIndex];
    if (currentStories?.[currentIndex]?.type === "PDP") {
      const vehicleData = currentStoryItem.vehicleData;

      return (
        <div className="p-4 mt-14">
          <div
            className="absolute top-0 left-1/4 w-1/2 h-screen z-10 cursor-pointer"
            onClick={() => {
              track(HOME_STORY_LINK_CLICKED, track_properties);
              navigate(`/${city}/buy-two-wheelers/${vehicleData.vehicleId}`);
            }}
          ></div>
          <Image
            transform="q-10"
            className="h-[33vh] max-h-[240px] se:h-[30vh] object-cover w-screen rounded-md"
            src={currentStoryItem?.vehicleData.imagePaths[0]}
            alt={`story-${currentIndex}`}
          />

          <Carousel
            opts={{
              align: "start",
            }}
            className="overflow-hidden px-0 pt-4 md:px-0"
          >
            <CarouselContent className="z-10 flex gap-1 px-3">
              {currentStoryItem?.vehicleData.imagePaths.map(
                (carouselImg: string, index: Key | null | undefined) => (
                  <CarouselItem
                    key={index}
                    className="basis-auto pl-2 h-[88px] w-[33%]"
                  >
                    <Image
                      transform="q-90,w-104,h-65"
                      className="w-[104px] h-[65px] object-cover border border-sm rounded-md"
                      src={carouselImg}
                      alt={`story-${currentIndex}`}
                    />
                  </CarouselItem>
                ),
              )}
            </CarouselContent>
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>

          <div className="flex flex-col gap-3 p-4 bg-white rounded-md">
            <h1 className="text-xl font-semibold">{vehicleData.vehicleName}</h1>
            <p className="text-neutral300 text-base font-semibold">
              {vehicleData.kmDriven.value.toLocaleString("en-IN")} KMs •{" "}
              {getOwnerShipStatus(vehicleData.ownerShip)} •{" "}
              {vehicleData.manufactureYear} Year
            </p>

            <div className="border-b leading-10 flex-between items-center">
              <p className="text-xl text-primaryA2">
                ₹{vehicleData.price.value.toLocaleString("en-IN")}
              </p>

              <p className="text-[15px] text-right text-neutral300">
                ₹
                {emi_calculator(
                  vehicleData.price.value -
                    (MINIMUM_DOWN_PAYMENT / 100) * vehicleData.price.value,
                  RATE_OF_INTEREST_DEFAULT_VALUE,
                  MAXIMUM_TENURE / 12,
                ).toLocaleString("en-IN")}
                /m
              </p>
            </div>

            <div className="flex items-center text-xs text-neutral300 gap-1 max-w-[70%] cursor-pointer">
              <MapPin colorOne="#919191" colorTwo="#919191" size={18} />
              <p className="font-semibold text-sm capitalize leading-5 overflow-hidden text-ellipsis whitespace-nowrap">
                {vehicleData.storeName}
              </p>
              <span className="text-base">&gt;</span>
            </div>

            <Button
              onClick={() => {
                track(HOME_STORY_LINK_CLICKED, track_properties);
                navigate(`/${city}/buy-two-wheelers/${vehicleData.vehicleId}`);
              }}
              variant="outline"
              className="z-10 bg-primaryA2 border-none text-white text-base w-full max-w-96 py-6 hover:bg-primaryA2 hover:text-white"
            >
              View
            </Button>
          </div>
        </div>
      );
    } else if (currentStories?.[currentIndex]?.type === "IMAGE") {
      return (
        <>
          <Image
            transform="q-50"
            className="h-full w-screen object-cover"
            src={currentStories?.[currentIndex].media.data.attributes.url}
            alt={`story-${currentIndex}`}
          />
          {currentStoryItem.cta_title && (
            <div className="z-10 absolute bottom-0  w-full flex justify-center px-3 mb-4">
              <Button
                onClick={() => {
                  track(HOME_STORY_LINK_CLICKED, track_properties);
                  window.location.href = currentStoryItem.cta_url;
                }}
                variant="outline"
                className="bg-primaryA2 border-none text-white text-base w-full max-w-96 py-6 hover:bg-primaryA2 hover:text-white"
              >
                {currentStoryItem.cta_title}
              </Button>
            </div>
          )}
        </>
      );
    } else if (currentStories?.[currentIndex]?.type === "VIDEO") {
      return (
        <>
          <Video
            className="h-full cover w-full"
            src={currentStories?.[currentIndex].media.data.attributes.url}
            muted={false}
          />
          {currentStoryItem.cta_title && (
            <div className="absolute bottom-0  w-full flex justify-center px-3 mb-4 z-10">
              <Button
                onClick={() => {
                  track(HOME_STORY_LINK_CLICKED, track_properties);
                  window.location.href = currentStoryItem.cta_url;
                }}
                variant="outline"
                className="bg-primaryA2 border-none text-white text-base w-full max-w-96 py-6 hover:bg-primaryA2 hover:text-white"
              >
                {currentStoryItem.cta_title}
              </Button>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className="relative w-full h-full">
      <div className="progress-bars w-full">
        {currentStories?.map((_: any, index: any) => (
          <div key={index} className="progress-bar w-full">
            <div
              className="progress"
              style={{ width: `${progress[index]}%` }}
            ></div>
          </div>
        ))}
      </div>

      <div className="flex justify-between w-full pt-6 p-4 absolute">
        <p className="text-white">{currentStory?.attributes.profile.name}</p>
        <CloseIcon
          size={30}
          color="#fff"
          className="z-10"
          onClick={() => {
            setCurrent(0);
            setCurrentIndex(0);
            setSearchParams("", { preventScrollReset: true });
            track(HOME_STORY_CLOSED, track_properties);
          }}
        />
      </div>

      <div className="h-full cover w-full bg-black">{renderStory()}</div>

      <div className="navigation">
        <div className="left" onClick={handlePrevious}></div>
        <div className="right" onClick={handleNext}></div>
      </div>
    </div>
  );
};
export default StoryViewer;
