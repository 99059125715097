import { formatUTCDate } from "@/utils/date";

export const soldVehicleCardData = [
  {
    label: "Selling Price",
    value: (product: IVehicleInfo) => `₹${product?.price?.displayValue}`,
  },
  {
    label: "Date of Sale",
    value: (product: IVehicleInfo) =>
      formatUTCDate(new Date(product?.order_updated_at ?? "")),
  },
];
