import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { ChangeEventHandler, MouseEventHandler } from "react";
import { Link } from "react-router-dom";

interface Iprops {
  isSignUpView: boolean;
  userDetails: {
    phoneNumber: string;
    name: string;
  };
  errorMsg: string;
  handleButtonDisable: () => boolean;
  handleProceed: MouseEventHandler<HTMLButtonElement>;
  handleChange: ChangeEventHandler<HTMLInputElement>;
}

const LoginOrSignup = ({
  isSignUpView,
  userDetails,
  errorMsg,
  handleChange,
  handleButtonDisable,
  handleProceed,
}: Iprops) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col space-y-3">
        <label
          htmlFor="terms"
          className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Enter your number
        </label>

        <div
          className={cn(
            "flex items-center px-4 rounded-md bg-neutral30 border-none",
            errorMsg && "bg-red100",
          )}
        >
          <span>+91</span>
          <Input
            className={cn(
              "bg-neutral30 py-6 pl-1 border-none focus-visible:ring-0 focus-visible:ring-offset-0 text-base",
              errorMsg && "bg-red100",
            )}
            name="phoneNumber"
            value={userDetails.phoneNumber}
            onChange={handleChange}
            pattern="[0-9]*"
            type="number"
            autoFocus
          />
        </div>

        {!isSignUpView && errorMsg && (
          <p className="text-sm text-red600">{errorMsg}</p>
        )}
      </div>

      {isSignUpView && (
        <div className="flex flex-col space-y-3">
          <label
            htmlFor="terms"
            className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Enter Name
          </label>
          <Input
            className="bg-neutral30 py-5"
            name="name"
            type="text"
            value={userDetails.name}
            onChange={handleChange}
            autoFocus
          />

          {errorMsg && <p className="text-sm text-red600">{errorMsg}</p>}
        </div>
      )}

      <Button
        variant="outline"
        className={cn(
          "bg-primaryA2 border-none text-white py-6 text-base hover:bg-primaryA2 hover:text-white",
          userDetails.phoneNumber.length < 10 &&
            "disabled:opacity-1 bg-[#F0F0F0] text-neutral200",
        )}
        disabled={handleButtonDisable()}
        onClick={handleProceed}
      >
        Proceed
      </Button>

      <p className="text-neutral300 text-xs flex justify-center">
        By logging in, you agree to
        <Link to="/terms" className="text-orange-500">
          &nbsp;Terms
        </Link>
        &nbsp;and
        <Link to="/privacy-policy" className="text-orange-500">
          &nbsp;Privacy Policy
        </Link>
        .
      </p>
    </div>
  );
};

export default LoginOrSignup;
