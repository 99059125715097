import { useSelector } from "react-redux";
import IncompleteUserAction from "./IncompleteUserAction";
import PDPViewed from "./PDPViewed";
import { useNavigate } from "react-router-dom";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import {
  removeExpiredCustomerData,
  removeReturningCustomerData,
} from "@/utils/returningCustomer";
import { valuePropositionData } from "../data";
import {
  TEST_DRIVE_INITIATED,
  WHATSAPP_TOUR_INITIATED,
} from "@/utils/mixpanel/Events/test_drive_clicked_events";
import { track } from "@/utils/mixpanel/actions";
import { SELL_REQ_INSPECTION_INITIATED } from "@/utils/mixpanel/Events/sell_flow_events";
import { PLP_VEHICLE_CARD_CLICKED_EVENT } from "@/utils/mixpanel/Events/plp_clicked_events";
import AvailabilityData from "@/components/AvailabilityData";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { IInspectionDetail } from "@/pages/Sell/data";

const ReturningCustomer = ({
  returningCustomerData,
}: {
  returningCustomerData: { data: { type: string }[] };
}) => {
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);

  useEffect(() => {
    removeExpiredCustomerData();
  }, [returningCustomerData]);

  const findIncompleteAction = (type: string) =>
    returningCustomerData.data.find((item) => item.type === type);

  const incompleteTestDriveData = findIncompleteAction("test-ride");
  const incompleteWhatsappTourData = findIncompleteAction("whatsapp-tour");
  const incompleteScheduleInspectionData = findIncompleteAction(
    "schedule-inspection",
  );

  const isIncompleteActionDataAvailable =
    incompleteTestDriveData ||
    incompleteWhatsappTourData ||
    incompleteScheduleInspectionData;

  const getHeadingText = () => {
    switch (isIncompleteActionDataAvailable?.type) {
      case "test-ride":
        return "Store test drive";
      case "whatsapp-tour":
        return "WhatsApp tour";
      default:
        return "Home inspection";
    }
  };

  function trackTestDriveInitiated(data: {
    type: string;
    data: IInspectionDetail & IVehicleInfo;
  }) {
    track(TEST_DRIVE_INITIATED, {
      source: "Returning_user",
      vehicle_id: data?.data?.vehicleId,
      vehicle_name: data?.data?.vehicleName,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  }

  function trackSellReqInspectionInitiated(data: {
    type: string;
    data: IInspectionDetail & IVehicleInfo;
  }) {
    track(SELL_REQ_INSPECTION_INITIATED, {
      source: "Returning_user",
      pincode: data?.data?.pincode,
      kms_driven: `${data?.data?.min_km_range}-${data?.data?.max_km_range} KM`,
      no_of_owners: data?.data?.no_of_owners,
      ...(data?.data?.registration_number && {
        registration_number: data?.data?.registration_number,
      }),
      state: data?.data?.state,
      model: data?.data?.model,
      variant: data?.data?.variant,
      brand: data?.data?.brand,
      manufacturing_year: data?.data?.manufacturing_year,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  }

  const handleUserNotLoggedIn = (data: {
    type: string;
    data: IInspectionDetail & IVehicleInfo;
  }) => {
    switch (data?.type) {
      case "test-ride":
        trackTestDriveInitiated(data);
        return navigate(
          `${city}/buy-two-wheelers/${data?.data?.vehicleId}?login=true&redirect=test-ride`,
        );
      case "schedule-inspection":
        trackSellReqInspectionInitiated(data);
        return navigate(`/sell-two-wheelers/bike-inspection?login=true`);
      default:
        navigate(`${location.pathname}?login=true`);
    }
  };

  const handleCompleteNow = (data: {
    type: string;
    data: IInspectionDetail & IVehicleInfo;
  }) => {
    if (!user && data?.type !== "whatsapp-tour") {
      handleUserNotLoggedIn(data);
      return;
    }

    if (data.type === "test-ride") {
      trackTestDriveInitiated(data);
      navigate(`${city}/buy-two-wheelers/${data?.data?.vehicleId}/test-ride`, {
        state: {
          source: "returning-customer",
        },
      });
      return;
    }

    if (data.type === "whatsapp-tour") {
      track(WHATSAPP_TOUR_INITIATED, {
        source: "Returning_user",
        vehicle_id: data?.data?.vehicleId,
        vehicle_name: data?.data?.vehicleName,
        name: user?.userName,
        phone_number: user?.phoneNumber,
      });
      const message = encodeURIComponent(
        `Hi, I'm interested in this ${data?.data?.vehicleName}: ${window.location.href}${city}/buy-two-wheelers/${data?.data?.vehicleId}! Can I see it live on video call?`,
      );
      const whatsappURL = `https://wa.me/${data?.data?.storePhoneNumber}?text=${message}`;
      window.open(whatsappURL, "_blank");

      removeReturningCustomerData({ vehicleDetail: data });
      navigate("/");
      return;
    }

    trackSellReqInspectionInitiated(data);

    navigate("/sell-two-wheelers/bike-inspection", {
      replace: true,
      state: {
        source: "returning-customer",
      },
    });
  };

  const handleDismiss = (vehicleDetail: any) => {
    removeReturningCustomerData({ vehicleDetail });
    navigate("/", { replace: true });
  };

  const handlePDPProductCardClick = (item: IVehicleInfo) => {
    track(PLP_VEHICLE_CARD_CLICKED_EVENT, {
      vehicle_brand: item.brand,
      vehicle_model: item.modelName,
      store_location: item.storeName,
      vehicle_id: item.vehicleId,
      source: "Returning_user",
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <div className="lg:flex lg:justify-between lg:items-center max-w-7xl mx-auto w-full">
      <div className="flex flex-col gap-2 lg:gap-4 mb-2">
        <h1 className="text-white font-semibold text-2xl lg:text-4xl">
          Good As New
        </h1>
        <h2 className="text-neutral300 font-medium text-sm lg:text-base">
          Two-wheelers that Look, Feel & Ride Like New
        </h2>

        <div className="flex gap-4 py-4">
          {valuePropositionData.map((info, index) => (
            <div
              key={index}
              className="flex flex-col lg:flex-row gap-4 w-[5rem] lg:w-[10rem]"
            >
              <span className="h-12 w-12 bg-accent rounded-full flex-center">
                {info.icon}
              </span>
              <div className="flex flex-col text-white">
                <p className="font-semibold text-xl lg:text-2xl">
                  {info.quantity}
                </p>
                <p className="text-sm lg:text-base text-neutral300">
                  {info.type}
                </p>
              </div>
            </div>
          ))}
        </div>

        <Button
          onClick={() => navigate(`${city}/buy-two-wheelers`)}
          variant="outline"
          className="hidden lg:flex bg-primaryA2 border-none text-white text-base max-w-44 py-6 hover:bg-primaryA2 mb-5 hover:text-white"
        >
          Explore All
        </Button>

        <div className="hidden lg:flex justify-around text-white text-center lg:justify-start gap-4 m-4 lg:m-0">
          <AvailabilityData />
        </div>
      </div>

      <div className="flex flex-col">
        {isIncompleteActionDataAvailable && (
          <IncompleteUserAction
            data={isIncompleteActionDataAvailable}
            handleCompleteNow={handleCompleteNow}
            handleDismiss={handleDismiss}
            getHeadingText={getHeadingText}
            user={user}
          />
        )}

        {!isIncompleteActionDataAvailable &&
          returningCustomerData?.data?.length > 1 && (
            <PDPViewed
              data={returningCustomerData?.data}
              handleProductCardClick={handlePDPProductCardClick}
            />
          )}
      </div>
    </div>
  );
};

export default ReturningCustomer;
