import { useState, useEffect } from "react";

interface Timer {
  minutes: number;
  seconds: number;
  formattedTime: string;
  timerEnd: boolean;
}

interface Options {
  restartTimer?: boolean;
}

const useOtpTimer = (
  initialMinutes: number = 1,
  initialSeconds: number = 0,
  options: Options = {},
): Timer => {
  const { restartTimer } = options;
  const [timer, setTimer] = useState<Timer>({
    minutes: initialMinutes,
    seconds: initialSeconds,
    formattedTime: formatTime(initialMinutes, initialSeconds),
    timerEnd:
      formatTime(initialMinutes, initialSeconds) == "00:00" ? true : false,
  });
  const [restartFlag, setRestartFlag] = useState<boolean>(false);

  useEffect(() => {
    setTimer({
      minutes: initialMinutes,
      seconds: initialSeconds,
      formattedTime: formatTime(initialMinutes, initialSeconds),
      timerEnd: false,
    });
    setRestartFlag(!restartFlag);
  }, [restartTimer]);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer.minutes === 0 && prevTimer.seconds === 0) {
          clearInterval(countdown);
          return {
            minutes: 0,
            seconds: 0,
            formattedTime: "00:00",
            timerEnd: true,
          };
        }

        const totalSeconds = prevTimer.minutes * 60 + prevTimer.seconds - 1;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return {
          minutes,
          seconds,
          formattedTime: formatTime(minutes, seconds),
          timerEnd: formatTime(minutes, seconds) == "00:00" ? true : false,
        };
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [restartFlag]);

  return timer;
};

const formatTime = (minutes: number, seconds: number): string => {
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};

export default useOtpTimer;
