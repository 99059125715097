import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import Image from "@/components/ui/image";
import Video from "@/components/ui/video";

interface IProps {
  data: ITestimonialsWithType;
  heading: React.ReactNode;
}

const Testimonials = ({ data, heading }: IProps) => {
  const testimonials: ITestimonials[] = data?.data;
  if (!testimonials) return null;

  return (
    <div className="flex flex-col gap-6 p-4 max-w-5xl mx-auto w-full">
      <p className="text-xl font-semibold lg:text-center">{heading}</p>

      <Carousel>
        <CarouselContent className="flex lg:justify-center">
          {testimonials?.map((testimonial: any) => {
            const isVideoPresent =
              testimonial?.attributes?.image?.data?.[0]?.attributes?.mime?.includes(
                "video",
              );

            return (
              <CarouselItem
                key={testimonial?.attributes?.orderIndex}
                className="basis-8/12 sm:basis-6/12 md:basis-4/12 lg:basis-3/12 relative"
              >
                {!isVideoPresent && (
                  <Image
                    className="object-cover w-full h-full rounded-lg"
                    src={
                      testimonial?.attributes?.image?.data?.[0]?.attributes
                        ?.url as string
                    }
                    alt="Testimonial"
                  />
                )}

                {isVideoPresent && (
                  <Video
                    className="object-cover w-full h-full rounded-lg"
                    src={
                      testimonial?.attributes?.image?.data?.[0]?.attributes
                        ?.url as string
                    }
                  />
                )}

                {!isVideoPresent && (
                  <div className="absolute flex flex-col px-[14px] py-[15px] gap-2 bottom-0">
                    <p className="text-white text-lg font-semibold">
                      {testimonial?.attributes?.heading}
                    </p>
                    <p className="text-white text-xs font-medium line-clamp-4">
                      {testimonial?.attributes?.description}
                    </p>
                    <p className="text-white text-xs font-medium opacity-50">
                      {testimonial?.attributes?.userDetail}
                    </p>
                  </div>
                )}
              </CarouselItem>
            );
          })}
        </CarouselContent>
      </Carousel>
    </div>
  );
};

export default Testimonials;
