import { DownloadIcon, ShareIcon } from "@/assets/Icons";
import { FC } from "react";

interface DocumentItemProps {
  icon: FC<{ size: number }>;
  title: string;
  size: string;
  handleDownload: () => void;
  shareMessage: () => void;
}

const DocumentItem: FC<DocumentItemProps> = ({
  icon: Icon,
  title,
  size,
  handleDownload,
  shareMessage,
}) => (
  <div className="flex justify-between">
    <div className="flex gap-3">
      <div className="flex-center bg-violet50 rounded-md p-2">
        <Icon size={16} />
      </div>
      <div>
        <p className="text-sm">{title}</p>
        <p className="text-xs text-neutral300">{size}</p>
      </div>
    </div>
    <div className="flex gap-2">
      <div
        className="flex-center bg-neutral30 rounded-md p-2"
        onClick={handleDownload}
      >
        <DownloadIcon size={16} />
      </div>
      <div
        className="flex-center bg-neutral30 rounded-md p-2"
        onClick={shareMessage}
      >
        <ShareIcon size={16} oneStopColor="#000" twoStopColor="#000" />
      </div>
    </div>
  </div>
);

export default DocumentItem;
