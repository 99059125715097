import VehicleListLayout from "../VehicleListLayout";
import ProductCardSkeletonLoader from "../SkeletonLoaders/ProductCardSkeletonLoader";
import { cn } from "@/lib/utils";

interface Iprops {
  className?: string;
  listCount?: number;
}

const VehicleListSkeletonLoader = ({ className, listCount = 6 }: Iprops) => {
  return (
    <VehicleListLayout className={cn("mt-0 pt-2", className)}>
      {Array.from({ length: listCount }, (_, index) => (
        <ProductCardSkeletonLoader key={index} />
      ))}
    </VehicleListLayout>
  );
};

export default VehicleListSkeletonLoader;
