import { sellInfoData } from "../data";

const SellInfo = () => {
  return (
    <>
      <p className="text-xl md:text-[28px] md:text-center md:leading-10">
        Selling Your Two-Wheeler?
        <br />
        <span className="text-primaryA2">We Make it an Easy Ride!</span>
      </p>

      <div className="flex flex-wrap justify-center gap-4 max-w-screen-lg mx-auto w-full">
        {sellInfoData.map((sellInfo, index) => (
          <div
            key={index}
            className="flex flex-col w-[47%] md:w-[20%] p-4 bg-neutral30 rounded-md gap-4"
          >
            <p className="text-primaryA2">{sellInfo.icon}</p>

            <div className="flex flex-col gap-1">
              <p className="text-black">{sellInfo.heading}</p>
              <p className="text-neutral300">{sellInfo.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default SellInfo;
