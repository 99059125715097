export const getLabel = ({
  drivexAssured,
  status,
}: {
  drivexAssured?: boolean;
  status?: string;
}) => {
  const label = {
    isReserved: "",
    isAssured: "",
  };

  status === "reserved"
    ? (label.isReserved = "Booked")
    : (label.isReserved = "");

  drivexAssured ? (label.isAssured = "Assured") : (label.isAssured = "");

  return label;
};
