import React from "react";
import "./App.css";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import { privateRoutes as privateRoutesData, routes } from "./utils/routes.tsx";
import PrivateRoutes from "./components/PrivateRoutes/index.tsx";
import Auth from "./pages/Auth/index.tsx";
import InitializeConfigs from "./components/InitializeConfigs/index.tsx";
import { IS_CLIENT_SIDE } from "./utils/constants.ts";
import RedirectionHandler from "./hooks/useRedirection.tsx";

const App = () => {
  return (
    <>
      {IS_CLIENT_SIDE && (
        <>
          <RedirectionHandler />
          <InitializeConfigs />
        </>
      )}
      <Auth />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}

        <Route element={<PrivateRoutes />}>
          {privateRoutesData.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default App;
