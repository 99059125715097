import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = import.meta.env.VITE_SCHEDULE_BACKSTAGE_API_BASE_URL;

export const servicesApi = createApi({
  reducerPath: "servicesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getServicePackages: builder.query({
      query: () => {
        return {
          url: "/services/",
        };
      },
    }),
    getServicePackagesBasedOnStore: builder.query({
      query: ({ storeId }) => {
        return {
          url: `/stores/${storeId}/services/`,
        };
      },
    }),
    getVehicleInfo: builder.mutation({
      query: (payload) => {
        return {
          url: "/services/vehicle-info/",
          body: payload,
          method: "POST",
        };
      },
    }),
    scheduleAppointment: builder.mutation({
      query: (payload) => {
        return {
          url: "/schedule/services/",
          body: payload,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useGetServicePackagesQuery,
  useGetServicePackagesBasedOnStoreQuery,
  useGetVehicleInfoMutation,
  useScheduleAppointmentMutation,
} = servicesApi;
