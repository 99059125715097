import { AlertToast, ArrowCircleUpRight } from "@/assets/Icons";
import InspectionDetailMiniCard from "@/components/InspectionDetailMiniCard/InspectionDetailMiniCard";
import ProductMiniCard from "@/components/ProductMiniCard";
import VehicleAppointmentDetails from "./VehicleAppointmentDetails";
import ServiceMiniCard from "@/pages/Services/ServiceMiniCard";

const ShowOrderedVehicle = ({
  productInfo,
  isTabSelected,
  handleOrderedVehicleCardClick,
  containerRef,
}: any) => {
  const handleCallStore = () => {
    window.location.href = `tel:${productInfo?.storePhoneNumber.replaceAll("-", "")}`;
  };

  const enquiryType = () => {
    if (productInfo?.enquiry_type == "TEST_RIDE") {
      return "Store test drive";
    } else if (productInfo?.enquiry_type == "WHATSAPP_TOUR") {
      return "WhatsApp tour";
    } else if (productInfo?.enquiry_type == "HOME_INSPECTION") {
      return "Home inspection";
    } else {
      return "Bike service";
    }
  };

  return (
    <div className="flex flex-col gap-4 bg-white p-4 w-[100%] sm:w-[50%] rounded-md">
      <div className="flex items-center gap-2 bg-[#F0ECF5] py-3 px-4 border border-[#CFC5E0] rounded-md">
        {isTabSelected === "buy" && !productInfo?.isAvailable ? (
          <>
            <AlertToast size={18} fillColor="#0A0A0A" />
            <p className="font-semibold ">This two-wheeler is sold out</p>{" "}
          </>
        ) : (
          <>
            <ArrowCircleUpRight size={18} />
            <p className="text-primaryB1 font-semibold">
              {enquiryType()} requested
            </p>
          </>
        )}
      </div>
      {productInfo?.isAvailable !== false &&
        (productInfo?.enquiry_type == "WHATSAPP_TOUR" ? (
          <p className="text-neutral300 font-medium text-sm">
            Reach out to our store for assistance
          </p>
        ) : (
          <p className="text-neutral300 font-medium text-sm">
            A DriveX executive will call you to confirm your {enquiryType()}
          </p>
        ))}
      {productInfo &&
        (isTabSelected === "buy" ? (
          <ProductMiniCard
            productInfo={productInfo}
            className={"px-0"}
            handleOrderedVehicleCardClick={handleOrderedVehicleCardClick}
            containerRef={containerRef}
          />
        ) : isTabSelected === "sell" ? (
          <InspectionDetailMiniCard
            productInfo={productInfo}
            showEdit={false}
            profilePage={true}
            handleOrderedVehicleCardClick={handleOrderedVehicleCardClick}
            containerRef={containerRef}
          />
        ) : (
          <ServiceMiniCard
            service={productInfo}
            vehicleName={productInfo?.vehicleName}
            handleOrderedVehicleCardClick={handleOrderedVehicleCardClick}
            containerRef={containerRef}
          />
        ))}

      {/* Ordered Vehicle Card Bottom */}

      <VehicleAppointmentDetails
        productInfo={productInfo}
        isTabSelected={isTabSelected}
        handleCallStore={handleCallStore}
      />
    </div>
  );
};

export default ShowOrderedVehicle;
