import usePriceEstimateSteps from "./usePriceEstimateSteps";
import {
  SearchParams,
  Title,
  getPriceEstimateSteps,
  initialAutomaticState,
  initialManualState,
  parseSearchParams,
} from "./data";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import FlowStepper from "@/components/FlowStepper";
import { useScreenDetector } from "@/hooks/useScreenDetector";

function ManualSellFlow() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useScreenDetector();
  const currentStep = searchParams.get("step") as PriceEstimateSteps;
  const type = searchParams.get("type") as PriceEstimateStepsType;
  const priceEstimateSteps = getPriceEstimateSteps({ type });
  const { estimatedPrice } = useSelector((state: any) => state.estimatedPrice);
  const setSearchParamsWithType = (params: SearchParams) => {
    setSearchParams(params);
  };

  const locationState = location.state;

  const [priceEstimate, setPriceEstimate] = useState(
    type === "manual" || type === null
      ? initialManualState
      : initialAutomaticState,
  );

  useEffect(() => {
    const targetElement = document.getElementById(`step_${currentStep}`);
    targetElement?.scrollIntoView({ behavior: "smooth", block: "nearest" });

    // Extract pin_code from location.state or use the one from priceEstimate state
    const pin_code =
      locationState?.vehicleDetails?.pincode ||
      locationState?.pin_code ||
      priceEstimate?.pin_code ||
      estimatedPrice?.pincode;

    parseSearchParams({
      currentStep,
      type,
      navigate,
      pin_code,
    });
  }, [currentStep, type, locationState, priceEstimate]);

  const isCurrentStepComplete = (currentStep: PriceEstimateSteps) => {
    return priceEstimate.steps[currentStep] !== "" ? true : false;
  };

  const { renderCurrentStep } = usePriceEstimateSteps({
    priceEstimate,
    setPriceEstimate,
    setSearchParams,
    currentStep,
    type,
  });

  const handleBackClick = useCallback(() => {
    if (currentStep === Object.keys(priceEstimate.steps)[0]) {
      navigate("/sell-two-wheelers");
    } else {
      const currentStepIndex = priceEstimateSteps.findIndex(
        (p) => p.type === currentStep,
      );
      const previousStep = priceEstimateSteps[currentStepIndex - 1];

      setSearchParamsWithType({
        step: previousStep.type,
        type,
      });

      // Create a copy of the current steps object
      const updatedSteps = { ...priceEstimate.steps };

      // Reset the previousStep property
      updatedSteps[previousStep.type] = "";

      setPriceEstimate({
        steps: updatedSteps,
        pin_code: priceEstimate.pin_code,
        ...(type === "automatic" && {
          registration_number: priceEstimate.registration_number,
        }),
      });
    }
  }, [currentStep, priceEstimate]);

  const handlePillClick = (step: { type: PriceEstimateSteps }) => {
    if (!isCurrentStepComplete(step.type)) return;

    setSearchParamsWithType({ step: step.type, type });
    setPriceEstimate({
      steps: {
        ...priceEstimate.steps,
      },
      pin_code: priceEstimate.pin_code,
      ...(type === "automatic" && {
        registration_number: priceEstimate.registration_number,
      }),
    });
  };

  return (
    <FlowStepper
      onBackClick={handleBackClick}
      headerContent={
        <p className="text-base text-neutral900">
          {priceEstimate.steps.brand} {priceEstimate.steps.year}{" "}
          {priceEstimate.steps.model} {priceEstimate.steps.variant}
        </p>
      }
      onPillClick={(step) =>
        handlePillClick(step as { type: PriceEstimateSteps })
      }
      isStepComplete={(step: string) =>
        isCurrentStepComplete(step as PriceEstimateSteps)
      }
      steps={priceEstimateSteps}
      activeStep={currentStep}
      stepTitles={Title}
      renderActiveStep={renderCurrentStep}
      showNavbar={!isMobile}
    />
  );
}

export default ManualSellFlow;
