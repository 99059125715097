import { track } from "@/utils/mixpanel/actions";
import { PDP_IMAGE_CLICKED_EVENT } from "@/utils/mixpanel/Events/pdp_clicked_events";
import {
  ArrowsCounterClockwiseIcon,
  ClockIcon,
  SparkleIcon,
  WrenchIcon,
} from "@/assets/Icons";

export const differenceData = [
  {
    icon: <SparkleIcon size={40} />,
    heading: (
      <>
        100% <br /> Refurbished
      </>
    ),
  },
  {
    icon: <ArrowsCounterClockwiseIcon size={40} />,
    heading: "Easy paperwork process",
  },
  {
    icon: <ClockIcon size={40} />,
    heading: (
      <>
        1 Year <br /> Warranty
      </>
    ),
  },
  {
    icon: <WrenchIcon size={40} url="pdp_paint_wrench" />,
    heading: (
      <>
        3 Free <br /> Services
      </>
    ),
  },
];

export const DOUBLE_CLICK_TIMEOUT = 300; // time in ms

export const trackImageViewerOpened = ({
  productDetail,
  currentImageIndex,
}: {
  productDetail: IVehicleInfo;
  currentImageIndex: number;
}) => {
  track(PDP_IMAGE_CLICKED_EVENT, {
    vehicle_id: productDetail.vehicleId,
    image_id: productDetail.imagePaths[currentImageIndex],
  });
};
