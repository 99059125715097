import { IS_CLIENT_SIDE } from "@/utils/constants";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  store: IS_CLIENT_SIDE
    ? JSON.parse(localStorage.getItem("current_store_dmx") || "null")
    : null,
};

const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
      if (IS_CLIENT_SIDE) {
        localStorage.setItem(
          "current_store_dmx",
          JSON.stringify(action.payload),
        );
      }
    },
  },
});

export const { setStore } = storeSlice.actions;

export default storeSlice.reducer;
