import { CallIcon } from "@/assets/Icons";
import { supportContactEmail, supportContactNumber } from "@/utils/constants";
import { MailIcon } from "lucide-react";

const helpData = [
  {
    icon: <CallIcon size={15} />,
    title: "Phone Number:",
    value: supportContactNumber,
    type: "tel",
  },
  {
    icon: <MailIcon color="white" size="15" />,
    title: "Email Address:",
    value: supportContactEmail,
    type: "mailto",
  },
];

const HelpCard = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <h3 className="text-[20px] font-semibold">Need help?</h3>
      <div className="my-4 bg-white shadow-sm rounded-xl border border-[#EDEDED] px-4">
        {helpData.map((help, index) => {
          return (
            <div className="flex items-center my-6" key={index}>
              <div className="w-10 h-10 bg-primaryA2 rounded-full flex-center">
                {help.icon}
              </div>
              <span className="ml-4 space-y-1">
                <p className="text-sm font-medium text-neutral300">
                  {help.title}
                </p>
                <a
                  href={`${help.type}:${help.value}`}
                  className="text-sm font-medium text-neutral900 underline"
                >
                  {help.value}
                </a>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HelpCard;
