export const yearOfManufacturing = [
  {
    year: "2019",
  },
  {
    year: "2020",
  },
  {
    year: "2021",
  },
  {
    year: "2022",
  },
];

export const ownershipOptions = [
  {
    ownership: "1st Owner",
  },
  {
    ownership: "2nd Owner",
  },
  {
    ownership: "3rd Owner",
  },
];
