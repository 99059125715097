import { MapPin } from "@/assets/Icons";
import { Button } from "../ui/button";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { NAVBAR_MANUAL_LOCATION_EVENT } from "@/utils/mixpanel/Events/navbar_clicked_events";
import { track } from "@/utils/mixpanel/actions.ts";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";

interface IProps {
  setOpenLocation: (value: boolean) => void;
  setShowNudge: (value: boolean) => void;
}

const Nudge = ({ setOpenLocation, setShowNudge }: IProps) => {
  const { location } = useSelector((state: any) => state.location);
  const user = useSelector((state: any) => state.user.currentUser);
  const { isDesktop } = useScreenDetector();
  const pathURL = window.location.href;

  useEffect(() => {
    window.addEventListener("scroll", () => setShowNudge(false));
  }, []);

  if (location) return;

  const handleSelectManually = () => {
    setOpenLocation(true);
    setShowNudge(false);
    track(NAVBAR_MANUAL_LOCATION_EVENT, {
      url: pathURL,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  return (
    <div className="relative bg-white p-4 border rounded-lg w-fit">
      <div className="text-gray-800 text-sm flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <MapPin size={40} />
          <p>Please share your location for the best experience</p>
        </div>

        <Button
          onClick={handleSelectManually}
          variant="outline"
          className="text-primaryA1 border-primaryA1"
          data-test-id="select-city-manually-button"
        >
          Select Manually
        </Button>
      </div>

      <div
        className={cn(
          "absolute top-0 transform -translate-x-1/2 -translate-y-1/2 rotate-45 w-4 h-4 bg-white border-l border-t",
          isDesktop ? "left-[39px]" : "right-[39px]",
        )}
      ></div>
    </div>
  );
};

export default Nudge;
