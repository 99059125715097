import React, { useCallback, useRef } from "react";
import VehicleQRModal from "./VehicleQRModal";
import { QRIcon, RightArrowIcon } from "@/assets/Icons";
import { useGetQRCodeUrlMutation } from "@/store/services/Dmx/storeVehicles";

type VehicleQRProps = {
  openQRModal: boolean;
  productDetail: any;
  setOpenQRModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const VehicleQR = ({
  openQRModal,
  productDetail,
  setOpenQRModal,
}: VehicleQRProps) => {
  const [getQRCodeUrl] = useGetQRCodeUrlMutation({});
  const QRCodeUrl = useRef<string>("");

  const getQRCode = useCallback(async () => {
    const res = await getQRCodeUrl({
      vehicle_id: productDetail?.vehicleId.toString(),
    });

    const url = res?.data?.data?.url;

    const completeUrl = `https://api.dub.co/qr?url=${url}&qr=1&fgColor=%23912D7D`;

    QRCodeUrl.current = completeUrl;
  }, [productDetail?.vehicleId]);

  const openQRModalHandler = () => {
    setOpenQRModal(true);
    if (!QRCodeUrl.current) {
      getQRCode();
    }
  };

  return (
    <>
      <VehicleQRModal
        open={openQRModal}
        vehicleDetails={productDetail}
        setClose={() => setOpenQRModal(false)}
        QRCodeUrl={QRCodeUrl.current}
      />
      <div
        className="flex justify-between items-center p-2.5 rounded-md bg-violet50 mt-2 cursor-pointer"
        onClick={openQRModalHandler}
      >
        <span className="flex gap-2 items-center">
          <QRIcon />
          <p className="font-semibold text-neutral700 text-sm">QR Code</p>
        </span>
        <RightArrowIcon />
      </div>
    </>
  );
};

export default VehicleQR;
