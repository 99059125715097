import SuperTokens from "supertokens-web-js";
import Session from "supertokens-web-js/recipe/session";
import PasswordLess from "supertokens-web-js/recipe/passwordless";

export const supertokenInit = () => {
  SuperTokens.init({
    appInfo: {
      apiDomain: import.meta.env.VITE_SUPERTOKEN_API_DOMAIN,
      apiBasePath: import.meta.env.VITE_SUPERTOKEN_API_BASE_PATH,
      appName: import.meta.env.VITE_SUPERTOKEN_APP_NAME,
    },
    recipeList: [
      Session.init(),
      PasswordLess.init({
        preAPIHook: async (context) => {
          if (context.action === "PASSWORDLESS_CONSUME_CODE") {
            const payload = context.userContext;

            // is the fetch config object that contains the header, body etc..
            let requestInit = context.requestInit;

            const body = {
              ...JSON.parse(requestInit.body?.toString() || "{}"),
              ...payload,
            };

            requestInit = {
              ...requestInit,
              body: JSON.stringify(body),
            };

            return {
              url: context.url,
              requestInit,
            };
          } else {
            // Return a default value if the condition is not met
            return {
              url: context.url,
              requestInit: context.requestInit,
            };
          }
        },
      }),
    ],
  });
};
