import { Link, useSearchParams } from "react-router-dom";
import { Calendar, MapPinIcon } from "lucide-react";
import { ArrowCircleUpRight } from "@/assets/Icons";
import Navbar from "@/components/Navbar";
import { useEffect, useState } from "react";
import { scheduledServiceTransactionDetails } from "@/store/services/scheduleApi";
import { convertToDDMMYYYY } from "@/utils/date";
import { ensureHttpsProtocol } from "@/utils/storeLocation";
import FAQs from "@/components/FAQs";
import { serviceFAQsData } from "@/components/FAQs/data";
import Confetti from "react-confetti";
import MiniProductCardSkeletonLoader from "@/components/SkeletonLoaders/MiniProductCardSkeletonLoader";
import Chips from "@/components/SkeletonLoaders/Chips";
import ServiceMiniCard from "../ServiceMiniCard";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { track } from "@/utils/mixpanel/actions";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import { useSelector } from "react-redux";

const ServiceRequestedPage = () => {
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const [transactionDetails, setTransactionDetails] =
    useState<IServiceRequestedResp | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transactionId") || "";
  const storeURL = ensureHttpsProtocol(
    transactionDetails?.store_map_link || "",
  );

  useEffect(() => {
    if (transactionDetails) return;
    setIsFetching(true);

    const getTransactionDeatils = async () => {
      const data = await scheduledServiceTransactionDetails(
        parseInt(transactionId),
      );

      if (data.status === "OK") {
        setTransactionDetails(data.data);
        setIsFetching(false);
      }
    };
    getTransactionDeatils();
  }, [transactionDetails]);

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Service Requested Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  return (
    <>
      <Navbar
        backgroundColor="bg-neutral30"
        showLocation={false}
        showSearchbar={false}
      />

      <div className="flex flex-col gap-4 bg-neutral30 h-screen p-4 pt-[90px]">
        <div className="flex flex-col gap-3 flex-center text-center px-4">
          <ArrowCircleUpRight />
          <p className="text-2xl">Service Requested</p>
          <p className="text-neutral300 text-sm">
            A DriveX executive will call you in the next 12 hours to confirm
            your service request
          </p>
        </div>

        <div className="w-full ">
          <Confetti numberOfPieces={200} recycle={false} gravity={0.2} />
        </div>

        <div className="bg-white p-4 max-w-[60rem] mx-auto w-full rounded-sm">
          {isFetching ? (
            <div className="flex flex-col item gap-3">
              <MiniProductCardSkeletonLoader />
              {Array.from({ length: 3 }, (_, index) => (
                <Chips className="h-[13px] w-full max-w-[295px]" key={index} />
              ))}
            </div>
          ) : (
            <>
              {transactionDetails && (
                <ServiceMiniCard
                  service={transactionDetails?.package_info}
                  vehicleName={transactionDetails?.vehicle_info?.make_model}
                  className="px-0 items-center border-b-[1px] pb-4"
                />
              )}

              <div className="flex flex-col pt-2 gap-2">
                <div className="flex-between">
                  <div className="flex gap-2 text-sm">
                    <MapPinIcon size="20" />
                    <p className="text-sm capitalize">
                      {transactionDetails?.store_name}
                    </p>
                  </div>
                  <Link
                    className="text-primaryA2 font-semibold text-sm underline underline-offset-1 decoration-orange-500 "
                    to={(transactionDetails?.store_map_link && storeURL) || ""}
                    target={transactionDetails?.store_map_link && `_blank`}
                  >
                    Directions
                  </Link>
                </div>

                <div className="flex gap-2">
                  <Calendar size="20" />
                  <p className="text-sm">
                    {transactionDetails &&
                      convertToDDMMYYYY(transactionDetails?.date)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>

        <FAQs content={serviceFAQsData} handleFAQClick={handleFAQClick} />
      </div>
    </>
  );
};

export default ServiceRequestedPage;
