import { DriveXFilledIcon } from "@/assets/Icons";
import Video from "@/components/ui/video";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";

const EngineOil = `${IMAGE_KIT_BASE_URL}/ik_servicepage/EngineOil.gif/ik-gif-video.mp4`;

const HeroGIFBanner = ({
  title,
  showDriveXFilledIcon,
}: {
  title: string;
  showDriveXFilledIcon?: boolean;
}) => {
  return (
    <div className="flex-center flex-col relative">
      <Video src={EngineOil} className="w-full lg:h-96 h-80 object-cover" />
      <div className="absolute inset-0 bg-[#000000B2]" />
      <div className="flex-center flex-col absolute">
        {!showDriveXFilledIcon && (
          <DriveXFilledIcon size={64} color="#684499" />
        )}
        <h1 className="text-2xl font-semibold text-center max-w-80 text-white mt-4">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default HeroGIFBanner;
