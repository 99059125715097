import {
  Documentation,
  PriceNote,
  ClockIcon,
  SearchIcon,
  RupeeIcon,
  ArrowLeftRightIcon,
} from "@/assets/Icons";

export const sellingSteps = [
  {
    icon: <RupeeIcon size={22} />,
    step: (
      <>
        Online Price <br /> Estimate
      </>
    ),
  },
  {
    icon: (
      <PriceNote
        size={22}
        oneStopColor="#fff"
        twoStopColor="#fff"
        url="hero_paint_price_note"
      />
    ),
    step: (
      <>
        Free Doorstep <br /> Evaluation
      </>
    ),
  },
  {
    icon: <ArrowLeftRightIcon size={22} />,
    step: (
      <>
        Instant <br /> Payment
      </>
    ),
  },
];

// Interface for representing estimated price information of a vehicle
export interface IInspectionDetail {
  registration_number?: string;
  min_price?: number | null;
  max_price?: number | null;
  icon_url: string;
  model: string;
  variant: string;
  no_of_owners: number;
  location_info: string;
  min_km_range: number;
  max_km_range: number;
  brand: string;
  manufacturing_year: string;
  state: string;
  pincode: number;
  vehicleName?: string;
  transaction_id?: number;
  vehicle_id?: number;
}

export const sellInfoData = [
  {
    heading: "Best Price Guaranteed",
    description:
      "Get the best value for your two-wheeler with our expert evaluation",
    icon: <PriceNote />,
  },
  {
    heading: "Hassle-free Documentation ",
    description: "Leave the hassle of paperwork to us and sell stress-free",
    icon: <Documentation />,
  },
  {
    heading: "Fast and Secure Payment",
    description:
      "Get instant payment on your account made through secure sources",
    icon: <ClockIcon size={36} oneStopColor="#E63C32" twoStopColor="#F47823" />,
  },
  {
    heading: "Free Doorstep Evaluation",
    description:
      "Free doorstep evaluation for your two-wheeler on selling with us",
    icon: (
      <SearchIcon
        size={34}
        oneStopColor="#E63C32"
        twoStopColor="#F47823"
        fill="url(#paint0_linear_4485_19885)"
      />
    ),
  },
];
