import { userData } from "./data";

interface IProps {
  handleUserDataClick: (text: string) => void;
}

const UserData = ({ handleUserDataClick }: IProps) => {
  return (
    <>
      {userData.map((data, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 py-4 px-3 w-[47%] bg-neutral50 rounded-md"
          onClick={() => handleUserDataClick(data.value)}
        >
          <p>{data.icon}</p>
          <p className="font-semibold text-sm">{data.text}</p>
        </div>
      ))}
    </>
  );
};

export default UserData;
