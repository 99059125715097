import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface DataContextProps {
  data: any;
  setData: React.Dispatch<React.SetStateAction<any>>;
}

interface DataProviderProps {
  children: React.ReactNode;
  initialData: any;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({
  children,
  initialData,
}) => {
  const [data, setData] = useState<any>(initialData);
  const location = useLocation();
  const previousLocation = React.useRef(location);

  useEffect(() => {
    if (location !== previousLocation.current) {
      setData(null); // Clear data on route change
      window.scrollTo(0, 0); // Scroll to top on route change
      previousLocation.current = location; // Update previous location
    }
  }, [location]);

  return (
    <DataContext.Provider value={{ data, setData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};
