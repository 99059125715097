// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  pendingAction: null,
};

const pendingActionsSlice = createSlice({
  name: "pendingActions",
  initialState,
  reducers: {
    setPendingAction(state, action: PayloadAction<any>) {
      state.pendingAction = action.payload;
    },
    clearPendingAction(state) {
      state.pendingAction = null;
    },
  },
});

export const { setPendingAction, clearPendingAction } =
  pendingActionsSlice.actions;
export default pendingActionsSlice.reducer;
