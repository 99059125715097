import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = import.meta.env.VITE_SCHEDULE_BACKSTAGE_API_BASE_URL;

export const dealerApi = createApi({
  reducerPath: "dealerApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getAllStores: builder.query({
      query: ({ cityName, category }) => {
        return {
          url: `/stores`,
          params: {
            cityName,
            category,
          },
        };
      },
    }),
    getStoreBySlug: builder.query({
      query: ({ slug }) => {
        return {
          url: `/stores/${slug}`,
        };
      },
    }),
  }),
});

export const { useGetAllStoresQuery, useGetStoreBySlugQuery } = dealerApi;
