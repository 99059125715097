import { InfoIcon, RightArrowIcon } from "@/assets/Icons";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
} from "@/components/ui/sheet";
import { useScreenDetector } from "@/hooks/useScreenDetector";
import { cn } from "@/lib/utils";
import { track } from "@/utils/mixpanel/actions";
import {
  DX_BOOKING_CANCELLED,
  DX_PDP_EDIT_DETAILS_BUTTON_CLICKED,
  DX_SELL_OR_RESERVE,
} from "@/utils/mixpanel/DMXEvents/dx_pdp_clicked_events";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { X as CloseIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { getOwnerShipStatus } from "@/common/product";
import { useCancelReservationMutation } from "@/store/services/Dmx/storeVehicles";

interface IProps {
  productDetail: IVehicleInfo;
  className?: string;
  orderStatus?: string;
  setOpenEditVehicleModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchGetVehicleOrderDetail?: () => void;
}

interface ICancelBookingModel {
  isCancelBookingModalOpen: boolean;
  setIsCancelBookingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCancelReservation: () => void;
}
const CancelBookingModel = function (props: ICancelBookingModel) {
  const {
    isCancelBookingModalOpen,
    setIsCancelBookingModalOpen,
    onCancelReservation,
  } = props;
  return (
    <Sheet
      open={isCancelBookingModalOpen}
      onOpenChange={() => {
        setIsCancelBookingModalOpen(false);
      }}
    >
      <SheetContent
        className={cn("h-fit flex flex-col  p-0 px-2 rounded-lg")}
        side={"center"}
      >
        <div className="p-5 text-center">
          Are you sure you want to cancel this booking?
        </div>
        <div className="flex justify-between gap-2 w-full mb-5">
          <Button
            variant="outline"
            className={cn(
              "flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500",
            )}
            onClick={() => setIsCancelBookingModalOpen(false)}
          >
            No
          </Button>
          <Button
            variant="outline"
            className={cn(
              "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
            )}
            onClick={onCancelReservation}
          >
            Yes
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

interface IButtonComponent {
  onClick: () => void;
  title: string;
  description: string;
}

const ButtonComponent = ({ onClick, title, description }: IButtonComponent) => (
  <div
    onClick={onClick}
    className={cn("flex justify-between p-4 items-center", "border-b")}
  >
    <div className="flex flex-col">
      <p>{title}</p>
      <p className="text-xs text-neutral300">{description}</p>
    </div>
    <RightArrowIcon />
  </div>
);

interface ICancelButton {
  setIsCancelBookingModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelButton = ({ setIsCancelBookingModalOpen }: ICancelButton) => (
  <ButtonComponent
    onClick={() => setIsCancelBookingModalOpen(true)}
    title="Cancel Booking"
    description="Cancels the booking for this vehicle"
  />
);

const UpdateVehicleDetail = ({
  productDetail,
  className,
  orderStatus,
  setOpenEditVehicleModal,
  refetchGetVehicleOrderDetail = () => undefined,
}: IProps) => {
  const [, setSearchParams] = useSearchParams();
  const { isDesktop, isMobile } = useScreenDetector();
  const user = useSelector((state: any) => state.user.currentUser);
  const [openSellOrReserve, setOpenSellOrReserve] = useState(false);
  const [isCancelBookingModalOpen, setIsCancelBookingModalOpen] =
    useState<boolean>(false);
  const [cancelReservation] = useCancelReservationMutation();
  const role = localStorage.getItem("role");
  const location = useLocation();
  const navigate = useNavigate();
  const storeDetail = JSON.parse(
    localStorage.getItem("current_store_dmx") || "null",
  );

  // close the sheet if the location changes
  useEffect(() => {
    if (openSellOrReserve) {
      setOpenSellOrReserve(false);
    }
  }, [location]);

  const isVehicleAssured = productDetail?.drivexAssured
    ? "Assured"
    : "Self_Procured";

  const handleTracking = (eventName: string, additionalProperties?: any) => {
    track(eventName, {
      role: role,
      user_id: user?.id,
      vehicle_name: productDetail?.vehicleName,
      vehicle_id: productDetail?.vehicleId,
      purchase_price: productDetail?.purchase_price?.displayValue,
      sale_price: productDetail?.price?.displayValue,
      km_driven: productDetail?.kmDriven?.displayValue,
      registration_number: productDetail?.registrationNumber,
      manufacture_year: productDetail?.manufactureYear,
      ownership: productDetail?.ownerShip,
      store_id: productDetail?.storeId,
      inventory_type: isVehicleAssured,
      store_name: productDetail?.storeName,
      ...additionalProperties,
    });
  };

  const handleEditDetails = () => {
    handleTracking(DX_PDP_EDIT_DETAILS_BUTTON_CLICKED);

    setOpenEditVehicleModal(true);
  };

  const handleMarkAsSold = () => {
    if (orderStatus !== "RESERVED") {
      handleTracking(DX_SELL_OR_RESERVE);
    }

    if (orderStatus === "RESERVED") {
      setOpenSellOrReserve(true);
    } else {
      navigate(`${location.pathname}?type=reserve&step=customer_details`);
    }
  };

  const onCancelReservation = async () => {
    await cancelReservation({
      vehicle_id: productDetail?.vehicleId,
      status: "REJECTED",
    });
    handleTracking(DX_BOOKING_CANCELLED, {
      store_name: storeDetail?.store_name,
      store_id: storeDetail?.store_id,
      store_contact_number: storeDetail?.spoc_number,
      store_location: storeDetail?.city,
    });
    setIsCancelBookingModalOpen(false);
    refetchGetVehicleOrderDetail();
  };

  return (
    <>
      <div
        className={cn(
          "bg-white border-t fixed bottom-0 right-0 left-0 w-full py-2 px-3 flex flex-col gap-2 items-center",
          className,
          isDesktop && "border-none relative py-0",
        )}
      >
        <p className="flex items-center gap-2 text-neutral300 text-xs font-semibold">
          <InfoIcon size={20} /> Sell to complete the sale with Invoice &
          Delivery Note. <br /> Book to hold and get a Booking Receipt.
        </p>
        <div className="flex justify-between gap-2 w-full">
          <Button
            variant="outline"
            className={cn(
              "flex-center gap-2 bg-white text-primaryA2 text-base w-full py-6 hover:bg-primaryA2 hover:text-primaryA2 border border-orange-500",
            )}
            onClick={() => handleEditDetails()}
          >
            Edit Details
          </Button>

          <Button
            variant="outline"
            className={cn(
              "bg-primaryA2 border-none text-white text-base w-full py-6 hover:bg-primaryA2 hover:text-white",
            )}
            onClick={() => handleMarkAsSold()}
          >
            {orderStatus === "RESERVED" ? "Sell/Cancel" : "Sell/Book"}
          </Button>
        </div>
      </div>

      <Sheet
        open={openSellOrReserve}
        onOpenChange={() => {
          if (openSellOrReserve) {
            setOpenSellOrReserve(false);
          }
        }}
      >
        <SheetContent
          className={cn(
            "max-w-full flex flex-col h-fit p-0 px-2 rounded-t-lg",
            isDesktop && "h-dvh",
          )}
          side={isMobile ? "bottom" : "right"}
        >
          <SheetHeader className="bg-white border-neutral50 border-b absolute top-0 right-0 left-0 w-full py-4 px-3 z-10 max-w-7xl mx-auto rounded-t-lg">
            <div className="flex justify-between">
              <p>Change Status</p>
              <SheetClose onClick={() => setOpenSellOrReserve(false)}>
                <CloseIcon />
              </SheetClose>
            </div>

            <p className="text-sm text-left">
              {productDetail?.registrationNumber} •{" "}
              {productDetail?.kmDriven?.displayValue}{" "}
              {productDetail?.kmDriven?.unit}s •{" "}
              {getOwnerShipStatus(productDetail?.ownerShip)}•{" "}
              {productDetail?.manufactureYear}
            </p>
          </SheetHeader>

          <div className="h-fit w-full mt-[85px] flex flex-col">
            <div
              onClick={() =>
                setSearchParams(
                  { type: "sell", step: "customer_details" },
                  {
                    preventScrollReset: true,
                    replace: true,
                  },
                )
              }
              className={cn("flex justify-between p-4 items-center")}
            >
              <div className="flex flex-col">
                <p>Sell Vehicle</p>
                <p className="text-xs text-neutral300">
                  Generates Invoice and Delivery note
                </p>
              </div>
              <RightArrowIcon />
            </div>
            {orderStatus === "RESERVED" && (
              <CancelButton
                setIsCancelBookingModalOpen={setIsCancelBookingModalOpen}
              />
            )}
          </div>
        </SheetContent>
      </Sheet>
      <CancelBookingModel
        isCancelBookingModalOpen={isCancelBookingModalOpen}
        setIsCancelBookingModalOpen={setIsCancelBookingModalOpen}
        onCancelReservation={onCancelReservation}
      />
    </>
  );
};

export default UpdateVehicleDetail;
