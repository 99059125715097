import { formatUTCDate } from "@/utils/date";
import { useNavigate } from "react-router-dom";
import Image from "../ui/image";
import CardComponent from "../CardComponent";

function BlogCard({ blog }: { blog: IBlog }) {
  const navigate = useNavigate();

  const handleBlogCardClick = () => {
    navigate(`/blogs/${blog?.attributes?.slug}`);
  };

  return (
    <CardComponent onClick={handleBlogCardClick}>
      <Image
        transform="f-webp"
        src={
          blog?.attributes?.cover?.data?.attributes?.url ??
          "https://ik.imagekit.io/drivex/og_image_productlist.jpg"
        }
        alt={`${blog?.attributes?.title} cover`}
        className="w-full h-[175px] object-cover object-top"
        width={"100%"}
        height={175}
      />

      <div className="flex flex-col p-4 gap-y-1.5">
        <span className="text-accent text-sm font-semibold">
          {blog?.attributes?.collection?.data?.attributes?.name}
        </span>

        <h6 className="text-neutral300 text-sm">
          {formatUTCDate(blog?.attributes?.publishedAt)} &bull; 2 mins read
        </h6>

        <h5 className="text-base text-neutral900 font-semibold">
          {blog?.attributes?.title}
        </h5>

        <div className="flex flex-wrap">
          {blog?.attributes?.keywords?.map((keyword: string, index: number) => (
            <div
              key={index}
              className="bg-neutral30 text-neutral300 text-sm font-semibold rounded-md px-2 py-1 mr-2.5 my-1"
            >
              {keyword}
            </div>
          ))}
        </div>
      </div>
    </CardComponent>
  );
}

export default BlogCard;
