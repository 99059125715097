import { Button } from "@/components/ui/button";
import { premiumRenewedData } from "./data";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useCurrentCity } from "@/hooks/useCurrentCity";
import VideoPopup from "@/components/VideoPopup";
import Image from "@/components/ui/image";
import { IMAGE_KIT_BASE_URL } from "@/utils/constants";
import { useScreenDetector } from "@/hooks/useScreenDetector";

const PremiumRenewed = () => {
  const navigate = useNavigate();
  const { city } = useCurrentCity();
  const { isDesktop } = useScreenDetector();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const video = "https://www.youtube.com/embed/QByjJU7luCw?autoplay=1&rel=0";
  const PremiumRenewedImage = `${IMAGE_KIT_BASE_URL}/ik_homepage/PremiumRenewed.webp`;
  const PremiumRenewedImageWeb = `${IMAGE_KIT_BASE_URL}/ik_homepage/PremiumRenewedWeb.webp`;
  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-10 max-w-screen-xl mx-auto w-full p-4">
      <p className="md:hidden text-xl text-white font-semibold">
        Premium. <span className="text-primaryA2">Renewed.</span>
      </p>

      <div className="flex flex-col gap-10">
        {!isDesktop && (
          <Image
            onClick={() => setIsModalOpen(true)}
            className="md:hidden rounded-lg"
            src={PremiumRenewedImage}
            alt="Premium Renewed"
            transform="w-330,h-180"
            width="100%"
            height="100%"
          />
        )}
        <p className="hidden md:block text-3xl text-white font-semibold">
          Premium. <span className="text-primaryA2">Renewed.</span>
        </p>
        <div className="flex flex-col gap-4 justify-center">
          {premiumRenewedData.map((item, index) => (
            <div className="flex gap-5 items-center" key={index}>
              <span className="w-[33px] h-[40px]">{item.icon}</span>
              <div className="flex flex-col gap-1">
                <p className="font-semibold text-white text-base">
                  {item.heading}
                </p>
                <p className="text-xs text-neutral300">{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        <Button
          onClick={() => navigate(`/${city}/buy-two-wheelers`)}
          variant="outline"
          className="bg-primaryA2 border-none text-white text-base font-semibold md:max-w-52 w-full mx-auto"
        >
          Browse Bikes
        </Button>
      </div>

      {isDesktop && (
        <Image
          onClick={() => setIsModalOpen(true)}
          className="hidden md:block w-[60%] object-cover rounded-lg"
          transform="q-10,w-740,h-440"
          src={PremiumRenewedImageWeb}
          alt="Premium Renewed"
          width="100%"
          height="100%"
        />
      )}

      <VideoPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        videoSource={video}
      />
    </div>
  );
};

export default PremiumRenewed;
