import { Skeleton } from "@/components/ui/skeleton";

const BlogSkeletonLoader = () => {
  return (
    <div className="flex flex-col gap-3 bg-white rounded-xl shadow-sm overflow-hidden">
      <Skeleton className="h-44 w-full shadow-sm bg-neutral-200" />
      <div className="flex flex-col gap-4 p-4">
        <Skeleton className="h-4 w-12 shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-32 shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-56 shadow-sm bg-neutral-200" />
        <Skeleton className="h-4 w-20 shadow-sm bg-neutral-200" />
      </div>
    </div>
  );
};

export default BlogSkeletonLoader;
