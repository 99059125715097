import { useEffect, useState } from "react";

interface IProps {
  texts: string[];
  interval: number;
  label: string;
}

const AnimatedLabel = ({ texts, interval, label }: IProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => clearInterval(intervalId);
  }, []);
  return (
    <label
      htmlFor="inputField"
      className={`absolute font-semibold top-1/2 left-[40px] transform -translate-y-1/2 text-sm text-neutral300 leading-6 overflow-hidden`}
    >
      {label}&nbsp;
      <span className="animate-placeholderAnimation relative px-[1px]">
        &apos;{texts[currentIndex]}&apos;
      </span>
    </label>
  );
};

export default AnimatedLabel;
