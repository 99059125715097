interface availabilityType {
  amount: string;
  type: string;
}

export const availabilityData: availabilityType[] = [
  { amount: "40k+", type: "Vehicles Sold" },
  { amount: "50+", type: "Stores" },
  { amount: "1.4L+", type: "Happy Customers" },
];
