import { useEffect, useState } from "react";
import Navbar from "@/components/Navbar/index.tsx";
import ShopByBrand from "./ShopBybrand.tsx";
import BuySellExchange from "./BuySellExchange.tsx";
import ShopByBudget from "./ShopByBudget.tsx";
import EmiPlan from "./EmiPlan.tsx";
import Testimonials from "@/components/Testimonials/Testimonials.tsx";
import SellNow from "../../components/SellNow/index.tsx";
import FAQs from "../../components/FAQs/index.tsx";
import Footer from "../../components/Footer/index.tsx";
import BottomNavigation from "@/components/BottomNavigation/index.tsx";
import { HomeTabs } from "@/components/BottomNavigation/data.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { homeFAQsData } from "@/components/FAQs/data.ts";
import { generateMetaInfo } from "../../../hyperion/seoData";
import SEO from "@/components/SEO/index.tsx";
import ProcessSteps from "@/components/ProcessSteps/index.tsx";
import { BuyCTASteps, QualityServiceSteps } from "./data.tsx";
import { useScreenDetector } from "@/hooks/useScreenDetector.ts";
import {
  HOME_BUY_CLICKED_EVENT,
  HOME_SERVICE_CLICKED_EVENT,
} from "@/utils/mixpanel/Events/home_clicked_events.ts";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { getPageSource } from "@/utils/mixpanel/pageSource.tsx";
import { track } from "@/utils/mixpanel/actions.ts";
import { useCurrentCity } from "@/hooks/useCurrentCity.tsx";
import Stories from "./Stories/index.tsx";
import { cn } from "@/lib/utils.ts";
import PremiumRenewed from "./PremiumRenewed.tsx";
import { useGetAllHomeDataQuery } from "@/store/services/home.ts";
import { IMAGE_KIT_BASE_URL, IS_CLIENT_SIDE } from "@/utils/constants.ts";
import { useSelector } from "react-redux";
import { useDataContext } from "@/context/useDataContext.tsx";

interface ComponentMapping {
  BuySellExchange: JSX.Element;
  ShopByBrand: JSX.Element;
  ShopByBudget: JSX.Element;
  EmiPlan: JSX.Element;
  BuyNow: JSX.Element;
  SellNow: JSX.Element;
  ServicesNow: JSX.Element;
  Testimonials: JSX.Element;
  FAQs: JSX.Element;
}

// adding "/ik-gif-video.mp4" to the url to convert the gif to .mp4 video
const BuyCTAImage = `${IMAGE_KIT_BASE_URL}/ik_homepage/BuyCTA.gif/ik-gif-video.mp4?tr=w-1000,h-700`;
const QualityService = `${IMAGE_KIT_BASE_URL}/ik_homepage/QualityService.gif/ik-gif-video.mp4?tr=w-1000,h-700`;

function GetComponentByName(
  name: keyof ComponentMapping,
  handleBuyNowCTA: () => void,
  handleBookServiceClick: () => void,
  isDesktop: boolean,
  isMobile: boolean,
  doesStoriesExist: boolean,
  storiesData: IStories,
  isFetchingStories: boolean,
  heroSectionData: IHeroSectionWithType,
  testimonialsData: ITestimonialsWithType,
  hasReturningCustomerData: number,
  handleFAQClick: (heading: string) => void,
): JSX.Element {
  const componentMapping = {
    BuySellExchange: (
      <div
        data-test-id="buySellExchange"
        data-color="bg-neutral900"
        className={cn(
          `content-div flex flex-col md:px-0 md:pb-0 bg-neutral900 md:pt-20 pt-[128px]`,
          isMobile &&
            hasReturningCustomerData > 1 &&
            "bg-returnCustomer bg-cover bg-bottom bg-no-repeat",
        )}
      >
        {doesStoriesExist && (
          <div
            data-color="bg-neutral900"
            className={cn(
              "content-div md:justify-center px-4 md:px-0 md:pb-0 lg:pt-28 pb-0 md:hidden",
              !doesStoriesExist ? "hidden flex-none" : "flex flex-start",
            )}
          >
            <Stories data={storiesData} />
          </div>
        )}
        <BuySellExchange data={heroSectionData} />
      </div>
    ),
    Stories: (
      <div
        data-color="bg-white"
        className={cn(
          "content-div md:justify-center bg-white p-4 my-5 hidden md:flex",
          !doesStoriesExist && "hidden flex-none",
        )}
      >
        <Stories data={storiesData} />
      </div>
    ),
    ShopByBrand: (
      <div
        data-test-id="shopBybrand"
        data-color="bg-white"
        className="content-div bg-white p-4 my-5"
      >
        <ShopByBrand />
      </div>
    ),
    PremiumRenewed: (
      <div
        data-test-id="premiumRenewed"
        data-color="bg-neutral900"
        className="content-div bg-neutral900 p-4 my-5"
      >
        <PremiumRenewed />
      </div>
    ),
    ShopByBudget: (
      <div
        data-test-id="shopByBudget"
        data-color="bg-white"
        className="content-div bg-white flex flex-col gap-6 p-4 my-5"
      >
        <ShopByBudget />
      </div>
    ),
    EmiPlan: (
      <div
        data-test-id="emiPlan"
        data-color="bg-white"
        className="content-div bg-white p-4 my-5"
      >
        <EmiPlan />
      </div>
    ),
    BuyNow: (
      <div
        data-test-id="buyCTA"
        data-color="bg-neutral900"
        className="content-div flex flex-col bg-neutral900"
      >
        <ProcessSteps
          data_test_id={"home-buy-now-cta"}
          stepsData={BuyCTASteps}
          media={BuyCTAImage}
          heading={
            <>
              {" "}
              Buy With Ease. <br />{" "}
              <span className="text-primaryA2">Ride with Peace.</span>
            </>
          }
          buttonText="Buy Now"
          handleCTAClick={handleBuyNowCTA}
        />
      </div>
    ),
    SellNow: (
      <div
        data-test-id="sellNow"
        data-color="bg-white"
        className="content-div flex flex-col gap-4 sm:p-3 mx-4 my-5"
      >
        <SellNow />
      </div>
    ),
    ServicesNow: (
      <div
        data-test-id="qualityService"
        data-color="bg-neutral900"
        className="content-div flex flex-col bg-neutral900"
      >
        <ProcessSteps
          data_test_id={"book-a-service-now"}
          stepsData={QualityServiceSteps}
          media={QualityService}
          heading={
            <>
              {" "}
              <span className="text-primaryA2">Quality Service</span> <br />
              at Your Convenience
            </>
          }
          type={"grid"}
          buttonText="Book a Service Now"
          handleCTAClick={handleBookServiceClick}
        />
      </div>
    ),
    Testimonials: (
      <div
        data-test-id="testimonials"
        data-color="bg-white"
        className="content-div bg-white flex flex-col gap-6 p-4 my-5"
      >
        <Testimonials
          data={testimonialsData}
          heading={
            <>
              Here’s why <span className="text-primaryA2">1L+ customers</span>{" "}
              <br className="sm:hidden" /> trust us
            </>
          }
        />
      </div>
    ),
    FAQs: (
      <div
        data-test-id="faqs"
        data-color="bg-white"
        className="content-div bg-white flex flex-col gap-6 p-4 my-5"
      >
        <FAQs content={homeFAQsData} handleFAQClick={handleFAQClick} />
      </div>
    ),
  };
  return componentMapping[name];
}

function Home() {
  const { data: ssrData } = useDataContext();
  const [navBackgroundColor, setNavBackgroundColor] =
    useState<string>("bg-neutral900");
  const { homeTabsData } = HomeTabs();
  const { isDesktop, isMobile } = useScreenDetector();
  const { city } = useCurrentCity();
  const user = useSelector((state: any) => state.user.currentUser);
  const pageSource = getPageSource("/");
  const location = useLocation();
  const navigate = useNavigate();
  const metaData = generateMetaInfo({ path: location.pathname });
  const returningCustomerData =
    (IS_CLIENT_SIDE &&
      JSON.parse(localStorage.getItem("returningCustomerData") as string)) ||
    [];
  const hasReturningCustomerData =
    returningCustomerData && returningCustomerData?.data?.length;

  const { data, isFetching } = useGetAllHomeDataQuery({ city });
  const homeData = data?.data || ssrData;
  const storiesData = homeData?.cards?.find(
    (card: { type: string }) => card.type === "Stories",
  );
  const heroSectionData = homeData?.cards?.find(
    (card: { type: string }) => card.type === "BuySellExchange",
  );
  const testimonialsData = homeData?.cards?.find(
    (card: { type: string }) => card.type === "Testimonials",
  );

  const doesStoriesExist =
    !isFetching && (!storiesData || storiesData?.data?.length <= 0)
      ? false
      : true;

  const defaultOrder = {
    order: [
      "BuySellExchange",
      "Stories",
      "ShopByBrand",
      "ShopByBudget",
      "EmiPlan",
      "BuyNow",
      "SellNow",
      "ServicesNow",
      "Testimonials",
      "FAQs",
      "Footer",
    ],
  };

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "Page View");

    const divs = document.querySelectorAll(".content-div");
    const navbar = document.querySelector(".navbar");

    const handleScroll = () => {
      // Todo: Find a way to optimize this wherein we prevent a for loop on every scroll event if possible.
      divs.forEach((div) => {
        const rect = div.getBoundingClientRect();
        const navbarBottom = navbar && navbar.getBoundingClientRect().bottom;

        // Check if the bottom of the navbar touches the top of the div
        if (
          navbarBottom &&
          rect.top <= navbarBottom &&
          rect.bottom >= navbarBottom
        ) {
          const backgroundColor: string | null = div.getAttribute("data-color");
          setNavBackgroundColor(backgroundColor ?? "");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBuyNowCTA = () => {
    navigate(`/${city}/buy-two-wheelers`);
    track(HOME_BUY_CLICKED_EVENT, {
      page: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
  };

  const handleBookServiceClick = () => {
    track(HOME_SERVICE_CLICKED_EVENT, {
      page: pageSource,
      name: user?.userName,
      phone_number: user?.phoneNumber,
    });
    navigate("/service");
  };

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: pageSource,
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  const handleBottomNavigationClick = (url: string) => {
    navigate(url);
  };

  const renderComponent = (component: string, index: number) => (
    <div key={index}>
      {GetComponentByName(
        component as keyof ComponentMapping,
        handleBuyNowCTA,
        handleBookServiceClick,
        isDesktop,
        isMobile,
        doesStoriesExist,
        storiesData,
        isFetching,
        heroSectionData,
        testimonialsData,
        hasReturningCustomerData,
        handleFAQClick,
      )}
    </div>
  );

  return (
    <>
      <SEO metaData={metaData} />
      <div className="flex flex-col relative">
        <Navbar backgroundColor={navBackgroundColor} />

        {homeData?.ordered_list?.length > 0
          ? homeData.ordered_list.map(renderComponent)
          : defaultOrder.order.map(renderComponent)}

        <div
          data-test-id="footer"
          data-color="bg-neutral900"
          className="content-div bg-neutral900 flex flex-col gap-6 py-4"
        >
          <Footer />
        </div>

        <BottomNavigation
          handleClick={handleBottomNavigationClick}
          tabs={homeTabsData}
          backgroundColor={navBackgroundColor}
        />
      </div>
    </>
  );
}

export default Home;
