import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import FAQs from "@/components/FAQs";
import { sellFAQsData } from "@/components/FAQs/data";
import { useEffect } from "react";
import { meta_conversion_api } from "@/utils/metaPixels";
import { useSelector } from "react-redux";
import HeroSection from "./HeroSection";
import { FAQ_CLICKED } from "@/utils/mixpanel/Events/faq_events";
import { track } from "@/utils/mixpanel/actions";
import { useCurrentCity } from "@/hooks/useCurrentCity";

const EstimatedPrice = () => {
  const user = useSelector((state: any) => state.user.currentUser);
  const { city } = useCurrentCity();

  useEffect(() => {
    meta_conversion_api({
      eventName: "ViewContent",
      eventId: new Date().getTime(),
      phoneNumber: user?.phoneNumber,
    });

    // eslint-disable-next-line
    // @ts-ignore
    window.fbq?.("track", "ViewContent");
  }, []);

  const handleFAQClick = (heading: string) => {
    track(FAQ_CLICKED, {
      page: "Estimated Price Page",
      city: city,
      faq_heading: heading,
      ...(user && { name: user?.userName, phone: user?.phoneNumber }),
    });
  };

  return (
    <>
      <Navbar
        backgroundColor={"bg-neutral900"}
        showLocation={false}
        showSearchbar={false}
      />

      {/* Hero Section */}
      <div className="flex flex-col gap-8 px-4 py-4 bg-neutral900 pt-[90px]">
        <HeroSection />
      </div>

      {/* FAQs */}
      <div className="flex flex-col gap-4 mt-4 p-4 pb-12">
        <FAQs content={sellFAQsData} handleFAQClick={handleFAQClick} />
      </div>

      {/* Footer */}
      <div className="flex flex-col gap-6 bg-neutral900 py-4">
        <Footer />
      </div>
    </>
  );
};

export default EstimatedPrice;
