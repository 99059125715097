import { LeftArrowIcon, SearchIcon } from "@/assets/Icons";
import { useUpdateVehicleDetailsMutation } from "@/store/services/Dmx/storeVehicles";
import { useSelector } from "react-redux";
import IncomingVehicleCard from "./IncomingVehicleCard";
import InputWithIcon from "@/components/InputWithIcon";
import AddVehicleDetails from "./AddVehicleDetails";
import { useEffect, useState } from "react";
import {
  INCOMING_VEHICLE_ADDED_TO_FLOOR_STOCK,
  INCOMING_VEHICLE_RECEIVED_BUTTON_CLICKED,
} from "@/utils/mixpanel/DMXEvents/dx_incoming_vehicle_events";
import { track } from "@/utils/mixpanel/actions";
import { calculatePercentageDifference } from "@/utils/calculatePercentageDifference";
import { useToast } from "@/components/ui/use-toast";
import { IVehicleOrder } from "..";
import { useLocation, useNavigate } from "react-router-dom";
import { pluralize } from "@/utils/pluralize";

const IncomingVehiclesListing = ({
  incomingVehicleData,
  refetchIncomingVehicles,
  refetchVehiclesData,
  onClose,
}: {
  incomingVehicleData: IVehicleOrder[];
  refetchIncomingVehicles: () => void;
  refetchVehiclesData: () => void;
  onClose: () => void;
}) => {
  const { toast } = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const clickedVehicle = location?.state;
  const role = localStorage?.getItem("role");
  const user = useSelector((state: any) => state.user.currentUser);
  const [submitVehicleDetails] = useUpdateVehicleDetailsMutation();
  const [searchValue, setSearchValue] = useState("");
  const [vehicleData, setVehicleData] = useState(incomingVehicleData || []);
  const [vehicleReceivedData, setVehicleReceivedData] = useState<any>([]);
  const [isVehicleDetailsDialogOpen, setIsVehicleDetailsDialogOpen] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<{
    [key: string]: boolean | string | { [key: string]: boolean | string };
  }>({});
  const [saveVehicleDetails, setSaveVehicleDetails] = useState({
    profit: "",
    kmDriven: "",
    salePrice: "",
    ownership: NaN,
    purchase_price: "",
    manufactureYear: "",
  });

  useEffect(() => {
    if (clickedVehicle) {
      setVehicleReceivedData(clickedVehicle);
      setIsVehicleDetailsDialogOpen(true);
    }
  }, []);

  useEffect(() => {
    setSaveVehicleDetails({
      ...saveVehicleDetails,
      kmDriven: vehicleReceivedData?.kmDriven?.displayValue || "",
      manufactureYear: vehicleReceivedData?.manufactureYear || "",
      purchase_price: vehicleReceivedData?.purchase_price?.displayValue || "",
      salePrice: vehicleReceivedData?.price?.displayValue || "",
      ownership: vehicleReceivedData?.ownerShip || NaN,
      profit:
        profitCalculation(
          vehicleReceivedData?.purchase_price?.displayValue || "",
          vehicleReceivedData?.price?.displayValue || "",
        ) || "",
    });
  }, [vehicleReceivedData]);

  const handleAddToFloorClick = (clickedItem: IVehicleOrder) => {
    track(INCOMING_VEHICLE_RECEIVED_BUTTON_CLICKED, {
      role: role,
      user_id: user?.id,
      vehicle_id: clickedItem?.vehicleId,
      vehicle_name: clickedItem?.vehicleName,
      purchase_price: clickedItem?.purchase_price?.displayValue,
      sales_price: clickedItem?.price?.displayValue,
      kms_driven: clickedItem?.kmDriven?.displayValue,
      registration_number: clickedItem?.registrationNumber,
      ownership: clickedItem?.ownerShip,
      year_of_manufacturing: clickedItem?.manufactureYear,
      store_id: clickedItem?.storeId,
      store_name: clickedItem?.storeName,
      store_type: clickedItem?.store_type,
    });

    setVehicleReceivedData(clickedItem);
    setIsVehicleDetailsDialogOpen(true);
    navigate(`?list=incoming-vehicles&id=${clickedItem?.vehicleId}`);
  };

  const handleOwnerClick = (index: number) => {
    setSaveVehicleDetails({ ...saveVehicleDetails, ownership: index + 1 });
  };

  const profitCalculation = (purchasePrice: string, salePrice: string) => {
    if (
      (purchasePrice || salePrice) === "" ||
      vehicleReceivedData?.store_type === "coco"
    )
      return;

    const profit = calculatePercentageDifference(
      parseInt(purchasePrice?.replaceAll(",", "")),
      parseInt(salePrice?.replaceAll(",", "")),
    );

    if (profit === "error") {
      setErrors({ ...errors, salePrice: "Invalid Sale Price" });
      return;
    }

    setErrors({ ...errors, salePrice: false });
    return profit;
  };

  const handleSaveVehicleDetails = async () => {
    try {
      const response = await submitVehicleDetails({
        vehicle_id: vehicleReceivedData?.vehicleId,
        data: {
          store_id: vehicleReceivedData?.storeId,
          store_type: vehicleReceivedData?.store_type,
          kms_driven: parseInt(saveVehicleDetails?.kmDriven.replace(/,/g, "")),
          year_of_manufacturing: parseInt(saveVehicleDetails?.manufactureYear),
          ownership: saveVehicleDetails?.ownership,
          purchase_price: parseInt(
            saveVehicleDetails?.purchase_price.replace(/,/g, ""),
          ),
          sales_price: parseInt(
            saveVehicleDetails?.salePrice.replace(/,/g, ""),
          ),
        },
      });

      if ("error" in response) {
        throw new Error(JSON.stringify(response.error));
      }

      track(INCOMING_VEHICLE_ADDED_TO_FLOOR_STOCK, {
        role: role,
        user_id: user?.id,
        vehicle_name: vehicleReceivedData?.vehicleName,
        vehicle_id: vehicleReceivedData?.vehicleId,
        purchase_price: saveVehicleDetails?.purchase_price,
        sales_price: saveVehicleDetails?.salePrice,
        kms_driven: saveVehicleDetails?.kmDriven,
        registration_number: vehicleReceivedData?.registrationNumber,
        ownership: saveVehicleDetails?.ownership,
        year_of_manufacturing: saveVehicleDetails?.manufactureYear,
        store_id: vehicleReceivedData?.storeId,
        store_type: vehicleReceivedData?.store_type,
        store_name: vehicleReceivedData?.storeName,
      });

      refetchIncomingVehicles();
      refetchVehiclesData();
      navigate("/dealer/vehicles", { replace: true });

      toast({
        title: `${vehicleReceivedData?.vehicleName} listed on the website `,
        variant: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error submitting vehicle details:", error);
      toast({
        title: "Something went wrong",
        variant: "destructive",
      });
    }
  };

  const updateVehicleDetails = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.replace(/\D/g, "");
    if (errors[e.target.name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [e.target.name]: false }));
    }

    const kmDriven =
      (e.target.name === "kmDriven" && e.target.value?.replaceAll(/,/g, "")) ||
      "";

    const lastRecordedKmDriven =
      vehicleReceivedData?.kmDriven?.value !== null
        ? vehicleReceivedData?.kmDriven?.value
        : undefined;

    if (parseInt(kmDriven) < lastRecordedKmDriven) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven: "KMs driven cannot be less than the last recorded value",
      }));
    }

    if (parseInt(kmDriven) > lastRecordedKmDriven + 100) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        kmDriven:
          "KMs driven cannot exceed 100 kilometers more than the last recorded value.",
      }));
    }

    const purchasePrice =
      e.target.name === "purchase_price"
        ? e.target.value
        : saveVehicleDetails.purchase_price;
    const salePrice =
      e.target.name === "salePrice"
        ? e.target.value
        : saveVehicleDetails.salePrice;

    setSaveVehicleDetails({
      ...saveVehicleDetails,
      [e.target.name]: e.target.value,
      profit: profitCalculation(purchasePrice, salePrice) || "",
    });
  };

  const handleSelectValueChange = (value: string) => {
    setSaveVehicleDetails({
      ...saveVehicleDetails,
      manufactureYear: value,
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchValue(value);

    const filteredData = incomingVehicleData?.filter(
      (item: any) =>
        // Filter By Vehicle Name
        item?.vehicleName
          ?.trim()
          ?.toLowerCase()
          .includes(value?.toLowerCase()?.trim()) ||
        // Filter By Registration Number
        item?.registrationNumber
          ?.trim()
          ?.toLowerCase()
          ?.includes(value?.toLowerCase()?.trim()),
    );

    setVehicleData(filteredData);
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      setVehicleData(incomingVehicleData);
    }
  };

  if (isVehicleDetailsDialogOpen && saveVehicleDetails) {
    return (
      <AddVehicleDetails
        vehicleReceivedData={vehicleReceivedData}
        saveVehicleDetails={saveVehicleDetails}
        setIsVehicleDetailsDialogOpen={setIsVehicleDetailsDialogOpen}
        handleSaveVehicleDetails={handleSaveVehicleDetails}
        handleOwnerClick={handleOwnerClick}
        updateVehicleDetails={updateVehicleDetails}
        errors={errors}
        handleSelectValueChange={handleSelectValueChange}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4">
      <div onClick={onClose} className="flex items-center gap-3 cursor-pointer">
        <LeftArrowIcon
          size={20}
          oneStopColor="#0A0A0A"
          twoStopColor="#0A0A0A"
        />
        <p className="text-base font-medium">Delivered Vehicles</p>
      </div>

      <div className="border-b border-neutral50 pb-4">
        <InputWithIcon
          parentClassName="flex flex-row-reverse pl-4"
          className="py-6 bg-neutral30"
          type="text"
          name="registrationNumber"
          handleChange={(e) => handleSearchChange(e)}
          onKeyDown={(e) => handleSearchKeyDown(e)}
          value={searchValue}
          placeholder="Search delivered vehicles"
          icon={<SearchIcon size={20} />}
        />
      </div>

      {vehicleData?.length > 0 ? (
        <p className="text-base font-medium">
          {incomingVehicleData?.length}{" "}
          {pluralize(incomingVehicleData?.length, "Vehicle")} Delivered
        </p>
      ) : (
        <p className="text-base font-medium">
          Your Search did not match any results
        </p>
      )}

      <div className="flex flex-col gap-4 overflow-y-auto">
        {vehicleData?.map((data: any, index: number) => (
          <IncomingVehicleCard
            key={index}
            data={data}
            handleAddToFloorClick={handleAddToFloorClick}
          />
        ))}
      </div>
    </div>
  );
};

export default IncomingVehiclesListing;
