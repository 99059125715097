import { cn } from "@/lib/utils";
import { Skeleton } from "../../ui/skeleton";

interface Iprops {
  className?: string;
}

const Chips = ({ className }: Iprops) => {
  return (
    <Skeleton
      className={cn(
        "h-[30px] w-[200px] rounded-xl shadow-sm bg-neutral-200",
        className,
      )}
    />
  );
};

export default Chips;
